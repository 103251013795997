<template>
    <li class="folder-item__container">
        <h3 :title="treeData.label" class="folder-item" ref="treeItem" @click="treeItemClick($event)" :class="
        [{'active' : isSelected}, {'folder-item--isFolder':isFolder}]">
            <i v-if="isFolder" @click="toggle" class="iconfont folder-item__container-toggle" :class="[open ? 'icon-folder-open': 'icon-folder-close']"></i>
            <div class="folder-item__label">
                <span class="folder-item__name">{{treeData.label}}</span>
                <span class="folder-item__operation" v-if="treeData.id!='internalMailList'">
                    <span @click.stop>
                        <el-dropdown menu-align="start" @command="handleCommand">
                            <span class="el-dropdown-link text-hover">
                                <i class="folder-item__operation-more iconfont icon-kehubankuaiyoushangfang-gengduo"></i>
                            </span>
                        </el-dropdown>
                    </span>
                </span>

            </div>
        </h3>
        <ul class="folder-tree" v-show="open" v-if="isFolder">
            <tree-menu v-for="(item,index) in treeData.children" :key="item.id" :moduleCode="moduleCode" :foldersList="foldersList" :treeData="item" :RowIndex="index" @getTabData="getTabData" @itemClick="handleItemClick"></tree-menu>
        </ul>
    </li>
</template>
<script>
/**
 * 描述：邮件部分
 * 作者：向士健(CSS) / ***(API)
 * 时间：2017/11/11
 */
export default {
    name: 'treeMenu',
    props: [
        'foldersList',
        'treeData',
        'RowIndex',
        'moduleCode'
    ],
    data() {
        return {
            open: true,
            internal: false,
            isSelectedBoxId: false,
            clickId: ''
        }
    },
    computed: {
        isFolder() {
            return this.treeData.children && this.treeData.children.length
        }
    },
    updated() {
    },
    methods: {
        getTabData() {
            this.$emit('getTabData')
        },

        handleItemClick(obj) {
            this.$emit('itemClick', obj)
        },
        treeItemClick(event) {
            let { id, label } = this.treeData
            let obj = {
                id: id.toString(),
                target: 'folder'
            }
            this.$emit('itemClick', { target: event.currentTarget, name: label, obj })
        },
        // 展开子菜单
        toggle() {
            if (this.isFolder) {
                this.open = !this.open
            }
        },
        UploadAttachment(treeData) {
            this.$emit('upload', treeData)
        }
    }
}
</script>
<style lang="less" scoped>
@import "./en.less";
@import "./zh-cn.less";
</style>

<template>
<div>
    <div v-for="(item,index) in itemData.group" :key="index">
      <div class="listBox">
          <component :type="type"  :dataId="'control'+index" :nameId="item.fieldName" :optCode="optCode" :moduleCode="moduleCode" :key="index" ref="control" v-bind:is="'control'+item.controlTypeId" :itemData="item" :checkRepeat="true"></component>
      </div>
    </div>
</div>
</template>

<script>
import Controls from '@/page/Client/CustPublic/Controls/index.js'
export default {
  name: '',
  props: {
    itemData: {// 赋值给控件的数据
      type: Object,
      default: function () {
        return {}
      }
    },
    type: {
        type: String,
        default: 'otNew'
    },
    moduleCode: {
        type: String,
        default: ''
    },
    optCode: {
        type: String,
        default: 'otNew'
    }
  },
  data () {
    return {
      ruleForm: {
        input: ''
      },
      rules: {
        input: []
      },
      isUpdata: false
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    updates () {
    },
    submit() {
      return {}
    }
  },
  components: Object.assign({}, Controls)
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.list{
    .min-height(45px);
    .line-height(45px);
    border-bottom: 1px solid #EAEDEF;
}
.list:last-child{
  border: 0;
}
</style>

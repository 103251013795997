var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addVue"},[_vm._l((_vm.editSet),function(item,index){return [(_vm.filterCompenent(item))?_c('div',{key:index,staticClass:"list",class:{ displayHidden: item.isDisplayHidden }},[(item.fieldGroup == 0)?[(item.controlTypeId == 0 || item.controlTypeId == 14)?[_c('p',{key:index,staticClass:"contactTitle"},[_c('span',[_vm._v(_vm._s(item.cnFieldCaption))]),(item.cnFieldCaption === '最终报价')?_c('span',{staticClass:"contactTitle-warn"},[_vm._v("根据新品开发原则：新品订单成交净价须在70折以上！")]):_vm._e()])]:(
                        item.controlTypeId != 84 &&
                        item.controlTypeId != 127
                    )?[_c('div',{staticClass:"listBox",class:{ noPaddingLeft: _vm.isNewFollow && _vm.moduleCode == 'BF004' && item.controlTypeId == 46 && item.fieldName == 'trackContent', listBoxV1: _vm.isNewFollow && _vm.moduleCode == 'BF004'}},[_c('control' + item.controlTypeId,{key:index,ref:"control",refInFor:true,tag:"component",staticClass:"listBox-component",attrs:{"isNewFollow":_vm.isNewFollow,"sysBoxValue":_vm.sysBoxValue,"currency":_vm.currency,"AONIcustSort":_vm.AONIcustSort,"type":_vm.type,"cusBoxValue":_vm.cusBoxValue,"dataId":'control' + index,"nameId":item.fieldName,"optCode":_vm.optCode,"moduleCode":_vm.moduleCode,"itemData":item},on:{"relyOn":_vm.relyOn,"bindContact":_vm.bindContact,"returnFieldList":_vm.returnFieldList,"input":function($event){return _vm.fieldChange(item.fieldName, arguments, item)},"changeLoading":_vm.changeLoading}})],1)]:_vm._e()]:[_c('div',{staticClass:"listBox",class:{
                        noPaddingLeft:
                            _vm.isNewFollow &&
                            _vm.moduleCode == 'BF004' &&
                            item.fieldGroup == 3,
                        listBoxV1: _vm.isNewFollow && _vm.moduleCode == 'BF004',
                    }},[_c('group' + item.fieldGroup,{key:index,ref:"controlGroup",refInFor:true,tag:"component",attrs:{"isNewFollow":_vm.isNewFollow,"sysBoxValue":_vm.sysBoxValue,"optCode":_vm.optCode,"type":_vm.type,"moduleCode":_vm.moduleCode,"dataId":'control' + index,"cusBoxValue":_vm.cusBoxValue,"itemData":item}})],1)]],2):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }
import baseComponent from './baseComponent'
import titleMixin from '@/mixin/title'
export default {
    name: 'DetailRight',
    mixins: [baseComponent],
    extends: titleMixin,
    props: {
        boxId: {
            type: [Number, String],
            default: ''
        },
        isTopList: {
            type: Boolean,
            default: false
        },
        isUndistributed: {
            type: Boolean,
            default: false
        }
    },
    title: '邮件详情',
    mounted() {
        this.pageInit('ordinary')
    },
    methods: {
        pageInit(type) {
            if (type == 'ordinary') {
                // let { mId } = this.$route.query
                let { index, listALL } = this.$route.params
                if (index !== undefined && listALL !== undefined) {
                    this.currentIndex = index
                    this.listData = listALL
                    let Id = this.listData[this.currentIndex]
                    this.getMailCurrent(Id)
                }
            } else {
                this.currentIndex = this.boxIndex
                this.listData = this.boxAarry
                let Id = this.listData[this.currentIndex]
                this.getMailCurrent(Id)
            }
            this.setMenu()
        }
    },
    beforeRouteLeave(to, from, next) {
        const flag = this.Global.utils.chosen.close()
        const flag2 = this.Global.utils.actionSheet.close()
        const flag3 = this.Global.utils.prompt.close()
        if (flag || flag2 || flag3) {
            next(false)
        } else {
            next()
        }
    },
    watch: {
        $route(to, from) {
            if (this.$route.path == '/mail/DetailRight' && (from.path == '/mail/mailList' || from.path.indexOf('/client/detail') != -1)) {
                this.pageInit('ordinary')
            } else if (this.$route.path == '/mail/DetailRight') {
                this.pageInit('special')
            }
        }
    }

}

<template>
    <div class="funcMark9">
        <loading-new :loading="loading"></loading-new>
        <template v-if="!loading">
            <nodata v-if="list.length == 0" :title="title" img="noData"></nodata>
            <div v-else class="list" v-for="(item, index) in list" :key="index" @click="ListCenterShowDetail(item.mId, index, list)">
                <span class="spot"></span>
                <div class="listBox">
                    <li class="li1Class">
                        <!--收件箱：显示发件人-->
                        <span class="subjectClass" v-if="item.source=='FMR'||item.source=='FMI'">
                            <span class="shortName">{{item.fromEx[0].personal==undefined?substringName(item.fromEx[0].address):item.fromEx[0].personal}}</span>
                            <span :class="item.fromEx[0].custId==undefined?'name strange':'name customer'">
                                <em>{{item.fromEx[0].custId==undefined?'陌':'客'}}</em>
                            </span>

                        </span>
                        <!--发件箱/文件夹：显示收件人-->
                        <span class="subjectClass" v-else>
                            <span class="shortName"> {{item.fromEx[0].personal==undefined?substringName(item.fromEx[0].address):item.fromEx[0].personal}}</span>
                            <span :class="item.fromEx[0].custId==undefined?'name strange':'name customer'">
                                <em>{{item.fromEx[0].custId==undefined?'陌':'客'}}</em>
                            </span>
                        </span>
                        <i v-if="item.stickyFlag==1" class="iconfont starColor icon-star"></i>
                        <span class="dataShowClass">
                            <i v-if="item.containAttachment" class="iconfont icon-attachment"></i>
                            {{DateDisplay(item.longSentDate)}}
                        </span>
                    </li>
                    <li :class="item.status==-1?'li2Class li4Class':'li2Class'">
                        <span v-if="item.replyFlag">
                            <i class="iconfont icon-share-left"></i>
                        </span>
                        <span v-if="item.repostSign">
                            <i class="iconfont icon-share-right"></i>
                        </span>
                        <label v-html="item.subject"></label>
                    </li>
                    <li class="li3Class">
                        <div class="contenClass2">
                            <i class="iconfont icon-dot" v-if="item.comments&&item.comments.length>0" :style="setCommentsColor(item.comments[item.comments.length-1].commentFlag)"></i>
                            {{item.abstractText}}
                        </div>
                        <div class="tagClass">
                            <span v-for="(item3,index3) in item.tagObjects" :key="index3">
                                <van-tag v-if="index3<=2" style="margin-left:5px" :style="setBgColor(item3.commentFlag)">
                                    {{item3.labelName}}
                                </van-tag>
                                <van-tag v-else-if="index3==3" style="margin-left:5px"> ... </van-tag>
                            </span>

                        </div>
                    </li>
                </div>
            </div>
            <div class="view__more" v-if="isShowPageCtrl">
                <!-- 查看更多 -->
                <span class="view__more-desc" @click="getMoreData">{{$t('mx_mobile.Client.1627017309696')}}</span>
            </div>
        </template>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import loadingNew from '../../../../CustPublic/LoadingVue/index.vue'
export default {
    name: 'funcMark9',
    props: {
        detailData: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            list: [],
            appendList: [],
            title: this.$t('mx_mobile.Client.1585280081936'), // 暂无邮件
            loading: true,
            size: 20,
            from: 0,
            total: 0
        }
    },
    computed: {
        isShowPageCtrl() {
            // 显示页
            return this.total > this.size && this.total > this.list.length
        }
    },
    created() {
        this.getData()
    },
    mounted() {
    },
    methods: {
        ...mapMutations('Mail', {
            set_boxIndex: 'SET_BOXINDEX',
            set_boxAarry: 'SET_BOXAARRY'
        }),
        initPage() {
            this.from = 0
        },
        // 双击显示详情
        ListCenterShowDetail(mId, index, listALL) {
            let list = []
            listALL.forEach(item => {
                list.push(item.mId)
            })
            this.set_boxAarry(list)
            this.set_boxIndex(index)
            this.$router.push({ name: 'DetailRight', query: { mId: mId, ...this.g_setQuery() }, params: { index: index, listALL: list } })
        },
        // 日期显示
        DateDisplay(times) {
            try {
                times = parseInt(times)
            } catch (error) {
                return times
            }
            const timeFormat = this.$moment(new Date(times)).format('YYYY/MM/DD HH:mm')

            const curData = new Date()
            const timArry = timeFormat.split('/')

            if (timArry[0] != curData.getFullYear()) { // 年份不同返回年
                return timeFormat
            } else {
                return this.$moment(new Date(times)).format('MM/DD HH:mm')
            }
        },
        // 标签颜色
        setBgColor(id) {
            return this.Global.utils.tagsBgColor(id)
        },
        // 批注颜色
        setCommentsColor(flag) {
            return this.Global.utils.commentsColor(flag)
        },
        // 名称截取
        substringName(adderss) {
            let str = adderss.split('@')
            return str[0]
        },
        async getData(isAppend) {
            let _this = this
            let data = {
                target: 'cust',
                from: this.from,
                size: this.size,
                type: 'all',
                custId: this.detailData.custId
            }
            await _this.axios.get(this.Global.config.apiBaseURL + _this.Global.api.Mail.getMaillist, { params: data }).then(async (res) => {
                _this.loading = false
                if (res.data.code.toString() === _this.Global.config.RES_OK) {
                    if (!_this.Global.utils.isObject(res.data.data)) {
                        _this.list = []
                        return
                    }
                    if (isAppend) {
                        this.total = res.data.data.totalNum
                        this.appendList = res.data.data.list
                        console.log('数据进错了', this.appendList)
                    } else {
                        _this.list = res.data.data.list
                        console.log('数据进来了', _this.list)
                        this.total = res.data.data.totalNum
                    }
                } else {
                    _this.list = []
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.loading = false
                _this.list = []
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        async getMoreData() {
            if (this.total >= this.from) {
                this.from += (this.size)
                await this.getData(true)
                if (this.appendList.length === 0) return true
                this.list = [...this.list, ...this.appendList]
                return false
            } else {
                return true
            }
        }
    },
    watch: {
    },
    components: {
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
.view__more {
    text-align: center;
    .view__more-desc {
        .padding(2px, 5px);
    }
}
</style>

<template>
    <div class="BatchSpace">
        <public-space ref="publicSpace" :isUndistributed="isUndistributed" :isTopList="isTopList" :showAction="showAction" :actionsList="actions" @listCancel="listCancel" :checkedListAll="checkedListAll" :boxId="boxId" @dialogJunkMail="dialogJunkMail"></public-space>
        <div class="footClass">
            <li>
                <van-checkbox v-model="value" @click.native="handleToggleSelectAll()"></van-checkbox>
            </li>
            <li @click="mergeChange()">
                <i class="iconfont icon-merge"></i>
            </li>
            <li @click="undistributedChange()">
                <i class="iconfont icon-neifenfa"></i>
            </li>
            <li @click="movingFolderChange()">
                <i class="iconfont icon-move"></i>
            </li>
            <li @click="delChange()">
                <i class="iconfont icon-del-light"></i>
            </li>
            <li @click="closeclick()">
                <i class="iconfont icon-close"></i>
            </li>
        </div>

    </div>
</template>
<script>
import PublicSpace from '../PublicSpace/index'
export default {
    name: 'BatchSpace',
    props: {
        checkedListAll: {
            type: Array,
            default: () => []
        },
        checkAll: {
            type: Boolean,
            default: false
        },
        boxId: {
            type: [Number, String],
            default: ''
        },
        isTopList: {
            type: Boolean,
            default: false
        },
        isUndistributed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            homeTitle: '孚盟邮',
            value: this.checkAll,
            showAction: false,
            actions: [
                {
                    name: '标记未读',
                    status: -1,
                    actionName: 'UnReadState',
                    buttonIndex: 0
                },
                {
                    name: '标记已读',
                    status: 1,
                    actionName: 'ReadState',
                    buttonIndex: 1
                }, {
                    name: '标记未回复',
                    replyFlag: false,
                    actionName: 'UnRecoverystate',
                    buttonIndex: 2
                },
                {
                    name: '标记已回复',
                    replyFlag: true,
                    actionName: 'Recoverystate',
                    buttonIndex: 3

                }, {
                    name: '标记未转发',
                    repostSign: false,
                    actionName: 'UnForwardingState',
                    buttonIndex: 4
                },
                {
                    name: '标记已转发',
                    repostSign: true,
                    actionName: 'ForwardingState',
                    buttonIndex: 5
                }, {
                    name: '举报',
                    actionName: 'JunkMail',
                    buttonIndex: 6
                },
                {
                    name: '星标置顶',
                    stickyFlag: 1,
                    actionName: 'TopOperation',
                    buttonIndex: 7

                },
                {
                    name: '取消星标',
                    stickyFlag: 0,
                    actionName: 'UnTopOperation',
                    buttonIndex: 8

                },
                {
                    name: '彻底删除',
                    actionName: 'deleteMail',
                    buttonIndex: 9

                }
            ]
        }
    },
    computed: {
        completeDelete() {
            return ['4', '5'].includes(this.boxId.toString())
        }
    },
    created() {

    },
    mounted() {

    },

    methods: {
        dialogJunkMail() {
            this.$emit('dialogJunkMail')
        },
        // 归并
        mergeChange() {
            this._spaceSelect({ actionName: 'MaileMerge' })
        },
        // 删除
        delChange() {
            this._spaceSelect({ actionName: this.completeDelete ? 'deleteMail' : 'delete' })
        },
        undistributedChange() {
            this._spaceSelect({ actionName: 'undistributed' })
        },
        movingFolderChange() {
            this._spaceSelect({ actionName: 'MovingFolder' })
        },
        _spaceSelect(action) {
            this.$refs.publicSpace.onSelect(action)
        },
        handleToggleSelectAll() {
            this.value = !this.value
            this.$emit('toggleSelectAll', this.value)
        },
        closeclick() {
            // let a = a!=true
            this.value = false
            this.$emit('toggleSelectAll', this.value)
        },
        showActionChange() {
            this.showAction = true
        },
        listCancel(type, action) {
            this.showAction = false
            if (type == 'action') {
                this.$emit('toggleSelectAll', this.value)
                this.$emit('refreshPage')
            }
            if (type == 'delete') {
                this.$emit('delete')
            }
            if (type == 'mark' && !!action) {
                this.$emit('mark', action)
            }
        }

    },
    components: {
        'public-space': PublicSpace

    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

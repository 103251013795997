<template>
    <div>
        <template>
            <div v-for="(item,index) in itemData.group" :key="index">
                <div v-if="item.fieldName == 'countryId'" class="list">
                    <div @click="choosePerson1()" class="listBox">
                        <span class="name">
                            {{item.cnFieldCaption}}
                            <span style="color:#D0021B" v-if="prop(item)">*</span>
                        </span>
                        <span>{{obj1.text}}</span>
                    </div>
                </div>
                <div v-if="item.fieldName == 'provinceId'" class="list">
                    <div @click="choosePerson2()" class="listBox">
                        <span class="name">
                            {{item.cnFieldCaption}}
                            <span style="color:#D0021B" v-if="prop(item)">*</span>
                        </span>
                        <span>{{obj2.text}}</span>
                    </div>
                </div>
                <div v-if="item.fieldName == 'cityId'" class="list">
                    <div @click="choosePerson3()" class="listBox">
                        <span class="name">
                            {{item.cnFieldCaption}}
                            <span style="color:#D0021B" v-if="prop(item)">*</span>
                        </span>
                        <span>{{obj3.text}}</span>
                    </div>
                </div>
                <div v-if="item.fieldName == 'townId'" class="list">
                    <div @click="choosePerson4()" class="listBox">
                        <span class="name">
                            {{item.cnFieldCaption}}
                            <span style="color:#D0021B" v-if="prop(item)">*</span>
                        </span>
                        <span>{{obj4.text}}</span>
                    </div>
                </div>
                <div v-if="item.fieldName == 'custAddr'" class="list" style="border:0;">
                    <span class="name">
                        {{item.cnFieldCaption}}
                        <span style="color:#D0021B" v-if="prop(item)">*</span>
                    </span>
                    <van-field v-model="obj5.value" style="padding-left:0;" clearable :placeholder="itemData.cnFieldHint" :maxlength="itemData.fieldLength" />
                </div>
            </div>
        </template>

    </div>
</template>
<script>
import selectCountryEvent from '@/components/SelectCountry/event.js'
export default {
    name: 'BelongTo',
    props: {
        itemData: {// 赋值给控件的数据
            type: Object,
            default: () => {
                return {}
            }
        },
        optCode: {
            type: String,
            default: ''
        },
        moduleCode: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            obj1: {
                text: '',
                value: ''
            },
            obj2: {
                text: '',
                value: ''
            },
            obj3: {
                text: '',
                value: ''
            },
            obj4: {
                text: '',
                value: ''
            },
            obj5: {
                value: ''
            },
            valueBase1: '',
            valueBase2: '',
            valueBase3: '',
            valueBase4: '',
            actions1: [],
            actions2: [],
            actions3: [],
            actions4: [],
            actionsBase1: [],
            actionsBase2: [],
            actionsBase3: [],
            actionsBase4: [],
            countrySelect: false
        }
    },
    created() {
        this.unit()
    },
    mounted() {
    },
    methods: {
        unit() {
            if (this.itemData.group[0] && this.itemData.group[0].fieldName == 'countryId') {
                this.getData1()
            }
        },
        returnName(list, value) {
            let obj = {
                text: '',
                value: ''
            }
            list.forEach(element => {
                if (element.value == value) {
                    obj = element
                }
            })
            return obj
        },
        async closeSelect(item) {
            this.countrySelect = false
        },
        async choosePerson1() {
            let returnValue = {
                text: '',
                value: ''
            }
            try {
                let data = await selectCountryEvent.show(this.obj1, this.actions1)
                returnValue = data
            } catch (error) {
                returnValue = {
                    text: '',
                    value: ''
                }
            }

            if (this.obj1.value != returnValue.value) {
                this.obj2 = {
                    text: '',
                    value: ''
                }
                this.obj3 = {
                    text: '',
                    value: ''
                }
                this.obj4 = {
                    text: '',
                    value: ''
                }
                this.obj5 = {
                    value: ''
                }
            }
            this.obj1 = returnValue
            this.getData2()
        },
        async choosePerson2() {
            if (this.actions2.length == '0') {
                this.$toast(this.$t('mx_mobile.Client.1585630468647')) // 暂无省份
                return false
            }
            let res = await this.Global.utils.chosen.open(this.actions2)
            this.obj2 = res
            this.getData3()
        },
        async choosePerson3() {
            if (this.actions3.length == '0') {
                this.$toast(this.$t('mx_mobile.Client.1585630468493')) // 暂无城市
                return false
            }
            let res = await this.Global.utils.chosen.open(this.actions3)
            this.obj3 = res
            this.getData4()
        },
        async choosePerson4() {
            if (this.actions4.length == '0') {
                this.$toast(this.$t('mx_mobile.Client.1585630465913')) // 暂无地区
                return false
            }
            let res = await this.Global.utils.chosen.open(this.actions4)
            this.obj4 = res
        },
        updates(items) {
            if (this.itemData.group) {
                this.itemData.group.forEach(item => {
                    if (item.fieldName == 'countryId' && items.countryId) {
                        this.valueBase1 = items.countryId
                    }
                    if (item.fieldName == 'provinceId' && items.provinceId) {
                        this.valueBase2 = items.provinceId
                    }
                    if (item.fieldName == 'cityId' && items.cityId) {
                        this.valueBase3 = items.cityId
                    }
                    if (item.fieldName == 'townId' && items.townId) {
                        this.valueBase4 = items.townId
                    }
                    if (item.fieldName == 'custAddr' && items.custAddr) {
                        this.obj5.value = items.custAddr
                    }
                })
                if (this.actionsBase1.length != '0') {
                    this.obj1 = this.returnName(this.actionsBase1, this.valueBase1)
                    if (item[this.itemData.group[1].fieldName]) {
                        this.getData2(true, this.valueBase2)
                    } else {
                        this.getData2()
                    }
                }
            }
            // if (this.itemData.group.length === 1) {
            //   if (item[this.itemData.group[0].fieldName]) {
            //     this.obj5.value = item[this.itemData.group[0].fieldName]
            //   }
            // } else {
            //     if ((item.fieldName == 'countryId' && this.obj1.value == '') ||
            //       (item.fieldName == 'provinceId' && this.obj2.value == '') ||
            //       (item.fieldName == 'cityId' && this.obj3.value == '') ||
            //       (item.fieldName == 'townId' && this.obj4.value == '') ||
            //       (item.fieldName == 'custAddr' && this.obj5.value == '')
            //   if (this.itemData.group[0] && item[this.itemData.group[0].fieldName]) {
            //     this.valueBase1 = item[this.itemData.group[0].fieldName]
            //     if (this.itemData.group[1] && item[this.itemData.group[1].fieldName]) {
            //       this.valueBase2 = item[this.itemData.group[1].fieldName]
            //       if (this.itemData.group[2] && item[this.itemData.group[2].fieldName]) {
            //         this.valueBase3 = item[this.itemData.group[2].fieldName]
            //         if (this.itemData.group[3] && item[this.itemData.group[3].fieldName]) {
            //           this.valueBase4 = item[this.itemData.group[3].fieldName]
            //         }
            //       }
            //     }
            //     if (this.actionsBase1.length != '0') {
            //       this.obj1 = this.returnName(this.actionsBase1, this.valueBase1)
            //       if (item[this.itemData.group[1].fieldName]) {
            //         this.getData2(true, this.valueBase2)
            //       } else {
            //         this.getData2()
            //       }
            //     }
            //   }
            //   if (item[this.itemData.group[4].fieldName]) {
            //     this.obj5.value = item[this.itemData.group[4].fieldName]
            //   }
            // }
        },
        prop(item) {
            if (item.disabled) {
                return ''
            }
            if (item.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        submit() {
            let data = {}
            let isProp = true
            this.itemData.group.forEach(item => {
                if (this.prop(item)) {
                    if ((item.fieldName == 'countryId' && this.obj1.value == '') ||
                        (item.fieldName == 'provinceId' && this.obj2.value == '') ||
                        (item.fieldName == 'cityId' && this.obj3.value == '') ||
                        (item.fieldName == 'townId' && this.obj4.value == '') ||
                        (item.fieldName == 'custAddr' && this.obj5.value == '')
                    ) {
                        isProp = false
                        this.$toast.fail(item.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    }
                }
            })
            if (!isProp) {
                return false
            }
            data = {
                countryId: this.obj1.value != '' ? this.obj1.value + '' : '0',
                provinceId: this.obj2.value != '' ? this.obj2.value + '' : '0',
                cityId: this.obj3.value != '' ? this.obj3.value + '' : '0',
                townId: this.obj4.value != '' ? this.obj4.value + '' : '0',
                custAddr: this.obj5.value + ''
            }
            return data
        },
        // 初始第一个下拉先 <-_->
        async getData1() {
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.area + 1, { params: { type: 'country' } })
            if (res.data.code.toString() === this.Global.config.RES_OK) {
                this.actions1 = []
                this.actionsBase1 = []
                res.data.data.forEach(element => {
                    if (element.inUse != '0') {
                        this.actions1.push({
                            text: element.cnName,
                            value: element.countryId
                        })
                    }
                    this.actionsBase1.push({
                        text: element.cnName,
                        value: element.countryId
                    })
                })
                if (this.type == 'otNew' && this.itemData.group[0].inDefaultValue != '') {
                    this.obj1 = this.returnName(this.actionsBase1, this.itemData.group[0].inDefaultValue)
                    if (this.itemData.group[1] && this.itemData.group[1].fieldName == 'provinceId') {
                        if (this.itemData.group[1].inDefaultValue != '') {
                            this.getData2(true, this.itemData.group[1].inDefaultValue)
                        } else {
                            this.getData2()
                        }
                    }
                } else {
                    if (this.valueBase1 != '') {
                        this.obj1 = this.returnName(this.actionsBase1, this.valueBase1)
                        if (this.itemData.group[1] && this.itemData.group[1].fieldName == 'provinceId') {
                            if (this.valueBase2 != '') {
                                this.getData2(true, this.valueBase2)
                            } else {
                                this.getData2()
                            }
                        }
                    }
                }
            } else {
                this.actions1 = []
                this.$toast.fail(res.data.msg)
            }
        },
        async getData2(update, value) {
            if (this.obj1.value === '') {
                this.actions2 = []
                return false
            }
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.area + this.obj1.value, { params: { type: 'province' } })
            if (res.data.code.toString() == this.Global.config.RES_OK) {
                this.actions2 = []
                this.actionsBase2 = []
                res.data.data.forEach(element => {
                    if (element.inUse != '0') {
                        this.actions2.push({
                            text: element.cnName,
                            value: element.provinceId
                        })
                    }
                    this.actionsBase2.push({
                        text: element.cnName,
                        value: element.provinceId
                    })
                })
                if (update) {
                    this.obj2 = this.returnName(this.actionsBase2, value)
                    if (this.itemData.group[2] && this.itemData.group[2].fieldName == 'cityId') {
                        if (this.valueBase3 != '') {
                            this.getData3(true, this.valueBase3)
                        } else {
                            this.getData3()
                        }
                    }
                }
            } else {
                this.actions2 = []
                this.$toast.fail(res.data.msg)
            }
        },
        async getData3(update, value) {
            if (this.obj2.value === '') {
                this.actions3 = []
                return false
            }
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.area + this.obj2.value, { params: { type: 'city' } })
            if (res.data.code.toString() == this.Global.config.RES_OK) {
                this.actions3 = []
                this.actionsBase3 = []
                res.data.data.forEach(element => {
                    if (element.inUse != '0') {
                        this.actions3.push({
                            text: element.cnName,
                            value: element.cityId
                        })
                    }
                    this.actionsBase3.push({
                        text: element.cnName,
                        value: element.cityId
                    })
                })
                if (update) {
                    this.obj3 = this.returnName(this.actionsBase3, value)
                    if (this.itemData.group[3] && this.itemData.group[3].fieldName == 'townId') {
                        if (this.valueBase4 != '') {
                            this.getData4(true, this.valueBase4)
                        } else {
                            this.getData4()
                        }
                    }
                }
            } else {
                this.actions3 = []
                this.$toast.fail(res.data.msg)
            }
        },
        async getData4(update, value) {
            if (this.obj3.value === '') {
                this.actions4 = []
                return false
            }
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.area + this.obj3.value, { params: { type: 'town' } })
            if (res.data.code.toString() == this.Global.config.RES_OK) {
                this.actions4 = []
                this.actionsBase4 = []
                res.data.data.forEach(element => {
                    if (element.inUse != '0') {
                        this.actions4.push({
                            text: element.cnName,
                            value: element.townId
                        })
                    }
                    this.actionsBase4.push({
                        text: element.cnName,
                        value: element.townId
                    })
                })
                if (update) {
                    this.obj4 = this.returnName(this.actionsBase4, value)
                }
            } else {
                this.actions4 = []
                this.$toast.fail(res.data.msg)
            }
        }
    },
    components: {

    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.list {
    .min-height(45px);
    .line-height(45px);
    border-bottom: 1px solid #eaedef;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    position: relative;
    .listBox {
        .min-height(45px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
}
</style>

import BaseFrame from './BaseFrame'
export default {
    name: '',
    extends: BaseFrame,
    data() {
        return {

        }
    },
    created() {

    },
    beforeDestroy() {},

    methods: {
        setContent(content = '') {
            if (this.Global.isFMApp) {
                content = content.replace(/<a /igm, "<a target='_blank' onclick='openlink(event,this)' ")
            } else {
                content = content.replace(/<a /igm, "<a target='_blank' ")
            }
            this.doc.body.innerHTML = content
            this.scrollTop()
        },
        scrollTop(top = 0) {
            this.doc.documentElement.scrollTop = 0
        },
        getTempHtml() {
            return `<!DOCTYPE html>
            <html>
            <head>
              <meta charset="utf-8">
              <meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no">
            </head>
            <style>
             
              body {
                font-family: -apple-system, 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'WenQuanYi Micro Hei', Arial, sans-serif;
                -webkit-font-smoothing: antialiased;
              }
              ::-webkit-scrollbar {
                  width: 5px;
                  height: 5px;
              }
              ::-webkit-scrollbar-thumb {
                  background-color: #999;
                  -webkit-border-radius: 5px;
                  border-radius: 5px;
              }  
              ::-webkit-scrollbar-thumb:vertical:hover {
                  background-color: #666;
              }
              ::-webkit-scrollbar-thumb:vertical:active {
                  background-color: #333;
              }
              ::-webkit-scrollbar-button {
                  display: none;
              }    
              ::-webkit-scrollbar-track {
                  background-color: #f1f1f1;
              }
          </style>
          <body id="body">${this._getCommonScript()}
          </body>
          </html>`
        }
    }
}

<template>
    <div class="ToolBar">
        <!-- 多选 -->
        <div class="toolItem" @click="onClick01">{{$t('mx_mobile.Doc.1585204922182')}}</div>
        <div class="toolItem" @click="onClick02">
            <span class="leftline van-hairline--left"></span>
            <!-- 筛选 -->
            <span>{{$t('mx_mobile.Doc.1585204944701')}}</span>
            <i class="iconfont icon-funnel"></i>
            <span class="rightline van-hairline--right"></span>
        </div>
        <div class="toolItem" @click="onClick03">
            <!-- 搜索 -->
            <span>{{$t('mx_mobile.Doc.1585204965787')}}</span>
            <i class="iconfont icon-search"></i>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ToolBar',
    data() {
        return {

        }
    },
    methods: {
        onClick01() {
            this.$emit('click01')
        },
        onClick02() {
            this.$emit('click02')
        },
        onClick03() {
            this.$emit('click03')
        }
    },
    components: {

    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

<template>
    <div class="showName" @click="openCustomer()">
        <dt>{{getName(showDetail)}}</dt>
        <dd>{{showDetail.address}}</dd>
        <span class="strange" v-if="this.showDetail.category==undefined&&isShow">
            <em>陌</em>
        </span>
        <span class="customer" v-if="this.showDetail.category !=undefined&&isShow">
            <em v-if="this.showDetail.category==1&&isShow">客</em>
            <em v-if="this.showDetail.category==2&&isShow">供</em>
            <em v-if="this.showDetail.category==3&&isShow">内</em>
            <em v-if="this.showDetail.category==4&&isShow">{{getType(this.showDetail.mark)}}</em>
        </span>
    </div>
</template>
<script>
import { getName, getType, substringName } from '../../../../PublicMethod.js'
export default {
    name: 'showName',
    props: {
        showDetail: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isShow: {
            type: Boolean,
            default: function () {
                return true
            }
        }
    },
    data() {
        return {
            homeTitle: '孚盟邮',
            searchValue: ''
        }
    },
    computed: {
    },
    created() {

    },
    mounted() {

    },
    methods: {
        openCustomer() {
            if (!this.showDetail.category) {
                let name = this.getName(this.showDetail)
                // TODO:跳转到新建客户页
                this.$router.push({ name: 'Strange', params: { name: this.substringName(name), mailAddress: this.showDetail.address } })
                return
            }
            if (this.showDetail.category.toString() === '1') {
                // TODO:跳转到客户页
                this.$router.push({ name: 'detail', params: { id: this.showDetail.custId } })
            }
        },
        getName,
        getType,
        substringName

    },
    components: {
    }
}
</script>
<style lang="less" scoped>
.showName {
    dt {
        color: #303133;
        font-size: 12px;
        .margin-bottom(1px);
        .margin-left(10px);
        .height(17px);
        .line-height(17px);
    }
    dd {
        .height(17px);
        .line-height(17px);
        font-size: 12px;
        color: #909399;
        .margin-bottom(1px);
        .margin-left(10px);
    }
    > span {
        position: absolute;
        .bottom(11px);
        right: 0;
        .width(20px);
        .height(20px);
        border-radius: 50%;
        text-align: center;
        background: #303133;
        color: #ffffff;
        .line-height(20px);
        transform: scale(0.8);
    }
    .customer {
        background: rgba(208, 2, 27, 1);
        // linear-gradient(135deg, rgba(255, 105, 124, 1), rgba(208, 2, 27, 1));
    }
    .strange {
        background: #303133;
    }
}
</style>

<template>
    <div class="PublicSpace"></div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: 'PublicSpace',
    props: {
        showAction: {
            type: Boolean,
            default: false
        },
        checkedListAll: {
            type: Array,
            default: () => []
        },
        actionsList: {
            type: Array,
            default: () => []
        },

        detailItem: {
            type: Object,
            default: () => ({})
        },
        boxId: {
            type: [Number, String],
            default: ''
        },
        isTopList: {
            type: Boolean,
            default: false
        },
        isUndistributed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            homeTitle: '孚盟邮',
            isShow: false
        }
    },

    computed: {
        ...mapGetters('Mail', ['currentCtid']),
        isTodo() {
            return this.boxId !== (void 0) && this.boxId.toString() === '0'
        }
    },
    components: {

    },
    created() {

    },
    mounted() {

    },
    methods: {
        onCancel() {
            this.isShow = false
            this.endOperation('close')
        },
        endOperation(type, action) {
            this.$emit('listCancel', type, action)
        },
        _getActionDataByActionName(actionName) {
            const dataStrates = {
                'ReadState': {
                    key: 'status',
                    value: '1',
                    type: 'status'
                },
                'UnReadState': {
                    key: 'status',
                    value: '-1',
                    type: 'status'
                },
                'Recoverystate': {
                    key: 'replyFlag',
                    value: true,

                    type: 'replyFlag'
                },
                'UnRecoverystate': {
                    key: 'replyFlag',
                    value: false,

                    type: 'replyFlag'
                },
                'ForwardingState': {
                    key: 'repostSign',
                    value: true,

                    type: 'repost'
                },
                'UnForwardingState': {
                    key: 'repostSign',
                    value: false,

                    type: 'repost'
                },
                'TopOperation': {
                    key: 'stickyFlag',
                    value: '1',
                    type: 'top'

                },
                'UnTopOperation': {
                    key: 'stickyFlag',
                    value: '0',
                    type: 'top'
                }
            }
            return dataStrates[actionName]
        },
        _isTopListCancel(action) {
            let newAction = this._getActionDataByActionName('UnTopOperation')
            return this.isTopList && newAction.key == action.key && newAction.value == action.value
        },
        async onSelect(command) {
            let mIds = this.checkedListAll // 获取当前选中的id  []
            let data = {}
            let isNotIdentification = true
            switch (command.actionName) {
                case 'ReadState':// 已读(次数)
                case 'UnReadState':// 标记为未读(-1)
                case 'Recoverystate':
                case 'UnRecoverystate':
                case 'ForwardingState':
                case 'UnForwardingState':
                case 'TopOperation':
                case 'UnTopOperation':

                    isNotIdentification = false
                    let action = this._getActionDataByActionName(command.actionName)

                    let { type, key, value } = action
                    data = { type, [key]: value, mIds }
                    let flag = await this.IdentificationMailSettings(data)
                    if (flag) {
                        this.$toast.success('标记成功')
                        if (this._isTopListCancel(action)) {
                            this.endOperation('delete')
                        } else {
                            this.endOperation('mark', Object.assign({}, action, { mIds }))
                        }
                    }
                    break
                case 'Comment':// 批注
                    break
                case 'MaileMerge':// 归并

                    data = {
                        mId: mIds.length > 1 ? mIds.join(',') : mIds[0]
                    }
                    this.mailMergePost(data)
                    break
                case 'undistributed':// 内分发

                    this._changeRoute({ name: 'DialogInDistribute', params: { mIds: mIds, isUndistributed: this.isUndistributed } })
                    break
                case 'MovingFolder':// 移动  //未完成  文件夹/邮件（多个/单个）

                    let boxId = this.boxId == -1 ? this.detailItem.folder : this.boxId
                    this._changeRoute({ name: 'MovingBox', params: { type: 'mail', mIds: mIds, boxId: boxId } })
                    break
                case 'JunkMail':// 举报  //未完成

                    if (this.detailItem.mId != undefined) {
                        this.dialogJunkMail(this.detailItem.mailAddress, this.detailItem.fromEx, this.detailItem.mId, this.detailItem.rawData, this.detailItem.size, this.detailItem.subject)
                    } else {
                        this.$emit('dialogJunkMail')// 批量
                    }
                    break
                case 'AttachmentsSend':// 作为附件发送

                    this.AttachmentsSendAction()
                    // subject, url, size
                    break
                case 'updataSubject':// 修改主题

                    this._changeRoute({ name: 'SubjectUpdate', params: { subjectName: this.detailItem.subject, mId: this.detailItem.mId } })
                    break
                case 'addMsg':// 设置提醒   //未完成

                    this._changeRoute({ name: 'SettingUpReminder', params: { mId: this.detailItem.mId } })
                    break
                case 'SetTag':// 标签

                    this._changeRoute({ name: 'TagList', params: { moduleCode: 'EM001', identFieldValue: this.detailItem.mId, selectedTags: this.detailItem.tags } })
                    break
                case 'delete':// 删除

                    data = {
                        'folder': '4',
                        'type': 'move',
                        'mIds': mIds
                    }
                    this.MoveRecyclingStation(data)
                    break
                case 'deleteMail':// 彻底删除

                    this.PhysicalDelete({ mIds: mIds })
                    break
                case 'ReplyMail':// 回复

                    this._changeRoute({ name: 'NewMail', params: { actionType: 'R', templateid: 0, originMid: mIds[0].toString() } })
                    break
                case 'ReturnToAllMail':// 回复全部

                    this._changeRoute({ name: 'NewMail', params: { actionType: 'Rl', templateid: 0, originMid: mIds[0].toString() } })
                    break
                case 'ForwardMail':// 转发

                    this._changeRoute({ name: 'NewMail', params: { actionType: 'F', templateid: 0, originMid: mIds[0].toString() } })
                    break
                case 'editAgain': // 再次编辑

                    this._changeRoute({ name: 'NewMail', params: { actionType: command.actionType, templateid: 0, originMid: mIds[0].toString() } })
                    break
            }
            if (isNotIdentification) {
                this.endOperation('close') // 选择完成后关闭
            }
        },
        _changeRoute(action) {
            if (action.name === 'NewMail') {
                this.goToNewMail(action.params)
            } else {
                this.$router.push(action)
            }
        },
        async  AttachmentsSendAction() {
            try {
                let data = { mId: this.detailItem.mId, type: 'eml' }
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.mails_download
                let res = await this.axios.get(url, { params: data })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    if (res.data.data.rawData != '') {
                        this.goToNewMail({ actionType: 'A', templateid: 0, subject: this.detailItem.subject + '.eml', url: res.data.data.rawData, size: this.detailItem.size })
                    } else {
                        this.$toast.fail('未找到eml文件')
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        // 垃圾邮件
        dialogJunkMail(mailAddress, fromEx, mId, rawData, size, subject) {
            let str = ''
            for (let i = 0; i < fromEx.length; i++) {
                str = str + fromEx[i].address + ','
            }

            let data = {
                junkOwnerMailAccount: mailAddress,
                junkMailText: str.substring(0, str.length - 1),
                mId: mId,
                type: 'single'
            }

            this.$router.push({ name: 'ReportSpam', params: data })
        },
        // 标识设置
        async IdentificationMailSettings(data) {
            let returnflag = false
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                data.ctid = this.currentCtid
            } else {
                delete data.ctid
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.mails_mail
                let res = await this.axios.put(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    returnflag = true
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
            return returnflag
        },
        // 归并
        async mailMergePost(data) {
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                data.ctid = this.currentCtid
            } else {
                delete data.ctid
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.mailMergePost
                let res = await this.axios.post(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success('归并成功')
                    this.endOperation(this.isTodo ? 'delete' : 'action')
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        async confirm(title, message) {
            let flag = true
            try {
                await this.$dialog.confirm({ title, message })
            } catch (error) {
                this.$toast.fail('已取消删除')
                flag = false
            }
            return flag
        },
        // 移入回收站|垃圾邮箱   type:0单个   1批量 mId, boxId, type
        async   MoveRecyclingStation(data) {
            let flag = await this.confirm('提示', '你确定要删除吗？')
            if (!flag) {
                return
            }

            let blg = await this.getRightsCheck({ 'optCode': 'otDelete', 'moduleCode': 'EM001' })
            if (!blg) { // && boxId == 4
                return
            }

            let isSuccess = await this.IdentificationMailSettings(data)
            if (isSuccess) {
                this.$toast.success('删除成功')
                this.endOperation('delete')
            }
        },
        // 物理删除
        async PhysicalDelete(data) {
            let flag = await this.confirm('提示', '你确定要彻底删除吗？')
            if (!flag) {
                return
            }

            let blg = await this.getRightsCheck({ 'optCode': 'otThoroughDelete', 'moduleCode': 'EM001' })
            if (!blg) {
                return
            }

            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                data.ctid = this.currentCtid
            } else {
                delete data.ctid
            }

            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.mailDelete

                let res = await this.axios.post(url, data)

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success('彻底删除成功')
                    this.endOperation('delete')
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },

        async getRightsCheck(data) {
            let returnFlag = false
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                data.targetCtid = this.currentCtid
            } else {
                data.targetCtid = this.companie.ctId
            }

            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.Mail.GetRightsCheck
                let res = await this.axios.get(url, { params: data })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    returnFlag = true
                } else {
                    this.$toast.fail('无权限！')
                }
            } catch (error) {
                console.log(error)
                this.$toast.fail('查询权限失败')
            }
            return returnFlag
        },
        async showChange() {
            try {
                let rest = await this.Global.utils.actionSheet.open(this.actionsList)
                if (rest.buttonIndex < this.actionsList.length) {
                    this.onSelect(rest)
                } else {
                    this.onCancel()
                }
            } catch (error) {
                this.onCancel()
            }
        }
    },
    watch: {
        showAction(newVal, oldVal) {
            this.isShow = newVal
            if (this.isShow) {
                this.showChange()
            }
        }
    }

}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

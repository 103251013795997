<template>
    <div class="DetailRight">
        <div class="subjectClass" @click="showActionChange()">
            {{detailObject.subject}}
        </div>
        <div class="consigneeClass">
            <show-all ref="ShowAll" :Isshow="Isshow" :recipientsArry="detailObject.recipients" :ccArry="detailObject.cc" :category="detailObject.category"></show-all>
            <span class="detailClass" @click="IsShowCahnge()">{{Isshow==true?'隐藏':'详情'}}</span>
        </div>
        <div class="divInfo" v-if="Isshow">
            <div class="divClass">
                <div class="name">发件人： </div>
                <div class="nameItem">
                    <dl v-for="(item,index) in detailObject.fromEx" :key="index">
                        <show-name ref="ShowName" :showDetail="item"></show-name>
                    </dl>
                </div>
            </div>
            <div class="divClass">
                <div class="name">收件人： </div>
                <div class="nameItem">
                    <dl v-for="(item,index) in detailObject.recipients" :key="index">
                        <show-name ref="ShowName" :showDetail="item"></show-name>
                    </dl>
                </div>
            </div>
            <div class="divClass" v-if="!showCc&&detailObject.cc" @click="changeshowCc">
                <div style="text-align: center;">
                    <i class="iconfont icon-page-down"></i>
                </div>
            </div>
            <div class="divClass" v-if="showCc&&detailObject.cc">
                <div class="name">抄送人： </div>
                <div class="nameItem">
                    <dl v-for="(item,index) in detailObject.cc" :key="index">
                        <show-name ref="ShowName" :showDetail="item"></show-name>
                    </dl>
                </div>
            </div>
            <div class="divClass">
                <div class="name">日期： </div>
                <div class="dataClass">
                    {{getTime(detailObject.longSentDate)}}
                    <!-- <dl v-for="(item,index) in detailObject.cc" :key="index">
                        <show-name ref="ShowName" :showDetail="item"></show-name>
                    </dl> -->
                </div>
            </div>
        </div>
        <div class="attachTile" v-if="detailObject.containAttachment" @click="toge()">
            <i class="iconfont icon-attachment"></i>
            <span>共有
                <span class="colorSpan">{{detailObject.attachmentList.length}}</span>个附件</span>
        </div>
        <div class="attachText" v-if="detailObject.containAttachment&&AttachmentShow">
            <file-list-show ref="fileListShow" :attachmentList="detailObject.attachmentList"></file-list-show>
        </div>
        <hr class="lineClass" id="lineId" />
        <div class="contentText" :style="{top:topS+'px'}">
            <iframe ref="iframe" class="frameEle" src="/mail/content.html" @load="loadedContent()" frameborder="0" marginheight="0" marginwidth="0" width="100%" height="100%"></iframe>
            <!--  <mail-frame class="frameBox" ref="mailFrame" @load="loadedContent()" frameborder="0" marginheight="0" marginwidth="0" width="100%" height="100%"></mail-frame> -->
        </div>

        <div class="quickOperation">
            <span class="showAll" @click="openLink()">
                <i class="iconfont icon-full-screen"></i>
            </span>
            <span class="nextC" @click="nextMailGet()">下一封</span>
        </div>
        <public-space ref="public-space" :detailItem="detailObject" :showAction="showAction" :checkedListAll="[detailObject.mId]" :actionsList="actions" :boxId="boxId" :isTopList="isTopList" :isUndistributed="isUndistributed" @listCancel="actionList"></public-space>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { urlIsEncode, getIsApp } from '@/libs/utils.js'
import MailFrame from '@/components/FramePage/Mail.js'
import ShowName from './VueAssembly/ShowName/index'
import ShowAll from './VueAssembly/ShowAll/index'
import PublicSpace from '../../MailPublic/ToolBar/PublicSpace/index'
import fileListShow from '../../MailPublic/fileListShow'
export default {
    name: 'DetailRightBase',
    data() {
        return {
            Isshow: false,
            showCc: false,
            detailObject: {},
            showAction: false,
            actions: [],
            currentIndex: 0,
            listData: [],
            AttachmentShow: false,
            topS: 0,
            // actionsShow: []
            // isShow: false
            frameIsLoad: false,
            dataIsLoad: false
        }
    },
    computed: {
        ...mapGetters('Mail', ['boxIndex', 'boxAarry', 'currentCtid'])
    },
    created() {

    },

    methods: {
        changeshowCc() {
            this.showCc = true
            this.getStyle()
        },
        IsShowCahnge() {
            this.Isshow = this.Isshow != true
            if (!this.Isshow) {
                this.showCc = false
            }
        },
        ...mapMutations('Mail', {
            set_boxAarry: 'SET_BOXAARRY'
        }),
        openLink() {
            let Id = this.listData[this.currentIndex]

            if (!Id) {
                console.log(Id)
                return
            }
            let url = `${window.location.origin}/render/maildetail?mId=${Id}&accessToken=${this.Global.utils.getToken()[this.Global.config.accessToken]}&type=details${getIsApp() ? '&showHeader=0' : ''}`
            window.open({ url: url, isShare: false })
        },
        toge() {
            this.AttachmentShow = this.AttachmentShow != true
            this.getStyle()
        },
        getTime(longTime) {
            return this.$moment(new Date(parseInt(longTime))).format('YYYY/MM/DD HH:mm')
        },
        actionList(type) {
            this.showAction = false
            if (['action', 'delete'].includes(type)) {
                let Id = this.listData[this.currentIndex]
                this.getMailCurrent(Id)
            }
        },
        setMenu() {
            let rightMenu = this.Global.utils.rightMenu.setMenu('更多')
            rightMenu.onClick(() => {
                this.showActionChange()
            })
        },
        showActionChange() {
            let detailActions = []

            let detail = this.detailObject

            detailActions.push({
                name: '回复',
                actionName: 'ReplyMail',
                buttonIndex: 0

            })

            detailActions.push({
                name: '回复全部',
                actionName: 'ReturnToAllMail',
                buttonIndex: 1

            })

            detailActions.push({
                name: '转发',
                actionName: 'ForwardMail',
                buttonIndex: 2

            })
            if (this.detailObject.folder == '2' || this.detailObject.folder == '3' || this.detailObject.folder == '6') {
                detailActions.push({
                    name: this.detailObject.folder == '6' ? '继续编辑' : '再次发送',
                    actionType: this.detailObject.folder == '6' ? 'D' : 'Z',
                    actionName: 'editAgain',
                    buttonIndex: 17

                })
            }

            detailActions.push({
                name: '归并',
                actionName: 'MaileMerge',
                buttonIndex: 3
            })

            detailActions.push({
                name: '内分发',
                actionName: 'undistributed',
                buttonIndex: 4
            })

            detailActions.push({
                name: '移动',
                actionName: 'MovingFolder',
                buttonIndex: 5
            })

            detailActions.push({
                name: '删除',
                actionName: 'delete',
                buttonIndex: 6

            })

            detailActions.push({
                name: '标签',
                actionName: 'SetTag',
                buttonIndex: 7

            })

            detailActions.push({
                name: '举报',
                actionName: 'JunkMail',
                buttonIndex: 8
            })
            if (detail.status == 1) { // 已读未读
                detailActions.push({
                    name: '标记未读',
                    status: -1,
                    actionName: 'UnReadState',
                    buttonIndex: 9
                })
            } else {
                detailActions.push({
                    name: '标记已读',
                    status: 1,
                    actionName: 'ReadState',
                    buttonIndex: 9
                })
            }
            if (detail.replyFlag == false) { // 已回复未回复
                detailActions.push({
                    name: '标记已回复',
                    replyFlag: true,
                    actionName: 'Recoverystate',
                    buttonIndex: 10

                })
            } else {
                detailActions.push({
                    name: '标记未回复',
                    replyFlag: false,
                    actionName: 'UnRecoverystate',
                    buttonIndex: 10
                })
            }
            if (detail.repostSign == false) { // 已转发未转发
                detailActions.push({
                    name: '标记已转发',
                    repostSign: true,
                    actionName: 'ForwardingState',
                    buttonIndex: 11
                })
            } else {
                detailActions.push({
                    name: '标记未转发',
                    repostSign: false,
                    actionName: 'UnForwardingState',
                    buttonIndex: 11
                })
            }

            // detailActions.push({
            //     name: '批注',
            //     actionName: 'Comment'

            // })
            if (detail.stickyFlag == 0) { // 已置顶未置顶
                detailActions.push({
                    name: '星标置顶',
                    stickyFlag: 1,
                    actionName: 'TopOperation',
                    buttonIndex: 12

                }
                )
            } else {
                detailActions.push({
                    name: '取消星标',
                    stickyFlag: 0,
                    actionName: 'UnTopOperation',
                    buttonIndex: 12

                })
            }

            detailActions.push({
                name: '彻底删除',
                actionName: 'deleteMail',
                buttonIndex: 13

            })

            detailActions.push(
                {
                    name: '作为附件发送',
                    actionName: 'AttachmentsSend',
                    buttonIndex: 14
                })

            detailActions.push({
                name: '修改主题',
                actionName: 'updataSubject',
                buttonIndex: 15
            })

            detailActions.push({
                name: '设置提醒',
                actionName: 'addMsg',
                buttonIndex: 16
            })
            this.actions = detailActions
            // this.actionsShow = actionShow
            this.showAction = true
        },
        loadedContent() {
            this.frameIsLoad = true
            if (!this.dataIsLoad) {
                this._loadContent('')
                return
            }
            let showContent = ''
            let { htmlContent, content } = this.detailObject
            let isHtmlContent = false
            if (htmlContent) {
                showContent = htmlContent
                isHtmlContent = true
            } else {
                showContent = content
            }
            if (!showContent) {
                showContent = "<div style='text-align: center;  margin-top: 20px;'>无数据</div>"
            }

            if (showContent.indexOf('style=') == -1) {
                let replaceStr = {
                    '\r\n': '<br>',
                    '\r': '<div>&nbsp;</div>',
                    '\n': '<div></div>',
                    '<': '&lt;',
                    '>': '&gt;'
                }
                let reg = isHtmlContent ? /(\r\n|\r|\n)/ig : /(\r\n|\r|\n|<|>)/ig
                showContent = showContent.replace(reg, t => replaceStr[t])
            }
            this._loadContent(showContent)
        },
        _loadContent(content) {
            try {
                const vm = this.$refs.iframe.contentWindow.vm
                vm.showContent(content)
                //     this.$refs.mailFrame.setContent(content)
            } catch (err) {
                console.log(err)
                console.log(' vm.showContent(_this.content) ')
            }
        },
        getMailCurrent(mId) {
            this.dataIsLoad = false
            this.detailObject = {}
            let params = {
                mId: mId,
                dataType: 'original',
                type: 'details'
            }
            const url = this.Global.config.apiBaseURL + this.Global.api.v2.mails_mail
            this.axios.get(url, { params })
                .then((res) => {
                    this.dataIsLoad = true
                    if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isObject(res.data.data)) {
                        this.detailObject = res.data.data
                        if (this.detailObject.attachmentList) {
                            this.detailObject.attachmentList.forEach(element => {
                                element.size = element.size * 1024 // 单位转换问题
                                element.url = urlIsEncode(element.url)
                            })
                        }
                        if (this.detailObject.status == -1 && (this.currentCtid == '' || this.currentCtid == this.companie.ctId)) {
                            this.detailObject.status = 1
                            this.IdentificationMailSettings(mId)
                        }

                        if (this.frameIsLoad) {
                            this.loadedContent()
                        }
                        this.getStyle()
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                }).catch((res) => {
                    this.dataIsLoad = true
                    console.log(res)
                })
        },
        // 标识设置
        async IdentificationMailSettings(mId) {
            let data = { mIds: [mId], status: '1', type: 'status' }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                data.ctid = this.currentCtid
            } else {
                delete data.ctid
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.mails_mail
                let res = await this.axios.put(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.actionList('mark', { key: 'status', value: '1' })
                } else {
                    this.$toast.fail('已读失败')
                }
            } catch (error) {

            }
        },

        getStyle() {
            this.$nextTick(() => {
                this.topS = this.$el.querySelector('#lineId').offsetTop + 10
            })
        },
        nextMailGet() {
            this.currentIndex++
            if (this.currentIndex >= this.listData.length) {
                // this.$dialog.alert({ message: '已经是最后一封了' })
                this.$toast.fail('已经是最后一封了')
                this.currentIndex--
                return
            }
            this.set_boxAarry(this.currentIndex)
            let Id = this.listData[this.currentIndex]
            this.getMailCurrent(Id)
            /*  this.$router.push({query: {boxId: Id}}) */
        }

    },
    components: {
        'show-name': ShowName,
        'show-all': ShowAll,
        'public-space': PublicSpace,
        'file-list-show': fileListShow,

        'mail-frame': MailFrame
    },

    watch: {
        Isshow(val, old) {
            this.$nextTick(() => {
                this.getStyle()
            })
        }
    }
}
</script>
<style lang="less" scoped>
@import "./index.less";
</style>

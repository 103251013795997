<template>
    <div class="ware">

    </div>
</template>

<script>
export default {
    name: 'VoiceInput',
    props: {},
    components: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {},
    beforeDestroy() { }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
</style>

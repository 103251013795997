<template>
    <div class="UploadFiles">
        <input type="file" v-show="false" ref="inputFile" :accept="option.accept" name="" @change="fileChange($event)" :multiple="option.multiple" />
    </div>
</template>
<script>
/**
 */
import { sleep } from '@/libs/utils.js'
export default {
    name: 'UploadFiles',
    props: {
        needAccessToken: {
            type: String,
            default: 'false'
        },
        filesNum: {
            // 限制上传图片文件数量
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            uploadUrl: this.Global.config.uploadUrl,
            limitSize: 30, // 限制文件大小，单位：M
            option: {
                compress: true,
                multiple: true,
                accept: '*'
            },
            listeners: {},
            events: ['progress', 'start', 'load', 'error', 'abort', 'loadend']
        }
    },
    methods: {

        init(option = {}) {
            Object.assign(this.option, option)
            this.listeners = {}
            return {
                on: (event, listener) => {
                    this._subscribe(event, listener)
                },
                trigger: () => {
                    this._trigger()
                }
            }
        },
        _subscribe(event, listener) {
            if (this.events.includes(event)) {
                if (this.listeners[event] && Array.isArray(this.listeners[event])) {
                    this.listeners[event].push(listener)
                } else {
                    this.listeners[event] = [listener]
                }
            } else {
                console.error(`you event:{${event}} not support`)
            }
        },
        _trigger() {
            this.$nextTick(() => {
                this.$refs.inputFile.value = ''
                this.$refs.inputFile.click()
            })
        },

        async fileChange(e) {
            let filesData = e.target.files
            console.log(filesData)
            if (!this.option.multiple) {
                filesData = [filesData[0]]
            }

            if (Object.keys(this.filesNum).length > 0) {
                let remainingNum = this.filesNum.maxNum - this.filesNum.hasNum
                if (filesData.length > remainingNum) {
                    this.$toast.fail(this.$t('mx_mobile.common.1665984112091')) // '超过上传最大数量限制，将自动截取文件上传'
                    await sleep(2500)
                    filesData = Array.from(filesData).slice(0, remainingNum - filesData.length)
                }
            }

            let files = []
            for (let i = 0; i < filesData.length; i++) {
                let fileSize = (filesData[i].size / 1048576).toFixed(2) // 转成M，并保存2位小数
                if (fileSize > this.limitSize) {
                    this.$toast.fail(`${this.$t('mx_mobile.common.1665983829972')}${this.limitSize}M`) // 有文件超过了

                    return
                }
                files.push(filesData[i])
            }

            var format = []
            if (this.option.format && this.option.format !== '') {
                format = this.option.format.split(',')
            }

            let newFiles = []
            if (format.length > 0) {
                let arr = []
                for (let i = 0; i < files.length; i++) {
                    let suffix = '.' + files[i].name.split('.').pop().toLowerCase()
                    if (format.indexOf(suffix)) {
                        arr.push(files[i])
                    }
                }
                newFiles = arr
            } else {
                newFiles = files
            }

            if (newFiles.length <= 0) {
                return
            }
            let _this = this
            this.Global.utils.fileUpload({
                method: this.option.method || 'put',
                url: this.option.uploadUrl || this.uploadUrl,
                accessToken: this.needAccessToken == 'true' ? this.Global.utils.getToken()[this.Global.config.accessToken] : '',
                FileList: newFiles,
                formName: this.option.formName || 'fileToUpload',
                compress: this.option.compress,
                compressStart: function () {
                    _this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true, // 禁用背景点击
                        loadingType: 'spinner',
                        message: _this.$t('mx_mobile.common.1665983909421') // 压缩中...
                    })
                },
                compressEnd: function () {
                    _this.$toast.clear()
                },
                onprogress: function (event) {
                    if (_this.listeners.progress) {
                        _this.listeners.progress.forEach(listener => {
                            listener(event)
                        })
                    }
                },
                onloadstart: function (event) {
                    _this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true, // 禁用背景点击
                        loadingType: 'spinner',
                        message: _this.$t('mx_mobile.common.1665983964576') // '上传中...'
                    })
                    if (_this.listeners.start) {
                        _this.listeners.start.forEach(listener => {
                            listener(event)
                        })
                    }
                },
                onload: function (res) {
                    if (_this.listeners.load) {
                        _this.listeners.load.forEach(listener => {
                            listener(res)
                        })
                    }
                },
                onerror: function (event) {
                    if (_this.listeners.error) {
                        _this.listeners.error.forEach(listener => {
                            listener(event)
                        })
                    }
                },
                onabort: function (event) {
                    if (_this.listeners.abort) {
                        _this.listeners.abort.forEach(listener => {
                            listener(event)
                        })
                    }
                },
                onloadend: function (event) {
                    _this.$toast.clear()
                    if (_this.listeners.loadend) {
                        _this.listeners.loadend.forEach(listener => {
                            listener(event)
                        })
                    }
                }
            })
        }
    },
    components: {

    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
</style>

<template>
    <div class="Controls">
        <template v-if="Global.utils.isArray(value.value)">
            <div v-for="(item, index) in value.value" :key="index" @click="toSendEmail">{{item}}</div>
        </template>
        <div v-else>{{value.value}}</div>
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        value: {
            value: {
                type: Object,
                default: function () {
                    return {
                        value: []
                    }
                }
            }
        }
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        toSendEmail() {
            this.$emit('toSendEmail')
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

<style lang="less" scoped>
.Controls {
    word-wrap: break-word;
}
</style>

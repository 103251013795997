<template>
    <div class="Controls" :class="{'Controls--disabled':itemData.disabled}">
        <div class="name">
            {{itemData.cnFieldCaption}}
            <span style="color:#D0021B" v-if="prop()">*</span>
        </div>
        <!-- 请填写 -->
        <van-field style="background-color:#F5F5F5" v-model="value" clearable type="textarea" :placeholder="itemData.cnFieldHint&&itemData.cnFieldHint != '' ? itemData.cnFieldHint : $t('mx_mobile.Client.1585301831976') + this.itemData.cnFieldCaption" :disabled="itemData.disabled" :maxlength="itemData.fieldLength" rows="4" autosize />
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            value: ''
        }
    },
    mounted() {
        if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
            this.updates(this.itemData.inDefaultValue)
        }
    },
    created() {
    },
    methods: {
        updates(value) {
            this.value = value
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.value == '') {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            data[this.itemData.fieldName] = this.value
            return data
        },
        prop() {
            if (this.itemData.disabled) {
                return false
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    position: relative;
    .padding(16px, 16px, 16px, 0);
    .name {
        color: #909399;
        .font-size(14px);
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

<template>
    <div class="Controls" :class="{'Controls--disabled':itemData.disabled}">
        <span class="name">
            {{itemData.cnFieldCaption}}
            <span style="color:#D0021B" v-if="prop()">*</span>
        </span>
        <van-field type="number" onkeypress="const invalidChars = ['+', 'e', 'E']
        if(invalidChars.indexOf(event.key) !== -1){
        event.preventDefault()
        }" v-model="value" @blur="handleValueInput" :disabled="itemData.disabled" :placeholder="itemData.cnFieldHint" :size="size" :min="0" :step="showFormat()" :style="{width: rightWidth}" class="nopadding"></van-field>
    </div>
</template>

<script>
export default {
    name: 'Controls-ExchangeRate',
    props: {
        itemData: {
            type: Object,
            default: () => ({})
        },
        isProp: {
            type: Boolean,
            default: false
        },
        labelWidth: {
            type: String,
            default: '100px'
        },
        rightWidth: {
            type: String,
            default: '160px'
        },
        labelPosition: {
            type: String,
            default: 'right'
        },
        size: {
            type: String,
            default: 'small'
        },
        showLabel: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            value: '',
            rules: Object.freeze({
                input: [
                    // 请输入
                    {
                        type: 'number',
                        required: true,
                        message:
                            this.$t('mxpcweb.basecomponents.1530696912786') +
                            this.itemData.cnFieldCaption,
                        trigger: 'change'
                    }
                ]
            }),
            oninput:
                "this.value=this.value.replace(/\\D*(\\d*)(\\.?)(\\d{0,oninputNum})\\d*/,'$1$2$3')"
        }
    },
    created() {},
    computed: {
        ruleForm() {
            return {
                input: parseFloat(this.value)
            }
        }
    },
    mounted() {
        // this.$refs.number?.addEventListener('DOMMouseScroll', this.MouseWheel, false)
    },
    methods: {
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.value == '') {
                    this.$toast.fail(
                        this.itemData.cnFieldCaption +
                            this.$t('mx_mobile.Client.1585301350281')
                    ) // 不能为空
                    return false
                }
            }
            data[this.itemData.fieldName] = this.value || '0'
            return data
        },
        updates(value) {
            this.value = value
        },
        MouseWheel(event) {
            event = event || window.event
            event.preventDefault()
        },
        getOninput() {
            let str = this.oninput
            let showFormat = this.itemData.showFormat
                ? this.itemData.showFormat.slice(2)
                : ''
            return str.replace('oninputNum', showFormat.length)
        },
        showFormat() {
            if (this.itemData.showFormat && this.itemData.showFormat != '') {
                let showFormat = this.itemData.showFormat.slice(2)
                return parseFloat(
                    Math.pow(0.1, showFormat.length).toFixed(showFormat.length)
                )
            }
            return 1
        },
        handleValueInput(event) {
            let value = event.target.value
            // let inputStr = value != '' ? parseFloat(value) : ''
            // if (inputStr != '' && this.itemData.showFormat && this.itemData.showFormat != '') {
            //     let showFormat = this.itemData.showFormat.slice(2)
            //     inputStr = inputStr.toFixed(showFormat.length)
            // }
            // if (this.itemData.fieldName == 'cnyExRate') {
            //     // 本币汇率
            //     this.$emit('ExchangeRateChange', value + '', 'cnyExRate')
            // } else if (this.itemData.fieldName == 'usdExRate') {
            //     // 美元汇率
            //     this.$emit('ExchangeRateChange', value + '', 'usdExRate')
            // }
            this.$emit('input', parseFloat(value))
        },
        checkValue() {
            if (this.itemData.showFormat && this.itemData.showFormat != '') {
                let value = this.ruleForm.input
                let valueList = (value + '').split('.')
                let length = 0
                if (valueList.length == 2) {
                    length = (value + '').split('.')[1].length
                }
                let showFormat = this.itemData.showFormat.slice(2)
                if (length > showFormat.length) {
                    this.ruleForm.input = (value + '').substring(
                        0,
                        (value + '').indexOf('.') + showFormat.length + 1
                    )
                }
            }
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.isProp == true) {
                return ''
            } else {
                if (this.itemData.isNotNull == 1) {
                    return 'input'
                } else {
                    return ''
                }
            }
        }
    },
    watch: {
        'ruleForm.input': function (val) {
            this.checkValue()
        }
    }
}
</script>
<style lang="less" scoped>
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
    .padding-left(100px);
    &:lang(en) {
        .padding-left(130px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
    .nopadding {
        padding: 10px 0;
    }
}
</style>

<template>
    <div class="DynamicFilter__ware" ref="DynamicFilter">
        <!-- 类型 -->
        <van-row class="screenSpanBox">
            <van-col :span="parseInt(24 / searchOption.length)" v-for="(item, index) in searchOption" :key="index">
                <div class="screenSpan">
                    <span @click="searchIndex === index ? searchIndex = '' : searchIndex = index">
                        {{item.title}}
                        <i class="iconfont" :class="searchIndex === index?'icon-page-up':'icon-page-down'" style="font-size:12px"></i>
                    </span>
                </div>
            </van-col>
        </van-row>
        <div class="search_mask">
            <div class="search_option" v-show="searchIndex === 0">
                <ul class="sortSeachBox">
                    <li @click="mergeParam('type', '')">
                        <!-- 所有动态 -->
                        {{$t('mx_mobile.Client.1627017696921')}}
                        <span v-if="dynamicType.total">({{dynamicType.total.count}})</span>
                    </li>
                    <template v-for="(item, key, index) in dynamicType">
                        <li v-if="key!='total'" :key="index" @click="returnType(item, key)">{{item.typeDesc}}({{item.count}})</li>
                    </template>
                </ul>
            </div>
            <div class="search_option" v-show="searchIndex === 1">
                <ul class="sortSeachBox">
                    <!-- 所有联系人 -->
                    <li @click="selectRelationId = {'contName': $t('mx_mobile.Client.1627017671178')}">
                        {{$t('mx_mobile.Client.1627017671178')}}
                    </li>
                    <template v-for="(item, index) in contactsList">
                        <li :key="index" @click="selectRelationId = item">{{item.contName}}</li>
                    </template>
                </ul>
            </div>
            <div class="search_option" v-show="searchIndex === 2">
                <ul class="sortSeachBox">
                    <!-- 所有人员 -->
                    <li @click="selectOwnerId = {'nickName': $t('mx_mobile.Client.1627017639190')}">
                        {{$t('mx_mobile.Client.1627017639190')}}
                    </li>
                    <template v-for="(item, index) in personnelTable">
                        <li :key="index" @click="selectOwnerId = item">{{item.nickName}}</li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DynamicFilter',
    props: {
        detailData: {
            type: Object,
            default: () => ({})
        },
        personnelTable: {
            type: Array,
            default: () => ([])
        }
    },
    components: {
    },
    data() {
        return {
            dynamicType: {}, // 所有动态类型
            selectType: '',
            selectRelationId: '',
            selectOwnerId: '',
            itemData2: {
                /* '联系人' */
                // cnFieldCaption: this.$t('mxpcweb.workbench.1530703534191'),
                controlData: '', // 返回值->客户联系人
                isNotNull: 0,
                cnFieldHint: this.$t('mx_mobile.Client.1630721576076') // 请选择联系人
            },
            ruleForm: {
                input: '' // 所属人id
            },
            returnParam: {
                type: '',
                relationId: '',
                ownerId: ''
            }, // 返回的参数
            searchType: false,
            searchRelationId: false,
            searchOwnerId: false,
            searchIndex: '',
            searchOption: [
                {
                    title: this.$t('mx_mobile.Client.1630721066408') // 动态类型
                },
                {
                    title: this.$t('mx_mobile.Client.1585278139730') // 联系人
                },
                {
                    title: this.$t('mx_mobile.Client.1585625343907') // 创建人
                }
            ],
            isShowSearchOption: false,
            contactsList: []
        }
    },
    computed: {
    },
    watch: {
        'selectType': {
            handler(newV, oldV) {
                this.mergeParam('type', newV)
            },
            deep: true
        },
        'selectRelationId': {
            handler(newV, oldV) {
                this.mergeParam('relationId', newV.contId || '')
                this.searchOption[1].title = newV.contName
            },
            deep: true
        },
        'selectOwnerId': {
            handler(newV, oldV) {
                this.mergeParam('ownerId', newV.ctId || '')
                this.searchOption[2].title = newV.nickName
            },
            deep: true
        },
        'itemData2.controlData': {
            handler(newV, oldV) {
                this.mergeParam('relationId', newV)
            },
            deep: true
        },
        'searchIndex': {
            handler(newV, oldV) {
                if (newV !== '') {
                    document.addEventListener('click', this.hideOption)
                }
            }
        }
    },
    created() {
        this.initData()
        this.getDynamicType()
        this.getContactsData()
    },
    mounted() {
        // 点击其他隐藏
        document.addEventListener('click', this.hideOption)
    },
    methods: {
        hideOption(e) {
            if (this.$refs.DynamicFilter && !this.$refs.DynamicFilter.contains(e.target)) this.searchIndex = ''
        },
        initData() {
            this.searchIndex = ''
            document.removeEventListener('click', this.hideOption)
        },
        getContactsData() {
            let params = {
                moduleCode: 'BF003',
                searchType: 'allList',
                order: 'desc',
                sort: 'createDate',
                custId: this.detailData.custId,
                from: 0,
                size: 100
            }
            const url = this.Global.config.apiBaseURL + this.Global.api.v2.document_generalOperate_get
            this.axios.get(url, { params: params })
                .then(res => {
                    if (res.data.code.toString() === this.Global.config.RES_OK) {
                        this.contactsList = res.data.data.list || []
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                })
                .catch(res => {
                    console.log(res)
                    this.$toast.fail(this.Global.config.errorTitle)
                })
        },
        mergeParam(arg, newV) {
            if (arg == 'type' && newV == '') this.selectType = ''
            this.returnParam[arg] = newV
            this.searchIndex = ''
            if (arg == 'type' && !newV) {
                // 所有动态
                this.searchOption[0].title = `${this.$t('mx_mobile.Client.1627017696921')}` + '(' + this.dynamicType.total.count + ')'
            }
            this.$emit('getFilterParams', this.returnParam)
        },
        selectCtId() {
            this.mergeParam('ownerId', this.ruleForm.input)
        },
        returnType(item, key) {
            this.selectType = key
            this.searchOption[0].title = item.typeDesc + '(' + item.count + ')'
        },
        getDynamicType() {
            let params = {
                customerId: this.detailData.custId, // 18900519
                serviceId: this.detailData.custId,
                serviceType: 1
            }
            const url = this.Global.config.apiBaseURL + this.Global.api.dynamic.dynamic_statistics
            this.axios.get(url, { params: params })
                .then(res => {
                    if (res.data.code.toString() === this.Global.config.RES_OK) {
                        this.dynamicType = res.data.data || {}
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                })
                .catch(res => {
                    console.log(res)
                    this.$toast.fail(this.Global.config.errorTitle)
                })
        }
    },
    activated() {
        console.log('activated')
    },
    deactivated() {
        console.log('deactivated')
    },
    beforeDestroy() {
        document.removeEventListener('click', this.hideOption)
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.DynamicFilter__ware {
    position: relative;
    background: #fff;
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    height: 34px;
    line-height: 34px;
    text-align: center;
    .filed__ware {
        display: inline-block;
        width: 180px;
        margin-right: 10px;
    }
    .borderLeft {
        border-left: 2px solid #f4f4f4;
    }
    .search_mask {
        // position: absolute;
    }
    .search_option {
        position: absolute;
        width: 100%;
        z-index: 2;
        .top(34px);
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
        text-align: left;
        .height(200px);
        overflow: scroll;
        background: #fff;
        .sortSeachBox {
            .padding-left(16px);
            background: white;
            > li {
                .height(44px);
                .line-height(44px);
                border-bottom: 1px solid #f4f4f4;
                .padding-right(60px);
                position: relative;
                .icon-hook {
                    position: absolute;
                    .right(20px);
                    .font-size(12px);
                }
            }
        }
    }
}
</style>

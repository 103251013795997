<template>
    <div class="Controls-Picture" style="width:250px" v-if="value.value && value.value.length > 0">
        <div class="openImgBox">
            <span class="openImg" :class="size" v-for="(item,index) in value.value" :key="index" @click="showImg(index)">
                <template v-if="item!=''"> <img :src="picUrl(item,'60x60')"> </template>
            </span>
        </div>
    </div>
</template>

<script>
import { ImagePreview } from 'vant'
export default {
    name: 'Controls-Picture',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        value: {
            type: Object,
            default: function () {
                return {
                    value: []
                }
            }
        },
        size: {
            type: String,
            default: 'small'
        }
    },
    data() {
        return {
        }
    },
    created() {

    },
    methods: {
        showImg(index) {
            let list = []
            this.value.value.forEach(element => {
                list.push(this.picUrl(element, 'orig'))
            })
            ImagePreview({
                images: list,
                startPosition: index
            })
        },
        picUrl(picId, size) {
            return this.getGlobalImgSrc(picId, size)
        }
    },
    components: {
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.Controls-Picture {
    position: relative;
    .openImgBox {
        width: 100%;
        .openImg {
            .width(30px);
            .height(30px);
            display: inline-block;
            border-radius: 3px;
            position: relative;
            // border: 1px #cccccc solid;
            margin-right: 5px;
            margin-top: 5px;
            img {
                .width(30px);
                .height(30px);
                display: block;
                border-radius: 3px;
            }
        }
        .small {
            .width(30px);
            .height(30px);
            img {
                .width(30px);
                .height(30px);
            }
        }
        .large {
            .width(50px);
            .height(50px);
            img {
                .width(50px);
                .height(50px);
            }
        }
    }
}
</style>

<template>
    <div :class="[textList && textList.length > 0? 'hanldeHeight' : '', 'subordList']">
        <loading-new :loading="loading"></loading-new>
        <div class="crumbs" v-show="textList && textList.length > 0">
            <span :class="[index + 1 == textList.length? 'special' : '', 'crumb']" v-for="(item, index) in textList" :key="index">
                <span>{{item}}</span>
                <i v-show="index + 1 != textList.length" class="iconfont icon-page-right"></i>
            </span>
        </div>
        <van-radio-group v-model="checked">
            <div class="item" v-show="showMySelf">
                <div class="left">
                    <div class="department">
                        <!-- 我自己 -->
                        <span class="dname">{{$t('mx_mobile.workbench.1665633097451')}}</span>
                    </div>
                </div>
                <div class="right">
                    <!-- 选定 -->
                    <van-radio :name="individualConfigInfo.ctId" checked-color="#d0021b" @click="handleChoose('isPers', '我自己')">{{$t('mx_mobile.common.1665639410751')}}</van-radio>
                </div>
            </div>
            <!-- 部门 -->
            <template v-if="deptList.length > 0">
                <template v-for="item in deptList">
                    <div class="item" :key="item.dCode" v-if="!isForbidden || item.deptStatus == 1">
                        <div class="left">
                            <div class="department">
                                <span class="dname">{{item.deptName}}</span>
                                <span v-if="(item.accountList && item.accountList.length > 0) || (item.children && item.children.length > 0)" class="wrapper" @click="toNextLevel(item)">
                                    <!-- 下级 -->
                                    <span class="opt">{{$t('mx_mobile.common.1665639482953')}}</span>
                                    <i class="iconfont icon-page-right"></i>
                                </span>
                            </div>
                        </div>
                        <div class="right">
                            <!-- 选定 -->
                            <van-radio :name="item.dkey" checked-color="#d0021b" @click="handleChoose('isDept', item.deptName)">{{$t('mx_mobile.common.1665639410751')}}</van-radio>
                        </div>
                    </div>
                </template>
            </template>
            <!-- 人员 -->
            <template v-if="personList.length > 0">
                <template v-for="item in personList">
                    <div class="item" v-if="returnStatus(item)" :key="item.companies[0].ctId">
                        <div class="left">
                            <div class="person">
                                <img class="avatar" :src="getUserPicUrl(item.avatar)">
                                <span class="name">{{item.realName}}</span>
                            </div>
                        </div>
                        <div class="right">
                            <!-- 选定 -->
                            <van-radio :name="item.companies[0].ctId" checked-color="#d0021b" @click="handleChoose('isPers', item.realName)">{{$t('mx_mobile.common.1665639410751')}}</van-radio>
                        </div>
                    </div>
                </template>
            </template>
            <nodata class="nodata" v-if="deptList.length == 0 && personList.length == 0" />
        </van-radio-group>
    </div>
</template>

<script>
import { isArray } from '@/libs/utils.js'
import loadingNew from '@/page/Client/CustPublic/LoadingVue'
import { mapGetters } from 'vuex'
export default {
    name: 'SubordList',
    data() {
        return {
            loading: true,
            list: [],
            deptList: [],
            personList: [],
            cacheData: {},
            crumbsData: [],
            textList: [],
            checked: '',
            showMySelf: false
        }
    },
    computed: {
        ...mapGetters(['greyFunctions']),
        isForbidden() {
            return this.greyFunctions.includes('Forbidden_Choice')
        }
    },
    created() {
        this.getList()
    },
    beforeRouteUpdate(to, from, next) {
        let id = to.params.id
        let index
        this.crumbsData.forEach((item, ind) => {
            if (item.dkey == id) {
                index = ind
            }
        })
        this.crumbsData = this.crumbsData.slice(0, index + 1)
        let tempList = []
        this.crumbsData.forEach(item => {
            tempList.push(item.deptName)
        })
        this.textList = tempList
        if (!id) {
            this.deptList = Object.freeze(this.cacheData.subordinate.deptList)
            this.personList = Object.freeze(this.cacheData.subordinate.personList)
        } else {
            this.deptList = Object.freeze(this.cacheData[id].deptList)
            this.personList = Object.freeze(this.cacheData[id].personList)
        }
        next()
    },
    methods: {
        returnStatus(item) {
            if (this.isForbidden && item?.companies[0]?.status == -1) {
                return false
            } else {
                return true
            }
        },
        // 搜索具体某一人Tom 可能会搜出多个Tom 因为Tom可能在多个部门比如既在研发部又在市场部
        getSearchData(keyword) {
            if (keyword.trim() == '') {
                return
            }
            this.loading = true
            let params = {
                type: 'subordinate',
                moduleCode: 'WB001',
                keyword: keyword.trim()
            }
            this.showMySelf = false
            const url = this.Global.config.apiBaseURL + this.Global.api.Subordinate.getTreeMenu
            this.axios.get(url, { params }).then((res) => {
                let backList = res.data.data
                if (res.data.code.toString() === this.Global.config.RES_OK && isArray(backList)) {
                    let tmp = []
                    backList.forEach(v => {
                        if (v.accountList.length > 0) {
                            tmp.push(v.accountList[0])
                        }
                    })
                    if (tmp.length > 0) {
                        // 如果Tom位于多个部门 暂时取出结果中的第一个Tom
                        this.personList = [tmp[0]]
                    } else {
                        this.personList = []
                    }
                    this.deptList = []
                    this.loading = false
                }
            }, (res) => {
                this.loading = false
                this.$toast.fail(res.data.msg)
            })
        },
        clearData() {
            this.crumbsData = []
            this.textList = []
            this.cacheData = {
                subordinate: {
                    deptList: this.list,
                    personList: []
                }
            }
            this.deptList = this.list
            this.checked = ''
        },
        handleChoose(flag, name) {
            let query = {
                subordKey: this.checked,
                subordName: name,
                flag
            }
            this.$router.replace({ path: '/workbench', query })
        },
        toNextLevel(item) {
            let temp = {
                [item.dkey]: {
                    deptList: item.children,
                    personList: item.accountList
                }
            }
            if (!Object.keys(this.cacheData).includes(item.dkey)) {
                Object.assign(this.cacheData, temp)
            }
            this.crumbsData.push({ dkey: item.dkey, deptName: item.deptName })
            this.$router.push(`/workbench/subordinate/${item.dkey}`)
        },
        getUserPicUrl(avatar) {
            let size = '28x28'
            return this.getGlobalImgSrc(avatar, size)
        },
        getList() {
            this.loading = true
            let params = {
                type: 'subordinate',
                moduleCode: 'WB001'
            }
            const url = this.Global.config.apiBaseURL + this.Global.api.Subordinate.getTreeMenu
            this.axios.get(url, { params }).then((res) => {
                let backList = res.data.data
                if (res.data.code.toString() === this.Global.config.RES_OK && isArray(backList)) {
                    this.deptList = this.list = Object.freeze(backList)
                    this.personList = []
                    this.cacheData = {
                        subordinate: {
                            deptList: this.deptList,
                            personList: []
                        }
                    }
                    this.loading = false
                    this.showMySelf = true
                }
            }, (res) => {
                this.showMySelf = true
                this.loading = false
                this.$toast.fail(res.data.msg)
            })
        }
    },
    components: {
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" rel="stylesheet/less" >
@import "./zh-cn.less";
</style>

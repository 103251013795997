<template>
    <div>
        <div class="Controls" @click="choosePerson()" :class="{'Controls--disabled':itemData.disabled}">
            <span class="name">
                {{itemData.cnFieldCaption}}
                <span style="color:#D0021B" v-if="prop()">*</span>
            </span>
            <span>{{obj.text}}</span>
        </div>
        <van-popup v-model="show" @click-overlay="onPickerCancel" :close-on-click-overlay="false" position="bottom">
            <van-tree-select height="55vw" :items="actions" :main-active-index.sync="activeIndex" @click-item="clickItem" />
        </van-popup>
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            show: false,
            actions: [],
            text: '',
            obj: {
                text: '',
                value: ''
            },
            activeIndex: 0
        }
    },
    created() {
    },
    mounted() {
        this.unit()
    },
    methods: {
        clickItem(data) {
            this.obj.value = data.id
            this.obj.text = data.text
            this.show = false
        },
        onPickerCancel() {
            this.Global.utils.chosen.reject({ msg: this.$t('mx_mobile.Client.1585282541776') }) // 取消了
        },
        updates(value) {
            if (value == '') {
                return false
            }
            this.obj.value = value
            this.returnItem(value)
        },
        async choosePerson() {
            if (this.itemData.disabled) return
            this.show = true
            // let res = await this.Global.utils.chosen.open(this.actions, '', true)
            // this.obj = res
        },
        returnItem(id) {
            if (id) {
                for (let index = 0; index < this.AONIcustSort.length; index++) {
                    const element = this.AONIcustSort[index]
                    let second = element.dictItem.filter(item => {
                        return item.id.toString() == id.toString()
                    })[0]

                    if (second) {
                        this.obj.text = second.itemName
                        break
                    }
                }
            }
            // this.sysBoxValue.forEach(element => {
            //     if (element.dictCode == this.itemData.dictCode) {
            //         element.dictItems.forEach(item => {
            //             if (item.dictItemCode == value) {
            //                 this.obj.text = item.itemName
            //             }
            //         })
            //     }
            // })
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.obj.value == '') {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            if (this.obj.value && this.obj.value != '' && this.obj.value != 0) {
                data[this.itemData.fieldName] = this.obj.value + ''
            } else {
                data[this.itemData.fieldName] = 0
            }
            // alert(data[this.itemData.fieldName])
            return data
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        unit() {
            this.actions = []
            let options = []
            this.AONIcustSort.forEach(element => {
                let item = {
                    text: element.itemName,
                    id: element.id,
                    children: []
                }
                element.dictItem.forEach(element2 => {
                    let items = {
                        text: element2.itemName,
                        id: element2.id
                    }
                    item.children.push(items)
                })
                options.push(item)
            })
            this.actions = options

            // this.sysBoxValue.forEach(element => {
            //     if (element.dictCode == this.itemData.dictCode) {
            //         options = element.dictItems
            //     }
            // })
            // options.forEach(element => {
            //     if (element.inUse != '0') {
            //         this.actions.push({
            //             text: element.itemName,
            //             value: element.dictItemCode
            //         })
            //     }
            // })
            if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
                this.updates(this.itemData.inDefaultValue)
            }
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

<template>
    <van-popup v-model="show" position="right" class="screenSearchPop" :lock-scroll="false">
        <div class="addPeople">
            <div class="boxList">
                <div class="titleBox">
                    <!-- 客户 -->
                    <div class="title">{{$t('mx_mobile.Client.1584681452354')}}</div>
                    <van-cell class="custName" :value="cust.custName" is-link @click="custIdshow = true" />
                </div>
            </div>
            <div class="boxList boxList1">
                <!--请填写提醒内容  -->
                <van-field style="background-color:#F5F5F5" v-model="message" type="textarea" :placeholder="$t('mx_mobile.Client.1585304565180')" rows="4" autosize />
            </div>
            <div class="boxList">
                <div class="titleBox">
                    <!-- 提醒时间 -->
                    <div class="title">{{$t('mx_mobile.Client.1585304564052')}}</div>
                    <div class="content" @click="getTime()" v-if="reminderDate!=''">{{reminderDate}}
                        <i class="iconfont icon-time"></i>
                    </div>
                    <!-- 请选择提醒时间 -->
                    <div class="content1" @click="getTime()" v-else>{{$t('mx_mobile.Client.1585304556358')}}
                        <i class="iconfont icon-time"></i>
                    </div>
                </div>
            </div>
            <van-popup v-model="custIdshow" position="right" class="custBox" :lock-scroll="false">
                <cust-lists @submitItem="submitItem"></cust-lists>
            </van-popup>
        </div>
        <DatePickerTime ref="datePickerTime"></DatePickerTime>
    </van-popup>
</template>
<script>
import custList from '@/page/Client/CustPublic/Controls/Customer/custList/index'
import DatePickerTime from '@/components/PublicControls/DatePickerTime/index'
// import titleMixin from '@/mixin/title'
/**
 * 描述：客户管理-客户列表-新增客户
 * 作者：何俊峰
 * 时间：2017/11/21
 */
export default {
    title: function fc() { return this.$t('mx_mobile.Client.1585279571604') }, // '提醒'
    name: 'otReminder',
    // mixins: [titleMixin],
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: ''
        },
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            commentFlag: '1',
            show: false,
            cust: {
                custName: '',
                custId: ''
            },
            message: '',
            custIdshow: false,

            reminderDate: ''
        }
    },
    created() {
    },
    mounted() {
        this.getData()
    },
    computed: {
    },
    methods: {
        setMenu() {
            setTimeout(() => {
                let setMenu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Client.1585301134763'))// 保存
                setMenu.onClick(() => {
                    this.submitAddFrom()
                })
            }, 100)
        },
        closeThis() {
            this.show = false
            this.$emit('setMenu')
            this.$emit('setTitle')
        },
        submitItem(value) {
            this.cust = {
                custName: value.custName,
                custId: value.custId
            }
            this.custIdshow = false
        },
        async getTime() {
            this.$refs.datePickerTime.getTime((res) => {
                this.reminderDate = res
            })
        },
        setCommentsColor(flag) {
            return this.Global.utils.commentsColor(flag)
        },
        // 初始第一个下拉先 <-_->
        getData() {
        },
        openWindow() {
            this.setHeaderTitle(this.$t('mx_mobile.Client.1585304673099')) // 设置提醒
            this.show = true
            this.setMenu()
            this.cust = {
                custName: this.item.custName,
                custId: this.item.custId
            }
        },
        submitAddFrom() {
            let _this = this
            let ctId = _this.companie.ctId
            // if (!ctId) {
            //     return false
            // }
            let data = {
                body: {
                    msgBody: _this.message,
                    eventId: ''
                },
                moduleCode: _this.moduleCode,
                billId: _this.cust.custId + '',
                iType: '2',
                msgSubType: '1',
                targets: ctId + '',
                sourceId: 'pc',
                deliveryTime: _this.reminderDate + ':00',
                fromAppCode: '',
                formModuleCode: ''
            }
            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: this.$t('mx_mobile.Client.1585300903213') // 保存中...
            })
            _this.axios.post(_this.Global.config.apiBaseURL + _this.Global.api.v2.messenger_post, data).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    _this.$toast.clear()
                    _this.$emit('getListData')
                    _this.closeThis()
                    _this.show = false
                    _this.$toast.success(res.data.msg)
                } else {
                    _this.$toast.clear()
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.clear()
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        }
    },
    watch: {
    },
    components: {
        'cust-lists': custList,
        'DatePickerTime': DatePickerTime
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

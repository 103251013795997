<template>
    <div class="funcMark5">
        <loading-new :loading="loading"></loading-new>
        <template v-if="!loading">
            <nodata v-if="list.length == 0" :title="title" img="noCustomer"></nodata>
            <div v-else class="list" v-for="(item, index) in list" :key="index">
                <img class="imgPeople" src="/images/avatar/noAvatar.png" alt="">{{returnRealName(item.cooperateCtId)}}
            </div>
        </template>
    </div>
</template>
<script>
import loadingNew from '../../../../CustPublic/LoadingVue/index.vue'
export default {
    name: 'funcMark5',
    props: {
        detailData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        personnelTable: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            list: [],
            title: this.$t('mx_mobile.Client.1585279765346'), // 暂无共享
            loading: true
        }
    },
    created() {
        this.getData()
    },
    mounted() {
    },
    methods: {
        returnRealName(ctId) {
            let realName = ''
            this.personnelTable.forEach(element => {
                if (element.ctId == ctId) {
                    realName = element.realName
                }
            })
            return realName
        },
        getData() {
            let data = {
                type: 'cooperatePersonList',
                custId: this.detailData.custId
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.customerCooperate_do, { params: data }).then((res) => {
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    this.list = res.data.data
                    this.title = this.$t('mx_mobile.Client.1585279765346') // 暂无共享
                    this.loading = false
                } else {
                    this.title = res.data.msg
                    this.list = []
                    this.loading = false
                }
            }, function (res) {
                this.$toast.fail(this.Global.config.errorTitle)
            })
        }
    },
    watch: {
    },
    components: {
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

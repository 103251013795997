<template>
    <div class="fileListShow">
        <ul v-if="fileList.length>0">
            <li v-for="(item,index) in fileList" :key="index" @click="previewImage(item)">
                <span class="icon">
                    <!-- <img v-if="isPicture(item.name.toLowerCase())" ref="image" :src="getimg(item.url)"> -->
                    <list v-if="isPicture(item.name.toLowerCase())" :item="item" ref="list"></list>
                    <svg v-else class="iconSVG" aria-hidden="true">
                        <use :xlink:href="getSuffixSVG(item.name)"></use>
                    </svg>
                </span>
                <div class="descr">
                    <div class="name">{{item.name}}</div>
                    {{ GetSize((item.size/1024).toFixed(2))}}
                </div>
                <span class="buts" v-show="close==1" @click.stop="delThis(index)">
                    <!--<i class="iconfont icon-hook"></i>-->
                    <i class="iconfont icon-close"></i>
                </span>
            </li>
        </ul>
    </div>

</template>
<script>
import { urlIsEncode } from '@/libs/utils.js'
import list from './list'
export default {
    name: 'fileListShow',
    props: {
        attachmentList: {
            type: Array,
            default: function () {
                return []
            }
        },
        close: {
            type: Number,
            default: function () {
                return 0
            }

        }
    },
    data() {
        let _this = this
        return {
            homeTitle: '附件显示',
            fileList: _this.attachmentList
            // ,  uploadUrl: this.Global.uploadUrl, // 图片服务器上传URL
            // picUrl: this.Global.imgBaseSrc, // 图片展示url前缀
            // fileUrl: this.Global.downloadBaseUrl // 文件下载url前缀

        }
    },
    computed: {

    },
    created() {

    },
    mounted() {

    },
    methods: {

        getUrl(url) {
            return urlIsEncode(url)
        },
        GetSize(size) {
            if (size > 1024) {
                return (size / 1024).toFixed(2) + ' M'
            } else {
                return size + ' KB'
            }
        },
        // getUrl(currentUrl) {
        //     if (currentUrl.indexOf(this.Global.config.downloadBaseUrl) != -1) {
        //         // imgBaseSrc
        //         let arry = currentUrl.split('/')
        //         return this.Global.config.imgBaseSrc + arry[arry.length - 1]
        //     }
        //     return currentUrl
        // },
        // 判断是不是图片
        isPicture(name) {
            let picFormat = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
            return picFormat.indexOf(this.Global.utils.getSuffix(name)) !== -1
        },
        getSuffixSVG(filename) {
            let suffix = this.Global.utils.getSuffix(filename)
            return '#file-' + this.Global.utils.isHasSuffix(suffix)
        },
        // 删除当前条
        delThis(index) {
            this.$emit('delAttachmentList', index)
        },
        async previewImage(item) {
            let SuffixName = this.Global.utils.getSuffix(item.name.toLowerCase())
            try {
                if (!this.Global.utils.filePreview.isSupport(SuffixName)) {
                    this.$toast('暂不支持该格式文件预览')
                    return
                }
                this.Global.utils.filePreview.open(item.url, SuffixName, `${item['fileName'] || item['name'] || (new Date().getTime() % 100000)}.${SuffixName}`)
            } catch (error) {
                alert(error)
            }
        }

    },
    components: {
        list: list
    },
    watch: {
        attachmentList: function (params) {
            this.fileList = this.attachmentList
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

<template>
    <div>
        <van-dialog v-model="show" show-cancel-button :before-close="beforeClose">
            <div @click="dateFocus()">
                <!-- 延迟时间  请选择延迟时间 -->
                <van-field v-model="dateTime" :label="$t('mx_mobile.workbench.1584939743820')" :placeholder="$t('mx_mobile.workbench.1584939767660')" disabled />
            </div>
        </van-dialog>
        <DatePickerTime ref="datePickerTime" />
    </div>
</template>

<script>
import DatePickerTime from '@/components/PublicControls/DatePickerTime/index'
export default {
    name: 'dialogDelay',
    data() {
        return {
            show: false,
            dateTime: '',
            // dateTime: '2019-04-17 08:00',
            item: {} // 当前操作的消息对象
        }
    },
    methods: {
        dateFocus() {
            this.$refs.datePickerTime.getTime((res) => {
                this.dateTime = res
            })
        },
        // 点确定
        async beforeClose(action, done) {
            if (action === 'confirm') {
                if (this.dateTime == '') {
                    // '请选择延迟时间'
                    this.$toast(this.$t('mx_mobile.workbench.1584939767660'))
                    done(false)
                } else {
                    // 判断item 来自 日程？还是待处理
                    if (this.item.jobName) {
                        await this.delayAPI2() ? done() : done(false)
                    } else {
                        await this.delayAPI() ? done() : done(false)
                    }
                }
            } else {
                done()
            }
        },
        async delayAPI() {
            console.log('待处理')
            let back
            await this.axios.put(this.Global.config.apiBaseURL + this.Global.api.v2.message_put, {
                msgId: this.item.msgId,
                type: 'delay',
                deliveryTime: this.dateTime + ':00',
                sourceId: 'mobile'
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success(res.data.msg)
                    console.log(' jjjK  ')
                    this.$emit('success') // 通知上级
                    back = true
                } else {
                    this.$toast.fail(res.data.msg)
                    back = false
                }
            }).catch(error => {
                console.log(error)
            })
            return back
        },
        async delayAPI2() {
            console.log('日程')
            let back
            await this.axios.put(this.Global.config.apiBaseURL + this.Global.api.v2.messenger_put, {
                jobName: this.item.jobName,
                deliveryTime: this.dateTime + ':00' // 接口须带秒
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success(res.data.msg)
                    this.$emit('success') // 通知上级
                    back = true
                } else {
                    this.$toast.fail(res.data.msg)
                    back = false
                }
            }, function (res) {
                this.$toast.fail(res.data.msg)
                back = false
            })
            return back
        },
        // 父来调
        open(item) {
            this.item = item
            this.show = true
        }
    },
    components: {
        DatePickerTime
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
</style>

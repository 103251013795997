<template>
    <div class="ShowAll">
        <template v-if="Isshow==false">
            <span v-for="(item,index) in recipientsArry" :key="index+'re'">{{getName(item)}}，</span>
            <span v-for="(item,index) in ccArry" :key="index+'cc'"> {{getName(item)}}</span>
        </template>
    </div>
</template>
<script>
import { getName } from '../../../../PublicMethod.js'
export default {
    name: 'ShowAll',
    props: {
        recipientsArry: {
            type: Array,
            default: function () {
                return []
            }
        },
        ccArry: {
            type: Array,
            default: function () {
                return []
            }
        },
        Isshow: {
            type: Boolean,
            default: function () {
                return false
            }
        }
    },
    data() {
        return {
            homeTitle: '孚盟邮'

        }
    },
    computed: {
    },
    created() {

    },
    mounted() {

    },
    methods: {
        getName

    },
    components: {
    }
}
</script>
<style lang="less" scoped>
.ShowAll {
    .height(20px);
    display: inline-block;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #d0021b;
}
</style>

<template>
    <div class="funcMark1">
        <div v-for="(item, index) in detailSet" :key="index" :class="item.fieldCategory == '4' ? 'list1' : 'list'">
            <div class="listBox" v-if="item.fieldCategory == '4'">
                <span class="title">{{item.cnFieldCaption}}</span>
            </div>
            <!-- 解决报错问题 -->
            <div class="listBox" v-else-if="item.fieldCategory != '4' && item.controlTypeId != 127 && item.controlTypeId != 126 && item.controlTypeId != 69">
                <span class="title">{{item.cnFieldCaption}}</span>
                <component v-if="item.fieldGroup == 0" class="ControlsShow" v-bind:is="'ControlsShow'+item.controlTypeId" :itemData="item" :value="{value:detailData[item.fieldName]}" ref="ControlsShow" :currency="currency" :sysBoxValue="sysBoxValue" :AONIcustSort="AONIcustSort" :cusBoxValue="cusBoxValue"></component>
                <template v-else-if="item.fieldGroup == 24">
                    <template v-for="(items,indexs) in item.group">
                        <div :key="indexs">
                            <span class="title">{{items.cnFieldCaption}}</span>
                            <component class="group" v-bind:is="'group'+item.fieldGroup" :cusBoxValue="cusBoxValue" :item="items" :items="detailData" ref="group"></component>
                        </div>
                    </template>

                </template>
                <component v-else class="group" v-bind:is="'group'+item.fieldGroup" :cusBoxValue="cusBoxValue" :item="item" :items="detailData" ref="group"></component>
            </div>
        </div>
    </div>
</template>
<script>
import ControlsShow from '@/page/Client/CustPublic/ControlsShow/index.js'
import GroupControlsShow from '@/page/Client/CustPublic/GroupControlsShow/index.js'

export default {
    name: 'funcMark1',
    props: {
        detailSet: {
            type: Array,
            default: function () {
                return []
            }
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        },
        currency: {
            type: Array,
            default: function () {
                return []
            }
        },
        moduleCode: {
            type: String,
            default: ''
        },
        detailData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        gettitle(item) {
            if (item.fieldGroup != 24) {
                return item.cnFieldCaption
            }
        },
        getData() { },
        getIcon(trackMode) {
            switch (trackMode) {
                case '1':
                    return 'icon-telephone'
                case '2':
                    return 'icon-mail'
                case '3':
                    return 'icon-one-to-one'
                case '4':
                    return 'icon-customer'
                case '5':
                    return 'icon-mail-a'
                case '6':
                    return 'icon-print'
                case '':
                    return 'icon-go'
            }
        }
    },
    watch: {
    },
    components: Object.assign({
    }, ControlsShow, GroupControlsShow)
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

<template>
    <div>
        <div @click="openActionSheet()" class="Controls" :class="{'Controls--disabled':itemData.disabled}">
            <span class="name">
                {{itemData.cnFieldCaption}}
                <span style="color:#D0021B" v-if="prop()">*</span>
            </span>
            <span>{{obj.name}}</span>
        </div>
        <!-- 取消 -->
        <van-action-sheet v-model="show" :actions="actions" @select="onSelect" :cancel-text="$t('mx_mobile.Client.1585301326634')" @cancel="cancel" />
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            show: false,
            actions: [],
            name: '',
            obj: {
                name: '',
                dictItemCode: ''
            }
        }
    },
    created() {
    },
    mounted() {
        this.unit()
        if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
            this.updates(this.itemData.inDefaultValue)
        }
    },
    methods: {
        openActionSheet() {
            if (this.itemData.disabled) return
            this.show = (this.actions.length != 0)
        },
        updates(value) {
            if (value == '') {
                return false
            }
            this.obj.dictItemCode = value
            this.returnItem(value)
        },
        cancel() {
            this.show = false
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.obj.dictItemCode == '') {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            data[this.itemData.fieldName] = this.obj.dictItemCode + ''
            return data
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        onSelect(item) {
            // 点击选项时默认不会关闭菜单，可以手动关闭
            this.show = false
            this.obj = item
        },
        returnItem(value) {
            this.cusBoxValue.forEach(element => {
                if (element.dictCode == this.itemData.dictCode) {
                    element.customDictitems.forEach(item => {
                        if (item.dictItemCode == value) {
                            this.obj.name = item.itemName
                        }
                    })
                }
            })
        },
        unit() {
            let options = []
            this.cusBoxValue.forEach(element => {
                if (element.dictCode == this.itemData.dictCode) {
                    options = element.customDictitems
                }
            })
            options.forEach(element => {
                element.name = element.itemName
                element.disabled = (element.inUse == '0')
            })
            this.actions = options
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

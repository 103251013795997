<template>
    <div class="Controls">
        <div v-for="(item3,index3) in returnList(value.value)" :key="index3" :title="item3.value.value">
            <span @touchstart="socialUrl(item3,item3.value.value)">
                <i class="iconfont" :class="item3.iconUri"></i>
                {{item3.value}}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        value: {
            type: Object,
            default: function () {
                return {
                    value: {}
                }
            }
        },
        socials: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        // 社交账号点击跳转
        socialUrl(obj, value) {
            if (obj.uri) {
                let uri = obj.uri.replace('{accountNo}', value)
                window.open(uri, uri.substring(0, 4) == 'http' ? '' : '_self')
            }
        },
        returnList(list) {
            let _this = this
            let socialArray = []
            if (list) {
                Object.keys(list).forEach(function (item1) {
                    if (list[item1] != '') {
                        _this.socials.forEach(function (item2) {
                            if (item1 == item2.socialId) {
                                socialArray.push({
                                    iconUri: item2.iconUri,
                                    socialId: item2.socialId,
                                    socialName: item2.socialName,
                                    typeFlag: item2.typeFlag,
                                    uri: item2.uri,
                                    value: list[item1]
                                })
                            }
                        })
                    }
                })
            }
            return socialArray
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

<template>
    <div class="TagAdd">
        <div class="addClass">
            <van-field v-model="labelName" placeholder="请输入标签名称" />
        </div>
        <div class="footClass" @click="submit()">完成</div>
    </div>

</template>
<script>
export default {
    name: 'TagAdd',
    props: {
        moduleCode: {
            type: String,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            labelName: ''
        }
    },
    computed: {

    },
    created() {
    },
    mounted() {
    },
    methods: {
        // 完成
        async submit() {
            this.colorInt = Math.floor((Math.random() * 10) + 1)
            let data = { color: this.colorInt, moduleCode: this.moduleCode, labelName: this.labelName }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.label_add
                let res = await this.axios.post(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success('添加成功')
                    this.$emit('addTags')
                    this.labelName = ''
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        }

    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

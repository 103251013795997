<template>
    <div class="Controls">
        {{value.value}}
    </div>
</template>

<script>
export default {
  name: 'Controls',
  props: {
    value: {
            type: Object,
            default: function () {
                return {
                    value: ''
                }
            }
        }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  },
  watch: {
  },
  components: {
  }
}
</script>

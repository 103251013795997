<template>
    <div class="Controls">
        {{ items ? items.custName : value.value}}
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        value: {
            type: Object,
            default: function () {
                return {
                    value: ''
                }
            }
        },
        items: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    },
    watch: {
    },
    components: {
    }
}
</script>

<template>
    <div class="Controls">
        {{itemData.cnFieldCaption}}
    </div>
</template>

<script>
export default {
  name: 'Controls',
  props: {
    itemData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    type: {
        type: String,
        default: 'otNew'
    }
  },
  data () {
    return {
    }
  },
  mounted () {
    if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
      this.updates(this.itemData.inDefaultValue)
    }
  },
  created () {
  },
  methods: {
    updates(value) {
      if (value == '') {
        return false
      }
    },
    submit() {
      let data = {}
      return data
    }
  },
  watch: {
  },
  components: {
  }
}
</script>

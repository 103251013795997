<template>
    <div>
        <span :class="status==1?'Invisible1':''" class="shortName">{{name}}</span>
        <span class="cust-category cust-category--strange" v-if="!item.category">陌</span>
        <span class="cust-category" v-if="item.category==1">客</span>
        <span class="cust-category" v-if="item.category==2">供</span>
        <span class="cust-category" v-if="item.category==3">内</span>
        <span class="cust-category" v-if="item.category==4">{{getType(item.mark)}}</span>
    </div>
</template>

<script>
import { getName, getType } from '../../../PublicMethod.js'
export default {
    name: '',
    props: {
        item: {
            type: Object,
            default: () => ({})
        },
        status: {
            type: String,
            default: '1'
        }
    },
    computed: {
        name() {
            return this.getName(this.item)
        }

    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        getName,
        getType

    },
    components: {
    }
}
</script>
<style lang="less" rel="stylesheet/less" scoped>
.shortName {
    max-width: 40%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Invisible1 {
    color: #606266;
}

.cust-category {
    display: inline-block;
    .font-size(12px);
    font-style: normal;
    .width(20px);
    .height(20px);
    .line-height(20px);
    text-align: center;
    border-radius: 50%;
    background: #d0021b;
    .margin-left(4px);
    color: #fff;
    transform: scale(0.8);
    &--strange {
        background: #303133;
    }
}
</style>

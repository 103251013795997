/*
[{
  "cnFieldCaption": "跟进方式",
  "cnFieldHint": "",
  "controlTypeId": 2,//决定使用哪个控件
  "dictCode": 12,
  "fieldCategory": 2,
  "fieldGroup": 0,
  "fieldLength": 3,
  "fieldName": "trackMode",
  "inDefaultValue": "",
  "isNotNull": 0,
  "strucId": 1,
  "uniqueCheck": 0
}]
*/
import Text from './Text/index.vue'
import SystemDownBox from './SystemDownBox/index.vue'
import CustomDownBox from './CustomDownBox/index.vue'
import MultilineText from './MultilineText/index.vue'
import Time from './Time/index.vue'
import Integer from './Integer/index.vue'
import Decimal from './Decimal/index.vue'
import AmountOfMoney from './AmountOfMoney/index.vue'
import AutoNumber from './AutoNumber/index.vue'
import MobilePhone from './MobilePhone/index.vue'
import Mailbox from './Mailbox/index.vue'
import Block from './Block/index.vue'
import Picture from './Picture/index.vue'
import Attachment from './Attachment/index.vue'
import Customer from './Customer/index.vue'
import PersonnelSelection from './PersonnelSelection/index.vue'
import SelectedPersonnel from './SelectedPersonnel/index.vue'
import SectorSelection from './SectorSelection/index.vue'
import MultipleDepartments from './MultipleDepartments/index.vue'
import Radio from './Radio/index.vue'
import SelectDropDown from './SelectDropDown/index.vue'
import Owner from './Owner/index.vue'
import Currency from './Currency/index.vue'
import ExchangeRate from './ExchangeRate/index.vue'
import GrossWeight from './GrossWeight/index.vue'
import Supplier from './Supplier/index.vue'
import Contacts from './Contacts/index.vue'
import Country from './Country/index.vue'
import Province from './Province/index.vue'
import City from './City/index.vue'
import Town from './Town/index.vue'
import SubordinateDepartme from './SubordinateDepartme/index.vue'
import ChooseCustom from './ChooseCustom/index.vue'
import TradeType from './TradeType/index.vue'
import Sex from './Sex/index.vue'
import Birthday from './Birthday/index.vue'
import SocialContact from './SocialContact/index.vue'
import Tel from './Tel/index.vue'
import Web from './Web/index.vue'
import customStr2 from './CustomStr2/index.vue'
import Score from './Score/index.vue'
import Intimacy from './Intimacy/index.vue'
import Port from './Port/index.vue'
import VoiceInput from './VoiceInput/index.vue' // 语音输入 - PC移动端都不显示
import AONIcustSort from './AONIcustSort/index.vue'
import region from './region/index.vue'
import SystemDownBoxForGC from './SystemDownBoxForGC/index.vue' // 共创项目特殊下拉框
import TimeDate from './TimeDate/index.vue'
export default {
  'control1': Text,
  'control2': SystemDownBox,
  'control3': CustomDownBox,
  'control4': MultilineText,
  'control5': Time,
  'control6': Integer,
  'control7': Decimal,
  'control8': AmountOfMoney,
  'control9': Decimal,
  'control10': Decimal,
  'control11': AutoNumber,
  'control12': MobilePhone,
  'control13': Mailbox,
  'control14': Block,
  'control15': Picture,
  'control16': Attachment,
  'control17': Customer,
  'control18': Radio,
  'control19': SelectDropDown,
  'control20': PersonnelSelection,
  'control21': SelectedPersonnel,
  'control22': SectorSelection,
  'control23': MultipleDepartments,
  'control24': Owner,
  'control25': Currency,
  'control26': ExchangeRate,
  'control27': Decimal,
  'control28': GrossWeight,
  'control29': Decimal,
  'control30': Supplier,
  'control31': Contacts,
  'control32': Country,
  'control33': Province,
  'control34': City,
  'control35': Town,
  'control36': SubordinateDepartme,
  'control37': ChooseCustom,
  'control38': TradeType,
  'control39': Sex,
  'control40': Birthday,
  'control41': SocialContact,
  'control42': Tel,
  'control43': Web,
  'control44': TimeDate,
  'control46': customStr2,
  'control47': Score,
  'control56': Port,
  'control71': Intimacy,
  'control84': AutoNumber,
  'control89': Decimal,
  'control104': VoiceInput,
  'control110': region,
  'control111': AONIcustSort,
  'control113': Text,
  'control114': Text,
  'control115': Text,
  'control127': SystemDownBox,
  'control143': SelectDropDown,
  'control150': Text,
  'control193': SystemDownBoxForGC
}

<template>
    <div class="Controls" :class="{'Controls--disabled':itemData.disabled}">
        <span class="name">
            {{itemData.cnFieldCaption}}
            <span style="color:#D0021B" v-if="prop()">*</span>
        </span>
        <!-- <van-cell-group>
            <van-field style="padding-left:0" v-model="value" clearable :placeholder="itemData.cnFieldHint" :maxlength="itemData.fieldLength">
                <van-button slot="button" size="small" type="primary" @click.prevent="addMail">新增</van-button>
            </van-field>
        </van-cell-group> -->
        <van-cell-group v-for="(item, index) in dynamicValidateForm.mailVals" :key="item.key" :prop="'mailVals.' + index + '.value'">
            <van-field style="padding-left:0" v-model="item.value" clearable :disabled="itemData.disabled" :placeholder="itemData.cnFieldHint" :maxlength="itemData.fieldLength" @change="change">
                <!-- 新增 -->
                <van-button slot="button" size="small" type="primary" @click.prevent @click.stop="addMail" v-show="index === 0">{{$t('mx_mobile.Client.1585272488531')}}</van-button>
                <!-- 删除 -->
                <van-button slot="button" size="small" type="primary" @click.prevent @click.stop="removeMail(item)" v-show="index !== 0">{{$t('mx_mobile.Client.1585301969072')}}</van-button>
            </van-field>
        </van-cell-group>
    </div>
</template>

<script>
import { isArray } from '@/libs/utils.js'
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            value: [],
            list: [],
            dynamicValidateForm: {
                mailVals: [{
                    value: ''
                }]
            }
        }
    },
    created() {
    },
    mounted() {
        if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
            this.updates(this.itemData.inDefaultValue)
        }
    },
    methods: {
        removeMail(item) {
            var index = this.dynamicValidateForm.mailVals.indexOf(item)
            if (index !== -1) {
                this.dynamicValidateForm.mailVals.splice(index, 1)
            }
        },
        change() {
            let arr = []
            this.dynamicValidateForm.mailVals.forEach(function (item) {
                if (arr.indexOf(item.value) == -1) {
                    arr.push(item.value)
                }
            })
        },
        addMail() {
            this.dynamicValidateForm.mailVals.push({
                value: '',
                key: Date.now()
            })
        },
        updates(value, disable) {
            // if (!value || value == '') {
            //     return false
            // }
            // if (value.length == 0) {
            //     return false
            // } else if (value.length == 1) {
            //     this.value = value[0]
            // } else {
            //     this.value = value[0]
            //     this.list = value
            // }
            if (isArray(value)) {
                this.updata(value)
            } else {
                this.dynamicValidateForm.mailVals = [{
                    value: value
                }]
            }
            if (disable) {
                this.dynamicValidateForm.mailVals[0].disable = disable
            }
        },
        // 修改数据时调用，用于更新传入的数据
        updata(items) {
            if (items && isArray(items) && items.length > 0) {
                this.dynamicValidateForm.mailVals = []
                items.forEach((item) => {
                    this.dynamicValidateForm.mailVals.push({
                        value: item
                    })
                })
            } else if (this.itemData.fieldValue && isArray(this.itemData.fieldValue) && this.itemData.fieldValue.length > 0) {
                // 先清空，再重新追加
                this.dynamicValidateForm.mailVals = []
                this.itemData.fieldValue.forEach((item) => {
                    this.dynamicValidateForm.mailVals.push({
                        value: item
                    })
                })
            } else {
                this.clearData()
            }
            // this.submitForm();//先对原有值验证一下
        },
        clearData() {
            this.dynamicValidateForm.mailVals = [{
                value: ''
            }]
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                let isPorp = false
                this.dynamicValidateForm.mailVals.forEach(item => {
                    if (item.value && item.value != '') {
                        isPorp = true
                    }
                })
                if (!isPorp) {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            // if (this.list > 0) {
            //     this.list[0] = this.value
            //     data[this.itemData.fieldName] = this.list
            // } else {
            //     data[this.itemData.fieldName] = []
            //     data[this.itemData.fieldName].push(this.value)
            // }
            if (this.dynamicValidateForm.mailVals.length > 0) {
                data[this.itemData.fieldName] = []
                this.dynamicValidateForm.mailVals.forEach(item => {
                    data[this.itemData.fieldName].push(item.value)
                })
            }
            return data
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    position: relative;
    .min-height(45px);
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

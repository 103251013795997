<template>
    <div :class="custContactsList.length == 0 ? '' : 'funcMark4'">
        <loading-new :loading="loading"></loading-new>
        <template v-if="!loading">
            <nodata v-if="custContactsList.length == 0" :title="title" img="noFollow"></nodata>
            <div v-else v-for="(items, indexs) in custContactsList" :key="indexs">
                <div v-if="indexs == '0'" class="list1">
                    <!-- 主联系人 -->
                    {{$t('mx_mobile.Client.1585279696450')}}
                    <span class="more" @click="moreClick(items)"><i class="iconfont icon-more" style="font-size:20px"></i></span>
                </div>
                <!-- 联系人 -->
                <div v-else class="list1">{{$t('mx_mobile.Client.1585278139730')}}<span class="more" @click="moreClick(items)"><i class="iconfont icon-more" style="font-size:20px"></i></span></div>
                <div v-for="(item, index) in list" :key="index" :class="item.fieldCategory == '4' ? 'list1' : 'list'">
                    <div class="listBox" v-if="item.fieldCategory == '4'">
                        <span class="title">{{item.cnFieldCaption}}</span>
                    </div>
                    <div class="listBox" v-else>
                        <span class="title">{{item.cnFieldCaption}}</span>
                        <component v-if="item.fieldGroup == 0" @toSendEmail="toSendEmail" class="ControlsShow" v-bind:is="'ControlsShow'+item.controlTypeId" :itemData="item" :value="{value:items[item.fieldName]}" ref="ControlsShow" :sysBoxValue="sysBoxValue" :cusBoxValue="cusBoxValue" :socials="socials"></component>
                        <!-- <component v-else class="group" v-bind:is="'group'+item.fieldGroup" :items="items" ref="group"></component> -->
                    </div>
                </div>
            </div>
        </template>
        <van-dialog v-model="showOther" show-cancel-button :before-close="beforeClose">
            <div class="optOtherMsg">
                {{optOtherMsg}}
            </div>
        </van-dialog>
    </div>
</template>
<script>
import ControlsShow from '@/page/Client/CustPublic/ControlsShow/index.js'
import GroupControlsShow from '@/page/Client/CustPublic/GroupControlsShow/index.js'
import loadingNew from '../../../../CustPublic/LoadingVue/index.vue'

export default {
    name: 'funcMark4',
    props: {
        detailData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        detailOptBF003: {
            type: Array,
            default: function () {
                return []
            }
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            list: [],
            socials: [],
            custContactsList: [],
            title: this.$t('mx_mobile.Client.1585279705362'), // 暂无联系ren
            loading: true,
            items: {},
            showOther: false,
            optItem: {},
            optOtherMsg: ''
        }
    },
    created() {
        this.unit()
    },
    mounted() {
    },
    methods: {
        toSendEmail() {
            console.log('check phone call')

            this.$emit('toSendEmail')
        },
        toOtherPage(url) {
            this.$router.push({ path: url, query: this.g_setQuery() })
        },
        beforeClose(action, done) {
            if (action === 'confirm') {
                this.optEvent(this.optItem, done)
            } else {
                done()
            }
        },
        optEvent(item, done) {
            let _this = this
            let data, url
            let optCodes = item.optCode
            if (optCodes == 'otFocus') {
                optCodes = item.optCodes
            }
            data = {
                optModuleCode: item.optModuleCode,
                identFieldValue: _this.items.contId,
                optCode: optCodes
            }
            url = _this.Global.api.v2.document_operate_detailOpt_put
            _this.axios.put(_this.Global.config.apiBaseURL + url, data).then(function (res) {
                if (res.data.code.toString() === _this.Global.config.RES_OK) {
                    _this.$toast.success(res.data.msg)
                    if (item.optCode == 'otDelete' || item.optCode == 'otPutSeas') {
                        _this.$router.back()
                    } else {
                        _this.getData()
                    }
                    done()
                } else {
                    _this.$toast.fail(res.data.msg)
                    done()
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
                done()
            })
        },
        async moreClick(itemsData) {
            try {
                let option = []
                this.detailOptBF003.forEach(items => {
                    if (items.optCode != 'otFocus' && items.optCode != 'otTag' && items.optCode != 'otPrimaryContact' && !(items.optCode == 'otNew' && items.optModuleCode == 'BF004')) {
                        items.name = items.optName
                        option.push(items)
                    }
                })
                console.log('qimiaode1shuju1', option)
                let res = await this.Global.utils.actionSheet.open(option)
                this.optClick(res, itemsData)
            } catch (error) {
                console.log(error)
            }
        },
        optClick(item, itemsData) {
            this.optItem = item
            this.items = itemsData
            this.rightCheck(item, itemsData)
        },
        rightCheck(item, itemsData) {
            let _this = this
            let obj = {
                moduleCode: 'BF003',
                identFieldValue: itemsData.contId,
                optCode: item.optCode
            }
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.document_rightCheck_do, { params: obj }).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    if (item.optCode == 'otEdit') {
                        let data = itemsData
                        _this.Global.utils.setItem('editObj' + item.optModuleCode, JSON.stringify(data))
                        _this.toOtherPage('/client/edit/' + item.optModuleCode + '/' + itemsData.contId)
                    } else {
                        _this.optOtherMsg = '是否要对此联系人进行' + item.optName + '操作'
                        _this.showOther = true
                    }
                } else {
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        unit() {
            let _this = this
            var p1 = new Promise((resolve, reject) => {
                _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.fieldShow_get, {
                    params: {
                        moduleCode: 'BF003',
                        type: 'viewSet'
                    }
                }).then((res) => {
                    if (res.data.code.toString() === _this.Global.config.RES_OK) {
                        resolve(res.data.data)
                    } else {
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })

            var p2 = new Promise((resolve, reject) => {
                _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.socialType_get, { params: {} }).then(function (res) {
                    if (res.data.code.toString() == _this.Global.config.RES_OK) {
                        resolve(res.data.data)
                    } else {
                        resolve([])
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })

            var p3 = new Promise((resolve, reject) => {
                let data = {
                    moduleCode: 'BF003',
                    order: 'desc',
                    custId: _this.detailData.custId,
                    from: 0,
                    size: 20,
                    sort: this.sort,
                    searchType: 'allList'
                }
                this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.document_generalOperate_get, { params: data }).then((res) => {
                    if (res.data.code.toString() === this.Global.config.RES_OK) {
                        resolve(res.data.data.list)
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    this.$toast.fail(this.Global.config.errorTitle)
                })
            })
            Promise.all([p1, p2, p3]).then(function (results) {
                _this.list = results[0]
                _this.socials = results[1]
                _this.custContactsList = results[2]
                _this.loading = false
            })
        },
        getData() {
            let data = {
                moduleCode: 'BF003',
                order: 'desc',
                custId: this.detailData.custId,
                from: 0,
                size: 20,
                sort: this.sort,
                searchType: 'allList'
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.document_generalOperate_get, { params: data }).then((res) => {
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    this.custContactsList = res.data.data.list
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                this.$toast.fail(this.Global.config.errorTitle)
            })
        }
    },
    watch: {
    },
    components: Object.assign({
        'loading-new': loadingNew
    }, ControlsShow, GroupControlsShow)
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

<template>
    <div class="SearchMail">
        <span class="spanClass">
            <i class="iconfont icon-search"></i>
            <form v-on:submit.prevent action="" class="formBox">
                <input placeholder="搜索" type="search" v-model="searchValue" @search="toSearch" @keyup.enter="toSearch" />
            </form>
        </span>
        <span @click="WriteMail()" class="nextClass">
            <i class="iconfont icon-edit-light"></i>
        </span>
    </div>
</template>
<script>

export default {
    name: 'SearchMail',
    data() {
        return {
            homeTitle: '孚盟邮',
            searchValue: ''
        }
    },
    computed: {

    },
    created() {

    },
    mounted() {
        const _self = this
        setTimeout(() => (_self.searchValue = _self.$route.query['searchValue'] || ''), 300)
    },
    methods: {
        ClearValue() {
            this.searchValue = ''
        },
        toSearch() {
            this.$emit('searchClick', this.searchValue, 1)
        },
        WriteMail() {
            this.goToNewMail({ actionType: 'W', templateid: 0 })
        }
    },
    components: {
    },
    watch: {
        $route(to, from) {
            if (to.query['searchValue']) {
                this.searchValue = to.query['searchValue']
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

<template>
    <van-popup :value="showDep" position="right" class="showDep">
        <div class="listBox">
            <div class="list" v-for="(item, index) in list1" :key="index" @click="selecteDep(item)">
                {{item.deptName}}
            </div>
        </div>
    </van-popup>
</template>
<script>
/**
 * 描述：客户管理-客户列表-新增客户
 * 作者：何俊峰
 * 时间：2017/11/21
 */
export default {
    title: function fc() { return this.$t('mx_mobile.Client.1585304336208') }, // '领取'
    name: 'otReceive',
    // mixins: [titleMixin],
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: ''
        },
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            showDep: false,
            list1: [],
            radio: ''
        }
    },
    created() {
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        selecteDep(item) {
            this.radio = item.dkey
            this.submit()
        },
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        closeThis() {
            this.showDep = false
            this.$emit('setMenu')
            this.$emit('setTitle')
        },
        openWindow() {
            this.radio = ''
            this.getData()
        },
        getData() {
            let _this = this
            let contactData = {
                dataType: 'department',
                funType: 'withRight',
                moduleCode: _this.moduleCode,
                optCode: _this.optCode,
                deptCascade: true,
                ctId: _this.companie.ctId
            }
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.accountDropList_get, { params: contactData }).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    let list = []
                    res.data.data.forEach(element => {
                        if (element.inUse == '1') {
                            list.push(element)
                        }
                    })
                    _this.list1 = list
                    if (_this.list1.length > 0) {
                        _this.radio = _this.list1[0].dkey
                    }
                    if (_this.list1.length == 0) {
                        _this.submit()
                    } else {
                        _this.setHeaderTitle(_this.$t('mx_mobile.Client.1585629904808')) // 选择部门
                        _this.showDep = true
                        _this.setMenu()
                    }
                } else {
                    _this.list1 = []
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        submit() {
            this.showDep = false
            let _this = this
            let data = {
                optModuleCode: _this.moduleCode,
                identFieldValue: _this.item.custId,
                optCode: 'otReceive',
                toDept: _this.radio
            }
            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: _this.$t('mx_mobile.Client.1585300903213') // 保存中...
            })
            _this.axios.put(_this.Global.config.apiBaseURL + _this.Global.api.v2.document_operate_detailOpt_put, data).then(function (res) {
                _this.$toast.clear()
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    _this.$toast.success(res.data.msg)
                    _this.closeThis()
                    _this.$emit('getListData', 'close')
                } else {
                    _this.closeThis()
                    _this.$toast.fail(res.data.msg)
                }
            })
        }
    },
    watch: {
        $route(to, from) {
            if (to.path == '/client/home') {
                this.showDep = false
            }
        }
    },
    components: {
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

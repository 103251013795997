<template>
    <div class="Controls">
        {{data[parseInt(value.value)|| 3]}}
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        value: {
            type: Object,
            default: function () {
                return {
                    value: ''
                }
            }
        }
    },
    data() {
        return {
            data: [this.$t('mx_mobile.Client.1585302401959'), this.$t('mx_mobile.Client.1585302392289'), this.$t('mx_mobile.Client.1585302401791'), '']// ['未知', '男', '女', '']

        }
    },
    created() {
    },
    methods: {
    },
    watch: {
    },
    components: {
    }
}
</script>

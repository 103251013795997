<template>
    <div class="list" :title="item.custName + ''">
        <div class="listBox">
            <div class="listTitle">
                <div class="ellipsis">{{item.custName}}</div>
                <span v-if="isFocusBill(item.focusTargets)" class="focus" :style="{color: '#FFB735'}" @click="$emit('optClick', focusObj)">{{$t('mx_mobile.Client.1585277371026')}}</span>
                <!--已关注-->
                <span v-else class="focus" @click="$emit('optClick', unFocusObj)" :style="{color: '#909399'}">{{$t('mx_mobile.Client.1585277302475')}}</span>
                <!--未关注-->
                <div class="labelBox">
                    <template v-for="(items, indexs) in item.tags">
                        <span v-if="returnTag(items) != ''" class="label ellipsis" :key="indexs">
                            {{returnTag(items)}}
                        </span>
                    </template>
                    <span class="labelAdd" @click="toTagPage()">
                        +{{$t('mx_mobile.Client.1585277425271')}}
                        <!--标签-->
                    </span>

                </div>
            </div>
            <div class="listContent">
                <div class="contentSpan">
                    <title-label :value="item.custState" :valueData="returnSysBoxValue('7', item.custState)"></title-label>
                    <span class="custType">{{returnAllSysBox(item)}}</span>
                    <span class="ownerCtId">{{returnRealName(item.ownerCtId)}}</span>
                </div>
                <template v-if="item.dynamics">
                    <div class="contentSpan contentSpan1 ellipsis" @click="showDynamics = true">
                        <i class="iconfont icon-message"></i>
                        {{JSON.parse(item.dynamics).content}}
                        <span class="ownerCtId">{{$m_formulateTime(JSON.parse(item.dynamics).date)}}</span>
                    </div>
                    <van-popup v-model="showDynamics" class="dynamicsPopup">{{JSON.parse(item.dynamics).content}}</van-popup>
                </template>
                <div class="contentSpan contentSpan1" v-else>
                    <i class="iconfont icon-message"></i>
                    {{$t('mx_mobile.Client.1585118248119')}}
                    <!--暂无动态-->

                </div>
            </div>
            <!-- 限孚盟开计算器 -->
            <template v-if="isFuma">
                <!-- 客户是否开通企业 -->
                <div class="mx_order" v-if="item.affCompanyId && item.affCompanyId !== ''  && isAuth && isFlag">
                    <dl class="mx_orderDescript" @click="toCalculator">
                        <!--当前配置-->
                        <dt>{{$t('mx_mobile.Client.1585277520847')}}</dt>
                        <dd>
                            <!--套餐名称-->
                            <span>{{$t('mx_mobile.Client.1585277513312')}}</span>
                            <em>{{selectConfig.pkname}}</em>
                        </dd>
                        <dd>
                            <!--用户许可数-->
                            <span>{{$t('mx_mobile.Client.1585277503824')}}</span>
                            <em>{{selectConfig.authcount}}人</em>
                        </dd>
                        <dd>
                            <!--到期时间-->
                            <span>{{$t('mx_mobile.Client.1585277494992')}}</span>
                            <em>{{selectConfig.expirationtime ? selectConfig.expirationtime.split(' ')[0] : ''}}</em>
                        </dd>
                        <dd>
                            <!--云空间-->
                            <span>{{$t('mx_mobile.Client.1585277492665')}}</span>
                            <em>{{selectConfig.spacecount}}G</em>
                        </dd>
                    </dl>
                </div>
                <van-button class="mx_btn" v-else @click="toCalculatorNew" style="margin-top:8px;width: 100%" :loading='isLoading'>{{msg}}</van-button>
            </template>
            <!-- <template v-else>
        <div class="mx_order"> -->
            <!-- <div class="mx_btn" v-else>您有一个订单未支付</div> -->
            <!-- </div>
      </template> -->
            <!-- -{{item.affCompanyId}}- -->
            <!-- <hr> -->

        </div>
        <!-- 价格计算器 -->
        <Calculator ref="Calculator" :item="item" :selectConfig="selectConfig" @close="Calculator = false" v-if="Calculator" />
        <CalculatorNew ref="CalculatorNew" :item="item" :selectConfig="selectConfig" @close="CalculatorNew = false" v-if="CalculatorNew" />
        <!--公海客户-->
        <div class="watermark seasFlag" v-if="item.seasFlag == 1">{{$t('mx_mobile.Client.1585277673398')}}</div>
    </div>
</template>
<script>
import titleLabel from '../custListView/titleLabel/index'
import Calculator from '../../Calculator/index'
import CalculatorNew from '../../CalculatorNew/index'
export default {
    name: 'custDetailView',
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            }
        },
        personnelTable: {
            type: Array,
            default: function () {
                return []
            }
        },
        listLabels: {
            type: Array,
            default: function () {
                return []
            }
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            loading: false,
            unFocusObj: {
                iconURI: 'icon-star',
                optCodes: 'focus',
                optCode: 'otFocus',
                optModuleCode: 'BF001',
                optName: this.$t('mx_mobile.Client.1585113565271') // 关注
            },
            focusObj: {
                iconURI: 'icon-star',
                optCode: 'otFocus',
                optCodes: 'unFocus',
                optModuleCode: 'BF001',
                optName: this.$t('mx_mobile.Client.1585277925669') // 取消关注
            },
            showDynamics: false,
            isFuma: false, // 登录的是否为孚盟
            selectConfig: {}, // 客户订单配置
            Calculator: false,
            CalculatorNew: false,
            isLoading: true,
            msg: '',
            orderno: '', // 未处理的订单号
            isFlag: true, // 检查是否有订单未支付
            isAuth: true // 检查是否是新购
        }
    },
    created() {
        this.checkFuma()
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        // setMenu() {
        //     this.Global.utils.rightMenu.clearMenu()
        // },
        isBill() {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.isBill, { params: { companyName: this.item.custName } }).then(res => {
                this.isLoading = false
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.msg = this.$t('mx_mobile.Client.1585277788791') // MX下单
                    this.isFlag = true
                } else if (res.data.code.toString() == '-3') {
                    this.orderno = res.data.data
                    this.msg = this.$t('mx_mobile.Client.1585277791501') // '您有一个订单待激活'
                } else {
                    this.orderno = res.data.data
                    this.msg = this.$t('mx_mobile.Client.1585277801508') // '您有一个订单待支付'
                    this.isFlag = false
                }
            }).catch(error => {
                this.isLoading = false
                console.log(error)
            })
        },
        checkFuma() {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.manageFlag, { params: {} }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.isFuma = res.data.data.manageFlag
                    this.isBill()
                    // 已开通查配置
                    if (this.item.affCompanyId && this.item.affCompanyId !== '') {
                        this.getCustConfig(this.item.affCompanyId)
                    }
                } else {
                    this.$toast(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        // 打开升级续费
        toCalculator() {
            this.Calculator = true
            this.$router.push({
                path: '',
                query: {
                    step: '01'
                }
            })
        },
        toCalculatorNew() {
            if (this.msg == this.$t('mx_mobile.Client.1585277788791')) { // MX下单
                this.CalculatorNew = true
                this.$router.push({
                    path: '',
                    query: {
                        stepNew: '01'
                    }
                })
            } else if (this.msg == this.$t('mx_mobile.Client.1585277801508')) { // 您有一个订单待支付
                this.$router.push('/my/orderDetail/' + this.orderno)
            } else if (this.msg == this.$t('mx_mobile.Client.1585277791501')) { // 您有一个订单待激活
                this.$router.push('/my/orderDetail/' + this.orderno)
            }
        },
        // 已开通查配置
        getCustConfig(id) {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.getAuthInfo, { params: { cid: id } }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    if (res.data.data && res.data.data.hasOwnProperty('pkcode')) {
                        this.selectConfig = res.data.data
                    } else if (res.data.data == undefined || res.data.data == '') { // 没用data字段相当于新购
                        this.isAuth = false
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        returnRealName(ctId) {
            let realName = ''
            this.personnelTable.forEach(element => {
                if (element.ctId == ctId) {
                    realName = element.realName
                }
            })
            return realName
        },
        returnAllSysBox(item) {
            let list = []
            let custLevel, custSource, tradeType
            custLevel = this.returnSysBoxValue('9', item.custLevel)
            custSource = this.returnSysBoxValue('8', item.custSource)
            tradeType = this.returnSysBoxValue('18', item.tradeType)
            if (custLevel != '') {
                list.push(custLevel)
            }
            if (custSource != '') {
                list.push(custSource)
            }
            if (tradeType != '') {
                list.push(tradeType)
            }
            return list.join('、')
        },
        returnSysBoxValue(dictCode, dictItemCode) {
            let itemName = ''
            if (!dictItemCode) {
                return ''
            }
            this.sysBoxValue.forEach(element => {
                if (element.dictCode == dictCode) {
                    element.dictItems.forEach(items => {
                        if (items.dictItemCode == dictItemCode) {
                            itemName = items.itemName
                        }
                    })
                }
            })
            return itemName
        },
        returnTag(tagId) {
            let labelName = ''
            this.listLabels.forEach(element => {
                if (element.labelId == tagId) {
                    labelName = element.labelName
                }
            })
            return labelName
        },
        isFocusBill(list) {
            if (!list) {
                return false
            }
            // let { ctId } = store.get('company')
            let ctId = this.companie.ctId
            let focus = false
            list.forEach(function (item) {
                if (ctId === item) {
                    focus = true
                }
            })
            return focus
        },
        toTagPage() {
            this.$router.push({ name: 'TagList', params: { moduleCode: 'BF001', identFieldValue: this.item.custId, selectedTags: this.item.tags ? this.item.tags : [] } })
        }
    },
    components: {
        'title-label': titleLabel,
        Calculator,
        CalculatorNew
    },
    watch: {
        '$route'(to, from) {
            if (to.path.indexOf('/client/detail/') >= 0) {
                this.checkFuma()
            }
            if (from.path == '/my/order' && to.query.stepNew == '03') {
                this.$router.push('/client/home')
            }
            if (from.path == '/my/order' && to.query.step == '03') {
                this.$router.push('/client/home')
            }
        }
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

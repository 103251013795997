<template>
    <div class="Controls" :class="{'Controls--disabled':itemData.disabled}">
        <span class="name">
            {{itemData.cnFieldCaption}}
            <span style="color:#D0021B" v-if="prop()">*</span>
        </span>
        <div v-for="(item, index) in files" :key="index" class="list">
            <img :src="picUrl(item,'75x75')" v-imgsrc="picUrl(item,'75x75')" data-initsrc="/images/icons/thumbnail.jpg" alt="" @click="showImg(index)">
            <span class="iconBox" @click="delFile(index)">
                x
            </span>
        </div>
        <div class="list list1" style="margin-right:0">
            <van-uploader class="uploader" :disabled="itemData.disabled" :after-read="onRead">
                <van-icon name="photograph" />
            </van-uploader>
        </div>
    </div>
</template>

<script>
import { ImagePreview } from 'vant'

export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            files: [],
            filesBase: [],
            toast: '',
            reloadTime: 0
        }
    },
    created() {
    },
    mounted() {
        if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
            this.updates(this.itemData.inDefaultValue)
        }
    },
    methods: {
        delFile(indexof) {
            this.files.splice(indexof, 1)
            this.filesBase.splice(indexof, 1)
        },
        showImg(index) {
            let list = []
            this.filesBase.forEach(element => {
                list.push(this.picUrl(element, 'orig'))
            })
            ImagePreview({
                images: list,
                startPosition: index
            })
        },
        picUrl(picId, size) {
            return this.getGlobalImgSrc(picId, size)
        },
        loadUrl(e, item) {
            console.log(e)
            if (this.reloadTime < 5) {
                const _self = this
                const reload = setTimeout(() => {
                    event.target.src = event.target.dataset.imgsrc
                    clearTimeout(reload)
                    _self.reloadTime++
                }, 5000)
            } else {
                this.reloadTime = 0
                this.$toast({ message: '缩略图请求超时' })
            }
        },
        updates(value) {
            if (value == '') {
                return false
            }
            this.files = value
            this.filesBase = value
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.filesBase.length != 0) {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            data[this.itemData.fieldName] = this.filesBase
            return data
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        onRead(file) {
            let _this = this
            if (this.Global.utils.isArray(file)) {
                file.forEach(element => {
                    let files = []
                    files.push(element.file)
                    let data = {
                        url: this.Global.config.uploadUrl,
                        FileList: files,
                        onprogress: function (event) { // 上传进度
                            console.log(event.percent)
                            _this.$toast.clear()
                            _this.$toast.loading({
                                duration: 0,
                                loadingType: 'spinner',
                                message: '上传中...' + event.percent + '%'
                            })
                        },
                        onloadstart: function (event) { // 开始上传
                            _this.$toast.loading({
                                duration: 0,
                                loadingType: 'spinner',
                                message: '上传中...'
                            })
                        },
                        onload: function (res) { // 上传成功
                            _this.$toast.clear()
                            _this.filesBase.push(res.data)
                            _this.files.push(res.data)
                        }
                    }
                    _this.Global.utils.fileUpload(data)
                })
            } else {
                let files = []
                files.push(file.file)
                let data = {
                    url: this.Global.config.uploadUrl,
                    FileList: files,
                    onprogress: function (event) { // 上传进度
                        console.log(event.percent)
                        _this.$toast.clear()
                        _this.$toast.loading({
                            duration: 0,
                            loadingType: 'spinner',
                            message: '上传中...' + event.percent + '%'
                        })
                    },
                    onloadstart: function (event) { // 开始上传
                        _this.$toast.loading({
                            duration: 0,
                            loadingType: 'spinner',
                            message: '上传中...'
                        })
                    },
                    onload: function (res) { // 上传成功
                        _this.$toast.clear()
                        if (res.code.toString() === _this.Global.config.RES_OK) {
                            console.log(_this.filesBase)
                            _this.filesBase.push(res.data)
                            _this.files.push(res.data)
                        } else {
                            _this.$toast.fail(res.msg)
                        }
                    }
                }
                this.Global.utils.fileUpload(data)
            }
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.Controls {
    .padding(14px, 0, 14px, 100px);
    position: relative;

    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
    .list {
        vertical-align: top;
        .width(75px);
        .height(75px);
        display: inline-block;
        .margin(5px);
        .margin-left(0);
        .margin-right(10px);
        line-height: 75px;
        text-align: center;
        position: relative;
        > img {
            .width(75px);
            .height(75px);
            vertical-align: top;
        }
        .iconBox {
            position: absolute;
            .width(19px);
            .line-height(14px);
            .height(19px);
            background: rgba(0, 0, 0, 0.6);
            border: 2px solid rgba(255, 255, 255, 1);
            border-radius: 50%;
            color: white;
            .font-size(12px);
            font-weight: bold;
            .right(-4px);
            .top(-4px);
        }
    }
    .list1 {
        background: #f3f3f3;
    }
}
</style>

<template>
    <div>
        <div v-if="this.fieldData.ownerCtId" class="list" :class="{'list-paddingLeft': isNewFollow && moduleCode == 'BF004'}">
            <div @click="choosePerson1()" class="listBox">
                <span class="name" :class="{'paddingLeft': isNewFollow && moduleCode == 'BF004'}">
                    {{this.fieldData.ownerCtId.cnFieldCaption}}
                    <span style="color:#D0021B" v-if="prop(this.fieldData.ownerCtId)">*</span>
                </span>
                <span>{{obj1.text}}</span>
            </div>
        </div>
        <div v-if="this.fieldData.ownerDeptKey" class="list" style="border:0" :class="{'list-paddingLeft': isNewFollow && moduleCode == 'BF004'}">
            <div @click="choosePerson2()" class="listBox">
                <span class="name" :class="{'paddingLeft': isNewFollow && moduleCode == 'BF004'}">
                    {{this.fieldData.ownerDeptKey.cnFieldCaption}}
                    <span style="color:#D0021B" v-if="prop(this.fieldData.ownerDeptKey)">*</span>
                </span>
                <span>{{obj2.text}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BelongTo',
    props: {
        itemData: {// 赋值给控件的数据
            type: Object,
            default: function () {
                return {}
            }
        },
        optCode: {
            type: String,
            default: ''
        },
        moduleCode: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'otNew'
        },
        isNewFollow: {
            type: [Boolean],
            default: false
        }
    },
    data() {
        return {
            fieldData: {},
            obj1: {
                text: '',
                value: ''
            },
            obj2: {
                text: '',
                value: ''
            },
            valueBase1: '',
            valueBase2: '',
            actions1: [],
            actions2: [],
            actionsBase1: [],
            actionsBase2: []
        }
    },
    created() {
        this.unit()
    },
    mounted() {
    },
    methods: {
        unit() {
            this.itemData.group.forEach(element => {
                if (element.fieldName == 'ownerCtId') {
                    this.fieldData.ownerCtId = element
                }
                if (element.fieldName == 'ownerDeptKey') {
                    this.fieldData.ownerDeptKey = element
                }
            })
            this.getData1()
        },
        returnName(list, value) {
            let obj = {
                text: '',
                value: ''
            }
            list.forEach(element => {
                if (element.value == value) {
                    obj = element
                }
            })
            return obj
        },
        async choosePerson1() {
            let res = await this.Global.utils.chosen.open(this.actions1)
            this.obj1 = res
            this.obj2 = {
                text: '',
                value: ''
            }
            this.getData2()
        },
        async choosePerson2() {
            if (this.actions2.length == '0') {
                this.$toast.fail(this.$t('mx_mobile.Client.1585630394433')) // 暂无部门
                return false
            }
            let res = await this.Global.utils.chosen.open(this.actions2)
            this.obj2 = res
        },
        updates(item) {
            if (item.ownerCtId) {
                this.valueBase1 = item.ownerCtId
                if (item.ownerDeptKey) {
                    this.valueBase2 = item.ownerDeptKey
                }
                if (this.actionsBase1.length != '0') {
                    this.obj1 = this.returnName(this.actionsBase1, this.valueBase1)
                    if (item.ownerDeptKey) {
                        this.getData2(true, this.valueBase2)
                    }
                }
            }
        },
        prop(item) {
            if (item.disabled) {
                return ''
            }
            if (item.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        submit() {
            let data = {}
            if (this.fieldData.ownerCtId && this.prop(this.fieldData.ownerCtId)) {
                if (this.obj1.value == '') {
                    this.$toast.fail(this.fieldData.ownerCtId.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            if (this.fieldData.ownerDeptKey && this.prop(this.fieldData.ownerDeptKey)) {
                if (this.obj2.value == '') {
                    this.$toast.fail(this.fieldData.ownerDeptKey.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            data = {
                ownerCtId: this.obj1.value + '',
                ownerDeptKey: this.obj2.value + ''
            }
            return data
        },
        // 初始第一个下拉先 <-_->
        async getData1() {
            let contactData = {
                dataType: 'contact',
                optCode: this.optCode,
                funType: 'withRight',
                moduleCode: this.moduleCode
            }
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.accountDropList_get, { params: contactData })
            if (res.data.code.toString() == this.Global.config.RES_OK) {
                this.actions1 = []
                this.actionsBase1 = []
                res.data.data.forEach(element => {
                    if (element.inUse != '0') {
                        this.actions1.push({
                            text: element.realName,
                            value: element.ctId
                        })
                    }
                    this.actionsBase1.push({
                        text: element.realName,
                        value: element.ctId
                    })
                })
                if (this.type == 'otNew' && this.fieldData.ownerCtId.inDefaultValue != '') {
                    this.obj1 = this.returnName(this.actionsBase1, this.fieldData.ownerCtId.inDefaultValue)
                    if (this.fieldData.ownerDeptKey.inDefaultValue != '') {
                        this.getData2(true, this.fieldData.ownerDeptKey.inDefaultValue)
                    }
                } else {
                    if (this.valueBase1 != '') {
                        this.obj1 = this.returnName(this.actionsBase1, this.valueBase1)
                        if (this.valueBase2 != '') {
                            this.getData2(true, this.valueBase2)
                        }
                    }
                }
            } else {
                this.actions1 = []
                this.$toast.fail(res.data.msg)
            }
        },
        async getData2(update, value) {
            if (!this.obj1.value) {
                return
            }
            let departmentData = {
                dataType: 'department',
                optCode: this.optCode,
                funType: 'withRight',
                deptCascade: true,
                ctId: this.obj1.value,
                moduleCode: this.moduleCode
            }
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.accountDropList_get, { params: departmentData })
            if (res.data.code.toString() == this.Global.config.RES_OK) {
                this.actions2 = []
                this.actionsBase2 = []
                res.data.data.forEach(element => {
                    if (element.inUse != '0') {
                        this.actions2.push({
                            text: element.deptName,
                            value: element.dkey
                        })
                    }
                    this.actionsBase2.push({
                        text: element.deptName,
                        value: element.dkey
                    })
                })
                if (update) {
                    this.obj2 = this.returnName(this.actionsBase2, value)
                } else if (this.actions2.length > 0) {
                    this.obj2 = this.actions2[0]
                }
            } else {
                this.actions2 = []
                this.$toast.fail(res.data.msg)
            }
        }
    },
    components: {
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.paddingLeft {
    .padding-left(16px);
}
.list {
    .min-height(45px);
    .line-height(45px);
    border-bottom: 1px solid #eaedef;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    position: relative;
    &.list-paddingLeft {
        .padding-left(116px);
    }
    .listBox {
        .min-height(45px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
}
</style>

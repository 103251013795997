<template>
    <van-popup class="controlsBox" v-model="show" position="bottom" :lock-scroll="true">
        <van-datetime-picker v-model="currentDate" type="datetime" @cancel="onCancel" @confirm="onConfirm" />
    </van-popup>
</template>
<script>
export default {
    name: 'DatePickerTime',
    props: {
        addSecond: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentDate: new Date(),
            show: false,
            callBack: function () { }
        }
    },
    created() {
        // ep.tail('publicControls', (source = [], selectedKey, onSuccess, onFail) => {
        //     this.columns = source || []
        //     this.onSuccess = onSuccess || function() {}
        //     this.onFail = onFail || function() {}
        // })
    },
    methods: {
        onSuccess(data) {
            this.callBack(data)
        },
        onFail(data) { },
        onConfirm(value) {
            this.show = false
            let newDate = this.getNowDate()
            this.onSuccess(newDate)
        },
        getNowDate() {
            let date = new Date(this.currentDate)
            let sign1 = '-'
            let sign2 = ':'
            let year = date.getFullYear() // 年
            let month = date.getMonth() + 1 // 月
            let day = date.getDate() // 日
            let hour = date.getHours() // 时
            let minutes = date.getMinutes() // 分
            // 给一位数数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = '0' + month
            }
            if (day >= 0 && day <= 9) {
                day = '0' + day
            }
            if (hour >= 0 && hour <= 9) {
                hour = '0' + hour
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = '0' + minutes
            }
            let currentdate = year + sign1 + month + sign1 + day + ' ' + hour + sign2 + minutes
            if (this.addSecond) {
                currentdate += sign2 + '00'
            }
            return currentdate
        },
        onCancel() {
            this.show = false
            this.onFail({})
        },
        getTime(next) {
            this.show = true
            if (next) {
                this.callBack = next
            } else {
                this.callBack = function () { }
            }
        }
    },
    components: {

    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
.controlsBox {
    width: 100%;
    height: 40%;
    background: white;
}
</style>

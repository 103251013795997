<template>
    <div class="FunBar">
        <van-checkbox v-model="value" @click.native="onClick"></van-checkbox>
        <div class="deleteBtn iconfont icon-del-light" @click="deleteFn"></div>
        <template v-if="!isRecycleBin">
            <div class="copyBtn iconfont icon-copy" @click="copyFn"></div>
            <div class="moveBtn iconfont icon-move" @click="moveFn"></div>
        </template>

        <div class="cancelBtn iconfont icon-close-strong" @click="undoFn"></div>
    </div>
</template>

<script>
export default {
    name: '',

    props: {
        checked: { type: Boolean, default: false },
        isRecycleBin: { type: Boolean, default: false }
    },
    data() {
        return {
            value: this.checked
        }
    },
    methods: {
        onClick() {
            this.$emit('change', this.value)
        },
        deleteFn() {
            this.$emit('delClick')
        },
        undoFn() {
            this.$emit('cancel')
        },
        copyFn() {
            this.$emit('copyClick')
        },
        moveFn() {
            this.$emit('moveClick')
        }
    },
    watch: {
        checked() {
            this.value = this.checked
        }
    },
    components: {}
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

<template>
    <div>
        <div class="Controls" @click="choosePerson()" :class="{'Controls--disabled':itemData.disabled}">
            <span class="name">
                {{itemData.cnFieldCaption}}
                <span style="color:#D0021B" v-if="prop()">*</span>
            </span>
            <span>{{obj.text}}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            show: false,
            actions: [{
                text: this.$t('mx_mobile.Client.1585302392289'), // 男
                value: '1'
            }, {
                text: this.$t('mx_mobile.Client.1585302401791'), // 女
                value: '2'
            }, {
                text: this.$t('mx_mobile.Client.1585302401959'), // 未知
                value: '0'
            }],
            obj: {
                text: '',
                value: ''
            }
        }
    },
    created() {
    },
    mounted() {
        if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
            this.updates(this.itemData.inDefaultValue)
        }
    },
    methods: {
        async choosePerson() {
            if (this.itemData.disabled) return
            try {
                this.obj = await this.Global.utils.chosen.open(this.actions, this.obj.text)
            } catch (error) {

            }
        },
        updates(value) {
            if (value == '') {
                return false
            }
            this.returnObj(value)
        },
        returnObj(value) {
            this.actions.forEach(elements => {
                if (elements.value == value) {
                    this.obj = elements
                }
            })
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.obj.value == '') {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            data[this.itemData.fieldName] = this.obj.value + ''
            return data
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

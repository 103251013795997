<template>
    <span class="Controls">
        <span class="list">{{regionName}}</span>
        <span class="list">{{countryName}}</span>
        <span class="list">{{provinceName}}</span>
        <span class="list">{{cityName}}</span>
        <span class="list">{{townName}}</span>
        <span class="list">{{items.custAddr}}</span>
        <map-location :itemData="items" />
    </span>
</template>

<script>
import MapLocation from './MapLocation'
export default {
    name: 'Controls',
    props: {
        value: {
            type: String,
            default: ''
        },
        items: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            regionName: '',
            countryName: '',
            provinceName: '',
            cityName: '',
            townName: '',
            regionList: [],
            countryList: [],
            provinceList: [],
            cityList: [],
            townList: []
        }
    },
    created() {
        this.init()
    },
    methods: {
        init() {
            this.getregionList()
            this.getCountryData()
            this.countryChange()
            this.provinceChange()
            this.cityChange()
        },
        returnName(id, fieldName) {
            if (fieldName == 'custAreas') {
                let name = ''
                this.regionList.forEach(function (item) {
                    if (item.areaId == id) {
                        name = item.areaName
                        return false
                    }
                })
                return name
            }
            if (fieldName == 'countryId') {
                let name = ''
                this.countryList.forEach(function (item) {
                    if (item.countryId == id) {
                        name = item.cnName
                        return false
                    }
                })
                return name
            }
            if (fieldName == 'provinceId') {
                let name = ''
                this.provinceList.forEach(function (item) {
                    if (item.provinceId == id) {
                        name = item.cnName
                        return false
                    }
                })
                return name
            }
            if (fieldName == 'cityId') {
                let name = ''
                this.cityList.forEach(function (item) {
                    if (item.cityId == id) {
                        name = item.cnName
                        return false
                    }
                })
                return name
            }
            if (fieldName == 'townId') {
                let name = ''
                this.townList.forEach(function (item) {
                    if (item.townId == id) {
                        name = item.cnName
                        return false
                    }
                })
                return name
            }
        },
        async  getregionList() {
            let _this = this
            if (!_this.items.custAreas || _this.items.custAreas == '') {
                _this.regionName = ''
                return false
            };
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.custArea, { params: { type: 'list' } })
            if (res.data.code.toString() === this.Global.config.RES_OK) {
                _this.regionList = res.data.data
                _this.regionName = _this.returnName(_this.items.custAreas, 'custAreas')
            }
        },
        getCountryData() {
            let _this = this
            if (!_this.items.countryId || _this.items.countryId == '') {
                _this.countryName = ''
                return false
            };
            this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.area + 1, { params: { type: 'country' } }).then(function (res) {
                if (res.data.code.toString() === _this.Global.config.RES_OK) {
                    _this.countryList = res.data.data
                    _this.countryName = _this.returnName(_this.items.countryId, 'countryId')
                } else {
                    _this.countryList = []
                    _this.countryName = ''
                }
            })
        },
        countryChange() {
            let _this = this
            if (_this.items.provinceId || _this.items.provinceId == '') {
                _this.provinceName = ''
                return false
            };
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.area + _this.items.countryId, { params: { type: 'province' } }).then(function (res) {
                if (res.data.code.toString() === _this.Global.config.RES_OK) {
                    _this.provinceList = res.data.data
                    _this.provinceName = _this.returnName(_this.items.provinceId, 'provinceId')
                } else {
                    _this.provinceList = []
                    _this.provinceName = ''
                }
            })
        },
        provinceChange(value) {
            let _this = this
            if (!_this.items.cityId || _this.items.cityId == '') {
                _this.cityName = ''
                return false
            };
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.area + _this.items.provinceId, { params: { type: 'city' } }).then(function (res) {
                if (res.data.code.toString() === _this.Global.config.RES_OK) {
                    _this.cityList = res.data.data
                    _this.cityName = _this.returnName(_this.items.cityId, 'cityId')
                } else {
                    _this.cityList = []
                    _this.cityName = ''
                }
            })
        },
        cityChange(value) {
            let _this = this
            if (!_this.items.townId || _this.items.townId == '') {
                _this.townName = ''
                return false
            };
            this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.area + _this.items.cityId, { params: { type: 'town' } }).then(function (res) {
                if (res.data.code.toString() === _this.Global.config.RES_OK) {
                    _this.townList = res.data.data
                    _this.townName = _this.returnName(_this.items.townId, 'townId')
                } else {
                    _this.townList = []
                    _this.townName = ''
                }
            })
        }
    },
    watch: {
        // 重新刷新数据时，自动关掉编辑
        items: {
            handler(newVal, oldVal) {
                this.init()
            },
            depp: true
        }
    },
    components: {
        'map-location': MapLocation
    }
}
</script>

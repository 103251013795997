<template>
    <div class="funcMark8">
        <div class="box">
            <loading-new :loading="loading"></loading-new>
            <template v-if="!loading">
                <nodata v-if="list.length == 0" :title="title" img="noData"></nodata>
                <template v-else>
                    <div class="list" v-for="(item, index) in list" :key="index">
                        <template v-if="item.multiList[0].detail.objectName">
                            <div class="iconTitle">
                                <i class="iconfont" :class="getIcon(item.moduleCode)"></i>
                            </div>
                            <div class="content">
                                <div class="contentTit">
                                    {{item.realName}}
                                    <span class="nextTime">{{$m_utcOffset(item.optDate).format('MM-DD HH:mm:ss')}}</span>
                                </div>
                                <div class="contentMsg">
                                    <div v-for="(item2,index2) in item.multiList" :key="index2">
                                        <user-action-item-detail :logFormat='item.logFormat' :detail="item2.detail"></user-action-item-detail>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>
<script>
import userActionItemDetail from './Vue/userActionItemDetail.vue'
import loadingNew from '../../../../CustPublic/LoadingVue/index.vue'
export default {
    name: 'funcMark8',
    props: {
        detailData: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            list: [],
            title: this.$t('mx_mobile.Client.1585279945544'), // 暂无操作
            loading: true
        }
    },
    created() {
        this.getData()
    },
    mounted() {
    },
    methods: {
        getData() {
            let data = {
                pageN: 1,
                pageSize: 25,
                sort: 'optDate',
                order: 'desc',
                isShowView: true,
                custId: this.detailData.custId
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.behaviorLog_get, { params: data }).then((res) => {
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    this.list = res.data.data.behaviorLogs
                    this.loading = false
                } else {
                    this.loading = false
                    this.list = []
                    this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                this.$toast.fail(this.Global.config.errorTitle)
            })
        },
        getIcon(moduleCode) {
            switch (moduleCode) {
                case 'DC002': // 文档
                    return 'icon-file-manager'
                case 'WO001': // 工单
                    return 'icon-work-sheet'
                case 'SY005': // 部门模块
                    return 'icon-groups'
                case 'SY006': // 角色模块
                    return 'icon-role-set'
                case 'BF001': // 客户模块
                    return 'icon-people_list_light'
                case 'BF004': // 跟进模块
                    return 'icon-follow-up'
                case 'BF003': // 联系人模块
                    return 'icon-contacts'
                case '': // 登录企业
                    return 'icon-go'
                default:
                    return 'icon-sign'
            }
        }
    },
    watch: {
    },
    components: {
        'user-action-item-detail': userActionItemDetail,
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

<template>
    <div class="orderSuccess" style="height: 100%">
        <div class="tips" style="color: #00C2B3">
            <van-icon name="passed" size="66px" />
            <div>订单提交成功，请尽快付款</div>
        </div>
        <div class="btnSheet" @click="$router.push('/my/orderDetail/' + orderno)">查看我的订单</div>
        <div class="btnSheet" @click="$router.push('/my/payment')" style="margin-top:20px;">付款方式</div>
    </div>
</template>

<script>
export default {
    name: 'step04',
    props: {
         orderno: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            activePay: 0
        }
    },
    created() {
        this.setMenu()
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        setMenu() {
            this.setHeaderTitle('提交成功')
        }
    },
    watch: {
        $route(to, form) {
            // if (form.query.step == '03') {
            // }
            // if (to.query.step == '02') {
            //     this.$router.push('/client/detail/')
            // }
        }
    }
}
</script>
<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
</style>

<template>
    <div class="Controls">
        {{returnSysBoxValue( value.value)}}
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        value: {
            type: Object,
            default: function () {
                return {
                    value: ''
                }
            }
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        returnSysBoxValue(dictItemCode) {
            let itemName = ''
            if (!dictItemCode) {
                return ''
            }
            for (let index = 0; index < this.AONIcustSort.length; index++) {
                const element = this.AONIcustSort[index]
                let second = element.dictItem.filter(item => {
                    return item.id.toString() == dictItemCode.toString()
                })[0]

                if (second) {
                    itemName = second.itemName
                    break
                }
            }
            return itemName
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

<template>
    <div class="Controls">
        {{returnCusBoxValue(itemData.dictCode, value.value)}}
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        value: {
            type: Object,
            default: function () {
                return {
                    value: ''
                }
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        returnCusBoxValue(dictCode, dictItemCode) {
            let itemName = ''
            if (!dictItemCode) {
                return ''
            }
            this.cusBoxValue.forEach(element => {
                if (element.dictCode == dictCode) {
                    element.customDictitems.forEach(items => {
                        if (items.dictItemCode == dictItemCode) {
                            itemName = items.itemName
                        }
                    })
                }
            })
            return itemName
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

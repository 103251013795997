import Region from './Region/index.vue'
import SocialContact from './SocialContact/index.vue'
import BelongTo from './BelongTo/index.vue'
import Create from './Create/index.vue'
import Modify from './Modify/index.vue'
import CurrencyExchangeRate from './CurrencyExchangeRate/index.vue'
import RequiredAssociation from './RequiredAssociation/index.vue'
import Region2 from './Region2/index.vue'
import Distributor from './Distributor/index.vue'
export default {
    'group1': Region,
    'group2': SocialContact,
    'group3': BelongTo,
    'group4': Create,
    'group5': Modify,
    'group6': CurrencyExchangeRate,
    'group24': RequiredAssociation,
    'group25': Region2,
    'group31': Distributor
}

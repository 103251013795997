/**
 * 分类动态组件
 // 类型 1.跟进 2.联系人 3.邮件 4.营销AM 5.报价 6.合同
 * dynamicType: {
                1: 'follow-up',
                2: 'contacts',
                3: 'mail',
                4: 'market-am',
                5: 'show-price',
                6: 'contract'
            }
 */
import FollowUp from './FollowUp/index.vue'
// import Contacts from './Contacts/index.vue'
import Mail from './Mail/index.vue'
import MarketAM from './MarketAM/index.vue'
import ShowPrice from './ShowPrice/index.vue'
import Contract from './Contract/index.vue'

const DynamicComponent = {
    'follow-up': FollowUp,
    // 'contacts': Contacts, // 不展示联系人动态
    'mail': Mail,
    'market-am': MarketAM,
    'show-price': ShowPrice,
    'contract': Contract
}
export default DynamicComponent

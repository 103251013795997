<template>
    <!-- 发邮件 -->
    <van-popup v-model="show" position="right" class="screenSearchPop">
        <loading-new :loading="loading"></loading-new>
        <template v-if="!loading">
            <nodata v-if="list.length == 0" :title="title" img="noData"></nodata>
            <template v-else>
                <div class="listsBox">
                    <div class="lists">
                        <div class="list" v-for="(item, index) in list" :key="index" @click="peopleOnSelect(item)">
                            {{item.contName}}（{{item.mailAddress}}）
                            <span class="iconBox" v-if="item.check">
                                <i class="iconfont icon-hook"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </van-popup>
</template>
<script>
import loadingNew from '../../LoadingVue/index.vue'
/**
 * 描述：客户管理-客户列表-新增客户
 * 作者：何俊峰
 * 时间：2017/11/21
 */
export default {
    name: 'view1',
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: 'otNew'
        },
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            submitLoading: false,
            show: false,
            list: [],
            loading: false,
            title: this.$t('mx_mobile.Client.1585304932626') // 暂无邮箱
        }
    },
    created() {
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        peopleOnSelect(item) {
            item.check = !item.check
        },
        openWindow() {
            this.setHeaderTitle(this.$t('mx_mobile.Client.1585300521873')) // 写邮件
            this.show = true
            this.loading = true
            this.setMenu()
            let people = this.item.custContacts
            let list = []
            if (people && people.length != 0) {
                people.forEach(element => {
                    if (element.mailAddress && element.mailAddress.length != 0) {
                        element.mailAddress.forEach(item => {
                            list.push({
                                contName: element.contName,
                                mailAddress: item,
                                check: false
                            })
                        })
                    }
                })
            }
            this.list = list
            this.loading = false
        },
        setMenu() {
            setTimeout(() => {
                let setMenu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Client.1585304971472')) // 确定
                setMenu.onClick(() => {
                    this.submitAddFrom()
                })
            }, 100)
        },
        closeThis() {
            this.show = false
            this.$emit('setMenu')
            this.$emit('setTitle')
        },
        submitAddFrom() {
            let list = []
            this.list.forEach(element => {
                if (element.check) {
                    list.push(element)
                }
            })
            if (list.length == 0) {
                this.$toast(this.$t('mx_mobile.Client.1585304960208')) // 请先选择邮件地址！
                return false
            }
            this.closeThis()
            this.goToNewMail({
                actionType: 'customerMail',
                recipientsArry: list
            })
        }
    },
    watch: {
    },
    components: {
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

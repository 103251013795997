<template>
    <div v-if="isShow" class="PersonnelSelection">
        <div class="list1">
            <!-- 搜索 -->
            <search-bar v-model="value" @keyup.enter.native="toSearch" :placeholder="$t('mx_mobile.Client.1585115144101')"></search-bar>
        </div>
        <div class="contenClass">
            <van-checkbox-group v-model="selectedArrys">
                <li v-for="(item,index) in optionsList" :key="index" @click="changeOption(item)">
                    <span v-if="!showType&&item.ctid==companie.ctId" style="color:red;">{{item.name}}</span>
                    <span v-else>{{item.name}}</span>
                    <div v-if="showType" class="chekedClass" @click.stop="stopAction($event)">
                        <van-checkbox :name="item">
                        </van-checkbox>
                    </div>
                </li>
            </van-checkbox-group>
        </div>
        <!-- 完成 -->
        <div class="footClass" v-if="showType" @click="submit()">{{$t('mx_mobile.Client.1585286721404')}}</div>

    </div>

</template>
<script>
import SearchBar from '@/page/Document/Vue/SearchBar/index'
import { mapGetters } from 'vuex'
export default {
    name: 'PersonnelSelection',
    title: function fc() { return this.$t('mx_mobile.Mail.1585111834931') }, // '人员选择'
    // mixins: [titleMixin],
    props: {
        optionsList: {
            type: Array,
            default: () => []
        },
        selectedArry: {
            type: Array,
            default: () => []
        },
        showModel: {
            type: Boolean,
            default: false
        },
        showType: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            selectedArrys: this.selectedArry,
            isShow: this.showModel,
            value: ''

        }
    },
    computed: {
        ...mapGetters(['companie'])
    },
    created() {

    },
    mounted() {
        // this.setMenu()
    },
    methods: {
        // 停止冒泡
        stopAction(e) {
            e.stopPropagation()
        },
        submit() {
            this.$emit('closePersonnelSelect', this.selectedArrys)
        },
        toSearch() {
            this.$emit('toSearch', this.value)
        },
        changeOption(item) {
            if (this.showType) {
                let index = this.selectedArrys.indexOf(item)
                if (index >= 0) {
                    this.selectedArrys.splice(index, 1)
                } else {
                    this.selectedArrys.push(item)
                }
                return
            }
            //
            this.$emit('closePersonnelSelect', item.ctid)
        }
    },
    watch: {
        showModel() {
            this.isShow = this.showModel
        }
    },
    components: {
        'search-bar': SearchBar
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

import funcMark1 from './funcMark1/index.vue'
import funcMark2 from './funcMark2/indexV2.vue'
import funcMark3 from './funcMark3/index.vue'
import funcMark4 from './funcMark4/index.vue'
import funcMark5 from './funcMark5/index.vue'
import funcMark6 from './funcMark6/index.vue'
import funcMark7 from './funcMark7/index.vue'
import funcMark8 from './funcMark8/index.vue'
import funcMark9 from './funcMark9/index.vue'
// import funcMark10 from './funcMark10/index.vue'
// import funcMark11 from './funcMark11/index.vue'
// import funcMark12 from './funcMark12/index.vue'
// import funcMark13 from './funcMark13/index.vue'
// import funcMark14 from './funcMark14/index.vue'
// import funcMark16 from './funcMark16/index.vue'
// import funcMark18 from './funcMark18/index.vue'
// import funcMark19 from './funcMark19/index.vue'
// import funcMark21 from './funcMark21/index.vue'
// import funcMark35 from './funcMark35/index.vue'
// import funcMark40 from './funcMark40/index.vue'
// import funcMark41 from './funcMark41/index.vue'
// import funcMark42 from './funcMark42/index.vue'
// import funcMark44 from './funcMark44/index.vue'
// import funcMark45 from './funcMark45/index.vue'
export default {
    'funcMark1': funcMark1,
    'funcMark2': funcMark2,
    'funcMark3': funcMark3,
    'funcMark4': funcMark4,
    'funcMark5': funcMark5,
    'funcMark6': funcMark6,
    'funcMark7': funcMark7,
    'funcMark8': funcMark8,
    'funcMark9': funcMark9
    // 'funcMark10': funcMark10,
    // 'funcMark11': funcMark11,
    // 'funcMark12': funcMark12,
    // 'funcMark13': funcMark13,
    // 'funcMark14': funcMark14,
    // 'funcMark16': funcMark16,
    // 'funcMark18': funcMark18,
    // 'funcMark19': funcMark19,
    // 'funcMark21': funcMark21,
    // 'funcMark35': funcMark35,
    // 'funcMark40': funcMark40,
    // 'funcMark41': funcMark41,
    // 'funcMark42': funcMark42,
    // 'funcMark44': funcMark44,
    // 'funcMark45': funcMark45
}

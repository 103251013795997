<template>
    <div class="MailList">
        <loading-new :loading="isLoading" text="数据解析中...请稍等" style="margin-top:40px"></loading-new>
        <van-checkbox-group v-model="checkedListAll">
            <template v-if="listCenterData.length>0||!isLoading">
                <div class="mail-item" v-for="(item,index) in listCenterData" :key="index" @click="ListCenterShowDetail(item.mId,index,listALL)" :style="contactPaddingLeft()">
                    <div class="checkRadio" @click.stop :style="contactRadio()">
                        <van-checkbox :name="item.mId"></van-checkbox>
                    </div>
                    <div class="mail-item__content">
                        <li class="line line-name">
                            <div class="line-name__text">
                                <!--发件箱/文件夹：显示收件人-->
                                <span class="subjectClass" v-if="isSendMail(item)">
                                    <show-name v-if="item.recipients" :item="item.recipients[0]" :status="item.status?item.status.toString():''"></show-name>
                                </span>
                                <!--收件箱：显示发件人-->
                                <span class="subjectClass" v-else>
                                    <show-name v-if="getReplyTo()!=''" :item="getReplyTo()" :status="item.status?item.status.toString():''"></show-name>
                                    <show-name v-else-if="item.fromEx" :item="item.fromEx[0]" :status="item.status?item.status.toString():''"></show-name>
                                </span>
                                <i v-if="item.stickyFlag==1" class="iconfont starColor icon-star"></i>
                            </div>
                            <span class="dataShowClass">
                                <i v-if="item.containAttachment" class="iconfont icon-attachment"></i>
                                {{dateDisplay(item.longSentDate)}}
                            </span>
                        </li>
                        <!-- 主题 -->
                        <li :class="item.status==1?'Invisible2':''" class="line line-subject">
                            <span class="replyIcon" v-if="item.replyFlag">
                                <i class="iconfont icon-share-left"></i>
                            </span>
                            <span class="replyIcon" v-if="item.repostSign">
                                <i class="iconfont icon-share-right"></i>
                            </span>
                            <label v-html="item.subject"></label>
                        </li>
                        <!-- 正文 -->
                        <li :class="item.status==1?'Invisible2':''" class="line line-content">
                            <div class="contenClass2">
                                <i class="commentIcon iconfont icon-dot" v-if="item.comments&&item.comments.length>0" :style="setCommentsColor(item.comments[item.comments.length-1].commentFlag)"></i>
                                {{item.abstractText}}
                            </div>
                            <div class="tagClass">
                                <span v-for="(item3,index3) in item.tagObjects" :key="index3">
                                    <van-tag v-if="index3<=2" style="margin-left:5px" :style="setBgColor(item3.commentFlag)">
                                        {{item3.labelName}}
                                    </van-tag>
                                    <van-tag v-else-if="index3==3" style="margin-left:5px"> ... </van-tag>
                                </span>
                            </div>
                        </li>
                    </div>
                </div>
            </template>
            <nodata title="暂无邮件" img="noMail" v-if="!isLoading&&listCenterData.length<=0"></nodata>
        </van-checkbox-group>
    </div>
</template>
<script>

import { mapMutations, mapGetters } from 'vuex'
import ShowName from './ShowName/index'
import loadingNew from '../LoadingVue/index.vue'
import { getItem, getIsApp } from '@/libs/utils'
import hybrid from '@/libs/hybrid'

export default {
    name: 'MailListView',
    props: {
        routerDetail: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            homeTitle: '孚盟邮',
            checkedListAll: [],
            listALL: [],
            // dynamicTags: [],
            labelMap: new Map(),
            listCenterData: [],
            AtypismAddress: '',
            junkMailText: '',
            junkOwnerMailAccount: '',
            junkMid: '',

            contactAction: false,
            PaddingLeft: 20,
            isLoading: false

        }
    },
    computed: {
        ...mapGetters('Mail', ['currentAddress', 'boxMid', 'currentCtid'])
    },
    created() {
    },
    mounted() {
    },
    components: {
        'show-name': ShowName,
        'loading-new': loadingNew
    },
    methods: {
        getReplyTo(item2) {
            let ReplyToArry = ''
            if (item2 && item2.replyTo && item2.replyTo.length > 0) {
                for (let index = 0; index < item2.replyTo.length; index++) {
                    const element = item2.replyTo[index]
                    if (element) { // 不等于null
                        if (item2.fromEx && item2.fromEx.length > 0) {
                            if (element.address && item2.fromEx[0].address && element.address != item2.fromEx[0].address) {
                                ReplyToArry = element
                                break
                            }
                        } else {
                            ReplyToArry = element
                            break
                        }
                    }
                }
            }
            return ReplyToArry
        },
        isSendMail(item2) {
            if (item2.source == 'FMDRAFT' || item2.source == 'FMD' || (item2.mailAddress && item2.fromEx[0] && item2.fromEx[0].address && item2.mailAddress.toLowerCase() == item2.fromEx[0].address.toLowerCase()) || (item2.source == 'FMI' && item2.mailAddress.toLowerCase() == item2.fromEx[0].address.toLowerCase())) {
                return true
            }
            return false
        },
        contactShow(PaddingLeft) {
            this.contactAction = true
            this.PaddingLeft = PaddingLeft
        },
        contactRadio() {
            if (this.contactAction) {
                return 'display:none'
            }
        },
        contactPaddingLeft() {
            if (this.contactAction) {
                return 'padding-left:' + this.PaddingLeft + 'px'
            }
        },
        ...mapMutations('Mail', {
            set_boxIndex: 'SET_BOXINDEX',
            set_boxAarry: 'SET_BOXAARRY'
        }),
        /**
        * 改变举报地址
        */
        changeJunkText(newVal) {
            this.junkMailText = ''
            this.junkOwnerMailAccount = ''
            if (this.Global.utils.isArray(newVal)) {
                newVal.forEach(element => {
                    this.junkMailText =
                        this.junkMailText + element.junkMailAddress + ';'
                    this.junkOwnerMailAccount =
                        this.junkOwnerMailAccount + element.ownerMailAddress + ';'
                    this.junkMid = this.junkMid + element.mid + ','
                })
                this.junkMailText = this.junkMailText.substr(
                    0,
                    this.junkMailText.length - 1
                )
                this.junkOwnerMailAccount = this.junkOwnerMailAccount.substr(
                    0,
                    this.junkOwnerMailAccount.length - 1
                )
                this.junkMid = this.junkMid.substr(
                    0,
                    this.junkMid.length - 1
                )
            }
        },
        // 回复地址不一致   AtypismAddress
        adderssComparison(replyTo, fromEx) {
            if (!this.Global.utils.isArray(replyTo) || !this.Global.utils.isArray(fromEx)) {
                return
            }
            let count = 0
            for (let j = 0; j < replyTo.length; j++) {
                count = 0
                for (let i = 0; i < fromEx.length; i++) {
                    if (replyTo[j].address == fromEx[i].address) {
                        break
                    } else {
                        count++
                    }
                }
                if (count == fromEx.length) {
                    this.AtypismAddress = this.AtypismAddress + replyTo[j].address + ','
                }
            }
        },
        // 垃圾邮件
        dialogJunkMail() {
            let data = []
            let paramsData = []
            let blg = false
            let str = ''
            for (let k = 0; k < this.checkedListAll.length; k++) {
                blg = false
                for (let i = 0; i < this.listCenterData.length; i++) {
                    if (this.listCenterData[i].mId == this.checkedListAll[k]) {
                        this.AtypismAddress = ''
                        str = ''
                        this.adderssComparison(this.listCenterData[i].replyTo, this.listCenterData[i].fromEx)
                        for (let p = 0; p < this.listCenterData[i].fromEx.length; p++) { // 发件地址
                            str = str + this.listCenterData[i].fromEx[p].address + ','
                        }
                        if (this.AtypismAddress != '') { // 回复地址不一致
                            str = str + this.AtypismAddress + ','
                        }
                        data.push({
                            'ownerMailAddress': this.listCenterData[i].mailAddress,
                            'junkMailAddress': str.substring(0, str.length - 1),
                            'mid': this.listCenterData[i].mId
                        })
                        paramsData.push({
                            'mid': this.listCenterData[i].mId,
                            'rawData': this.listCenterData[i].rawData,
                            'size': this.listCenterData[i].size,
                            'subject': this.listCenterData[i].subject
                        })
                        blg = true
                        break
                    }
                    if (blg) {
                        break
                    }
                }
            }
            // alert(data)
            this.changeJunkText(data)
            let junkMailText = this.junkMailText
            let junkOwnerMailAccount = this.junkOwnerMailAccount
            let params = { mId: this.junkMid, junkMailText: junkMailText, junkOwnerMailAccount: junkOwnerMailAccount, type: 'bulk' }
            this.$router.push({ name: 'ReportSpam', params: params })
            // this.$refs.dialogJunkMail.isDialog('open', 'bulk', data, paramsData)
        },
        // 标签颜色
        setBgColor(id) {
            return this.Global.utils.tagsBgColor(id)
        },
        // 批注颜色
        setCommentsColor(flag) {
            return this.Global.utils.commentsColor(flag)
        },
        // 停止冒泡
        stopAction(e) {
            e.stopPropagation()
        },
        // 点击显示详情
        ListCenterShowDetail(mId, index, listALL) {
            if (getIsApp() && (getItem('isNewVersion') == '1')) {
                hybrid.openMail({ mId: mId, boxId: this.boxMid, mailAddress: this.currentAddress, currentCtid: this.currentCtid })
                this.$emit('showDetail', index, this.listCenterData)
                return
            }
            if (this.routerDetail) {
                this.set_boxAarry(listALL)
                this.set_boxIndex(index)
                this.$router.push({ name: 'DetailRight', query: { mId: mId, ...this.g_setQuery() }, params: { index: index, listALL: listALL } })
            } else {
                this.$emit('showDetail', index, this.listCenterData)
            }
        },
        // 全选
        toggleCheckAll(blg) {
            if (blg) {
                this.checkedListAll = this.listALL
            } else {
                this.checkedListAll = []
            }
        },
        // 特殊加载  置顶
        async specialLoading(params) {
            try {
                this.checkedListAll = []
                this.listALL = []
                let ordinaryMail = []

                let results = await Promise.all([this._getMailList(params), this._getLabel()])
                this.isLoading = false

                ordinaryMail = results[0].list || []
                this.listCenterData = ordinaryMail.length > 0 ? this.tagsOjectList(ordinaryMail) : []
                let totalNum = results[0].totalNum || 0
                // this.dynamicTags =
                this.labelMap = this.processLabelArrayToMap(results[1])

                this.$emit('totalChange', this.listCenterData.length, totalNum)
            } catch (error) {
                console.log(params)
                console.log('specialLoading')
            }
        },
        async _getMailList(params) {
            let returnData = {}
            try {
                const url = this.Global.config.apiBaseURL + this.Global.api.v2.mails_maillist
                let res = await this.axios.get(url, { params })

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    returnData = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                throw error
            }
            return returnData
        },
        async _getLabel() {
            let returnData = []
            try {
                const url = this.Global.config.apiBaseURL + this.Global.api.v2.label_get
                let params = {
                    searchType: 'list',
                    moduleCode: 'EM001'
                }
                let res = await this.axios.get(url, { params })

                if (res.data.code.toString() == this.Global.config.RES_OK && res.data.data) {
                    returnData = res.data.data
                }
            } catch (error) {
                throw error
            }
            return returnData
        },
        // 第一页
        async onePage(params) {
            this.isLoading = true
            try {
                this.checkedListAll = []
                this.listALL = []
                // let topMail = []
                // let ordinaryMail = []

                let results = await Promise.all([this._getMailList(params), this._getLabel()])

                //  ordinaryMail = results[1].list || []
                //  this.dynamicTags = results[1]
                this.labelMap = this.processLabelArrayToMap(results[1])
                this.listCenterData = this.tagsOjectList(results[0].list)
                //    this.listCenterData = this.tagsOjectList(topMail.concat(ordinaryMail))

                let totalNum = results[0].totalNum

                this.$emit('totalChange', this.listCenterData.length, totalNum)
            } catch (error) {
                console.log(error)
                console.log('onePage')
            }
            this.isLoading = false
        },
        // 获取指定文件夹的邮件列表追加
        async getMailListAppend(params) {
            try {
                let data = await this._getMailList(params)

                if (!this.Global.utils.isObject(data)) {
                    return
                }
                this.isLoading = false
                let addArry = this.tagsOjectList(data.list)

                this.listCenterData = this.listCenterData.concat(addArry)

                this.$emit('totalChange', this.listCenterData.length, data.totalNum)
            } catch (error) {
                console.log(error)
            }
        },
        // 搜索  frequency次数
        async searchChange(params, frequency) {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.mails_fastSearch
                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isObject(res.data.data)) {
                    this.isLoading = false
                    if (frequency == 1) {
                        this.checkedListAll = []
                        this.listALL = []
                        this.listCenterData = this.tagsOjectList(res.data.data.list)
                    } else {
                        let addArry = this.tagsOjectList(res.data.data.list)
                        this.listCenterData = this.listCenterData.concat(addArry)
                    }
                    this.noMail = this.listCenterData.length == 0

                    this.$emit('totalChange', this.listCenterData.length, res.data.data.totalNum)
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        // 数据处理（匹配）
        tagsOjectList(Listdata) {
            try {
                if (!this.Global.utils.isArray(Listdata)) {
                    return []
                }
                let TagsAayy = []

                let mIdALL = this.listALL
                for (let i = 0; i < Listdata.length; i++) { // 标签
                    let detailObj = this.processMailItemTags(Listdata[i])

                    detailObj.key = i
                    TagsAayy.push(detailObj)

                    mIdALL.push(Listdata[i].mId)
                }
                this.listALL = mIdALL
                return TagsAayy
            } catch (error) {
                console.log('tagsOjectList error')
            }
        },
        processMailItemTags(mailItem) {
            let tagObjects = []
            let tags = mailItem.tags
            let len = tags.length
            if (this.Global.utils.isArray(tags)) {
                for (let i = 0; i < len; i++) {
                    if (this.labelMap.has(tags[i])) {
                        let { labelId, labelName, color } = this.labelMap.get(tags[i])
                        tagObjects.push({ labelId, labelName, commentFlag: color })
                    }
                }
            }

            mailItem.tagObjects = tagObjects
            return mailItem
        },
        processLabelArrayToMap(labelArray) {
            let labelMap = new Map()
            labelArray.forEach(item => {
                labelMap.set(item.labelId, item)
            })
            return labelMap
        },
        // 日期显示
        dateDisplay(times) {
            try {
                times = parseInt(times)
            } catch (error) {
                return times
            }
            const timeFormat = this.$moment(new Date(times)).format('YYYY/MM/DD HH:mm')

            const curData = new Date()
            const timArry = timeFormat.split('/')

            if (timArry[0] != curData.getFullYear()) { // 年份不同返回年
                return timeFormat
            } else {
                return this.$moment(new Date(times)).format('MM/DD HH:mm')
            }
        },
        removeItemsFromList(removeIdList = []) {
            let tempArr = this.listCenterData.filter(item => !removeIdList.includes(item.mId))
            this.listCenterData = tempArr
        },
        markItemsFromList(action, markIdList = []) {
            let { key, value } = action
            this.listCenterData.forEach(item => {
                if (markIdList.includes(item.mId)) {
                    Object.assign(item, { [key]: value })
                }
            })
        },
        modifyItemTagsFromList(mId, tags) {
            this.listCenterData.forEach(item => {
                if (mId == item.mId) {
                    item.tags = tags
                    this.processMailItemTags(item)
                }
            })
        }
    },
    watch: {
        checkedListAll: {
            handler(curVal, oldvalue) {
                // console.log(this.listALL);
                this.$emit('manyShowClick', this.checkedListAll, this.listALL)
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

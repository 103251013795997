export default {
    methods: {
        getIcon(fileExtName) {
            return `#file-${this.Global.utils.isHasSuffix(fileExtName)}`
        },
        isImage(data) {
            const imgArr = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
            return imgArr.includes(data.fileExtName.toLowerCase())
        },
        async deleteFile(fileIds = {}, moduleCode) {
            let flag = false
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_files
                let data = {
                    fileIds,
                    moduleCode
                }
                let res = await this.axios.delete(url, { data })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success(res.data.msg)
                    flag = true
                } else {
                    this.$toast.fail(res.data.msg)
                    flag = false
                }
            } catch (error) {
                flag = false
            }
            return flag
        },
        async deleteFolder(folderId, moduleCode, folderType) {
            let flag = false
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_foldersTree
                let data = {
                    folderId,
                    moduleCode,
                    folderType
                }
                let res = await this.axios.delete(url, { data })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success(res.data.msg)
                    flag = true
                } else {
                    this.$toast.fail(res.data.msg)
                    flag = false
                }
            } catch (error) {
                flag = false
            }
            return flag
        }

    }
}

<template>
    <div>
        <div class="list">
            <div @click="openActionSheet()" class="listBox" :class="{'listBox--disabled':itemData.disabled}">
                <span class="name">
                    {{itemData.cnFieldCaption}}
                    <span style="color:#D0021B" v-if="prop()">*</span>
                </span>
                <span>{{obj1.name}}</span>
            </div>
            <!-- 取消 -->
            <van-action-sheet v-model="show1" :actions="actions1" @select="onSelect1" :cancel-text="$t('mx_mobile.Client.1585301326634')" @cancel="cancel1" />
        </div>
    </div>
</template>
<script>
export default {
    name: 'BelongTo',
    props: {
        itemData: {// 赋值给控件的数据
            type: Object,
            default: () => {
                return {}
            }
        },
        optCode: {
            type: String,
            default: ''
        },
        moduleCode: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            obj1: {
                name: '',
                ctId: ''
            },
            show1: false,
            actions1: []
        }
    },
    created() {
        this.getData1()
    },
    mounted() {
        if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
            this.updates(this.itemData.inDefaultValue)
        }
    },
    methods: {
        openActionSheet() {
            console.log('1111')
            // if (this.itemData.disabled) return
            this.show1 = (this.actions1.length != 0)
        },
        cancel1() {
            this.show1 = false
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        updates(value) {
            if (value == '') {
                return false
            }
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.obj1.ctId == '') {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            data[this.itemData.fieldName] = this.obj1.ctId + ''
            return data
        },
        onSelect1(item) {
            // 点击选项时默认不会关闭菜单，可以手动关闭
            this.show1 = false
            this.obj1 = item
        },
        // 初始第一个下拉先 <-_->
        getData1() {
            let contactData = {
                dataType: 'contact',
                optCode: this.optCode,
                funType: 'withRight',
                moduleCode: this.moduleCode
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.accountDropList_get, { params: contactData }).then((res) => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    let actions2 = []
                    let actions1 = res.data.data
                    actions1.forEach(element => {
                        element.name = element.realName
                        if (element.inUse == '1') {
                            actions2.push(element)
                        }
                    })
                    this.actions1 = actions2
                } else {
                    this.actions1 = []
                    this.$toast.fail(res.data.msg)
                }
            }, (res) => {
                this.$toast.fail(this.Global.config.errorTitle)
            })
        }
    },
    components: {
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.list {
    .min-height(45px);
    .line-height(45px);
    border-bottom: 1px solid #eaedef;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    position: relative;
    .listBox {
        .min-height(45px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

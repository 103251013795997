<template>
    <div class="Controls">
        {{returnCusBoxValue(itemData.dictCode, value.value)}}
        <!-- <span v-for="(item, index) in value.split(',')" :key="index"></span> -->
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        value: {
            type: Object,
            default: function () {
                return {
                    value: ''
                }
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        returnCusBoxValue(dictCode, dictItemCode) {
            if (!dictItemCode) {
                return ''
            }
            let list = dictItemCode.split(',')
            let nameList = []
            let itemName = ''
            this.cusBoxValue.forEach(element => {
                if (element.dictCode == dictCode) {
                    element.customDictitems.forEach(items => {
                        list.forEach(elements => {
                            if (items.dictItemCode == elements) {
                                itemName = items.itemName
                            }
                        })
                    })
                }
                if (itemName != '') {
                    nameList.push(itemName)
                }
            })
            return nameList.join(',')
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

<template>
    <div>
        <template>
            <div v-for="(item,index) in itemData.group" :key="index">
                <div v-if="item.fieldName == 'custAreas'" class="list">
                    <div @click="choosePerson0()" class="listBox">
                        <span class="name">
                            {{item.cnFieldCaption}}
                            <span style="color:#D0021B" v-if="prop(item)">*</span>
                        </span>
                        <span>{{obj0.text}}</span>
                    </div>
                </div>
                <div v-if="item.fieldName == 'countryId'" class="list">
                    <div @click="choosePerson1()" class="listBox">
                        <span class="name">
                            {{item.cnFieldCaption}}
                            <span style="color:#D0021B" v-if="prop(item)">*</span>
                        </span>
                        <span>{{obj1.text}}</span>
                    </div>
                </div>
                <div v-if="item.fieldName == 'provinceId'" class="list">
                    <div @click="choosePerson2()" class="listBox">
                        <span class="name">
                            {{item.cnFieldCaption}}
                            <span style="color:#D0021B" v-if="prop(item)">*</span>
                        </span>
                        <span>{{obj2.text}}</span>
                    </div>
                </div>
                <div v-if="item.fieldName == 'cityId'" class="list">
                    <div @click="choosePerson3()" class="listBox">
                        <span class="name">
                            {{item.cnFieldCaption}}
                            <span style="color:#D0021B" v-if="prop(item)">*</span>
                        </span>
                        <span>{{obj3.text}}</span>
                    </div>
                </div>
                <div v-if="item.fieldName == 'townId'" class="list">
                    <div @click="choosePerson4()" class="listBox">
                        <span class="name">
                            {{item.cnFieldCaption}}
                            <span style="color:#D0021B" v-if="prop(item)">*</span>
                        </span>
                        <span>{{obj4.text}}</span>
                    </div>
                </div>
                <div v-if="item.fieldName == 'custAddr'" class="list" style="border:0;">
                    <span class="name">
                        {{item.cnFieldCaption}}
                        <span style="color:#D0021B" v-if="prop(item)">*</span>
                    </span>
                    <van-field v-model="obj5.value" style="padding-left:0;" clearable :placeholder="itemData.cnFieldHint" :maxlength="itemData.fieldLength" />
                </div>
            </div>
        </template>

    </div>
</template>
<script>
import selectCountryEvent from '@/components/SelectCountry/event.js'
export default {
    name: 'BelongTo',
    props: {
        itemData: {// 赋值给控件的数据
            type: Object,
            default: () => {
                return {}
            }
        },
        optCode: {
            type: String,
            default: ''
        },
        moduleCode: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            countryShow: [],
            provinceShow: [],
            cityShow: [],
            obj0: {
                text: '',
                value: ''
            },
            obj1: {
                text: '',
                value: ''
            },
            obj2: {
                text: '',
                value: ''
            },
            obj3: {
                text: '',
                value: ''
            },
            obj4: {
                text: '',
                value: ''
            },
            obj5: {
                value: ''
            },
            valueBase0: '',
            valueBase1: '',
            valueBase2: '',
            valueBase3: '',
            valueBase4: '',
            actions0: [],
            actions1: [],
            actions2: [],
            actions3: [],
            actions4: [],
            actionsBase0: [],
            actionsBase1: [],
            actionsBase2: [],
            actionsBase3: [],
            actionsBase4: [],
            countrySelect: false
        }
    },
    created() {
        this.unit()
    },
    mounted() {
    },
    methods: {
        unit() {
            let fieldName1 = this.itemData.group[0]?.fieldName ?? ''
            console.log('初始化', fieldName1)
            if (fieldName1 == 'custAreas' || fieldName1 == 'countryId') {
                this.getregionList()
            }
            this.getData1()
        },
        returnName(list, value) {
            let obj = {
                text: '',
                value: ''
            }
            list.forEach(element => {
                if (element.value == value) {
                    obj = element
                }
            })
            return obj
        },
        async closeSelect(item) {
            this.countrySelect = false
        },
        async choosePerson0() {
            let returnValue = {
                text: '',
                value: ''
            }
            try {
                let data = await this.Global.utils.chosen.open(this.actions0)
                returnValue = data
            } catch (error) {
                returnValue = {
                    text: '',
                    value: ''
                }
            }
            this.obj0 = returnValue
            if (returnValue.areaItem) {
                this.limitedScope(returnValue.areaItem)
            }

            if (this.obj1.value != '' && !this.countryShow.includes(this.obj1.value)) {
                this.obj1 = {
                    text: '',
                    value: ''
                }
                this.obj2 = {
                    text: '',
                    value: ''
                }
                this.obj3 = {
                    text: '',
                    value: ''
                }
                this.obj4 = {
                    text: '',
                    value: ''
                }
                this.obj5 = {
                    value: ''
                }
                this.actions1 = []
                this.actions2 = []
                this.actions3 = []
                this.actions4 = []
                this.actionsBase1 = []
                this.actionsBase2 = []
                this.actionsBase3 = []
                this.actionsBase4 = []
            }
            if (this.obj0.value == '' && this.obj1.value != '') {
                this.getregionListByCountry(this.obj1.value, false)
            }
            this.getData1(false)
        },
        limitedScope(areaItem) {
            this.countryShow = []
            this.provinceShow = []
            this.cityShow = []
            areaItem.forEach(element => {
                if (element.countryId) this.countryShow.push(parseInt(element.countryId))
                if (element.provinceId && element.provinceId != 0) this.provinceShow.push(parseInt(element.provinceId))
                if (element.cityId && element.cityId != 0) this.cityShow.push(parseInt(element.cityId))
            })
            this.countryShow = [...new Set(this.countryShow)]
            this.provinceShow = [...new Set(this.provinceShow)]
            this.cityShow = [...new Set(this.cityShow)]
        },
        async choosePerson1() {
            let returnValue = {
                text: '',
                value: ''
            }
            try {
                let data = await selectCountryEvent.show(this.obj1, this.actions1)
                returnValue = data
            } catch (error) {
                returnValue = {
                    text: '',
                    value: ''
                }
            }
            // this.actions0
            if (this.obj0.value == '' && returnValue.value != '') {
                this.getregionListByCountry(returnValue.value)
            }
            if (this.obj0.value == '' && returnValue.value == '') {
                this.getregionList()
            }

            if (this.obj1.value != returnValue.value) {
                this.obj2 = {
                    text: '',
                    value: ''
                }
                this.obj3 = {
                    text: '',
                    value: ''
                }
                this.obj4 = {
                    text: '',
                    value: ''
                }
                this.obj5 = {
                    value: ''
                }
            }
            this.obj1 = returnValue
            this.getData2()
        },
        async choosePerson2() {
            if (this.actions2.length == '0') {
                this.$toast(this.$t('mx_mobile.Client.1585630468647')) // 暂无省份
                return false
            }
            let res = await this.Global.utils.chosen.open(this.actions2)
            this.obj2 = res
            this.getData3()
        },
        async choosePerson3() {
            if (this.actions3.length == '0') {
                this.$toast(this.$t('mx_mobile.Client.1585630468493')) // 暂无城市
                return false
            }
            let res = await this.Global.utils.chosen.open(this.actions3)
            this.obj3 = res
            this.getData4()
        },
        async choosePerson4() {
            if (this.actions4.length == '0') {
                this.$toast(this.$t('mx_mobile.Client.1585630465913')) // 暂无地区
                return false
            }
            let res = await this.Global.utils.chosen.open(this.actions4)
            this.obj4 = res
        },
        updates(items) {
            if (this.itemData.group) {
                this.itemData.group.forEach(item => {
                    if (item.fieldName == 'custAreas' && items.custAreas) {
                        this.valueBase0 = items.custAreas
                    }
                    if (item.fieldName == 'countryId' && items.countryId) {
                        this.valueBase1 = items.countryId
                    }
                    if (item.fieldName == 'provinceId' && items.provinceId) {
                        this.valueBase2 = items.provinceId
                    }
                    if (item.fieldName == 'cityId' && items.cityId) {
                        this.valueBase3 = items.cityId
                    }
                    if (item.fieldName == 'townId' && items.townId) {
                        this.valueBase4 = items.townId
                    }
                    if (item.fieldName == 'custAddr' && items.custAddr) {
                        this.obj5.value = items.custAddr
                    }
                })
                if (this.actionsBase1.length != '0') {
                    this.obj1 = this.returnName(this.actionsBase1, this.valueBase1)
                    if (item[this.itemData.group[1].fieldName]) {
                        this.getData2(true, this.valueBase2)
                    } else {
                        this.getData2()
                    }
                }
            }
        },
        prop(item) {
            if (item.disabled) {
                return ''
            }
            if (item.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        submit() {
            let data = {}
            let isProp = true
            this.itemData.group.forEach(item => {
                if (this.prop(item)) {
                    if ((item.fieldName == 'custAreas' && this.obj0.value == '') ||
                        (item.fieldName == 'countryId' && this.obj1.value == '') ||
                        (item.fieldName == 'provinceId' && this.obj2.value == '') ||
                        (item.fieldName == 'cityId' && this.obj3.value == '') ||
                        (item.fieldName == 'townId' && this.obj4.value == '') ||
                        (item.fieldName == 'custAddr' && this.obj5.value == '')
                    ) {
                        isProp = false
                        this.$toast.fail(item.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    }
                }
            })
            if (!isProp) {
                return false
            }

            data = {
                countryId: this.obj1.value != '' ? this.obj1.value + '' : '',
                provinceId: this.obj2.value != '' ? this.obj2.value + '' : '0',
                cityId: this.obj3.value != '' ? this.obj3.value + '' : '0',
                townId: this.obj4.value != '' ? this.obj4.value + '' : '0',
                custAddr: this.obj5.value + ''
            }

            if (this.obj0.value) {
                data.custAreas = this.obj0.value
            }

            if (this.obj1.value != '') {
                data.countryId = this.obj1.value + ''
            } else if (this.type == 'otEdit') {
                data.countryId = '0'
            }
            // if (this.obj2.value != '') {
            //     data.provinceId = this.obj2.value
            // }
            // if (this.obj3.value != '') {
            //     data.cityId = this.obj3.value
            // }
            // if (this.obj4.value != '') {
            //     data.townId = this.obj4.value
            // }
            // data.custAddr = this.obj5.value + ''

            return data
        },
        async getregionListByCountry(countryId, flg = true) {
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.custArea, { params: { valueType: 0, searchId: countryId, type: 'linkSearch' } })
            if (res.data.code.toString() === this.Global.config.RES_OK) {
                this.actions0 = []
                this.actionsBase0 = []
                res.data.data.forEach(element => {
                    if (element.inUse != '0' && element.useFlag == 1) {
                        this.actions0.push({
                            text: element.areaName,
                            value: element.areaId,
                            areaItem: element.areaItem
                        })
                    }
                    this.actionsBase0.push({
                        text: element.areaName,
                        value: element.areaId,
                        areaItem: element.areaItem
                    })
                })
                if (flg) {
                    this.obj0 = this.actions0[0]
                    if (this.obj0.areaItem) {
                        this.limitedScope(this.obj0.areaItem)
                    }
                }
            } else {
                this.actions0 = []
                this.$toast.fail(res.data.msg)
            }
        },
        async getregionList() {
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.custArea, { params: { type: 'list' } })
            if (res.data.code.toString() === this.Global.config.RES_OK) {
                this.actions0 = []
                this.actionsBase0 = []
                res.data.data.forEach(element => {
                    if (element.inUse != '0') {
                        this.actions0.push({
                            text: element.areaName,
                            value: element.areaId,
                            areaItem: element.areaItem
                        })
                    }
                    this.actionsBase0.push({
                        text: element.areaName,
                        value: element.areaId,
                        areaItem: element.areaItem
                    })
                })

                if (this.type == 'otNew' && this.itemData.group[0].inDefaultValue != '') {
                    this.obj0 = this.returnName(this.actionsBase0, this.itemData.group[0].inDefaultValue)
                    if (this.itemData.group[1] && this.itemData.group[1].fieldName == 'countryId') {
                        if (this.itemData.group[0].inDefaultValue != '') {
                            this.limitedScope(this.obj0.areaItem)
                            this.getData1()
                        }
                    }
                } else {
                    if (this.valueBase0 != '') {
                        this.obj0 = this.returnName(this.actionsBase0, this.valueBase0)
                        if (this.itemData.group[1] && this.itemData.group[1].fieldName == 'countryId') {
                            this.limitedScope(this.obj0.areaItem)
                            this.getData1()
                        }
                    }
                }
            } else {
                this.actions0 = []
                this.$toast.fail(res.data.msg)
            }
        },
        // 初始第一个下拉先 <-_->
        async getData1(updRegion = true) {
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.area + 1, { params: { type: 'country' } })
            if (res.data.code.toString() === this.Global.config.RES_OK) {
                this.actions1 = []
                this.actionsBase1 = []
                res.data.data.forEach(element => {
                    if (element.inUse != '0') {
                        this.actions1.push({
                            text: element.cnName,
                            value: element.countryId
                        })
                    }
                    this.actionsBase1.push({
                        text: element.cnName,
                        value: element.countryId
                    })
                })
                console.log('国家获取成功', this.actions1)
                if (this.countryShow.length > 0) {
                    console.log('区域值已选择', this.countryShow)
                    this.actions1 = this.actions1.filter(item => {
                        return this.countryShow.includes(item.value)
                    })
                    this.actionsBase1 = this.actionsBase1.filter(item => {
                        return this.countryShow.includes(item.value)
                    })
                }
                if (this.type == 'otNew' && this.itemData.group[1].inDefaultValue != '') {
                    this.obj1 = this.returnName(this.actionsBase1, this.itemData.group[1].inDefaultValue)
                    if (this.itemData.group[2] && this.itemData.group[2].fieldName == 'provinceId') {
                        if (this.itemData.group[2].inDefaultValue != '') {
                            this.getData2(true, this.itemData.group[2].inDefaultValue)
                        } else {
                            this.getData2()
                        }
                    }
                } else {
                    if (this.valueBase1 != '') {
                        this.obj1 = this.returnName(this.actionsBase1, this.valueBase1)
                        if (this.itemData.group[2] && this.itemData.group[2].fieldName == 'provinceId') {
                            if (this.valueBase2 != '') {
                                this.getData2(true, this.valueBase2)
                            } else {
                                this.getData2()
                            }
                        }
                    }
                }
                if (this.obj0.value == '' && this.obj1.value != '' && updRegion) {
                    this.getregionListByCountry(this.obj1.value)
                }
            } else {
                this.actions1 = []
                this.$toast.fail(res.data.msg)
            }
        },
        async getData2(update, value) {
            if (this.obj1.value === '') {
                this.actions2 = []
                return false
            }
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.area + this.obj1.value, { params: { type: 'province' } })
            if (res.data.code.toString() == this.Global.config.RES_OK) {
                this.actions2 = []
                this.actionsBase2 = []
                res.data.data.forEach(element => {
                    if (element.inUse != '0') {
                        this.actions2.push({
                            text: element.cnName,
                            value: element.provinceId
                        })
                    }
                    this.actionsBase2.push({
                        text: element.cnName,
                        value: element.provinceId
                    })
                })
                if (this.provinceShow.length > 0) {
                    this.actions2 = this.actions2.filter(item => {
                        return this.provinceShow.includes(item.value)
                    })
                    this.actionsBase2 = this.actionsBase2.filter(item => {
                        return this.provinceShow.includes(item.value)
                    })
                }
                if (update) {
                    this.obj2 = this.returnName(this.actionsBase2, value)
                    if (this.itemData.group[3] && this.itemData.group[3].fieldName == 'cityId') {
                        if (this.valueBase3 != '') {
                            this.getData3(true, this.valueBase3)
                        } else {
                            this.getData3()
                        }
                    }
                }
            } else {
                this.actions2 = []
                this.$toast.fail(res.data.msg)
            }
        },
        async getData3(update, value) {
            if (this.obj2.value === '') {
                this.actions3 = []
                return false
            }
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.area + this.obj2.value, { params: { type: 'city' } })
            if (res.data.code.toString() == this.Global.config.RES_OK) {
                this.actions3 = []
                this.actionsBase3 = []
                res.data.data.forEach(element => {
                    if (element.inUse != '0') {
                        this.actions3.push({
                            text: element.cnName,
                            value: element.cityId
                        })
                    }
                    this.actionsBase3.push({
                        text: element.cnName,
                        value: element.cityId
                    })
                })
                if (this.cityShow.length > 0) {
                    this.actions3 = this.actions3.filter(item => {
                        return this.cityShow.includes(item.value)
                    })
                    this.actionsBase3 = this.actionsBase3.filter(item => {
                        return this.cityShow.includes(item.value)
                    })
                }
                if (update) {
                    this.obj3 = this.returnName(this.actionsBase3, value)
                    if (this.itemData.group[4] && this.itemData.group[4].fieldName == 'townId') {
                        if (this.valueBase4 != '') {
                            this.getData4(true, this.valueBase4)
                        } else {
                            this.getData4()
                        }
                    }
                }
            } else {
                this.actions3 = []
                this.$toast.fail(res.data.msg)
            }
        },
        async getData4(update, value) {
            if (this.obj3.value === '') {
                this.actions4 = []
                return false
            }
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.area + this.obj3.value, { params: { type: 'town' } })
            if (res.data.code.toString() == this.Global.config.RES_OK) {
                this.actions4 = []
                this.actionsBase4 = []
                res.data.data.forEach(element => {
                    if (element.inUse != '0') {
                        this.actions4.push({
                            text: element.cnName,
                            value: element.townId
                        })
                    }
                    this.actionsBase4.push({
                        text: element.cnName,
                        value: element.townId
                    })
                })
                if (update) {
                    this.obj4 = this.returnName(this.actionsBase4, value)
                }
            } else {
                this.actions4 = []
                this.$toast.fail(res.data.msg)
            }
        }
    },
    components: {

    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.list {
    .min-height(45px);
    .line-height(45px);
    border-bottom: 1px solid #eaedef;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    position: relative;
    .listBox {
        .min-height(45px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
}
</style>

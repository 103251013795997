<template>
    <div class="screenBox">
        <div class="searchShow" :class="search?'searchShow1':''">
            <van-row class="screenSpanBox">
                <van-col span="8">
                    <div class="screenSpan">
                        <span @click="sortSeach = !sortSeach">
                            <!-- 排序 -->
                            {{$t('mx_mobile.Client.1585115124586')}}
                            <i class="iconfont" :class="sortSeach?'icon-page-up':'icon-page-down'" style="font-size:12px"></i>
                        </span>
                    </div>
                </van-col>
                <van-col span="8">
                    <div class="screenSpan borderLeft">
                        <span @click="$emit('searchScreenClick')">
                            <!-- 筛选 -->
                            {{$t('mx_mobile.Client.1585115139237')}}
                            <i class="iconfont icon-funnel" style="font-size:12px"></i>
                        </span>
                    </div>
                </van-col>
                <van-col span="8">
                    <div class="screenSpan borderLeft">
                        <span @click="search = true">
                            <!-- 搜索 -->
                            {{$t('mx_mobile.Client.1585115144101')}}
                            <i class="iconfont icon-search" style="font-size:13px"></i>
                        </span>
                    </div>
                </van-col>
            </van-row>
            <div class="screenSpanBox1">
                <!-- 请输入搜索关键词 -->
                <search-bar v-model="value" :showBtnNow="true" @keyup.enter.native="toSearch" @btnClick="btnClick" :placeholder="$t('mx_mobile.Client.1585116578431')"></search-bar>
            </div>
        </div>
        <div class="sortSeach" v-show="sortSeach" @click="sortSeach = false">
            <div class="sortSeachBox">
                <li class="ellipsis" @click="sortSeachClick(item)" v-for="(item, index) in sortData" :key="index" :style="{color:item.check ? '#D0021B' : ''}">
                    {{item.cnFieldCaption}}
                    <i v-if="item.check" class="iconfont icon-hook"></i>
                </li>
            </div>
        </div>
    </div>
</template>
<script>
import SearchBar from '@/page/Document/Vue/SearchBar/index'
export default {
    name: 'screenBox',
    props: {
        sort: {
            type: String,
            default: 'createDate'
        },
        isSea: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            search: false,
            sortSeach: false,
            value: '',
            sortData: [],
            searchData: []
        }
    },
    created() {
    },
    mounted() {
        this.getSortData()
        this.getSearchData()
    },
    watch: {
    },
    computed: {
    },
    methods: {
        toSearch() {
            let obj = {
                keywords: this.value
            }
            this.$emit('getListData', obj)
        },
        btnClick() {
            this.search = false
            this.$emit('getListData')
        },
        sortSeachClick(item) {
            // this.sortSeach = true
            this.sortData.forEach(element => {
                element.check = false
            })
            item.check = true
            this.$emit('sortSeachClick', item.fieldName)
            return false
        },
        getSearchData() {
            let _this = this
            let data = {
                moduleCode: 'BF001',
                type: 'searchSet'
            }
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.fieldShow_get, { params: data }).then((res) => {
                if (res.data.code.toString() === _this.Global.config.RES_OK) {
                    let searchData = res.data.data
                    _this.searchData = searchData
                } else {
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        getSortData() {
            let _this = this
            let data = {
                moduleCode: 'BF001',
                type: 'sortSet',
                isSea: _this.isSea
            }
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.fieldShow_get, { params: data }).then((res) => {
                if (res.data.code.toString() === _this.Global.config.RES_OK) {
                    let sortData = res.data.data
                    sortData.forEach(element => {
                        if (element.fieldName == _this.sort) {
                            element.check = true
                        } else {
                            element.check = false
                        }
                    })
                    _this.sortData = sortData
                } else {
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        }
    },
    components: {
        'search-bar': SearchBar
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

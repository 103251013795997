<template>
    <van-popup v-model="isScreen" position="right" class="popUp" :close-on-click-overlay="false">
        <div class="bigBox">
            <div class="filterBox">
                <div class="screen">
                    <div class="roll">
                        <div class="screenList" style="margin-bottom:15px;">
                            <!-- 附件分组 -->
                            <div class="screenTitle">{{$t('mx_mobile.Client.1631872191757')}}</div>
                            <span style="margin-right:8px;">
                                <template v-for="(item,index) in listTabArr">
                                    <van-button :key="index" v-show="item.isShow" size="small" :class="listTabVal == item.value ? 'button2' : ''" @click="listTabClick(item)" class="button">{{item.name}}</van-button>
                                </template>
                            </span>
                        </div>
                        <div class="screenList" style="margin-bottom:28px;" v-if="listTabVal != listTabArr[1].value">
                            <!-- 文件夹筛选 -->
                            <h2 class="screenTitle">{{$t('mx_mobile.Client.1631872300938')}}</h2>
                            <div class="allDocuments">
                                <div style="width: 95%;" v-show="ifShowTree&&listTabVal == listTabArr[0].value">
                                    <li class="folder-item__container">
                                        <h3 class="folder-item" @click="allFiles()" style="padding-left:0;margin-left:0" :class="[{'allfile_active' : allFilesFlag}]">
                                            <i class="iconfont folder-item__container-toggle" @click="isMyMail=!isMyMail" :class="[isMyMail ? 'icon-liebiao-shouqi' : 'icon-liebiao-zhankai']"></i>
                                            <div class="folder-item__label">
                                                <!--所有文件-->
                                                <div class="folder-item__name">{{$t('mx_mobile.Client.1631864216033')}}
                                                    <div v-if="allFilesFlag" class="circular">
                                                        <i class="iconfont hook" :class="allFilesFlag?'icon-hook':''"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </h3>
                                    </li>

                                    <div v-if="isMyMail">
                                        <!--系统分组-->
                                        <p><i @click="isMyGroup=!isMyGroup" class="tree-item__icon iconfont" :class="[isMyGroup ? 'icon-liebiao-shouqi' : 'icon-liebiao-zhankai']"></i>{{$t('mx_mobile.Client.1632295840882')}}</p>
                                        <template v-if="isMyGroup">
                                            <li class="folder-item__container" v-for="(item) in groupArr" :key="item.groupId">
                                                <h3 :title="item.groupName" class="folder-item" ref="treeItem" @click="groupitemClick(item)" :class="searchisId == item.groupId?'group_active':''">
                                                    <div class="folder-item__label1">
                                                        <span class="folder-item__nameTwo"><i class="iconfont icon-liebiaoxiangmufuhao-yuanxing iconCircular"></i>{{item.groupName}}</span>
                                                        <div v-if="searchisId == item.groupId" class="circular"><i class="iconfont hook" :class="searchisId == item.groupId?'icon-hook':''"></i></div>
                                                    </div>
                                                </h3>
                                            </li>
                                        </template>
                                        <p><i @click="isMyFolder=!isMyFolder" class="tree-item__icon iconfont" :class="[isMyFolder ? 'icon-liebiao-shouqi' : 'icon-liebiao-zhankai']"></i>{{$t('mx_mobile.Client.1632295682721')}}</p>
                                        <template v-if="isMyFolder">
                                            <li class="folder-item__container" v-for="(item,index) in foldersList" :key="index">
                                                <h3 :title="item.folderName" class="folder-item" ref="treeItem" @click="groupitemClick(item)" :class="searchisId == item.groupId?'group_active':''">
                                                    <div class="folder-item__label1">
                                                        <span class="folder-item__name">{{item.folderName}}</span>
                                                    </div>
                                                    <div class="folder-item__label1">
                                                        <span class="folder-item__nameTwo"><i class="iconfont icon-liebiaoxiangmufuhao-yuanxing iconCircular"></i>{{item.folderName}}</span>
                                                        <div v-if="searchisId == item.folderId" class="circular"><i class="iconfont hook" :class="searchisId == item.groupId?'icon-hook':''"></i></div>
                                                    </div>
                                                </h3>
                                            </li>
                                        </template>
                                        <!--自定义文件夹-->
                                        <template v-if="isMyFolder">
                                            <tree-menu ref="treeMenu" v-for="(item,index) in foldersList" @itemClick="folderitemClick" :moduleCode="moduleCode" :foldersList="foldersList" :key="index" :treeData="item" :RowIndex="index" @getTabData="getTabData">
                                            </tree-menu>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="screenList" style="margin-bottom:15px;">
                            <!-- 文件格式 -->
                            <div class="screenTitle">{{$t('mx_mobile.Client.1631872335197')}}</div>
                            <ul class="file_format">
                                <li v-for="item in category" :key="item.code" :class="[item.code == categoryValue ? 'click_flie' : '']" @click="flieFormatClick(item.code)">{{item.name}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fixedBotton">
                <div class="flexBotton">
                    <van-button class="cancel" @click="cancelClick">{{$t('mx_mobile.Client.1585301326634')}}</van-button> <!-- 取消 -->
                    <van-button class="determine cancel" @click="determineClick">{{$t('mx_mobile.Client.1585304971472')}}</van-button> <!-- 确认 -->
                </div>
            </div>
        </div>
    </van-popup>
</template>
<script>
import TreeMenu from './TreeMenu/index.vue'
export default {
    name: 'filterBox',
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        isScreen: {
            type: Boolean,
            default: true
        },
        categoryVal: {
            type: String,
            default: ''
        },
        listTab: {
            type: String,
            default: ''
        },
        searchId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isTouch: false,
            allFilesFlag: true,
            isMyFolder: true,
            ifShowTree: true,
            isMyMail: true,
            isMyGroup: true,
            screen: this.isScreen,
            listTabVal: this.listTab || 'listTab1', // 活动项按钮
            listTabArr: [ // 按钮组
                {
                    name: this.$t('mx_mobile.Client.1631872229994'), // '全部客户附件',
                    value: 'listTab1',
                    isShow: true
                },
                {
                    name: this.$t('mx_mobile.Client.1631872275328'), // 邮件附件
                    value: 'listTab2',
                    isShow: true
                }
            ],
            category: Object.freeze([
                {
                    name: this.$t('mx_mobile.Client.1627009173954'), // 全部
                    code: 'list'
                },
                {
                    name: this.$t('mx_mobile.Client.1626945668546'), // 图片
                    code: 'PICTURE'
                },
                {
                    name: this.$t('mx_mobile.Client.1632295110746'), // 视频
                    code: 'VIDEO'
                },
                {
                    name: 'DOC',
                    code: 'DOC'
                },
                {
                    name: 'XLS',
                    code: 'XLS'
                },
                {
                    name: 'PPT',
                    code: 'PPT'
                },
                {
                    name: 'PDF',
                    code: 'PDF'
                }
            ]),
            categoryValue: this.categoryVal || 'list',
            groupArr: [],
            foldersList: [],
            searchFolderId: '',
            searchGroupId: '',
            enclosureTitle: '',
            searchisId: this.searchId // 传值进来的id,用作于回显
        }
    },
    created() {
        this.getTabData()
    },
    mounted() {
    },
    methods: {
        listTabClick(item) {
            if (this.listTabVal != item.value) {
                this.listTabVal = item.value
                // this.categoryValue = 'list'
                this.$emit('listTabValue', this.listTabVal)
            }
            if (item.value == 'listTab2') {
                this.enclosureTitle = this.$t('mx_mobile.Client.1631864216033') // 所有文件
            }
        },
        folderitemClick({ target, name, obj }) {
            this.allFilesFlag = false
            this.searchFolderId = obj.id
            this.searchGroupId = ''
            this.groupArr.forEach(v => {
                if (v.flag) {
                    v.flag = false
                }
            })
        },
        // 取消按钮，关闭弹出层
        cancelClick() {
            this.screen = false
            this.$emit('cancelClick', this.screen)
        },
        // 点击成功按钮
        determineClick() {
            this.screen = false
            this.$emit('determineClick', this.screen)
        },
        // 文件格式
        flieFormatClick(value) {
            if (this.categoryValue != value) {
                this.categoryValue = value
                this.$emit('categoryValue', this.categoryValue)
            }
        },

        _changeTreeItemStatus(target) {
            if (target) {
                this.$el.querySelectorAll('.active')
                    .forEach(el => {
                        el.classList.remove('active')
                    })
                target.classList.add('active')
            } else {
                this.$el.querySelectorAll('.active')
                    .forEach(el => {
                        el.classList.remove('active')
                    })
            }
        },
        // 点击了所有文件
        allFiles() {
            this.enclosureTitle = this.$t('mx_mobile.Client.1631864216033') // 所有文件
            this.allFilesFlag = true
            this.groupArr.forEach(v => {
                if (v.flag) {
                    v.flag = false
                }
            })
            this._changeTreeItemStatus()
            this.searchFolderId = ''
            this.searchGroupId = ''
            this.searchisId = 0
            this.$emit('searchGroupId', this.searchGroupId)
        },
        groupitemClick(item) {
            this.enclosureTitle = item.groupEnName
            this.$emit('groupArr', item)
            this.allFilesFlag = false
            this.groupArr.forEach(v => {
                if (v.flag) {
                    v.flag = false
                }
            })
            item.flag = true
            this._changeTreeItemStatus()
            this.searchFolderId = ''
            this.searchisId = item.groupId
            this.searchGroupId = item.groupId
            this.$emit('searchGroupId', this.searchGroupId)
        },
        getTabData() {
            let _this = this
            // 获取分组
            let data = {
                moduleCode: this.moduleCode,
                searchType: 'list'
            }
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.group_get, { params: data }).then((res) => {
                if (res.data.code.toString() == _this.Global.config.RES_OK && res.data.data) {
                    let list = res.data.data || []
                    _this.groupArr = list
                } else {
                    _this.$message.error(_this.$t(_this.Global.errorTitle))
                }
            }, (res) => {
                // _this.$message.error(_this.$t(_this.Global.errorTitle))
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
            // 获取文件夹
            let url = _this.Global.config.apiBaseURL + _this.Global.api.v2.folders_foldersTree
            let foldersData = {
                params: {
                    folderSource: 5,
                    custId: localStorage.getItem('pageId'),
                    moduleCode: this.moduleCode
                }
            }
            _this.axios.get(url, foldersData).then(res => {
                if (res.data.code.toString() == _this.Global.config.RES_OK && res.data.data) {
                    let folderData = res.data.data
                    if (folderData) {
                        _this.foldersList = folderData
                    }
                    // return folderData
                }
            }).catch(res => {
                // _this.$message.error(_this.msg(res.body))
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        }
    },
    watch: {
        enclosureTitle(newV, oldV) {
            if (newV != oldV) {
                this.$emit('enclosureTitle', this.enclosureTitle)
            }
        }
    },
    component: {
        'tree-menu': TreeMenu
    }
}
</script>
<style lang="less" rel="stylesheet/less"  scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

<template>
    <div>
        <!-- @searchClick="searchClick" @searchClick="$refs.SearchScreen.searchScreenClick()"-->
        <search-mail v-show="searchMailIs" ref="searchMail" @searchClick="searchClick"> </search-mail>
        <search-screen ref="SearchScreen" @getListData="filterGetMail"></search-screen>
        <div class="MaliList">
            <div ref="wrapper" class="navView" style="top:100px;" :style="{bottom:manyShow?'50px':'0'}">
                <scroll ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp" v-if="!isLoading" class="wrapper">
                    <mail-list-view ref="mailListView" :routerDetail="false" @showDetail="handleShowDetail" @manyShowClick="manyShowClick" @totalChange="totalChange"></mail-list-view>
                </scroll>
                <!-- </div> -->
            </div>
            <batch-space ref="batchSpace" v-if="manyShow" class="footClass" :isUndistributed="isUndistributed" :boxId="boxId" :isTopList="isTopList" :checkAll="checkAll" :checkedListAll="checkedListAll" @toggleSelectAll="toggleSelectAll" @refreshPage="refreshPage" @delete="removeItemsFromList()" @mark="markItemsFromList" @dialogJunkMail="dialogJunkMail"></batch-space>
        </div>
        <mail-detail ref="mailDetail" v-if="showDetail" :detailMailIndex="detailMailIndex" :boxId="boxId" :isTopList="isTopList" :isUndistributed="isUndistributed" :detailMailList="detailMailList" @close="showDetail=false"></mail-detail>
    </div>
</template>

<script>
import SearchMail from '../../MailPublic/SearchMail/index.vue'
import MailListView from '../../MailPublic/MailListView/index.vue'
import BatchSpace from '../../MailPublic/ToolBar/BatchSpace/index.vue'
import SearchScreen from '../../MailPublic/SearchScreen/index.vue'
import { PageBlock as MailDetail } from '../DetailRight'
import { moveMailSuccess, modifyTagSuccess } from '../../mailConst.js'
import Scroll from '@/components/Scroll/index'
import titleMixin from '@/mixin/title'
import { mapGetters, mapMutations } from 'vuex'

const itemKeyStrate = {
    'MovingBox': moveMailSuccess,
    'TagList': modifyTagSuccess
}
export default {
    name: 'MaliList',
    props: {
        custId: {
            type: String,
            default: ''
        }
    },
    mixins: [titleMixin],
    data() {
        return {
            headerTitle: '',
            boxId: '',
            scroll: '',
            manyShow: false,
            checkAll: false,
            postParams: {},
            from: 1,
            pageSize: 20,
            total: 0,
            listSize: 0,
            operation: 'folder',
            inputText: '',
            checkedListAll: [],
            isLoading: false,
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: '加载更多',
            pullUpLoadNoMoreTxt: '没有更多数据了',
            startY: 0,
            scrollToX: 0,
            scrollToY: -200,
            scrollToTime: 700,
            scrollToEasing: 'bounce',
            scrollToEasingOptions: ['bounce', 'swipe', 'swipeBounce'],
            searchMailIs: true,
            filterObect: {
                attach: '',
                color: '',
                readFlag: '',
                labelName: ''
            },
            filterType: 'all',
            showDetail: false,
            detailMailId: '',
            detailMailList: [],
            isSearching: false
        }
    },
    computed: {
        ...mapGetters('Mail', ['boxMid', 'actionTarget', 'currentAddress', 'currentCtid', 'boxName']),
        pullDownRefreshObj() {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj() {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        },
        isTopList() {
            return this.operation === 'toplist'
        },
        isUndistributed() {
            return this.operation === 'undistributed'
        }

    },
    created() {
    },
    mounted() {
        this.pageInit()
        if (this.$route.query['searchValue']) {
            this.searchClick(this.$route.query['searchValue'], 1)
        }
    },
    methods: {
        ...mapMutations('Mail', {
            set_boxMid: 'SET_BOXMID',
            set_boxName: 'SET_BOXNAME',
            set_actionTarget: 'SET_ACTIONTARGET'
        }),
        handleShowDetail(index, mailList) {
            this.showDetail = true
            this.detailMailIndex = index
            this.detailMailList = mailList
        },
        filterGetMail(onject) {
            this.filterObect.attach = onject.attach
            this.filterObect.color = onject.key
            this.filterObect.readFlag = onject.readFlag
            this.filterObect.labelName = onject.labelName
            this.filterType = 'filter'
            this.refreshPage()
        },
        removeItemsFromList(idList = this.checkedListAll) {
            let deleteIdList = [...idList]
            this.toggleSelectAll(false)
            this.$refs.mailListView.removeItemsFromList(deleteIdList)
        },
        markItemsFromList(action, idList = this.checkedListAll) {
            let markIdList = [...idList]
            this.toggleSelectAll(false)
            this.$refs.mailListView.markItemsFromList(action, markIdList)
        },
        modifyItemTagsFromList(mId, tags) {
            this.$refs.mailListView.modifyItemTagsFromList(mId, tags)
        },
        dialogJunkMail() {
            this.$refs.mailListView.dialogJunkMail()
        },
        toggleSelectAll(blg) { // 全选/全不选
            this.$refs.mailListView.toggleCheckAll(blg)
            this.manyShow = false
        },
        manyShowClick(arry, arryAll) { // 工具栏显示
            this.checkedListAll = arry
            if (arry.length > 0) {
                this.manyShow = true
                if (arry.length == arryAll.length) {
                    this.checkAll = true
                } else {
                    this.checkAll = false
                }
            } else {
                this.manyShow = false
                this.checkAll = false
            }
        },

        async getMailListByBoxId() {
            this.operation = 'folder'
            let params = {
                boxId: this.boxId,
                target: 'folder',
                type: 'all',
                from: (this.from - 1) * this.pageSize,
                size: this.pageSize,
                //  stickyFlag: 1,
                mailAccount: this.currentAddress
            }

            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                params.ctid = this.currentCtid
            } else {
                delete params.ctid
            }

            if (this.filterType == 'filter') {
                params = this.getParams(params)
                // data2 = this.getParams(data2)
            }
            this.$refs.mailListView.onePage(params)
        },
        getParams(data) {
            data.type = 'filter'
            data.attach = this.filterObect.attach
            if (this.filterObect.color) {
                data.commentFlag = parseInt(this.filterObect.color)
            }
            data.readFlag = this.filterObect.readFlag
            data.label = this.filterObect.labelName
            this.Global.utils.delEmptyStrObj(data, true)
            return data
        },
        async getMailListAppend() { // 列表追加
            let params = {}
            let pageStart = this.from == 1 ? 0 : this.$refs.mailListView.listCenterData.length // || (this.from - 1) * this.pageSize

            switch (this.operation) {
                case 'folder':
                    params = {
                        boxId: this.boxId,
                        target: 'folder',
                        type: 'all',
                        from: pageStart,
                        size: this.pageSize,
                        mailAccount: this.currentAddress
                    }
                    break
                case 'toplist':
                    params = {
                        target: 'toplist',
                        type: 'all',
                        from: pageStart,
                        size: this.pageSize,
                        mailAccount: this.currentAddress
                    }
                    break
                case 'undistributed':
                    params = {
                        boxId: -1,
                        target: 'folder',
                        type: 'all',
                        from: pageStart,
                        size: this.pageSize,
                        cid: this.currentCtid,
                        ctid: this.currentCtid
                    }
                    break
                case 'custmerlist':
                    params = {
                        target: 'cust',
                        type: 'all',
                        custId: this.boxId,
                        from: pageStart,
                        size: this.pageSize
                    }
                    break

                default:
                    break
            }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                params.ctid = this.currentCtid
            } else {
                delete params.ctid
            }
            if (this.filterType == 'filter') {
                params = await this.getParams(params)
            }
            this.$refs.mailListView.getMailListAppend(params)
        },
        searchClick(inputText, page, issearchM = true) { // 快速搜索
            this.searchMailIs = issearchM
            if (inputText.toString().trim() == '') {
                this.pageInit()
                return
            }
            if (this.$route.query['searchValue']) {
               this.setHeaderTitle('快速搜索')
            }
            this.inputText = inputText
            this.operation = 'searchShow'
            this.set_actionTarget(this.operation)
            this.from = page
            let params = {
                keywords: inputText,
                type: 'folder',
                from: (this.from - 1) * this.pageSize,
                size: this.pageSize
            }
            if (issearchM) {
                params.boxId = this.boxId
            }
            if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                params.ctid = this.currentCtid
            } else {
                delete params.ctid
            }
            this.$refs.mailListView.searchChange(params, page)
        },
        specialLoading() {
            let params = {}
            this.from = 1
            switch (this.operation) {
                case 'toplist':
                    this.operation = 'toplist'
                    params = {
                        target: 'toplist',
                        type: 'all',
                        from: (this.from - 1) * this.pageSize,
                        size: this.pageSize,
                        mailAccount: this.currentAddress
                    }
                    break
                case 'custmerlist':
                    this.operation = 'custmerlist'
                    params = {
                        target: 'cust',
                        type: 'all',
                        custId: this.boxId,
                        from: (this.from - 1) * this.pageSize,
                        size: this.pageSize
                    }
                    break
                case 'undistributed':
                    console.log('cid', this.currentCtid)

                    this.operation = 'undistributed'
                    params = {
                        boxId: -1,
                        target: 'folder',
                        type: 'all',
                        from: (this.from - 1) * this.pageSize,
                        size: this.pageSize,
                        cid: this.currentCtid,
                        ctid: this.currentCtid
                    }
                    break

                default:
                    break
            }
            if (this.operation != 'undistributed') {
                if (this.currentCtid != this.companie.ctId && this.currentCtid != '') {
                    params.ctid = this.currentCtid
                } else {
                    delete params.ctid
                }
            }
            this.$refs.mailListView.specialLoading(params)
        },
        async onPullingDown() {
            this.from = 1
            if (this.operation == 'folder') {
                this.getMailListByBoxId()
            } else if (this.operation == 'searchShow') {
                this.searchClick(this.inputText, 1, this.searchMailIs)
            } else if (this.operation == 'toplist' || this.operation == 'custmerlist') {
                this.specialLoading()
            }
            if (this._isDestroyed) {
                return
            }
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        async onPullingUp() { // 最下面往上拉
            if (this.listSize >= this.total) {
                this.$nextTick(() => {
                    this.$refs.scroll.forceUpdate(false)
                })
                return
            }
            this.from += 1
            if (['folder', 'toplist', 'custmerlist', 'undistributed'].includes(this.operation)) {
                this.getMailListAppend()
            } else if (this.operation == 'searchShow') {
                this.searchClick(this.inputText, this.from, this.searchMailIs)
            }
        },
        rebuildScroll() {
            this.$nextTick(() => {
                this.$refs.scroll.destroy()
                this.$refs.scroll.initScroll()
            })
        },
        totalChange(listSize, total) {
            this.listSize = listSize
            this.total = total
            this.$nextTick(() => {
                if (listSize >= total) {
                    this.$refs.scroll.forceUpdate(false)
                } else {
                    this.$refs.scroll.forceUpdate(true)
                }
            })
        },
        async initTitleBar(isShowDetail = false) {
            if (isShowDetail) {
                this.setHeaderTitle('邮件详情')
                await this.$nextTick()
                this.$refs.mailDetail.setMenu()
            } else {
                this.setMenu()
                this.setHeaderTitle(this.headerTitle)
                if (this.$route.query['searchValue']) {
                    this.setHeaderTitle('快速搜索')
                }
            }
        },

        setMenu() {
            let rightMenu = this.Global.utils.rightMenu.setMenu('更多')
            rightMenu.onClick(() => {
                if (this.manyShow) {
                    this.$refs.batchSpace.showActionChange()
                } else {
                    this.$toast.fail('请选择操作项')
                }
            })
        },
        pageInit(type = 'normal') {
            if (type == 'normal') {
                this.from = 1
                let { boxId, target, boxName } = this.$route.params
                this.headerTitle = boxName

                this.boxId = boxId

                this.operation = target

                this.filterType = 'all'
                this.set_boxMid(this.boxId)
                this.set_boxName(boxName)
                this.set_actionTarget(this.operation)
            } else {
                this.boxId = this.boxMid
                this.operation = this.actionTarget
                this.headerTitle = this.boxName
            }
            this.initTitleBar()

            this.refreshPage()
        },
        refreshPage() {
            switch (this.operation) {
                case 'folder':
                    this.getMailListByBoxId()
                    break
                case 'toplist':
                case 'undistributed':
                case 'custmerlist':
                    this.specialLoading()
                    break
            }
            this.$refs.searchMail.ClearValue()
        },
        processMailOperationPageBack(prevPageName) {
            this.initTitleBar(this.showDetail)

            const itemKey = itemKeyStrate[prevPageName]
            let res = this.Global.utils.getItem(itemKey)

            if (!res) { return }
            try {
                let resJson = JSON.parse(res)
                let { date } = resJson
                if ((+new Date()) - date < 1000) {
                    this._getMailOperationPageBackAction(prevPageName)(resJson)
                }
            } catch (error) {

            }
        },
        _isMailOperationPageBack(pageName) {
            return itemKeyStrate.hasOwnProperty(pageName)
        },

        _getMailOperationPageBackAction(prevPageName) {
            const actionStrate = {
                'MovingBox': resJson => {
                    let { data } = resJson
                    if (this.showDetail && data.length == 1) {
                        this.$refs.mailDetail.actionList('delete')
                    } else {
                        this.removeItemsFromList(data)
                    }
                },
                'TagList': resJson => {
                    let { tags, mId } = resJson
                    this.modifyItemTagsFromList(mId, tags)
                }
            }
            return actionStrate[prevPageName]
        }

    },
    beforeRouteLeave(to, from, next) {
        const flag = this.Global.utils.chosen.close()
        const flag2 = this.Global.utils.actionSheet.close()
        const flag3 = this.Global.utils.prompt.close()
        if (flag || flag2 || flag3) {
            next(false)
        } else if (this._isMailOperationPageBack(to.name)) {
            next()
        } else if (this.showDetail) {
            this.showDetail = false
            this.initTitleBar()
            if (to.name === 'MailHome') {
                next(false)
            } else {
                next()
            }
        } else {
            next()
        }
    },
    components: {
        'search-mail': SearchMail,
        'mail-list-view': MailListView,
        'batch-space': BatchSpace,
        'scroll': Scroll,
        'search-screen': SearchScreen,
        'mail-detail': MailDetail
    },
    watch: {
        showDetail() {
            this.initTitleBar(this.showDetail)
        },
        $route(to, old) {
            if (this.$route.path !== '/mail/mailList') {
                return
            }
            if (old.path == '/mail/home') {
                this.pageInit()
                this.$nextTick(() => {
                    this.$refs.SearchScreen.clearFilfter()
                })
            } else if (this._isMailOperationPageBack(old.name)) {
                this.processMailOperationPageBack(old.name)
            } else if (old.name != 'DetailRight') {
                this.pageInit('special')
            } else {
                this.initTitleBar()
                this.$refs.scroll.refresh()
            }
            if (this.$route.query['searchValue']) {
                this.searchClick(this.$route.query['searchValue'], 1)
            }
        }
    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

<template>
    <div class="Group-Modify">
    </div>
</template>

<script>
export default {
  name: 'Group-Modify',
  data () {
    return {
      options: [],
      value: ''
    }
  },
  methods: {
    updates (item) {
    },
    submit() {
      return {}
    }
  }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.Group-Modify {
    width: 100%;
}
</style>

<template>
    <div class="Calculator">

        <template v-if="step01.isShow">
            <dl class="orderTop">
                <dt>产品名称</dt>
                <dd class="btns">
                    <template v-for="(item,index) in pkData">
                        <span :key="index" class="mx_btn" @click="formObj.pkValue = item.pkCode; getActivity(),getPrice()" :class="[formObj.pkValue == item.pkCode ? 'active' :'']" v-if="!hidenPkcode.includes(item.pkCode)">{{item.cnName}}</span>
                    </template>
                </dd>
            </dl>
            <dl class="orderTop">
                <dt>购买时长</dt>
                <dd class="btns">
                    <span class="mx_btn" @click="formObj.yearValue = item.value; getActivity();changeMin(item.value),getPrice()" v-for="(item,index) in yearData" :key="index" :class="[item.text && item.text != '' ? 'mx_gift' : '', formObj.yearValue == item.value ? 'active' :'']">{{item.value}}年</span>
                </dd>
                <dd class="descript">
                    <div><i class="iconfont icon-calendar"></i> 购买后到期时间：<span style="color:#d0021b">{{getEndTime}}（{{totalMonth}}个月）</span></div>
                    <div v-show="isDiscounts && priceConfig.offCost !=0"><span class="gift">省</span>{{formObj.yearValue}}年付立省{{Math.abs(priceConfig.offCost)}}元</div>
                    <div v-if="yearData[formObj.yearValue - 1].text != ''"><span class="save">促</span>{{yearData[formObj.yearValue - 1].text}}</div>
                </dd>
            </dl>

            <dl class="orderTop">
                <div class="peopleNum">
                    <van-stepper v-model="formObj.memberNum" integer :min="min" :max="999" :step="1" @change="getPrice()" />
                </div>
                <dt>用户许可数</dt>
                <dd>
                    购买后用户许可数：<span style="color:#d0021b">{{formObj.memberNum}}人</span>
                </dd>
            </dl>
            <div class="divDetailFix" v-show="priceConfig.extraMemberCost != 0">
                <em>{{priceConfig.packageCost}}元 + <span v-show="priceConfig.extraMemberCost != 0">{{priceConfig.costOfMember}}元 * {{priceConfig.outMember}}用户 * {{formObj.yearValue}}年</span></em>
            </div>
            <div class="divFixedBottom">
                <div style="font-size:14px;border-top: 1px solid #e3e3e4;color:#606266"><span>￥{{isNaN(priceConfig.totalCost)?'0':returnFloat(priceConfig.totalCost)}}</span><em class="original" v-show='formObj.yearValue != 1'>￥{{isNaN(priceConfig.totalCost)?'0':priceConfig.totalCost+
                        priceConfig.offCost}}</em></div>
                <div @click="clickStep01" class="btn">下一步</div>
            </div>
        </template>

        <template v-if="step02.isShow">
            <div class="orderSheet">
                <div class="current">
                    <span>已选配置</span>
                    <ul>
                        <li>
                            <span>产品名称：</span>
                            <em>{{getpkName}}</em>
                        </li>
                        <li>
                            <span>到期时间：</span>
                            <em>{{getEndTime}}</em>
                        </li>
                        <li>
                            <span>用户许可数：</span>
                            <em>{{formObj.memberNum}}</em>
                        </li>
                        <li>
                            <span>云空间：</span>
                            <em>{{formObj.memberNum*20}}G</em>
                        </li>
                    </ul>
                </div>
                <div class="detailList">
                    <ul>
                        <li>
                            <span>新购 {{getpkName}} ({{formObj.memberNum-priceConfig.outMember}}用户)</span>
                            <em :class="priceConfig.offCost != 0 ?'original':''">￥{{isNaN(priceConfig.totalCost)?'0':priceConfig.packageCost + priceConfig.offCost}}</em>
                        </li>
                        <li v-show="priceConfig.offCost != 0">
                            <div class="left"><span class="gift">省</span>{{formObj.yearValue}}年付立省{{priceConfig.offCost}}元</div>
                            <em class="blod">￥{{priceConfig.packageCost}}</em>
                        </li>
                        <li class="listTitle" v-if="priceConfig.outMember!=0">
                            <span>增加用户*{{priceConfig.outMember}}</span>
                            <em class="blod">￥{{priceConfig.extraMemberCost}}</em>
                        </li>
                        <li v-if="priceConfig.outMember!=0">
                            <span>费用小计：{{priceConfig.costOfMember}}元* {{priceConfig.outMember}}用户* {{formObj.yearValue}}年 = {{priceConfig.extraMemberCost}}元</span>
                        </li>
                        <li class="listTitle" v-if="activityConfig.nums && activityConfig.nums != 0 && activityConfig.type == 'day'">
                            <div class="left"><span class="save">促</span>{{activityConfig.description}}</div>
                            <em class="blod">￥0</em>
                        </li>
                        <li class="listTitle" v-if="activityConfig.nums && activityConfig.nums != 0 && activityConfig.type == 'money'">
                            <div class="left"><span class="save">促</span>{{activityConfig.description}}</div>
                            <em class="blod red">-￥{{activityConfig.nums}}</em>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="divFixedBottom">
                <!-- priceConfig.offCost返回时负值所以- -->
                <div style="font-size:14px;border-top: 1px solid #e3e3e4;color:#606266"><span>￥{{isNaN(priceConfig.totalCost)?'0':returnFloat(priceConfig.totalCost)}}</span><em class="original" v-show='formObj.yearValue != 1'>￥{{isNaN(priceConfig.totalCost)?'0':priceConfig.totalCost+priceConfig.offCost}}</em></div>
                <div @click="clickStep02" class="btn">下一步</div>
            </div>
        </template>

        <step03 v-if="step03.isShow" :item="item" :priceConfig="priceConfig" :formObj="formObj" @success="successStep03" :activityId="activityId" />

        <step04 v-if="step04.isShow" :orderno="orderno" />

    </div>
</template>

<script>
import titleMixin from '@/mixin/title'
import { mapGetters } from 'vuex'
import step03 from './step03.vue'
import step04 from './step04.vue'
export default {
    name: 'Order',
    mixins: [titleMixin],
    // title: '选择配置',
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            step01: {
                isShow: true
            },
            step02: {
                isShow: false
            },
            step03: {
                isShow: false
            },
            step04: {
                isShow: false
            },
            pkData: [
                {
                    value: 'PK0001',
                    label: 'CRM'
                },
                {
                    value: 'PK0002',
                    label: '发现'
                },
                {
                    value: 'PK0003',
                    label: '营销云'
                },
                {
                    value: 'PK0006',
                    label: '销售云-Pro'
                },
                {
                    value: 'PK0005',
                    label: '销售云-企业版'
                }
            ],
            yearData: [
                {
                    value: 1,
                    text: ''
                },
                {
                    value: 2,
                    text: ''
                },
                {
                    value: 3,
                    text: ''
                }
            ],
            formObj: {
                pkValue: 'PK0001',
                yearValue: 1, // 续费年限
                memberNum: 5
            },
            priceConfig: {}, // 选配置后 算出来的结果
            activityConfig: {}, // 选配置后 算出来的活动优惠
            currentActivity: {}, // 正在进行的活动
            min: 0,
            isDiscounts: true,
            isContinue: false,
            orderno: '',
            activityId: '', // 活动id
            hidenPkcode: ['PK0011', 'PK0007']
        }
    },
    async created() {
        await this.getAllPackage()
        this.setMenu('选择配置')
        await this.getPrice()
    },
    mounted() {
        this.getActivity()
        this.changeMin(1)
    },
    computed: {
        ...mapGetters(['companie']),
        getEndTime() {
            var myDate = new Date()
            if (this.activityConfig.type == 'day') {
                myDate = myDate.setMonth(myDate.getMonth() + this.activityConfig.nums)
                myDate = new Date(myDate)
            }
            let newDate = this.DateAdd(this.formObj.yearValue, myDate)
            return newDate.toLocaleDateString()
        },
        getpkName() {
            let clickItem = this.pkData.filter((item) => {
                return item.pkCode == this.formObj.pkValue
            })
            return clickItem[0].cnName
        },
        memberNum() {
            return this.formObj.memberNum
        },
        totalMonth() {
            let activityMonth = 0
            if (this.activityConfig.hasOwnProperty('nums') && this.activityConfig.type == 'day') {
                activityMonth = this.activityConfig.nums
            }
            return this.formObj.yearValue * 12 + activityMonth
        }
    },
    methods: {
        successStep03(x) {
            this.orderno = x
            this.step03.isShow = false
            this.step04.isShow = true
        },
        async getAllPackage() { // 全部套餐
            await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.allPackages).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.pkData = res.data.data
                    this.formObj.pkValue = this.pkData[0].pkCode // 设置默认升级的套餐
                } else {
                    // this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        setMenu(x) {
            this.setHeaderTitle(x)
            this.Global.utils.rightMenu.clearMenu()
        },
        showDiscounts() {
            if (this.formObj.memberNum == 3 || this.formObj.memberNum == 4) {
                // (this.formObj.yearValue == 2 && ) || (this.formObj.yearValue == 3 && this.formObj.memberNum == 3)
                this.isDiscounts = false
            } else {
                this.isDiscounts = true
            }
        },
        changeMin(x) {
            if (x == 1) {
                this.formObj.memberNum = 5
                this.min = 5
            } else {
                this.min = 3
            }
        },
        DateAdd(number, date) {
            date.setFullYear(date.getFullYear() + number)
            return date
        },
        async clickStep01() {
            this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: this.$t('mx_mobile.Client.1585281647545') // 加载中...
            })
            await this.getPrice()
            this.$toast.clear()
            if (this.isContinue) {
                this.step01.isShow = false
                this.step02.isShow = true
                this.$router.push({
                    path: '',
                    query: {
                        stepNew: '02'
                    }
                })
                this.setMenu('购买清单')
                this.getActivity()
            }
        },
        getActivity() {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.activity, { params: { type: 'now' } }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    if (res.data.data.length > 0) {
                        let obj = res.data.data[0]
                        this.currentActivity = obj
                        this.getDataActivity(obj.activityId)

                        // 处理当前的活动，展示用户看
                        if (!this.currentActivity.activityInfos) {
                            return
                        }
                        let PK0001 = this.currentActivity.activityInfos.filter(item => {
                            return item.pkCode == this.formObj.pkValue
                        })
                        this.yearData.forEach(item => {
                            item.text = ''
                        })
                        PK0001.forEach(item => {
                            this.yearData.forEach(item2 => {
                                if (item2.value == item.yearCount) {
                                    item2.text = item.description
                                }
                            })
                        })
                    }
                } else {
                    // this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        getDataActivity(id) {
            let data = {
                type: 'infoDetail',
                activityId: id,
                yearCount: this.formObj.yearValue,
                pkCode: this.formObj.pkValue
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.activity, { params: data }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.activityConfig = res.data.data
                    if (this.activityConfig.activityId) {
                        this.activityId = this.activityConfig.activityId + ''
                    } else {
                        this.activityId = ''
                    }
                    console.log(typeof (this.activityId), 'this.activityId')
                } else {
                    // this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        async getPrice() {
            if (this.formObj.yearValue == 1 && this.formObj.memberNum < 5) {
                this.$toast.fail('一年时长最低选择5用户！')
                this.isContinue = false
                return false
            }
            let data = {
                orderType: 'N',
                pkCode: this.formObj.pkValue,
                yearCount: this.formObj.yearValue,
                memberCount: this.formObj.memberNum,
                // spaceCount: this.formObj.spaceAdd,
                rType: '3',
                nums: this.formObj.yearValue * 12,
                custId: this.item.custId
            }
            await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.priceCount, { params: data }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.isContinue = true
                    this.priceConfig = res.data.data
                    // this.priceConfig.totalCost = res.data.data.totalCost
                } else {
                    this.isContinue = false
                    // this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        returnFloat(x) {
            var value = Math.round(parseFloat(x) * 100) / 100
            var xsd = value.toString().split('.')
            if (xsd.length == 1) {
                value = value.toString() + '.00'
                return value
            }
            if (xsd.length > 1) {
                if (xsd[1].length < 2) {
                    value = value.toString() + '0'
                }
                return value
            }
        },
        clickStep02() {
            this.step02.isShow = false
            this.step03.isShow = true
            this.$router.push({
                path: '',
                query: {
                    stepNew: '03'
                }
            })
        },
        checkURL() {
            if (this.$route.query) {
                switch (this.$route.query.stepNew) {
                    case '01':
                        this.step01.isShow = this.step02.isShow = this.step03.isShow = false
                        this.step01.isShow = true
                        break
                    case '02':
                        this.step01.isShow = this.step02.isShow = this.step03.isShow = false
                        this.step02.isShow = true
                        break
                    case '03':
                        this.step01.isShow = this.step02.isShow = this.step03.isShow = false
                        this.step03.isShow = true
                        break
                    case '04':
                        this.step01.isShow = this.step02.isShow = this.step03.isShow = false
                        this.step04.isShow = true
                        break
                    default:
                        this.step01.isShow = this.step02.isShow = this.step03.isShow = false
                        this.$emit('close')
                }
            }
        }
    },
    watch: {
        $route(to, form) {
            if (form.query.stepNew == '01' && to.query.stepNew == '02') {
                this.setMenu('购买清单')
            }
            if (form.query.stepNew == '02' && to.query.stepNew == '01') {
                this.setMenu('选择配置')
            }
            if (form.query.stepNew == '03' && to.query.stepNew == '02') {
                this.step01.isShow = this.step02.isShow = this.step03.isShow = false
                this.$router.push('/client/home')
                this.$emit('close')
            }
            this.checkURL()
        },
        formObj: {
            handler(val, oldVal) {
                this.showDiscounts()
            },
            deep: true
        }
    },
    components: {
        step03,
        step04
    }
}
</script>

<style lang="less">
@import "./zh-cn.less";
</style>

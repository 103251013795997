<template>
    <!-- 共创项目特殊下拉框 -->
    <!-- <el-form :model="ruleForm" onsubmit="return false" :rules="rules" ref="ruleForm" :label-width="labelWidth" :label-position="labelPosition">
            <el-form-item :label="showLabel?itemData.cnFieldCaption:''" :prop="prop()" :style="{'margin-bottom':isProp?0:''}">
                <drop-down ref="dropDown" :labelKey="getLabelKey" :valueKey="getLabelKey" inUse :itemData="itemData" :list="options" :rightWidth="rightWidth" :size="size" :value="value+''" @input="handleInput" class="vxe-table--ignore-clear" :splitFieldArr="splitFieldArr" :keyCode="getKeyCode()" :keyCode2="getKeyCode(2)" :keyCode3="getKeyCode(3)"></drop-down>
            </el-form-item>
        </el-form> -->
    <div class="Controls" :class="{ 'Controls--disabled': itemData.disabled }" @click="choosePerson()">
        <span class="name">
            {{ itemData.cnFieldCaption }}
            <span style="color: #d0021b" v-if="prop()">*</span>
        </span>
        <span>{{ obj.text }}</span>
    </div>
</template>

<script>
import selectGrassSilkEvent from '@/components/SelectGrassSilk/event.js'
export default {
    name: 'Controls-SystemDownBox',
    props: {
        itemData: {
            type: Object,
            default: () => ({
                cnFieldCaption: '',
                cnFieldHint: '',
                controlTypeId: 41,
                dictCode: 0,
                fieldCategory: 2,
                fieldGroup: 2,
                fieldName: 'twitter',
                inDefaultValue: '',
                isNotNull: 0,
                strucId: 1
            })
        },
        labelPosition: {
            type: String,
            default: 'right'
        },
        isProp: {
            type: Boolean,
            default: false
        },
        labelWidth: {
            type: String,
            default: '100px'
        },
        rightWidth: {
            type: String,
            default: '160px'
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        dictCode: {
            type: Number,
            default: 0
        },
        size: {
            type: String,
            default: 'small'
        },
        optCode: {
            type: String,
            default: ''
        },
        value: {
            type: [Number, String],
            default: ''
        }
    },
    data() {
        return {
            obj: {},
            options: Object.freeze([]),
            rules: Object.freeze({
                input: [
                    {
                        type: 'string',
                        required: true,
                        message:
                            this.$t('mxpcweb.basecomponents.1530696912786') +
                            this.itemData.cnFieldCaption,
                        trigger: 'change'
                    }
                ]
            }),
            fieldMap: {
                rowSpacing: {
                    name: '行距',
                    moduleCode: 'CP001',
                    labelKey: 'lineSpacName',
                    isGetData: true,
                    isGrassSilk: false
                },
                bottomName: {
                    name: '底布名称',
                    moduleCode: 'CP002',
                    labelKey: 'buttomclothName',
                    isGetData: true,
                    isGrassSilk: false
                },
                grassSilk1: {
                    name: '草丝1',
                    moduleCode: 'CP003',
                    labelKey: 'grassSeries',
                    keyCode: 'dtexCode',
                    keyCode2: 'grassColor',
                    keyCode3: 'grassLevel',
                    isGetData: false,
                    isGrassSilk: true
                },
                grassSilk2: {
                    name: '草丝2',
                    moduleCode: 'CP003',
                    labelKey: 'grassSeries',
                    keyCode: 'dtexCode',
                    keyCode2: 'grassColor',
                    keyCode3: 'grassLevel',
                    isGetData: false,
                    isGrassSilk: true
                },
                grassSilk3: {
                    name: '草丝3',
                    moduleCode: 'CP003',
                    labelKey: 'grassSeries',
                    keyCode: 'dtexCode',
                    keyCode2: 'grassColor',
                    keyCode3: 'grassLevel',
                    isGetData: false,
                    isGrassSilk: true
                },
                grassSilk4: {
                    name: '草丝4',
                    moduleCode: 'CP003',
                    labelKey: 'grassSeries',
                    keyCode: 'dtexCode',
                    keyCode2: 'grassColor',
                    keyCode3: 'grassLevel',
                    isGetData: false,
                    isGrassSilk: true
                },
                lineSpacing: {
                    name: '行距(英寸)',
                    moduleCode: 'CP005',
                    labelKey: 'rowSpacing',
                    isGetData: false,
                    isGrassSilk: false
                },
                baseClothName: {
                    name: '底布名称',
                    moduleCode: 'CP004',
                    labelKey: 'bottomName',
                    isGetData: false,
                    isGrassSilk: false
                },
                releaseDays: {
                    name: '放账天数',
                    moduleCode: 'CP011',
                    labelKey: 'releaseDays',
                    isGetData: true,
                    isGrassSilk: false
                },
                widthCloth: {
                    name: '幅宽',
                    moduleCode: 'CP004',
                    labelKey: 'breath',
                    isGetData: false,
                    isGrassSilk: false
                }
            },
            splitFieldArr: [
                'grassSilk1',
                'grassSilk2',
                'grassSilk3',
                'grassSilk4'
            ],
            loading: false,
            page: {
                from: 0,
                size: 100
            },
            factory: '',
            // updateFlag为true时说明草丝列表还未全部查出，false时说明草丝列表已全部查出，不用再查接口
            updateFlag: true
        }
    },
    computed: {
        // ...mapGetters(['sysBoxValue']),
        ruleForm() {
            return {
                input: this.value + ''
            }
        },
        getLabelKey() {
            return this.fieldMap[this.itemData.fieldName].labelKey
        }
    },
    created() {},
    mounted() {
        if (this.fieldMap[this.itemData.fieldName].isGetData) {
            this.getData()
        }
    },
    methods: {
        async choosePerson() {
            if (this.itemData.disabled) return
            let options = this.options.map((el) => {
                return {
                    text: el[this.fieldMap[this.itemData.fieldName].labelKey],
                    value: el[this.fieldMap[this.itemData.fieldName].labelKey]
                }
            })
            let valTemp
            if (this.fieldMap[this.itemData.fieldName].isGrassSilk) {
                options = this.options.map((el) => {
                    const labelStr =
                        el.grasspriceId +
                        '+-*/&' +
                        (el.grassSeries ? el.grassSeries : '') +
                        (el.dtexCode ? '_' + el.dtexCode : '') +
                        (el.grassColor ? '_' + el.grassColor : '') +
                        (el.grassLevel ? '_' + el.grassLevel : '') +
                        (el.curvature ? '_' + el.curvature : '') +
                        (el.shares ? '_' + el.shares : '')
                    return {
                        text: labelStr.split('+-*/&')[1],
                        value: labelStr
                    }
                })
                try {
                    if (options.length) {
                        this.loading = false
                        this.$emit('changeLoading', this.loading)
                        let data = await selectGrassSilkEvent.show({}, options, this.factory)
                        this.obj = data
                        valTemp = data.value.split('+-*/&')[0]
                        let res = await this.getBaseModuleData('CP003', {grasspriceId: valTemp})
                        this.$emit('input', this.obj, res?.[0])
                    } else {
                        if (!this.loading) {
                            this.loading = true
                            this.$emit('changeLoading', this.loading)
                        }
                        setTimeout(() => {
                            this.choosePerson()
                        }, 500)
                    }
                } catch (error) {
                    console.log('res', error)
                    // this.obj = {
                    //     text: '',
                    //     value: ''
                    // }
                }
                return
            }
            if (this.itemData.fieldName === 'baseClothName') {
                options = []
                this.options.forEach(item => {
                    options.push({
                        text: (item.bottomclothCode ? item.bottomclothCode : '') + '/' + (item.bottomName ? item.bottomName : ''),
                        value: (item.bottomclothCode ? item.bottomclothCode : '') + '/' + (item.bottomName ? item.bottomName : '')
                    })
                })
            }
            try {
                let res = await this.Global.utils.chosen.open(options)
                this.obj = res
                valTemp = res.value
                let data = this.options.filter(
                    (el) => valTemp ===
                        el[this.fieldMap[this.itemData.fieldName].labelKey]
                )
                if (this.itemData.fieldName === 'baseClothName') {
                    valTemp = res.value.split('/')
                    data = this.options.filter(el => valTemp?.[0] == el.bottomclothCode && valTemp?.[1] == el.bottomName)
                }
                this.$emit('input', this.obj, data?.[0])
            } catch (error) {
                console.log(error)
            }
        },
        getKeyCode(num = '') {
            return this.fieldMap[this.itemData.fieldName]?.['keyCode' + num]
        },
        async getData() {
            this.options = await this.getOptions()
        },
        /**
         * 共创特殊逻辑更新下拉值
         * @param {String} val 修改的值
         * @param {Object} optionsObj 调接口所需的筛选条件
         * @param {Boolean} isRemoveDulp 是否将结果按指定的key去重
         */
        async updateGCOption(val = '', optionsObj = {}, isRemoveDulp = false) {
            let options = []
            if (this.splitFieldArr.includes(this.itemData.fieldName)) {
                this.page.from = 0
            }
            if (val) {
                let config = {
                    sort: 'modifyDate'
                }
                options = await this.getOptions({...config, ...optionsObj})
            }
            // 底背价格表需去除单织加价系数或者混织加价系数为空、0的数据
            if (['widthCloth', 'baseClothName'].includes(this.itemData.fieldName)) {
                options = options.filter(e => e.aloneAdd * 1 && e.blendAdd * 1)
            }
            if (isRemoveDulp) {
                const map = new Map()
                // 幅宽需要按数值去重，其他字段按字符串去重
                if (['widthCloth'].includes(this.itemData.fieldName)) {
                    options.forEach(e => {
                        map.set(e[this.fieldMap[this.itemData.fieldName].labelKey] * 1, e)
                    })
                } else if (this.itemData.fieldName === 'baseClothName') {
                    const optionsTemp = options.reverse()
                    optionsTemp.forEach(e => {
                        map.set(e['bottomclothCode'] + '/' + e[this.fieldMap[this.itemData.fieldName].labelKey], e)
                    })
                } else {
                    options.forEach(e => {
                        map.set(e[this.fieldMap[this.itemData.fieldName].labelKey] + '', e)
                    })
                }
                options = Array.from(map.values())
            }
            this.options = Object.freeze([...options])
            this.cloneOption = Object.freeze([...options])
            return this.options
        },
        /**
         * 查询基础表
         * @param {String} moduleCode 模块号
         * @param {Object} selectOptions 筛选条件对象
         */
        async getBaseModuleData(moduleCode = '', selectOptions = {}) {
            let data = {
                from: 0,
                moduleCode: moduleCode,
                order: 'desc',
                searchType: 'list',
                size: 100,
                sort: 'modifyDate',
                withRight: false
            }
            data = { ...data, ...selectOptions }
            const url =
                this.Global.config.apiBaseURL +
                this.Global.api.v2.document_newStructSearch_do
            let options = []
            try {
                let res = await this.axios.post(url, data)
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    options = res.data.data.list || {}
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (err) {
                console.log(err)
            }
            return options
        },
        async getOptions(config = {}) {
            let data = {
                from: 0,
                moduleCode: this.fieldMap[this.itemData.fieldName].moduleCode,
                order: 'desc',
                searchType: 'list',
                size: 9999,
                sort: 'createDate',
                withRight: false
            }
            data = { ...data, ...config }
            let url =
                this.Global.config.apiBaseURL +
                this.Global.api.v2.document_newStructSearch_do
            if (this.splitFieldArr.includes(this.itemData.fieldName)) {
                if (config?.factory) {
                    this.factory = config?.factory
                }
                data = {
                    from: this.page.from,
                    size: this.page.size,
                    keywords: this.keywords,
                    factory: config?.factory || this.factory
                }
                url = this.Global.config.apiBaseURL + this.Global.api.v2.thirdparty_grassSilkPrice
            }
            let options = []
            try {
                let res = await this.axios.post(url, data)
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    options = res.data.data.list || []
                    if (this.splitFieldArr.includes(this.itemData.fieldName)) {
                        this.updateFlag = res.data.data.totalNum > this.page.from
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (err) {
                console.log(err)
            }
            return options
        },
        updates(value) {
            let obj = {
                value: value,
                text: value
            }
            if (this.fieldMap[this.itemData.fieldName].isGrassSilk) {
                obj.text = value.split('+-*/&')[1]
            }
            this.obj = obj
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.obj.value == '') {
                    this.$toast.fail(
                        this.itemData.cnFieldCaption +
                            this.$t('mx_mobile.Client.1585301350281')
                    ) // 不能为空
                    return false
                }
            }
            if (this.obj.value && this.obj.value != '' && this.obj.value != 0) {
                data[this.itemData.fieldName] = this.obj.value + ''
            } else {
                if (this.itemData.fieldName === 'releaseDays' && this.obj.value == 0) {
                    data[this.itemData.fieldName] = '0'
                } else {
                    data[this.itemData.fieldName] = 0
                }
            }
            // alert(data[this.itemData.fieldName])
            return data
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.isProp == true) {
                return ''
            } else {
                if (this.itemData.isNotNull == 1) {
                    return 'input'
                } else {
                    return ''
                }
            }
        }
    },
    watch: {
    },
    components: {}
}
</script>
<style lang="less" rel="stylesheet/less" scoped>
.export_type {
    margin-bottom: 10px;
    .export_type-name {
        line-height: 30px;
    }
    .export_type-desc {
        font-size: 12px;
        color: #606266;
        line-height: 20px;
        padding-left: 19px;
    }
    .export_type-fee {
        background: #f0f0f0;
        border-radius: 16px;
        padding: 5px;
    }
}
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
    .padding-left(100px);
    &:lang(en) {
        .padding-left(130px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

<template>
<div class="objectBox">
    <div class="object object_one"></div>
    <div class="object object_two"></div>
    <div class="object object_three"></div>
</div>
</template>
<script>
export default {
    name: 'loading',
    props: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
    components: {
    },
    watch: {
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.objectBox{
    width: 54px;
    margin: 0 auto;
}
.object{
    .width(10px);
    .height(10px);
    background-color: rgba(239,74,74,1);
    float: left;
    .margin-right(8px);
    -moz-border-radius: 50% 50% 50% 50%;
    -webkit-border-radius: 50% 50% 50% 50%;
    border-radius: 50% 50% 50% 50%;
}

.object_one {
    -webkit-animation: object_one 1.5s infinite;
    animation: object_one 1.5s infinite;
    }
.object_two {
    -webkit-animation: object_two 1.5s infinite;
    animation: object_two 1.5s infinite;
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
    }
.object_three {
    -webkit-animation: object_three 1.5s infinite;
    animation: object_three 1.5s infinite;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
    }
@-webkit-keyframes object_one {
    75% { -webkit-transform: scale(0); }
}
@keyframes object_one {

    75% {
    transform: scale(0);
    -webkit-transform: scale(0);
    }
}
@-webkit-keyframes object_two {
    75% { -webkit-transform: scale(0); }
}
@keyframes object_two {
    75% {
    transform: scale(0);
    -webkit-transform:  scale(0);
    }
}
@-webkit-keyframes object_three {

    75% { -webkit-transform: scale(0); }
}
@keyframes object_three {

    75% {
    transform: scale(0);
    -webkit-transform: scale(0);
    }
}
</style>

<template>
    <div>
        <div class="Controls" @click="getTime()" :class="{'Controls--disabled':itemData.disabled}" :style="itemData.disabled && !isNewFollow ? 'background: #eff2f4' : ''">
            <span class="name">
                {{itemData.cnFieldCaption}}
                <span style="color:#D0021B" v-if="prop()">*</span>
            </span>
            <span>
                {{value}}
            </span>
            <i class="iconfont icon-time"></i>
        </div>
        <date-picker ref="datePickerTime"></date-picker>
    </div>
</template>

<script>
import DatePickerTime from '@/components/PublicControls/DatePickerTime/index'
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        type: {
            type: String,
            default: 'otNew'
        },
        isNewFollow: {
            type: [Boolean],
            default: false
        }
    },
    data() {
        return {
            value: ''
        }
    },
    created() {
    },
    mounted() {
        if (this.itemData.inDefaultValue == '%currenttime%') {
            let date = new Date()
            let sign1 = '-'
            let sign2 = ':'
            let year = date.getFullYear() // 年
            let month = date.getMonth() + 1 // 月
            let day = date.getDate() // 日
            let hour = date.getHours() // 时
            let minutes = date.getMinutes() // 分
            // 给一位数数据前面加 “0”
            if (month >= 1 && month <= 9) {
                month = '0' + month
            }
            if (day >= 0 && day <= 9) {
                day = '0' + day
            }
            if (hour >= 0 && hour <= 9) {
                hour = '0' + hour
            }
            if (minutes >= 0 && minutes <= 9) {
                minutes = '0' + minutes
            }
            this.value = year + sign1 + month + sign1 + day + ' ' + hour + sign2 + minutes + sign2 + '00'
        }
    },
    methods: {
        async getTime() {
            if (this.itemData.disabled) {
                return
            }
            this.$refs.datePickerTime.getTime((res) => {
                this.value = res + ':00'
            })
        },
        updates(value) {
            if (value == '') {
                return false
            }
            this.value = value
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.value == '') {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            if (this.value == '') {
                return {}
            }
            // console.log('我的路由', this.$route.name)
            // 判断是新增还是编辑，待优化
            data[this.itemData.fieldName] = this.value
            return data
        }
    },
    watch: {
    },
    components: {
        'date-picker': DatePickerTime
    }
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
    .padding-left(100px);
    &:lang(en) {
        .padding-left(130px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    .icon-time {
        position: absolute;
        .right(20px);
        .font-size(16px);
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

<template>
    <div class="Controls">
        <van-rate class="rateBox" v-model="value.value" readonly />
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        value: {
            type: Object,
            default: function () {
                return {
                    value: ''
                }
            }
        }
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    }
}
</script>
<style lang="less" scoped>
.rateBox {
    .min-height(38px);
    .line-height(38px);
    .padding-top(7px);
}
</style>

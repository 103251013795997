<template>
    <div class="orderSuccess" style="height: 100%">
        <div class="tip">
            <van-icon name="passed" size="66px" />
            <div>订单提交成功，请尽快付款</div>
        </div>
        <div class="btnSheet" @click="$router.push('/my/orderDetail/' + orderno)">查看订单</div>
        <div class="btnSheet" @click="$router.push('/my/payment')" style="margin-top:20px;">付款方式</div>
    </div>
</template>

<script>
export default {
    name: 'step04',
    props: {
        orderno: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            activePay: 0
        }
    },
    created() {
    },
    mounted() {

    },
    computed: {
    },
    methods: {
    },
    watch: {

    }
}
</script>

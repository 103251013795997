<template>
    <div class="Controls" :class="{'Controls--disabled':itemData.disabled}">
        <span class="name">
            {{itemData.cnFieldCaption}}
            <span style="color:#D0021B" v-if="prop()">*</span>
        </span>
        <div v-for="(item, index) in numVals" :key="item.key" :prop="'numVals.' + index + '.value'">
            <div class="listBox" @click="choosePerson(index)">
                <span v-if="item.text!=''">{{item.text}}</span>
                <span v-else style="color:#757575">{{$t('mx_mobile.Client.1585302263530')}}</span> <!-- 请选择社交类型-->
                <van-button slot="button" size="small" type="primary" @click.prevent @click.stop="addDomain" v-show="index === 0" class="list_btn" :disabled="itemData.disabled">{{$t('mx_mobile.Client.1585272488531')}}</van-button>
                <!--新增-->
                <van-button slot="button" size="small" type="primary" @click.prevent @click.stop="removeDomain(item)" v-show="index !== 0" class="list_btn" :disabled="itemData.disabled">{{$t('mx_mobile.Client.1585301969072')}}</van-button>
                <!--删除-->
            </div>
            <div>
                <!--请输入账号-->
                <van-field style="padding-left:0" v-model="item.inputValue" clearable :placeholder="$t('mx_mobile.Client.1585302266970')" :disabled="itemData.disabled" />
            </div>
        </div>
    </div>
</template>

<script>
import { isObject } from '@/libs/utils.js'
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            show: false,
            actions: [],
            value: '',
            numVals: [{
                text: '',
                value: '',
                inputValue: ''
            }]
        }
    },
    created() {
    },
    async mounted() {
        await this.unit()
        if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
            this.updates(this.itemData.inDefaultValue)
        }
    },
    methods: {
        // 移除一条
        removeDomain(item) {
            var index = this.numVals.indexOf(item)
            if (index !== -1) {
                this.numVals.splice(index, 1)
            }
        },
        // 新增一条，下拉就给减少一条，设计不能重复
        addDomain() {
            this.numVals.push({
                text: '',
                value: '',
                key: Date.now()
            })
        },
        async choosePerson(index) {
            this.actions.map(item => {
                if (this.numVals.filter((ele) => ele.value == item.value).length > 0) {
                    item.disabled = true
                } else {
                    item.disabled = false
                }
            })
            try {
                let res = await this.Global.utils.chosen.open(this.actions)
                let obj = {
                    text: res.text,
                    value: res.value
                }
                this.numVals.splice(index, 1, obj)
            } catch (error) {
                console.log(error)
            }
        },
        async updates(value) {
            // 先清空，再重新追加
            this.numVals = [{
                text: '',
                value: '',
                inputValue: ''
            }]
            let fieldValueObj = value
            await this.unit()
            // 不能为空对象发，如{1:'',2:''}：
            if (isObject(fieldValueObj) && Object.values(fieldValueObj).join('') !== '') {
                this.numVals = []// 有数据时，前面的留空去了
                // 对象里空值的，不放进去
                Object.keys(fieldValueObj).forEach((item) => {
                    // 把回显的数据对应上列表中的text字段用于回显
                    let text = ''
                    if (fieldValueObj[item] != '') {
                        let matchObj = this.actions.filter(ele => ele.value == item)
                        text = matchObj[0].text
                    }
                    if (fieldValueObj[item] !== '') {
                        this.numVals.push({
                            value: item,
                            inputValue: fieldValueObj[item],
                            text: text
                        })
                    }
                })
            } else {
                this.clearData()
            }
        },
        clearData() {
            // this.$refs['dynamicValidateForm'].resetFields()
            this.numVals = [{
                text: '',
                value: '',
                inputValue: ''
            }]
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                let isProp = false
                this.numVals.forEach(item => {
                    if (item.inputValue != '') {
                        isProp = true
                    }
                })
                if (!isProp) {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            let obj = { 1: '', 2: '', 3: '', 4: '', 5: '', 6: '', 7: '', 8: '' }
            this.numVals.forEach(item => {
                if (item.inputValue != '') {
                    obj[item.value] = item.inputValue
                }
            })
            data[this.itemData.fieldName] = obj
            return data
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        oninputValue(item) {
            // 点击选项时默认不会关闭菜单，可以手动关闭
            this.show = false
            this.obj = item
        },
        async unit() {
            await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.socialType_get, { params: {} })
                .then((res) => {
                    if (res.data.code.toString() === this.Global.config.RES_OK) {
                        let actions = res.data.data
                        actions.forEach(element => {
                            element.text = element.socialName
                            element.value = element.socialId
                        })
                        this.actions = actions
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                }, (res) => {
                    this.$toast.fail(this.Global.config.errorTitle)
                })
        }
    },
    components: {
    }
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    .listBox {
        .min-height(45px);
        border-bottom: 1px solid #eaedef;
        position: relative;
        .list_btn {
            position: absolute;
            right: 15px;
            top: 5px;
        }
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

<template>
<div v-if="loading" ref="loadingBox" class="loading-center-absolute">
    <div class="animationBox">
        <loading2 v-if="type == '2'"></loading2>
        <loading3 v-if="type == '3'"></loading3>
    </div>
    <div class="textBox" v-if="text&&text!=''">{{text}}</div>
</div>
</template>
<script>
import loading2 from './Vue/loading2.vue'
import loading3 from './Vue/loading3.vue'
export default {
    name: 'loading',
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: '3'
        },
        text: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            // show: false
        }
    },
    mounted() {
    },
    methods: {
        fadeOut() {
            let ele1 = this.$refs.loadingBox
            if (!ele1) {
                return
            }
            let opacitynum = ele1.style.opacity || 1
            let opacityt = setInterval(() => {
                if (opacitynum > 0) {
                    ele1.style.opacity = opacitynum = (opacitynum - 0.01).toFixed(2)
                } else {
                    clearInterval(opacityt)
                    this.show = false
                }
            }, 5)
        },
        fadeIn() {
            this.show = true
            this.$nextTick(() => {
                let ele1 = this.$refs.loadingBox
                if (!ele1) {
                    return
                }
                let opacitynum = 0
                let opacityt = setInterval(() => {
                    if (opacitynum < 1) {
                        ele1.style.opacity = opacitynum = (parseFloat(opacitynum) + 0.01).toFixed(2)
                    } else {
                        clearInterval(opacityt)
                    }
                }, 5)
            })
        }
    },
    components: {
        'loading2': loading2,
        'loading3': loading3
    },
    watch: {
        // loading(val) {
        //     console.log(val)
        //     if (val && !this.show) {
        //         this.fadeIn()
        //     } else if (!val && this.show) {
        //         this.fadeOut()
        //     }
        // }
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.loading-center-absolute {
    .height(80px);
    .width(160px);
    position: absolute;
    top: 40%;
    left: 50%;
    .margin-top(-40px);
    .margin-left(-80px);
    z-index: 99999;
    .animationBox{
        .width(160px);
        .min-height(30px);
    }
    .textBox{
        text-align:center;
        .min-height(20px);
        .width(160px);
        .font-size(12px);
        color: #909399;
    }
}
</style>

<template>
    <div class="Controls" :class="{'Controls--disabled':itemData.disabled}">
        <span class="name">
            {{itemData.cnFieldCaption}}
            <span style="color:#D0021B" v-if="prop()">*</span>
        </span>
        <van-field style="padding-left:0" v-model="value" clearable :disabled="itemData.disabled" :placeholder="itemData.cnFieldHint" :maxlength="itemData.fieldLength" @change="change" />
    </div>
</template>

<script>
export default {
    name: 'Controls-AmountOfMoney',
    data() {
        return {
            value: this.itemData.inDefaultValue || ''
        }
    },
    props: {
        itemData: {
            type: Object,
            default: () => ({})
        },
        isProp: {
            type: Boolean,
            default: false
        },
        labelWidth: {
            type: String,
            default: '100px'
        },
        dictCode: {
            type: Number,
            default: 0
        },
        size: {
            type: String,
            default: 'small'
        },
        rightWidth: {
            type: String,
            default: '160px'
        },
        showLabel: {
            type: Boolean,
            default: true
        }
    },
    created() {},
    methods: {
        updates(value) {
            // if (value == '') {
            //     return false
            // }
            this.value = value
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.value == '') {
                    this.$toast.fail(
                        this.itemData.cnFieldCaption +
                            this.$t('mx_mobile.Client.1585301350281')
                    ) // 不能为空
                    return false
                }
            }
            data[this.itemData.fieldName] = this.value || '0'
            return data
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.isProp == true) {
                return ''
            } else {
                if (this.itemData.isNotNull == 1) {
                    return 'input'
                } else {
                    return ''
                }
            }
        },
        change(newValue) {
            this.$emit('input', this.value)
        }
    }
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
    .padding-left(100px);
    &:lang(en) {
        .padding-left(130px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

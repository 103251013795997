<template>
    <div class="ShowPrice__ware" :class="{'del__mask': dynamicData.deleteFlag}">
        <div class="ShowPrice__top">
            <div>
                <span class="top__icon">
                    <color-icon class="top__icon-color" iconName="dynamic-price" fontSize="20"></color-icon>
                </span>
                <span class="top__name">{{dynamicData.createName}}&nbsp;</span>
                <span class="top__desc">
                    <!-- 新建了一个报价 -->
                    {{$t('mx_mobile.Client.1627019400090')}}
                </span>
                <span class="top__del" v-if="dynamicData.deleteFlag"><span class="top__del-word">
                        <!-- 已删 -->
                        {{$t('mx_mobile.Client.1627018747290')}}
                    </span></span>
                <span class="top__right pull-right">
                    <span class="top__time">{{returnTime(dynamicData.createTime, '1')}}</span>
                </span>
            </div>
            <div class="contacts_ware ellipsis">
                <span class="contacts_info" @click="jumpToSale">{{dynamicData.theme}}</span>
                <span class="body__content-quote pull-right" v-if="dynamicData.quotePrice">{{dynamicData.cur}}&nbsp;{{dynamicData.quotePrice}}</span>
            </div>
        </div>
        <div class="ShowPrice__body">
            <div class="body__content" v-if="dynamicData.code">
                <span class="body__content-quote">{{dynamicData.code}}</span>
                <!-- <span class="body__content-quote">报价状态：{{dynamicData.quoteStatus}}</span> -->
            </div>
            <div class="body__bottom" v-if="dynamicData.updateName || dynamicData.updateTime">
                <span class="body__bottom-type">{{dynamicData.updateName}}
                    <!-- 最后修改于 -->
                    {{$t('mx_mobile.Client.1627017934765')}}
                    {{returnTime(dynamicData.updateTime, '2')}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
/* 报价 */
import { formatValidTime } from '@/libs/utils.js'
import ColorIcon from '@/components/ColorIcon/iconColor.vue'
export default {
    name: 'ShowPrice',
    props: {
        dynamicData: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        'color-icon': ColorIcon
    },
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        returnTime(validTime, n) {
            return formatValidTime(validTime, n == 1 ? 'MM-dd HH:mm' : 'yyyy-MM-dd HH:mm')
        },
        jumpToSale() {
            console.log('dynamicData', this.dynamicData)
            // moduleCode: SC002, billId: sourceId  报价：SC001
            // this.$router.push('/main/sale/tabs/list/SC001/' + this.dynamicData.sourceId)
        }
    },
    beforeDestroy() { },
    beforeRouteLeave(to, from, next) {
        next()
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.ShowPrice__ware {
    border-bottom: 1px solid #f9f9f9;
    .margin-bottom(10px);
    color: #6b6b6b;
    position: relative;
    &.del__mask {
        color: #bcbcbc !important;
        .top__name,
        .contacts_info {
            color: #bcbcbc !important;
        }
    }
    &.del__mask::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        background: rgba(249, 249, 249, 0.1);
        position: absolute;
        top: 0;
        left: 0;
    }
    .ShowPrice__top {
        font-size: 0;
        .top__icon {
            display: inline-block;
            .height(20px);
            .width(20px);
            .line-height(20px);
            .margin-right(5px);
            position: relative;
            .top__icon-color {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .top__name {
            display: inline-block;
            .height(20px);
            .font-size(14px);
            color: #222;
            font-weight: bold;
            display: inline-block;
            vertical-align: top;
        }
        .top__desc {
            display: inline-block;
            .height(20px);
            .font-size(14px);
            vertical-align: top;
        }
        .top__del {
            display: inline-block;
            .height(20px);
            vertical-align: top;
            position: relative;
            .top__del-word {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                .width(35px);
                text-align: center;
                display: inline-block;
                color: #fff !important;
                .font-size(12px);
                .margin-left(5px);
                background: #d0021b;
                .height(20px);
                .line-height(20px);
                .border-radius(10px);
                .padding(0px, 5px);
                z-index: 1;
            }
        }
        .top__right {
            display: inline-block;
            .height(20px);
            .top__time {
                display: inline-block;
                .height(20px);
                .line-height(20px);
                .font-size(14px);
            }
        }
        .contacts_ware {
            .font-size(14px);
            .contacts_info {
                color: #008cee;
                font-weight: 600;
            }
        }
    }
    .ShowPrice__body {
        .padding-bottom(5px);
        .body__content {
            display: inline-block;
            white-space: pre-wrap;
            word-break: break-word;
            .padding-bottom(5px);
            .body__content-quote {
                .margin-right(30px);
            }
        }
        .body__bottom {
            .body__bottom-type {
                .margin-right(10px);
            }
        }
    }
}
</style>

<template>
    <span class="region">
        <span class="list">{{ownerName}}</span>
        <span class="list">{{deptName}}</span>
    </span>
</template>
<script>
export default {
    name: 'BelongTo',
    props: {
        value: {
            type: String,
            default: ''
        },
        items: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            ownerName: '',
            deptName: '',
            owners: [],
            departments: []
        }
    },
    created() {
        this.getOwerData()
    },
    mounted() {
    },
    methods: {
        returnName(id, fieldName) {
            if (fieldName == 'ownerCtId') {
                let name = ''
                this.owners.forEach(function (item) {
                    if (item.ctId == id) {
                        name = item.realName
                        return false
                    }
                })
                return name
            }
            if (fieldName == 'ownerDeptKey') {
                let name = ''
                this.departments.forEach(function (item) {
                    if (item.dkey == id) {
                        name = item.deptName
                        return false
                    }
                })
                return name
            }
        },
        // 初始第一个下拉先 <-_->
        getOwerData() {
            let _this = this
            if (_this.items.ownerCtId && _this.items.ownerCtId == '') {
                _this.ownerName = ''
                return false
            };
            let contactData = {
                dataType: 'contact',
                funType: 'all'
            }
            let departmentData = {
                dataType: 'department',
                funType: 'all',
                deptCascade: true,
                ctId: _this.items.ownerCtId
            }
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.accountDropList_get, { params: contactData }).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    _this.owners = res.data.data
                    _this.ownerName = _this.returnName(_this.items.ownerCtId, 'ownerCtId')
                } else {
                    _this.$message.error(_this.msg(res.data))
                    _this.ownerName = ''
                    _this.owners = []
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
            if (_this.items.ownerDeptKey && _this.items.ownerDeptKey == '') {
                _this.deptName = ''
                return false
            };
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.accountDropList_get, { params: departmentData }).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    _this.departments = res.data.data
                    _this.deptName = _this.returnName(_this.items.ownerDeptKey, 'ownerDeptKey')
                } else {
                    _this.$toast.fail(res.data.msg)
                    _this.departments = []
                    _this.deptName = ''
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        }
    },
    components: {
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.control {
    margin-bottom: 0;
}
</style>

<template>
    <div class="orderBox">
        <div class="orderSubmit">
            <van-cell title="客户名称" :value="item.custName" />
            <van-field v-model="userName" required clearable label="企业负责人姓名" :placeholder="custContacts.contName" class="textalign" />
            <van-field v-model="userPhone" required clearable label="企业负责人手机" :placeholder="custContacts.mobile" class="textalign" />
            <van-field v-model="userEmail" clearable label="企业负责人邮箱" :placeholder="mailAddress" class="textalign" />
            <van-cell title="所属机构" :value="companie.corpName" />
            <!-- <van-cell title="业务员" :value="companie.nickName+companie.ctId" is-link /> -->
            <van-cell title="业务员" :value="companyObj.realName" is-link @click="openMenu" />
            <van-cell title="应付金额" :value="'￥'+returnFloat(priceConfig.totalCost)" class="money" />
            <van-field v-model="orderMoney" label="实付金额" :placeholder="'￥'+returnFloat(priceConfig.totalCost)" class="textalign money" />
            <!-- <van-field v-model="paytradeno" label="流水号" placeholder="输入流水号" class="textalign" /> -->
            <div class="uploadimg clearfix">
                <span>上传合同</span>
                <!-- :value="imagesId" -->
                <controlImg size="large" @getFiles="getFiles"></controlImg>
            </div>
        </div>
        <van-button type="primary" @click="clickStep03" class="submitBtn">提交订单</van-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import controlImg from './Picture/index.vue'
export default {
    name: 'step03',
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            }
        },
        priceConfig: {
            type: Object,
            default: function () {
                return {}
            }
        },
        formObj: {
            type: Object,
            default: function () {
                return {}
            }
        },
        activityId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            orderMoney: '',
            companyObj: {},
            userPhone: '',
            show: false,
            userEmail: '',
            columns: [],
            userName: '',
            obj: {
                text: '',
                value: ''
            },
            paymentvoucher: '' // 付款凭证
            // paytradeno: ''
        }
    },
    created() {
        this.getPerson()
        this.setMenu()
    },
    components: {
        controlImg: controlImg
    },
    mounted() {
        this.getCompanysetting()
    },
    computed: {
        ...mapGetters(['companie']),
        custContacts() {
            return this.item.custContacts[0]
        },
        mailAddress() {
            if (this.item.custContacts[0].hasOwnProperty('mailAddress')) {
                if (this.item.custContacts[0].mailAddress[0]) {
                    return this.item.custContacts[0].mailAddress[0]
                } else {
                    return ''
                }
            } else {
                return ''
            }
        }
    },
    methods: {
        getFiles(arr) {
            this.paymentvoucher = arr.join(';')
        },
        getPerson() {
            let params = {
                dataType: 'contact',
                funType: 'withRight',
                moduleCode: 'MO001',
                optCode: 'otView'
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.accountDropList_get, { params: params }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.columns = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        async openMenu() {
            let options = []
            this.columns.map(item => {
                let obj = {
                    text: '',
                    value: ''
                }
                obj.text = item.realName
                obj.value = item.ctId
                options.push(obj)
            })
            let res = await this.Global.utils.chosen.open(options, this.obj.text)
            this.obj = res
            this.companyObj.realName = this.obj.text
        },
        // 获取企业配置信息
        getCompanysetting() {
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.account_get, { params: {} }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    // console.log(res.data.data)
                    this.companyObj = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        clickStep03() {
            if (this.userPhone == '') {
                this.userPhone = this.custContacts.mobile || ''
            }
            if (this.orderMoney == '') {
                this.orderMoney = this.priceConfig.totalCost
            }
            if (this.userEmail == '') {
                this.userEmail = this.mailAddress
            }
            if (this.userName == '') {
                this.userName = this.custContacts.contName
            }
            this.$dialog.confirm({ title: '', message: '提交订单后，等待财务审核，是否提交订单？' })
                .then(() => {
                    this.saveOrder() // 保存订单
                })
                .catch(() => {
                    console.log('取消了')
                })
        },
        saveOrder() {
            this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: this.$t('mx_mobile.Client.1585281647545') // 加载中...
            })
            let data = {
                ordertype: 'N',
                phone: this.userPhone,
                custid: Number(this.item.custId),
                custname: this.item.custName,
                // companyid: Number(this.item.cId),
                // companyname: this.companie.corpName,
                productcode: this.formObj.pkValue,
                authcount: Number(this.formObj.memberNum),
                rType: 3,
                nums: Number(this.formObj.yearValue) * 12,
                spacecount: Number(this.formObj.spaceAdd) || 0,
                payamount: Number(this.priceConfig.totalCost) || 0,
                paymentvoucher: this.paymentvoucher,
                // paytradeno: this.paytradeno,
                result: this.priceConfig, // 付款金额明细，从‘价格计算’接口获取的数据
                username: this.userName,
                useremail: this.userEmail,
                salesManCtId: (this.obj.value == '') ? Number(this.companie.ctId) : Number(this.obj.value),
                actualreceiveamount: this.returnFloat(this.orderMoney) || '0'
            }
            if (this.activityId != '') {
                data.activityId = parseInt(this.activityId)
            }
            this.axios.post(this.Global.config.apiBaseURL + this.Global.api.v2.saleOrder, data).then(res => {
                this.$toast.clear()
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.$emit('success', res.data.data)
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                this.$toast.clear()
                console.log(error)
            })
        },
        returnFloat(x) {
            var value = Math.round(parseFloat(x) * 100) / 100
            var xsd = value.toString().split('.')
            if (xsd.length == 1) {
                value = value.toString() + '.00'
                return value
            }
            if (xsd.length > 1) {
                if (xsd[1].length < 2) {
                    value = value.toString() + '0'
                }
                return value
            }
        }
    },
    watch: {

    }
}
</script>
<style>
.textalign input.van-field__control {
    text-align: right !important;
}
.orderSubmit .money {
    font-weight: bold;
    font-size: 14px;
}
.orderSubmit .van-cell__title {
    max-width: 120px !important;
}
</style>

<style lang="less">
@import "./zh-cn.less";
</style>

<template>
    <svg class="color__icon-MX" :style="'font-size:' + fontSize + 'px'" aria-hidden="true">
        <use v-bind:xlink:href="'#color-icon_' + iconName"></use>
    </svg>
</template>

<script>
export default {
    name: 'iconColor',
    props: {
        iconName: {
            type: String,
            default: ''
        },
        fontSize: {
            type: [Number, String]
        }
    },
    components: {},
    data() {
        return {}
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {},
    beforeDestroy() { }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
// svg 样式
.color__icon-MX {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    font-size: 16px;
}
</style>

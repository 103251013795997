<template>
    <div class="Controls">
        <span style="margin-right:16px">{{createName}}</span><span>{{items.createDate}}</span>
    </div>
</template>

<script>
export default {
  name: 'Controls',
  props: {
    value: {
      type: String,
      default: ''
    },
    items: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      createName: '',
      owners: []
    }
  },
  created () {
    this.getOwerData()
  },
  methods: {
    returnName(id) {
      let name = ''
      this.owners.forEach(function(item) {
          if (item.ctId == id) {
              name = item.realName
              return false
          }
      })
      return name
    },
    getOwerData() {
        let _this = this
        if (_this.items.createCtId && _this.items.createCtId == '') {
            _this.createName = ''
            return false
        };
        let contactData = {
                dataType: 'contact',
                funType: 'all'
            }
        _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.accountDropList_get, { params: contactData }).then(function(res) {
            if (res.data.code.toString() == _this.Global.config.RES_OK) {
                _this.owners = res.data.data
                _this.createName = _this.returnName(_this.items.createCtId)
            } else {
                // _this.$toast.fail(res.data.msg)
                _this.createName = ''
                _this.owners = []
            }
        }, function(res) {
            _this.$toast.fail(_this.Global.config.errorTitle)
        })
    }
  },
  watch: {
      'items.createCtId' () {
            this.getOwerData()
      }
  },
  components: {
  }
}
</script>

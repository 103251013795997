<template>
    <div class="MapLocation">
        <remote-js src="//webapi.amap.com/ui/1.0/main.js?v=1.0.11"></remote-js>
        <!--附近客户-->
        <span @touchstart.stop="openThisCustomers"><i class="iconfont icon-location"></i> {{$t('mx_mobile.Client.1585630298302')}}({{totalNum}})</span>
        <!-- <span @touchstart.stop="openThisMap"><i class="iconfont icon-navigation"></i> 获取路线</span> -->
    </div>
</template>
<script>
export default {
    name: 'MapLocation',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            totalNum: 0,
            distance: '20km'
        }
    },
    created() {
        // console.log(this.itemData.custAddr)
        this.getNearbyConst()
    },
    mounted() {
    },
    methods: {
        getNearbyConst() {
            if (!this.itemData.custId) { return } // 没地址的不调接口
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.customerNearby_do, {
                params: {
                    custId: this.itemData.custId, // 客户ID
                    distance: this.distance // 距离
                }
            }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    // console.log(res.data.data)
                    this.totalNum = res.data.data.totalNum
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        openThisCustomers() {
            this.$router.push('/client/nearbyConst/' + this.itemData.custId)
        },
        openThisMap() {
            if (this.itemData.location && this.itemData.location !== '' && this.itemData.location.split(',').length > 0) {
                // let arr = this.itemData.location.split(',')
                // let obj = {
                //   latitude: arr[0], // 纬度
                //   longitude: arr[1], // 经度
                //   title: this.itemData.custName || '' // 地址/POI名称
                // }
                // ......................openLocation(obj)
            } else {
                this.$toast.fail(this.$t('mx_mobile.Client.1585630329655')) // 地址有误，请校正！
            }
        }
    },
    components: {
        'remote-js': {
            render(createElement) {
                return createElement('script', { attrs: { type: 'text/javascript', src: this.src } })
            },
            props: {
                src: { type: String, required: true }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.MapLocation {
    // border:1px solid red;
    margin: -12px 0 0 -10px;
    > span {
        // background: #ddd;
        display: inline-block;
        padding: 0 8px;
        margin-right: 8px;
        color: #008cee;
        border-radius: 5px;
        .iconfont {
            float: left;
            margin-right: 2px;
        }
        &:active {
            background: #eee;
        }
    }
}
</style>

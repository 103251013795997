<template>
    <div class="funcMark6">
        <loading-new :loading="loading"></loading-new>
        <!-- <van-cell is-link @click="showPopup">展示弹出层</van-cell> -->
        <!-- <van-popup v-model="show" position="right" class="popUp" :close-on-click-overlay="false">
            <filter-box :moduleCode="moduleCode" :isScreen='show' :categoryVal='categoryVal' :listTab='listTab' :searchId='searchId' @determineClick='determineClick' @cancelClick='cancelClick' @categoryValue='categoryValue' @searchGroupId='searchGroupIdVal' @groupArr="groupArrData" @listTabValue="listTabVal" @enclosureTitle='enclosureTitle'></filter-box>
        </van-popup> -->
        <div class="listTop">
            <div v-if="Search" class="Input_content">
                <i class="iconfont icon-search"></i>
                <!--输入模块名搜索-->
                <input type="text" v-model.trim="inputSearch" @keyup.enter="searchInput(inputSearch)" :placeholder="$t('mx_mobile.Client.1631863488717')">
                <!-- 取消 -->
                <div @click="isSearch" class="cancel">{{$t('mx_mobile.Client.1585301326634')}}</div>
            </div>
            <div v-else class="list_details">
                <!-- 筛选 -->
                <!-- <div class="list_all" @click="isScreen">{{$t('mx_mobile.Client.1585115139237')}}<span class="iconfont" :class="screen == false ? 'icon-page-down' : 'icon-page-up'"></span></div> -->
                <div class="list_all" @click="showPopup">{{$t('mx_mobile.Client.1585115139237')}}<span class="iconfont" :class="show == false ? 'icon-page-down' : 'icon-page-up'"></span></div>
                <!-- 所有文件 -->
                <!-- <div>{{$t('mx_mobile.Client.1631864216033')}}</div> -->
                <div>{{fjTitle}}</div>
                <!-- 搜索 -->
                <div class="list_all" @click="isSearch">{{$t('mx_mobile.Client.1585115144101')}}<span class="iconfont icon-search"></span></div>
            </div>
        </div>

        <div class="screenElse">
            <div v-if="listTab == 'listTab1'" class="listTab">
                <template v-if="!loading">
                    <nodata v-if="dataList.length == 0" :title="title" img="noData"></nodata>
                    <div v-else class="list" v-for="(item, index) in dataList" :key="index">
                        <div class="listImg" @click="previewImage(item)">
                            <img v-if="isImage(item.fileExtName)" :src="item.preViewImageUrl">
                            <svg v-else class="iconSVG" aria-hidden="true">
                                <use :xlink:href="checkIco(item.fileExtName)"></use>
                            </svg>
                        </div>
                        <div class="fileNameBox">
                            <!-- <div class="fileName ellipsis">{{item.fileName}}</div> -->
                            <div class="fileName ellipsis" v-html="item.fileName+'.'+item.fileExtName"></div>
                            <span class="modify">
                                {{$m_formulateTime(item.createDate)}}
                            </span>
                        </div>
                        <div class="size">
                            {{conver(item.fileSize)}}
                            <span class="modify">
                                {{item.createRealName}}
                            </span>
                        </div>
                    </div>
                    <!-- 查看更多 -->
                    <!-- <div class="view__more" v-if="isShowPageCtrl">
                        <span class="view__more-desc" @click="handlePage">{{$t('mx_mobile.Client.1627017309696')}}</span>
                    </div> -->
                </template>
            </div>
            <div v-else class="listTab">
                <template v-if="!loading">
                    <nodata v-if="dataList.length == 0" :title="title" img="noData"></nodata>
                    <div v-else class="list" v-for="(item, index) in dataList" :key="index">
                        <div class="listImg" @click="mailboxImg(item)">
                            <img v-if="isImage(item.fileExtName)" :src="item.attachmentList[0].url">
                            <svg v-else class="iconSVG" aria-hidden="true">
                                <use :xlink:href="checkIco(item.fileExtName)"></use>
                            </svg>
                        </div>
                        <div class="fileNameBox">
                            <div class="fileName ellipsis" v-html="item.fileName+'.'+item.fileExtName"></div>
                            <span class="modify">
                                {{$m_formulateTime(item.sentDate)}}
                            </span>
                        </div>
                        <div class="size">
                            {{conver(item.attachmentList[0].size)}}
                            <span class="modify">
                                {{item.createRealName}}
                            </span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import loadingNew from '../../../../CustPublic/LoadingVue/index.vue'
import filterBox from './Filterbox/index.vue'
export default {
    name: 'funcMark6',
    props: {
        detailData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        moduleCode: {
            type: String,
            default: ''
        },
        listTab: {
            type: String,
            default: ''
        },
        categoryVal: {
            type: String,
            default: ''
        },
        fjTitle: {
            type: String,
            default: ''
        },
        searchGroupIdValue: { // 客户附件筛选需要的id,父传子取得
            type: String,
            default: ''
        },
        groupArr: {
        }
    },
    data() {
        return {
            dataList: [],
            dataList1: [],
            title: this.$t('mx_mobile.Client.1585279883363'), // 暂无附件
            loading: true,
            screen: false, // 筛选开关
            Search: false, // 搜索开关
            inputSearch: '', // input里面存储的值
            // groupArr: [],
            foldersList: [],
            // categoryVal: 'list', // 选择的文件格式,默认全部
            pageSize: 100,
            from: 0,
            totalNum: 0, // 当前展开面板的数据总数
            searchId: '',
            searchFolderId: '',
            mailImg: '',
            // fjTitle: '所有文件',
            show: false
        }
    },
    created() {
        this.getData()
    },
    // 用于显示更多
    // computed: {
    //     isShowPageCtrl() {
    //         // 显示页
    //         return this.totalNum > this.pageSize && this.totalNum > this.dataList.length
    //     }
    // },
    mounted() {
    },
    methods: {
        showPopup() {
            this.$emit('handleShow')
            // this.show = true
        },
        // handlePage() {
        //     // 下一页
        //     let pageSize = this.pageSize
        //     this.pageSize = pageSize + 5
        //     this.getData()
        // },
        // 点击查看图片
        async previewImage(item) {
            try {
                if (!this.Global.utils.filePreview.isSupport(item.fileExtName)) {
                    this.$toast(this.$t('mx_mobile.Client.1585279886218')) // 暂不支持该格式文件预览
                    return
                }
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_downloadURL
                let { fileId, fileVersion, isOverCid, billId } = item
                let params = {
                    fileId,
                    fileVersion
                }
                if (isOverCid !== null && isOverCid !== undefined) {
                    Object.assign(params, { isOverCid })
                }
                if (billId) {
                    Object.assign(params, { billId })
                }

                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    let _url = ''

                    if (res.data.data[0].inLocalFileSystem == 1) {
                        /*  let protocol = window.location.protocol
                          let host = window.location.host */
                        /*  protocol + '//' + host +  */
                        _url = this.Global.config.apiBaseURL + this.Global.api.v2.doc_preView + res.data.data[0].downloadURL + '?'
                        let data = Object.assign({}, getToken())
                        Object.keys(data).forEach(key => {
                            _url += key + '=' + data[key] + '&'
                        })
                        _url = _url.substring(0, _url.length - 1)
                        console.log('_url', _url)
                    } else {
                        _url = res.data.data[0].downloadURL
                        console.log('else_url', _url)
                    }
                    this.Global.utils.filePreview.open(_url, item.fileExtName, `${item['fileName'] || item['name'] || (new Date().getTime() % 100000)}.${item.fileExtName}`)
                }
            } catch (error) {
                alert(error)
            }
        },
        // 点击查看邮箱附件信息
        mailboxImg(item) {
            if (!this.Global.utils.filePreview.isSupport(item.fileExtName)) {
                this.$toast(this.$t('mx_mobile.Client.1585279886218')) // 暂不支持该格式文件预览
                return
            }
            console.log('item', item)

            let _url = item.attachmentList[0].url
            this.Global.utils.filePreview.open(_url, item.fileExtName, `${item['fileName'] || item['name'] || (new Date().getTime() % 100000)}.${item.fileExtName}`)
        },
        // 查看大小
        conver(limit) {
            var size = ''
            if (limit < 0.1 * 1024) { // 如果小于0.1KB转化成B
                size = limit.toFixed(2) + 'B'
            } else if (limit < 0.1 * 1024 * 1024) { // 如果小于0.1MB转化成KB
                size = (limit / 1024).toFixed(2) + 'KB'
            } else if (limit < 0.1 * 1024 * 1024 * 1024) { // 如果小于0.1GB转化成MB
                size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
            } else { // 其他转化成GB
                size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
            }

            var sizestr = size + ''
            var len = sizestr.indexOf('.')
            var dec = sizestr.substr(len + 1, 2)
            if (dec == '00') { // 当小数点后为00时 去掉小数部分
                return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
            }
            return sizestr
        },
        // 判断是客户还是邮箱附件
        getData() {
            if (this.listTab == 'listTab1') {
                this.getTabData()
            } else {
                this.getMailTabData()
            }
        },
        // 点击全部客户附件的查询
        getTabData(reqObj) {
            if (this.searchGroupId) {
                this.searchGroupId = ''
            }
            let _this = this
            _this.dataList = []
            _this.loading = true
            let data = {
                moduleCode: _this.moduleCode,
                identFieldValue: _this.detailData.custId,
                type: _this.categoryVal,
                size: _this.pageSize,
                sort: 'modifyDate',
                order: 'desc',
                from: _this.from,
                params: 'w_40,h_40,m_fill',
                folderId: _this.searchFolderId,
                // groupId: _this.searchGroupId
                groupId: _this.searchGroupIdValue
            }
            data = Object.assign(data, reqObj)
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.docFile_get, { params: data }).then((res) => {
                if (res.data.code.toString() == _this.Global.config.RES_OK && res.data.data) {
                    let resData = res.data.data
                    _this.totalNum = resData.totalNum
                    resData.list.forEach(item => {
                        if (_this.groupArr.length > 0) {
                            _this.groupArr.forEach(item2 => {
                                if (item.groupId == item2.groupId) {
                                    item.groupName = item2.groupName
                                } else if (item.groupId == '' || item.groupId == 0) {
                                    item.groupName = '无'
                                }
                            })
                        } else {
                            item.groupName = '无'
                        }
                    })
                    let list = resData.list
                    _this.dataList = list
                    _this.loading = false
                } else {
                }
            }, (res) => {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        // 点击邮箱附件的查询
        getMailTabData(reqObj) {
            let _this = this
            _this.dataList = []
            _this.loading = true

            let data = {
                moduleCode: _this.moduleCode,
                identFieldValue: _this.detailData.custId,
                type: _this.categoryVal,
                size: _this.pageSize,
                // sort: 'modifyDate',
                // order: 'desc',
                from: _this.from
                // params: 'w_40,h_40,m_fill'
            }
            data = Object.assign(data, reqObj)

            _this.axios.get(_this.Global.config.apiBaseURL + this.Global.api.v2.mails_contAttachments, { params: data }).then((res) => {
                if (res.data.code.toString() == _this.Global.config.RES_OK && res.data.data) {
                    let resData = res.data.data
                    _this.totalNum = parseInt(resData.totalNum)
                    let list = resData.list
                    for (let index = 0; index < list.length; index++) {
                        let a = list[index]
                        let fileExtName = a.attachmentList[0].name
                        let fileExtNameData = fileExtName.split('.')
                        a.fileName = fileExtNameData[0]
                        a.fileExtName = fileExtNameData[1]
                        a.attachmentList.forEach((item, index2) => {
                            if (index2 > 0) {
                                let obj1 = []
                                obj1.push(item)
                                let obj = JSON.parse(JSON.stringify(a))
                                obj.attachmentList = obj1
                                list.push(obj)
                            }
                        })
                        a.attachmentList.splice(1)
                    }
                    let categoryVal = _this.categoryVal.toLowerCase().toString()
                    const imgArr = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
                    const VIDEO = ['mp4', 'avi']
                    const doc = ['doc', 'docx']
                    const XLS = ['xls', 'xlsx']
                    list.forEach(item => {
                        if (categoryVal == 'video') {
                            if (VIDEO.indexOf(item.fileExtName) > -1) {
                                if (item.fileName != item) {
                                    _this.dataList.push(item)
                                }
                            }
                        } else if (categoryVal == 'picture') {
                            if (imgArr.indexOf(item.fileExtName) > -1) {
                                _this.dataList.push(item)
                            }
                        } else if (categoryVal == 'doc') {
                            if (doc.indexOf(item.fileExtName) > -1) {
                                _this.dataList.push(item)
                            }
                        } else if (categoryVal == 'xls') {
                            if (XLS.indexOf(item.fileExtName) > -1) {
                                _this.dataList.push(item)
                            }
                        } else if (categoryVal == 'list') {
                            _this.dataList.push(item)
                        } else {
                            if (categoryVal == item.fileExtName) {
                                _this.dataList.push(item)
                            }
                        }
                    })
                    _this.loading = false
                } else {
                    _this.$message.error(res.data.msg)
                }
            }, (res) => {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        checkIco(ico) {
            return '#file-' + this.Global.utils.isHasSuffix(ico)
        },
        isImage(exe) {
            const imgArr = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
            return imgArr.includes(exe.toLowerCase())
        },
        // 筛选开关
        isScreen() {
            this.show = true
        },
        // 搜索开关
        isSearch() {
            this.Search = !this.Search
            if (this.dataList.length == 0) {
                this.getData()
            }
        },
        // input搜索
        searchInput(inputSearch) {
            // let search = inputSearch.trim() // 去除收尾空格
            // 客戶的搜索筛选
            if (this.listTab == 'listTab1') {
                if (inputSearch == '') {
                    this.getTabData()
                } else {
                    this.getTabData({
                        wildCardWords: inputSearch,
                        keywords: inputSearch,
                        type: 'SEARCH'
                    })
                }
            } else {
                // 邮箱的搜索筛选
                if (inputSearch == '') {
                    this.getMailTabData()
                } else {
                    this.getMailTabData({
                        wildCardWords: inputSearch,
                        keywords: inputSearch,
                        type: 'SEARCH'
                    })
                }
            }
        }
    },
    watch: {
        // 监听input值的变化，便利搜索筛选
        inputSearch(newV, oldV) {
            if (newV != oldV) {
                this.searchInput(newV)
            }
        }
    },
    components: {
        'loading-new': loadingNew,
        'filter-box': filterBox
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

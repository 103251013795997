<template>
    <div class="Controls" :class="{'Controls--disabled':itemData.disabled}">
        <span class="name">
            {{itemData.cnFieldCaption}}
            <span style="color:#D0021B" v-if="prop()">*</span>
        </span>
        <div class="ControlsBox">
            <van-cell class="custName" :value="obj.custName" is-link @click="openPopup()" />
        </div>
        <van-popup v-model="show" position="right" class="custBox" :lock-scroll="false">
            <custList @submitItem="submitItem"></custList>
        </van-popup>
    </div>
</template>

<script>
import custList from './custList/index'
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            obj: {
                custName: '',
                custId: ''
            },
            show: false
        }
    },
    created() {
    },
    methods: {
        openPopup() {
            if (this.itemData.disabled) return
            this.show = true
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        mounted() {
            if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
                this.updates(this.itemData.inDefaultValue)
            }
        },
        updates(value) {
            if (value == '') {
                return false
            }
            let data = {
                moduleCode: 'BF001',
                searchType: 'detail'
            }
            if (typeof (value) == 'object') {
                data.identFieldValue = value.custId
            } else {
                data.identFieldValue = value
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.document_generalOperate_get, { params: data }).then((res) => {
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    this.obj = res.data.data
                } else {
                    this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                this.$toast.fail(this.Global.config.errorTitle)
            })
        },
        update(item) {
            if (item) {
                this.obj = item
            }
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.obj.custId == '') {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            if (this.obj.custId == '') {
                data[this.itemData.fieldName] = 0
            } else {
                data[this.itemData.fieldName] = this.obj.custId
            }
            return data
        },
        submitItem(item) {
            this.obj = item
            this.show = false
        }
    },
    watch: {
        obj: function (val, old) {
            this.$emit('bindContact', val.custContacts || [], this.itemData.fieldName) // 客户关联联系人
            this.$emit('relyOn', val.custId, this.itemData.fieldName)
            if (this.itemData.returnFieldList && this.itemData.returnFieldList != '') {
                this.$emit('returnFieldList', val, this.itemData.returnFieldList)
            }
        }
    },
    components: {
        'custList': custList
    }
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    .ControlsBox {
        .min-height(45px);
        .custName {
            padding-left: 0;
            .padding-top(10px);
        }
    }
    .custBox {
        width: 80%;
        height: 100%;
        background: white;
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

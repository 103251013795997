<template>
    <div>
        <div class="Controls" @click="getTime()" :class="{'Controls--disabled':itemData.disabled}">
            <span class="name">
                {{itemData.cnFieldCaption}}
                <span style="color:#D0021B" v-if="prop()">*</span>
            </span>
            <span>{{value}}</span>
        </div>
        <date-picker ref="datePicker"></date-picker>
    </div>
</template>

<script>
import DatePicker from '@/components/PublicControls/DatePicker/index'
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            value: ''
        }
    },
    created() {
    },
    mounted() {
        if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
            this.updates(this.itemData.inDefaultValue)
        }
    },
    beforeRouteLeave(to, from, next) {
        console.log('aaaaaaaaa')
    },
    methods: {
        async getTime() {
            if (this.itemData.disabled) return
            this.$refs.datePicker.getTime((res) => {
                this.value = res
            })
        },
        updates(value) {
            if (value == '') {
                return false
            }
            this.value = value
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.value == '') {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            if (this.value == '') {
                return {}
            }
            data[this.itemData.fieldName] = this.value
            return data
        }
    },
    watch: {
    },
    components: {
        'date-picker': DatePicker
    }
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

<template>
    <div class="Calculator">

        <template v-if="step01.isShow">
            <dl class="orderTop">
                <dt class="orderTopTitle">
                    当前配置
                    <span @click="goRecord">购买记录</span>
                </dt>
                <dd>
                    <div style="margin: 0 -15px -12px;">
                        <van-cell title="客户名称" :value="item.custName" />
                        <van-cell title="套餐名称" :value="selectConfig.pkname" />
                        <van-cell title="用户许可数" :value="selectConfig.authcount" />
                        <van-cell title="到期时间" :value="selectConfig.expirationtime ? selectConfig.expirationtime.split(' ')[0] : ''" />
                        <van-cell title="云空间" :value="selectConfig.spacecount+'G'" />
                    </div>
                </dd>
            </dl>
            <dl class="orderTop">
                <dt>
                    <van-checkbox v-model="checked02" @change="upPackage" :disabled="checked01 || this.minYear > 3 || noUpPackage">升级</van-checkbox>
                </dt>
                <template v-if="checked02">
                    <dt>套餐名称</dt>
                    <dd class="btns">
                        <template v-for="(item,index) in pkData">
                            <span :key="index" class="mx_btn" @click="formObj.pkValue = item.pkCode;getPrice()" :class="[formObj.pkValue == item.pkCode ? 'active' :'']" v-if="item.pkCode != 'PK0007'">{{item.cnName}}</span>
                        </template>
                    </dd>
                    <dd>升级后套餐：<span style="color:#d0021b">{{getpkName}}</span></dd>
                    <dt style="margin-top:15px;">升级时长：</dt>
                    <dd class="stepperBox">
                        <!-- <van-stepper v-model="upgradeYear" :min="minYear" /> -->
                        <template v-for="(item,index) in upYearData">
                            <span class="mx_btn" @click="choiceUpYear(item.value)" :key="index" :class="[item.text && item.text != '' ? 'mx_gift' : '', upgradeYear == item.value ? 'active' :'',item.value< minYear? 'disable': '']" v-show="isFlag || item.value !=1">{{item.value}}年</span>
                        </template>
                    </dd>
                    <dd>升级后到期时间：<span style="color:#d0021b">{{upEndTime}}</span></dd>
                </template>
            </dl>
            <dl class="orderTop">
                <dt>
                    <span class="pull-right">剩余天数：{{selectConfig.totalDays - selectConfig.useDays}}天</span>
                    <van-checkbox v-model="checked01" @change="getActivity(),getPrice()" :disabled="checked02">续费</van-checkbox>
                </dt>
                <template v-if="checked01">
                    <dt>购买时长</dt>
                    <dd class="btns">
                        <template v-for="(item,index) in yearData">
                            <span class="mx_btn" @click="formObj.yearValue = item.value; getActivity(),getPrice()" :key="index" :class="[item.text && item.text != '' ? 'mx_gift' : '', formObj.yearValue == item.value ? 'active' :'']" v-show="isFlag || item.value !=1">{{item.value}}年</span>
                        </template>
                    </dd>
                    <dd class="descript">
                        <div><i class="iconfont icon-calendar"></i> 购买后到期时间：<span style="color:#d0021b">{{getEndTime}}</span></div>
                        <div v-show="priceConfig.offCost != 0"><span class="gift">省</span>{{formObj.yearValue}}年付立省{{Math.abs(priceConfig.offCost)}}元</div>
                        <div v-show="Object.keys(activityConfig).length > 0 && activityConfig.nums != 0"><span class="save">促</span>{{activityConfig.description}}</div>
                    </dd>
                </template>
            </dl>
            <dl class="orderTop">
                <dt>
                    <van-checkbox v-model="checked03" @change="getPrice()">增加用户</van-checkbox>
                </dt>
                <template v-if="checked03">
                    <div class="peopleNum" style="margin-top:0">
                        <van-stepper v-model="formObj.memberNum" integer :min="1" :max="999" :step="1" @change="getPrice()" />
                    </div>
                    <dt>用户许可数</dt>
                    <dd>
                        购买后用户许可数：<span style="color:#d0021b">{{selectConfig.authcount + formObj.memberNum}}人</span>
                    </dd>
                </template>
            </dl>

            <dl class="orderTop" style="margin-bottom:60px;">
                <dt>
                    <!-- <span class="pull-right">剩余空间：33333GB</span> -->
                    <van-checkbox v-model="checked04" @change="getPrice()">增加云空间</van-checkbox>
                </dt>
                <template v-if="checked04">
                    <div class="peopleNum" style="margin-top:0">
                        <van-stepper v-model="formObj.spaceAdd" integer :min="20" :max="10000" :step="20" :disable-input="true" @change="getPrice()" />
                    </div>
                    <dt>云空间</dt>
                    <dd>
                        购买后云空间：<span style="color:#d0021b">{{selectConfig.spacecount + formObj.spaceAdd}}GB</span>
                    </dd>
                </template>
            </dl>

            <div class="divFixedBottom">
                <div style="font-size:14px;border-top: 1px solid #e3e3e4;color:#606266"><span>{{totalMoney}}元</span>
                    <em class="original" v-show="discountMoney>totalMoney">￥{{discountMoney}}</em>
                </div>
                <div @click="clickStep01" class="btn" :class="minYear > 3 && checked02?'disable': ''">下一步</div>
            </div>
        </template>

        <template v-if="step02.isShow">
            <div class="orderSheet">
                <div class="current">
                    <span>已选配置</span>
                    <ul>
                        <li>
                            <span>产品名称：</span>
                            <em>{{getpkName==''?selectConfig.pkname:getpkName}}</em>
                        </li>
                        <li>
                            <span>到期时间：</span>
                            <!-- 续费时 upEndTime，升级时getEndTime， 都不选时原到期时间-->
                            <em>{{checked01?getEndTime:checked02?upEndTime:selectConfig.expirationtime.split(' ')[0]}}</em>
                        </li>
                        <li>
                            <span>用户许可数：</span>
                            <em>{{checked03?selectConfig.authcount + formObj.memberNum : selectConfig.authcount}}</em>
                        </li>
                        <li>
                            <span>云空间：</span>
                            <em>{{availSpace}}G</em>
                        </li>
                    </ul>
                </div>
                <div class="detailList">
                    <ul>
                        <template v-if="checked01">
                            <li>
                                <span>续费 {{selectConfig.pkname}} ({{priceConfig.packageMember}}用户)</span>
                                <em :class="priceConfig.offCost != 0 ?'original':''">￥{{priceConfig.standandPrice + priceConfig.offCost}}</em>
                            </li>
                            <li v-show="priceConfig.offCost != 0">
                                <div class="left"><span class="gift">省</span>{{formObj.yearValue}}年付立省{{Math.abs(priceConfig.offCost)}}元</div>
                                <em class="blod">￥{{priceConfig.standandPrice}}</em>
                            </li>
                        </template>
                        <template v-if="checked02">
                            <li>
                                <span>升级 {{getpkName}} ({{priceConfig.packageMember}}用户)</span>
                                <em :class="priceConfig.offCost != 0 ?'original':''">￥{{priceConfig.standandPrice + priceConfig.offCost}}</em>
                            </li>
                            <li v-show="priceConfig.offCost != 0">
                                <div class="left"><span class="gift">省</span>{{formObj.yearValue}}年付立省{{Math.abs(priceConfig.offCost)}}元</div>
                                <em class="blod">￥{{priceConfig.standandPrice}}</em>
                            </li>
                        </template>
                        <template v-if="(priceConfig.outMember != 0 &&checked02) || checked03 ||(priceConfig.outMember != 0 &&checked01)">
                            <li class="listTitle">
                                <span>增加用户*{{priceConfig.outMember != 0 ? priceConfig.outMember : formObj.memberNum}}用户</span>
                                <em class="blod">￥{{priceConfig.memberCost}}</em>
                            </li>
                            <li>
                                <span>费用小计：</span>
                                <span v-if="checked01 && priceConfig.outMember != 0 && checked03">
                                    {{priceConfig.costOfMember}}元/ 365天*({{priceConfig.totalDays}}天 - {{priceConfig.useDays}}天)
                                    *{{priceConfig.outMember == 0 ? formObj.memberNum :priceConfig.outMember}}用户
                                    <em v-show="priceConfig.outMember!=0">+</em>
                                </span>
                                <span v-else-if="checked02 || priceConfig.outMember == 0">
                                </span>
                                <span v-else>
                                    {{priceConfig.costOfMember}}元/ 365天*({{selectConfig.totalDays}}天 - {{selectConfig.useDays}}天)
                                    *{{priceConfig.outMember}}用户
                                    <em v-show="priceConfig.outMember!=0 && (checked02 || checked01)">+</em>
                                </span>
                                <span v-if="priceConfig.outMember!=0 &&checked02">{{priceConfig.costOfMember}}元*{{priceConfig.outMember}}用户*{{upgradeYear}}年 </span>
                                <span v-if="priceConfig.outMember!=0 &&checked01">{{priceConfig.costOfMember}}元*{{priceConfig.outMember}}用户*{{formObj.yearValue}}年</span>
                                <span><i v-if="priceConfig.memberCost!=0"> = </i>{{priceConfig.memberCost}}元</span>
                            </li>
                        </template>
                        <template v-if="isAddSpace">
                            <li class="listTitle">
                                <span>增加云空间 {{checked01||checked02?originalAddSpace+formObj.spaceAdd:formObj.spaceAdd}}G</span>
                                <em class="blod">￥{{priceConfig.spaceCost + priceConfig.hisSpaceCost}}</em>
                            </li>
                            <li class="space">
                                <!-- 升级 -->
                                <span v-if="checked02">
                                    <!-- 原套餐增加过空间 -->
                                    <i v-show="originalAddSpace !=0">{{originalAddSpace}}G/20G*500元/365天*({{upgradeYear}}年*365天 -({{priceConfig.totalDays}}天 - {{priceConfig.useDays}}天)) <i v-show="formObj.spaceAdd!=0">+</i></i>
                                    <i v-show="formObj.spaceAdd!=0">{{formObj.spaceAdd}}G/20G*500元*{{upgradeYear}}年</i>
                                </span>
                                <!-- 续费 -->
                                <span v-else-if="checked01">
                                    <!-- 把现在增加的空间数在套餐剩下的时间内补齐-->
                                    <i v-show="formObj.spaceAdd!=0">{{formObj.spaceAdd}}G/20G*500元/365天*({{priceConfig.totalDays}}天 - {{priceConfig.useDays}}天)+</i>
                                    <i>{{originalAddSpace+formObj.spaceAdd}}G/20G*500元*{{formObj.yearValue}}年</i>
                                </span>
                                <!-- 仅仅增加空间 -->
                                <span v-else>
                                    {{formObj.spaceAdd}} G/20G*500元/ 365天*({{selectConfig.totalDays}}天 - {{selectConfig.useDays}}天)
                                </span>
                                <span>={{priceConfig.spaceCost + priceConfig.hisSpaceCost}}元</span>
                            </li>
                        </template>
                        <template v-if="checked02">
                            <li class="listTitle">
                                <span>原套餐剩余金额抵扣</span>
                                <em class="red">-￥{{priceConfig.leftValue}}</em>
                            </li>
                            <li>
                                <div class="left">原套餐金额</div>
                                <em class="original">￥{{priceConfig.actualreceiveamount}}</em>
                            </li>
                            <li>
                                <span>费用小计：</span>
                                <span>（{{priceConfig.totalDays}}天-{{priceConfig.useDays}}天）/{{priceConfig.totalDays}}天 *{{priceConfig.actualreceiveamount}}元 = {{priceConfig.leftValue}}元</span>
                            </li>
                        </template>
                        <template>
                            <li class="listTitle" v-if="activityConfig.nums && activityConfig.nums != 0 && activityConfig.type == 'day'">
                                <div class="left"><span class="save">促</span>{{activityConfig.description}}</div>
                                <em class="blod">￥0</em>
                            </li>
                            <li class="listTitle" v-if="activityConfig.nums && activityConfig.nums != 0 && activityConfig.type == 'money'">
                                <div class="left"><span class="save">促</span>{{activityConfig.description}}</div>
                                <em class="blod red">-￥{{activityConfig.nums}}</em>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <div class="divFixedBottom">
                <div style="font-size:14px;border-top: 1px solid #e3e3e4;color:#606266"><span>{{totalMoney}}元</span>
                    <em class="original" v-show="discountMoney>totalMoney">￥{{discountMoney}}</em>
                </div>
                <div @click="clickStep02" class="btn">立即下单</div>
            </div>
        </template>

        <step03 v-if="step03.isShow" :item="item" :priceConfig="priceConfig" :getOrderType="getOrderType()" :formObj="formObj" @success="successStep03" :activityId="activityId" />

        <step04 v-if="step04.isShow" :orderno="orderno" />
        <record v-if="record.isShow" :item="item"></record>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import step03 from './step03.vue'
import step04 from './step04.vue'
import record from './record.vue'
export default {
    name: 'Order',
    title: '选择配置',
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            }
        },
        selectConfig: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            upYearData: [
                {
                    value: 1,
                    text: ''
                },
                {
                    value: 2,
                    text: ''
                },
                {
                    value: 3,
                    text: ''
                }
            ],
            minYear: 1,
            upgradeYear: 0, // 升级年限
            checked01: false,
            yearData: [
                {
                    value: 1,
                    text: ''
                },
                {
                    value: 2,
                    text: ''
                },
                {
                    value: 3,
                    text: ''
                }
            ],
            checked02: false,
            pkData: [
                {
                    value: 'PK0001',
                    label: 'CRM'
                },
                {
                    value: 'PK0002',
                    label: '发现'
                },
                {
                    value: 'PK0003',
                    label: '营销云'
                },
                {
                    value: 'PK0006',
                    label: '销售云-Pro'
                },
                {
                    value: 'PK0005',
                    label: '销售云-企业版'
                }
            ],
            checked03: false,
            checked04: false,

            step01: {
                isShow: true
            },
            step02: {
                isShow: false
            },
            step03: {
                isShow: false
            },
            step04: {
                isShow: false
            },
            record: {
                isShow: false
            },
            formObj: {
                yearValue: 1, // 续费年限
                pkValue: 'PK0001',
                memberNum: 5,
                spaceAdd: 20
            },
            priceConfig: {}, // 选配置后 算出来的结果
            activityConfig: {}, // 选配置后 算出来的活动优惠
            currentActivity: {}, // 正在进行的活动
            usesAmount: 0,
            isFlag: true,
            isActivity: true,
            isDiscounts: true,
            isContinue: false,
            defaultPkValue: '',
            noUpPackage: false, // 是否有可升级的套餐
            orderno: '',
            activityId: '' // 活动id
        }
    },
    async created() {
        await this.getAllPackage()
        this.setDefault()
    },
    mounted() {
        this.setMenu('选择配置')
    },
    computed: {
        ...mapGetters(['companie']),
        getEndTime() {
            if (this.selectConfig.hasOwnProperty('expirationtime')) {
                var myDate = new Date(this.selectConfig.expirationtime.replace(/-/g, '/'))
                if (this.activityConfig.type == 'day') {
                    // 选择的年数加赠送的月
                    myDate = myDate.setMonth(myDate.getMonth() + this.activityConfig.nums)
                    myDate = new Date(myDate)
                }
                let newDate = this.DateAdd(this.formObj.yearValue, myDate)
                return newDate.toLocaleDateString()
            }
        },
        upEndTime() {
            var now = new Date()
            let upMonth = this.upgradeYear
            let myDate = now.setFullYear(now.getFullYear() + upMonth)
            return new Date(myDate).toLocaleDateString()
        },
        getpkName() {
            let clickItem = this.pkData.filter((item) => {
                return item.pkCode == this.formObj.pkValue
            })
            if (clickItem.length > 0) {
                return clickItem[0].cnName
            } else {
                return ''
            }
        },
        //  最终可以用的空间
        availSpace() {
            if (this.checked04) {
                return this.selectConfig.spacecount + this.formObj.spaceAdd + this.formObj.memberNum * 20
            } else {
                return this.selectConfig.spacecount + this.formObj.memberNum * 20
            }
        },
        // 原来增加的空间
        originalAddSpace() {
            return this.selectConfig.spacecount - this.selectConfig.authcount * 20
        },
        // 清单列表是否显示增加云空间
        isAddSpace() {
            let isSpace = this.checked04 || (this.originalAddSpace > 0 && this.checked02) || (this.originalAddSpace > 0 && this.checked01)
            return isSpace
        },
        // 优惠前的总金额
        totalMoney() {
            if (isNaN(this.priceConfig.totalCost) || (!this.checked01 && !this.checked02 && !this.checked03 && !this.checked04)) {
                return 0
            } else {
                return this.returnFloat(this.priceConfig.totalCost)
            }
        },
        // 优惠后金额
        discountMoney() {
            if (isNaN(this.priceConfig.totalCost)) {
                return 0
            } else {
                return this.priceConfig.totalCost + this.priceConfig.offCost
            }
        }
    },
    methods: {
        successStep03(x) {
            this.orderno = x
            this.step03.isShow = false
            this.step04.isShow = true
        },
        async getAllPackage() { // 全部套餐
            await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.allPackages).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    let allPackage = res.data.data
                    let currentPkLevel // 当前套餐级别
                    allPackage.map((item, index) => {
                        if (item.pkCode == this.selectConfig.pkcode) {
                            currentPkLevel = item.pkLevel
                        }
                    })
                    this.pkData = allPackage.filter(item => item.pkLevel >= currentPkLevel && item.pkCode != this.selectConfig.pkcode) // 过滤出可升级的套餐
                    if (this.pkData.length == 0) {
                        this.noUpPackage = true
                        this.checked02 = false
                    } else {
                        this.formObj.pkValue = this.pkData[0].pkCode // 设置默认升级的套餐
                    }
                } else {
                    //  this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        upPackage() {
            if (this.pkData.length == 0) {
                this.noUpPackage = true
                this.checked02 = false
            } else {
                this.formObj.pkValue = this.pkData[0].pkCode // 设置默认升级的套餐
            }
            if (this.minYear <= 3) {
                this.getActivity()
                this.getPrice()
            }
        },
        choiceUpYear(x) {
            if (x >= this.minYear) {
                this.upgradeYear = x
                this.getPrice()
            }
        },
        setMenu(x) {
            this.setHeaderTitle(x)
            this.Global.utils.rightMenu.clearMenu()
        },
        goRecord() {
            this.step01.isShow = false
            this.record.isShow = true
            this.$router.push({
                path: '',
                query: {
                    step: '05'
                }
            })
        },
        setDefault() {
            if (this.selectConfig.authcount >= 20) {
                this.usesAmount = 20
            } else if (this.selectConfig.authcount < 20 && this.selectConfig.authcount >= 10) {
                this.usesAmount = 10
            } else if (this.selectConfig.authcount < 10 && this.selectConfig.authcount >= 5) {
                this.usesAmount = 5
            } else {
                this.usesAmount = this.selectConfig.authcount
            }
            if (this.selectConfig.authcount < 5) { // 当前套餐数少于5人时
                this.formObj.yearValue = 2 // 设置套餐升级默认值为2年
            }
            let surplusDay = this.selectConfig.totalDays - this.selectConfig.useDays // 设置升级套餐年限最小值
            let surplusYear = Math.floor(surplusDay / 365)
            if (this.selectConfig.authcount < 5 && surplusYear + 1 < 2) { // 当前用户少于5时，升级最少是2年
                this.minYear = 2
                this.upgradeYear = 2
            } else {
                this.minYear = surplusYear + 1
                this.upgradeYear = surplusYear + 1
            }
            if (this.selectConfig.authcount < 5) {
                this.isFlag = false
            }
        },
        DateAdd(number, date) {
            date.setFullYear(date.getFullYear() + number)
            return date
        },
        returnFloat(x) {
            var value = Math.round(parseFloat(x) * 100) / 100
            var xsd = value.toString().split('.')
            if (xsd.length == 1) {
                value = value.toString() + '.00'
                return value
            }
            if (xsd.length > 1) {
                if (xsd[1].length < 2) {
                    value = value.toString() + '0'
                }
                return value
            }
        },
        async clickStep01() {
            this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: '加载中...'
            })
            await this.getPrice()
            await this.getActivity()
            this.$toast.clear()
            if (this.isContinue) {
                this.step01.isShow = false
                this.step02.isShow = true
                this.$router.push({
                    path: '',
                    query: {
                        step: '02'
                    }
                })
            }
            this.setMenu('购买清单')
        },
        async getActivity() {
            await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.activity, { params: { type: 'now' } }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    if (res.data.data.length > 0) {
                        let obj = res.data.data[0]
                        this.currentActivity = obj
                        this.getDataActivity(obj.activityId)
                        // 处理当前的活动，展示用户看
                        if (!this.checked02) {
                            this.formObj.pkValue = this.selectConfig.pkcode
                        }
                        // else {
                        //     let pkcode = parseFloat(this.selectConfig.pkcode.slice(3))
                        //     let filterCurrent = this.pkData.filter(item => pkcode < parseFloat(item.value.slice(3)))
                        //     this.defaultPkValue = filterCurrent[0].value // 设置套餐升级默认值
                        // }
                        if (this.activityConfig.activityId) {
                            this.activityId = this.activityConfig.activityId + ''
                        } else {
                            this.activityId = ''
                        }
                        if (!this.currentActivity.activityInfos) {
                            return
                        }
                        let PK0001 = this.currentActivity.activityInfos.filter(item => {
                            return item.pkCode == this.formObj.pkValue && item.moneyCut !== 0
                        })
                        this.yearData.forEach(item => {
                            item.text = ''
                        })
                        PK0001.forEach(item => {
                            this.yearData.forEach(item2 => {
                                if (item2.value == item.yearCount) {
                                    item2.text = item.description
                                }
                            })
                        })
                    } else {
                        this.isActivity = false
                    }
                } else {
                    //  this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        getDataActivity(id) {
            let yearCount
            if (!this.checked01 && !this.checked02) {
                yearCount = 0
            } else {
                yearCount = this.formObj.yearValue
            }
            let data = {
                type: 'infoDetail',
                activityId: id,
                yearCount: yearCount,
                pkCode: this.formObj.pkValue
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.activity, { params: data }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.activityConfig = res.data.data
                } else {
                    // this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        getOrderType() {
            let type = (this.checked01 ? 'R' : '-') + ',' + (this.checked02 ? 'H' : '-') + ',' + (this.checked03 ? 'U' : '-') + ',' + (this.checked04 ? 'U' : '-')
            type = type.replace(/U,U/g, 'U')
            type = type.replace(/-,|,-/g, '')
            return type
        },
        async getPrice() {
            let pkCode
            if (!this.checked02) { // 当套餐升级取消时
                pkCode = this.selectConfig.pkcode
            } else {
                pkCode = this.formObj.pkValue
            }
            let spaceCount
            if (!this.checked04) {
                spaceCount = 0
            } else {
                spaceCount = this.formObj.spaceAdd
            }
            let menberCount
            if (!this.checked03) { // 当增加用户选择去掉时
                menberCount = 0
            } else {
                menberCount = this.formObj.memberNum
            }
            let year = 0
            if (this.checked02) { // 当套餐升级
                year = this.upgradeYear
            }
            let yearCount
            if (this.checked01) {
                yearCount = this.formObj.yearValue
            } else {
                yearCount = 0
            }
            let data = {
                orderType: this.getOrderType(),
                pkCode: pkCode,
                yearCount: yearCount,
                memberCount: menberCount,
                spaceCount: spaceCount,
                year: year,
                custId: this.item.custId
            }
            this.formObj.memberNum = data.memberCount
            this.formObj.spaceAdd = data.spaceCount
            this.formObj.yearUpgrade = data.year
            this.formObj.yearRenew = data.yearCount
            this.formObj.pkValue = data.pkCode
            if (!this.checked01 && !this.checked02 && !this.checked03 && !this.checked04) {
                // this.formObj.pkValue = this.selectConfig.pkcode
                this.$toast('请选择您需要的项目')
                this.isContinue = false
                return
            }
            await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.priceCount, { params: data }).then(res => {
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.isContinue = true
                    this.priceConfig = res.data.data
                } else {
                    // this.$toast.fail(res.data.msg)
                }
            }).catch(error => {
                console.log(error)
            })
        },
        clickStep02() {
            this.step02.isShow = false
            this.step03.isShow = true
            this.$router.push({
                path: '',
                query: {
                    step: '03'
                }
            })
        },
        showDiscounts() {
            if (this.formObj.memberNum == 3 || this.formObj.memberNum == 4) {
                // (this.formObj.yearValue == 2 && ) || (this.formObj.yearValue == 3 && this.formObj.memberNum == 3)
                this.isDiscounts = false
            } else {
                this.isDiscounts = true
            }
        },
        checkURL() {
            if (this.$route.query) {
                switch (this.$route.query.step) {
                    case '01':
                        this.step01.isShow = this.step02.isShow = this.step03.isShow = this.record.isShow = false
                        this.step01.isShow = true
                        break
                    case '02':
                        this.step01.isShow = this.step02.isShow = this.step03.isShow = this.step04.isShow = this.record.isShow = false
                        this.step02.isShow = true
                        break
                    case '03':
                        this.step01.isShow = this.step02.isShow = this.step03.isShow = this.record.isShow = false
                        this.step03.isShow = true
                        break
                    case '04':
                        this.step01.isShow = this.step02.isShow = this.step03.isShow = this.record.isShow = false
                        this.step04.isShow = true
                        break
                    case '05':
                        this.step01.isShow = this.step02.isShow = this.step03.isShow = false
                        this.record.isShow = true
                        break
                    default:
                        this.step01.isShow = this.step02.isShow = this.step03.isShow = this.record.isShow = false
                        this.$emit('close')
                }
            }
        }
    },
    watch: {
        $route(to, form) {
            if (form.query.step == '01' && to.query.step == '02') {
                this.setMenu('购买清单')
            }
            if (form.query.step == '02' && to.query.step == '01') {
                this.setMenu('选择配置')
            }
            if (form.query.step == '03' && to.query.step == '02' && to.query.step == '05') {
                this.step01.isShow = this.step02.isShow = this.step03.isShow = this.record.isShow = false
                this.$emit('close')
            }
            if (to.query.step == '02' && form.query.step == '03') {
                this.$router.push('/client/home')
            }
            this.checkURL()
        }
    },
    components: {
        step03, step04, record
    }
}
</script>

<style lang="less" scoped>
.stepperBox {
    display: flex;
    margin-top: 10px;
}
.save,
.gift {
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    border-radius: 3px;
    font-size: 12px;
    background: #d0021b;
    color: #fff;
    margin-right: 5px;
}
.gift {
    background: #ffb735;
}
.orderTopTitle {
    overflow: hidden;
    span {
        float: right;
        color: #969799;
        font-weight: normal;
    }
}
@import "./zh-cn.less";
</style>
<style lang="less">
.peopleNum .van-stepper__input[disabled] {
    color: #323233 !important;
}
</style>

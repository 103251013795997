
<template>
    <div class="screenSearchPop">
        <div class="searchSetBox">
            <template>
                <div class="searchSet">
                    <div class="title">标签</div>
                    <searchLabelsV ref="searchLabelsV0" :options="dynamicTags" @anomalyCheck="anomalyCheck" @clearFilfter="clearFilfter"></searchLabelsV>
                </div>
            </template>
            <template>
                <div class="searchSet">
                    <div class="title">批注</div>
                    <searchLabelsV ref="searchLabelsV1" :options="arrMailSign" @anomalyCheck="anomalyCheck" @clearFilfter="clearFilfter"></searchLabelsV>
                </div>
            </template>
            <template>
                <div class="searchSet">
                    <div class="title">附件</div>
                    <searchLabelsV ref="searchLabelsV2" :options="attach" @anomalyCheck="anomalyCheck"></searchLabelsV>
                </div>
            </template>
            <template>
                <div class="searchSet">
                    <div class="title">阅读</div>
                    <searchLabelsV ref="searchLabelsV3" :options="Reading" @anomalyCheck="anomalyCheck"></searchLabelsV>
                </div>
            </template>
        </div>
        <div class="footer">
            <div class="leftBut" @click="clear()">
                重置
            </div>
            <div class="rightBut" @click="submit()">
                完成
            </div>
        </div>
    </div>
</template>
<script>
import searchLabelsV from '../Vue/searchLabelsV.vue'
import tag from '../Vue/tag.vue'
export default {
    name: 'ContentItem',
    props: {
        filterType: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            searchData: [],
            dynamicTags: [
            ],
            attach: [
                {
                    itemName: '不限',
                    attach: '',
                    dictItemCode: 0
                },
                {
                    itemName: '有附件',
                    attach: true,
                    dictItemCode: 1
                },
                {
                    itemName: '无附件',
                    attach: false,
                    dictItemCode: 2
                }

            ],
            Reading: [
                {
                    itemName: '不限',
                    readFlag: '',
                    dictItemCode: 0
                },
                {
                    itemName: '已读',
                    readFlag: 1,
                    dictItemCode: 1
                },
                {
                    itemName: '未读',
                    readFlag: -1,
                    dictItemCode: 2
                }

            ],
            arrMailSign: [
                {
                    itemName: '不限',
                    value: '',
                    dictItemCode: 0
                },
                {
                    key: '1',
                    dictItemCode: 1
                },
                {
                    key: '2',
                    dictItemCode: 2
                },
                {
                    key: '3',
                    dictItemCode: 3
                },
                {
                    key: '4',
                    dictItemCode: 4
                },
                {
                    key: '5',
                    dictItemCode: 5
                },
                {
                    key: '6',
                    dictItemCode: 6
                },
                {
                    key: '7',
                    dictItemCode: 7
                },
                {
                    key: '8',
                    dictItemCode: 8
                },
                {
                    key: '9',
                    dictItemCode: 9
                },
                {
                    key: '10',
                    dictItemCode: 10
                }
            ],
            filfterObj: {
                labelName: '',
                key: '',
                attach: '',
                readFlag: ''
            }
        }
    },
    created() {
        this.maillabelsGet()
    },
    mounted() {
    },
    watch: {
    },
    computed: {
    },
    methods: {
        // 获取指定文件夹的邮件列表追加
        async maillabelsGet() {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.label_get
                let res = await this.axios.get(url, { params: { searchType: 'list', moduleCode: 'EM001' } })
                if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isArray(res.data.data)) {
                    let list = res.data.data
                    let templtateArry = [{
                        itemName: '不限',
                        labelName: '',
                        dictItemCode: 0
                    }]
                    let count = 0
                    list.forEach(element => {
                        count++
                        templtateArry.push({
                            itemName: element.labelName,
                            labelName: element.labelId,
                            dictItemCode: count
                        })
                    })
                    this.dynamicTags = templtateArry
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        },
        anomalyCheck(params) {
            if (params.hasOwnProperty('labelName')) {
                this.filfterObj.labelName = params.labelName
            } else if (params.hasOwnProperty('key')) {
                this.filfterObj.key = params.key
            } else if (params.hasOwnProperty('attach')) {
                this.filfterObj.attach = params.attach
            } else if (params.hasOwnProperty('readFlag')) {
                this.filfterObj.readFlag = params.readFlag
            }
        },
        clearFilfter() {
            this.filfterObj.labelName = ''
            this.filfterObj.key = ''
            this.filfterObj.attach = ''
            this.filfterObj.readFlag = ''
        },
        clear() {
            this.$refs.searchLabelsV0.clear()
            this.$refs.searchLabelsV1.clear()
            this.$refs.searchLabelsV2.clear()
            this.$refs.searchLabelsV3.clear()
        },
        submit() {
            this.$emit('getListData', this.filfterObj)
        }
    },
    components: {
        'searchLabelsV': searchLabelsV,
        'tag': tag
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

<template>
    <div class="addVue">
        <template v-for="(item, index) in editSet">
            <div :key="index" class="list" v-if="filterCompenent(item)" :class="{ displayHidden: item.isDisplayHidden }">
                <template v-if="item.fieldGroup == 0">
                    <template v-if="item.controlTypeId == 0 || item.controlTypeId == 14">
                        <p class="contactTitle" :key="index">
                            <span>{{ item.cnFieldCaption }}</span>
                            <span class="contactTitle-warn" v-if="item.cnFieldCaption === '最终报价'">根据新品开发原则：新品订单成交净价须在70折以上！</span>
                        </p>
                    </template>
                    <template v-else-if="
                            item.controlTypeId != 84 &&
                            item.controlTypeId != 127
                        ">
                        <div class="listBox" :class="{ noPaddingLeft: isNewFollow && moduleCode == 'BF004' && item.controlTypeId == 46 && item.fieldName == 'trackContent', listBoxV1: isNewFollow && moduleCode == 'BF004'}">
                            <component :isNewFollow="isNewFollow" :sysBoxValue="sysBoxValue" :currency="currency" :AONIcustSort="AONIcustSort" :type="type" @relyOn="relyOn" @bindContact="bindContact" @returnFieldList="returnFieldList" :cusBoxValue="cusBoxValue" :dataId="'control' + index" :nameId="item.fieldName" :optCode="optCode" :moduleCode="moduleCode" :key="index" ref="control" v-bind:is="'control' + item.controlTypeId" :itemData="item" @input="fieldChange(item.fieldName, arguments, item)" class="listBox-component" @changeLoading="changeLoading"></component>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div class="listBox" :class="{
                            noPaddingLeft:
                                isNewFollow &&
                                moduleCode == 'BF004' &&
                                item.fieldGroup == 3,
                            listBoxV1: isNewFollow && moduleCode == 'BF004',
                        }">
                        <component :isNewFollow="isNewFollow" :sysBoxValue="sysBoxValue" :optCode="optCode" :type="type" :moduleCode="moduleCode" :key="index" :dataId="'control' + index" :cusBoxValue="cusBoxValue" v-bind:is="'group' + item.fieldGroup" ref="controlGroup" :itemData="item"></component>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Controls from '@/page/Client/CustPublic/Controls/index.js'
import GroupControls from '@/page/Client/CustPublic/GroupControls/index.js'
import DatePickerTime from '@/components/PublicControls/DatePickerTime/index'
import { Decimal } from 'decimal.js'
export default {
    name: 'addVue',
    props: {
        editSet: {
            type: Array,
            default: function () {
                return []
            }
        },
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: 'otNew'
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        },
        currency: {
            type: Array,
            default: function () {
                return []
            }
        },
        grassSilk: {
            type: Array,
            default: function () {
                return []
            }
        },
        type: {
            type: String,
            default: 'otNew'
        },
        billData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            picList: [],
            fileLists: [],
            maxNum: 9,
            maxNumBase: 9,
            nextTrackDate: '', // 下次跟进时间
            nextTrackDesc: '', // 下次跟进备注
            fileType: '', // 1-图片, 2-附件
            activeImg: '1',
            activeFile: '2',
            custRepeatType: false,
            refsMap: {},
            grassSilkDataMap: new Map(),
            formData: {},
            fieldNameToIdMap: {},
            fieldFloatFixMap: {},
            allShowFieldMap: {},
            CP001List: [],
            copyType: '',
            previousRequest: null,
            // 国内工厂
            domesticFactory: ['1', '100', 1, 100]
        }
    },
    async created() {
        let { type } = this.$route.query
        this.copyType = type
        this.setFieldNameMap()
        this.getDecimalList()
        if (this.optCode === 'otNew') {
            const rate = this.currencyShow['USD'].showFlag
                ? this.currencyShow['USD'].cnyExRate
                : this.currencyShow['USD'].fixedRate
            this.$nextTick(() => {
                this.$refs['control'].forEach((el) => {
                    this.refsMap[el.$attrs.nameId] = el
                })
                this.refsMap['usdExRate'].updates(rate)
                this.refsMap['usdExRateFixed'].updates(rate)
            })
        }
        await this.$nextTick()
        /**
         * 编辑或转抄算价单时，草丝价格、底背价格、簇绒制造费用、背胶制造费用这4个价格若更新了新的价格，则重新取价并计算
         */
        if (this.optCode === 'otEdit' || this.copyType === 'otCopy') {
            this.$refs['control'].forEach((el) => {
                this.refsMap[el.$attrs.nameId] = el
            })
            this.formData = JSON.parse(JSON.stringify(this.billData))
            // 编织方式带出草坪材质下拉选项
            let options = this.sysBoxValueMap[355]?.dictItem
            let result = ''
            if (this.formData.weavingMode) {
                result = Object.entries(options).filter(e => e[1].dictItemCode == this.formData.weavingMode)[0]
            }
            this.parseResult = this.parseWeavingMode(result[1]?.itemName)
            let changeDisabledArr = []
            let { parseObj } = this.parseResult
            for (let i = 4 - parseObj.length; i > 0; i--) {
                changeDisabledArr.push('grassSilk' + (5 - i))
            }
            this.$emit('changeGrassSilkDisabled', changeDisabledArr)
            let moduleCode = 'CP006'
            let dictItemsCodes = []
            let selectOptions = {
                weavingMode: this.formData['weavingMode']
            }
            this.CP006List = await this.getBaseModuleData(moduleCode, selectOptions)
            // 带出草坪材质下拉项
            this.CP006List.forEach(el => {
                el.material && dictItemsCodes.push(Number(el.material))
            })
            dictItemsCodes = Array.from(new Set(dictItemsCodes))
            this.refsMap['lawnMaterial'].updateGCOption(dictItemsCodes)

            // 背胶方式带出幅宽、胶水类型下拉选项
            moduleCode = 'CP009'
            selectOptions = {
                gumWay: this.formData['backGlueMode']
            }
            dictItemsCodes = []
            // 带出幅宽下拉项
            this.refsMap['widthCloth'].updateGCOption(this.formData['backGlueMode'], {gumType: this.formData['backGlueMode'], factory: this.formData['factory']}, true)
            // 带出胶水下拉项
            this.CP009List = await this.getBaseModuleData(moduleCode, selectOptions)
            this.CP009List.forEach(el => {
                el.glueType && dictItemsCodes.push(Number(el.glueType))
            })
            dictItemsCodes = Array.from(new Set(dictItemsCodes))
            this.refsMap['glueType'].updateGCOption(dictItemsCodes)
            // 带出底布名称的下拉项
            const CP004List = await this.refsMap['baseClothName'].updateGCOption(this.formData['widthCloth'], {gumType: this.formData.backGlueMode, breath: this.formData['widthCloth'], factory: this.formData['factory']}, true)
            let baseClothNameItem = null
            if (CP004List && CP004List.length) {
                baseClothNameItem = CP004List.filter(el => (el.bottomclothCode + '/' + el.bottomName) === this.formData['baseClothName'])
            }

            // 编辑时将所选的草丝放入map中
            let showFieldsMap = new Map()
            this.editSet.forEach(el => {
                showFieldsMap.set(el.fieldName, el)
            })
            this.grassSilk.forEach(async el => {
                if (this.formData[el] && showFieldsMap.has(el)) {
                    let grasspriceSplitArr = this.formData[el].split('+-*/&')
                    let result = null
                    if (this.copyType === 'otCopy') {
                        result = await this.getLatestGrassPriceData(grasspriceSplitArr[1])
                        const { grasspriceId, grassSeries, dtexCode, grassColor, grassLevel, curvature, shares } = result[0]
                        const grassField = grasspriceId + '+-*/&' + grassSeries + '_' + dtexCode + '_' + grassColor + '_' + grassLevel + '_' + curvature + '_' + shares
                        this.refsMap[el].updates(grassField)
                        this.fieldChange(el, [grassField, result[0]], this.allShowFieldMap[el])
                    }
                    if (!result || result.length === 0) {
                        result = await this.getBaseModuleData('CP003', {grasspriceId: grasspriceSplitArr[0]})
                    }
                    this.grassSilkDataMap.set(el, result[0])
                }
            })
            this.updateGrassSilk(this.formData['factory'])
            this.refsMap['lineSpacing'].updateGCOption(Number(this.formData['singleMixed']), { weaveType: Number(this.formData['singleMixed'])}, true)
            if (this.copyType === 'otCopy') {
                const rate = this.currencyShow['USD'].showFlag ? this.currencyShow['USD'].cnyExRate : this.currencyShow['USD'].fixedRate
                // usdExRateFixed - 美元兑人民币汇率-固定(不可修改) - 转抄要带最新的
                this.refsMap['usdExRateFixed'].updates(rate)
                baseClothNameItem && this.fieldChange('baseClothName', [this.formData['baseClothName'], baseClothNameItem[0]], this.allShowFieldMap['baseClothName'])
                this.updateTuftingCost()
                this.setBackGlueCost()
                if (showFieldsMap.has('grassSilk1')) {
                    this.setDepletion()
                    this.setGrassCostAndFactor()
                    this.setAdivceAndGramWeight()
                }
                this.setDtexActual()
                this.updateProductModel()
                if (this.grassSilkDataMap.size > 0) {
                  this.setLawnStandard()
                }
                this.setNewCnyExRate()
                this.setTaxRebateRateAndAddRate()
            }
            this.setCostCoefficientOfCapitalOccupation()
        }
    },
    computed: {
        ...mapGetters(['greyFunctions', 'currencyShow', 'sysBoxValueMap']),
        isNewFollow() {
            if (this.greyFunctions.includes('BF004__newFollow')) {
                return true
            } else {
                return false
            }
        },
        belongToData() {
            // 分离所属字段
            let belongTo = []
            if (this.isNewFollow && this.moduleCode == 'BF004') {
                belongTo = this.editSet.filter(
                    (item) => item.hasOwnProperty('fieldGroup') &&
                        item.fieldGroup == 3
                )
                return [...belongTo]
            } else {
                return [...belongTo]
            }
        }
    },
    methods: {
        /**
         * 更新草丝变化后的建议和草丝克重
         */
        setAdivceAndGramWeight() {
            for (let item of this.grassSilkDataMap) {
                this.refsMap[item[0] + 'Adivce'].updates(item[1]?.useAdvise || '')
                this.refsMap['gramWeight' + item[0].slice(-1)].updates(this.setFloat('gramWeight' + item[0].slice(-1), item[1]?.gramWeight))
            }
        },
        /**
         * 算价单依赖单据查询 - 通过 工厂 - 草丝颜色 - 草丝等级 - 草丝系列 - DTEX编码 - 曲度 - 股数 查询一条最新的草丝价格
         * @param grassStr { string } 草丝展示的字符串
         *  factory { number } 工厂
         *  grassColor { string } 草丝颜色
         *  grassLevel { string } 草丝等级
         *  grassSeries { string } 草丝系列
         *  dtexCode { string } DTEX编码
         *  curvature { string } 曲度
         *  shares { string } 股数
         *  moduleCode { string } 模块号
         */
        async getLatestGrassPriceData(str) {
            const fieldArr = str.split('_')
            const paramsObj = {}
            const map = {
                0: 'grassSeries',
                1: 'dtexCode',
                2: 'grassColor',
                3: 'grassLevel',
                4: 'curvature',
                5: 'shares'
            }
            for (let i = 0; i < fieldArr.length; i++) {
                paramsObj[map[i]] = fieldArr[i]
            }
            const factory = this.formData['factory']

            const url = this.Global.config.apiBaseURL + this.Global.api.v2.thirdparty_calculationDependDocument
            const data = {
                factory: Number(factory),
                grassColor: paramsObj.grassColor,
                grassLevel: paramsObj.grassLevel,
                grassSeries: paramsObj.grassSeries,
                dtexCode: paramsObj.dtexCode,
                curvature: paramsObj.curvature || '',
                shares: paramsObj.shares || '',
                moduleCode: 'CP003'
            }
            const res = await this.axios.post(url, data)
            if (res.data.code.toString() === this.Global.config.RES_OK) {
                return res.data.data.list || {}
            } else {
                this.$toast.fail(res.data.msg)
            }
        },
        changeLoading(loading) {
            if (loading) {
                this.$toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true, // 禁用背景点击
                    loadingType: 'spinner',
                    message: '加载中...'
                })
            } else {
                this.$toast.clear()
            }
        },
        /**
         * 产品型号命名规则
         * 单织 => 草丝系列-草高行距编码底布编码DTEX-背胶方式
         * 混织情况比较复杂
         *          直丝                曲丝
         * + => 空格+草丝系列        空格+草丝材质+X
         * & => &+草丝系列           空格+草丝材质+X2
         * 再拼上-草高行距编码底布编码DTEX-背胶方式
         */
        async updateProductModel() {
            this.updateFormData()

            let { singleMixed, grassSilkHeight, baseClothName, backGlueMode, lineSpacing } = this.formData
            console.log('result', singleMixed, grassSilkHeight, baseClothName, backGlueMode, lineSpacing, this.grassSilkDataMap.size)
            if (!(singleMixed && grassSilkHeight && baseClothName && backGlueMode && lineSpacing && this.grassSilkDataMap.size)) {
                this.refsMap['productModel'].updates('')
                return
            }
            grassSilkHeight = isNaN(Number.parseInt(grassSilkHeight)) ? 0 : Number.parseInt(grassSilkHeight)
            let result = ''
            if (this.CP001List.length === 0) {
                this.CP001List = await this.getBaseModuleData('CP001', {lineSpacName: lineSpacing})
            }
            this.grassSilkDataMap = this.sortGrassMapBykey(this.grassSilkDataMap)
            const lineSpacCode = this.CP001List?.[0]?.lineSpacCode
            const bottomclothCode = baseClothName.split('/')[0]
            const backGlueModeName = this.sysBoxValueMap[354]?.dictItem?.[backGlueMode]?.itemName
            if (singleMixed == 1) {
                const grassSilk1Data = this.grassSilkDataMap.get('grassSilk1')
                let dtexCode = Decimal.div(grassSilk1Data.dtexCode, 100).truncated().toString()
                if (dtexCode.length < 3) {
                    for (let i = 0; i < 3 - dtexCode.length; i++) {
                        dtexCode = '0' + dtexCode
                    }
                }
                result = grassSilk1Data.grassSeries + '-' + grassSilkHeight + lineSpacCode + bottomclothCode + dtexCode + '-' + backGlueModeName
            } else {
                // grasscategory 直丝 or 曲丝
                let { parseObj, parseStack } = this.parseResult
                let grassSeries = this.grassSilkDataMap.get('grassSilk1').grassSeries
                let map = {}
                // console.log(' this.grassSilkDataMap', this.grassSilkDataMap)
                const iteator = this.grassSilkDataMap.values()
                for (let i = 0; i < parseObj.length; i++) {
                    map[parseObj[i]] = iteator.next().value
                }
                parseStack.forEach((el, index) => {
                    if (index === 0) {
                        if (this.Global.utils.isArray(el)) {
                            el.forEach((e, index) => {
                                if (index !== 0) {
                                    if (map[e]?.grasscategory === '直丝') {
                                        grassSeries += '&' + map[e].grassSeries
                                    } else if (map[e]?.grasscategory === '曲丝') {
                                        grassSeries += ' ' + map[e].grassMaterial + 'X2'
                                    }
                                }
                            })
                        }
                        return
                    }
                    if (this.Global.utils.isArray(el)) {
                        el.forEach((e, index) => {
                            if (map[e]?.grasscategory === '直丝') {
                                if (index === 0) {
                                    grassSeries += ' ' + map[e].grassSeries
                                } else {
                                    grassSeries += '&' + map[e].grassSeries
                                }
                            } else if (map[e]?.grasscategory === '曲丝') {
                                if (index === 0) {
                                    grassSeries += ' ' + map[e].grassMaterial + 'X'
                                } else {
                                    grassSeries += ' ' + map[e].grassMaterial + 'X2'
                                }
                            }
                        })
                    } else {
                        if (el === '+') return
                        if (map[el]?.grasscategory === '直丝') {
                            grassSeries += ' ' + map[el].grassSeries
                        } else if (map[el]?.grasscategory === '曲丝') {
                            grassSeries += ' ' + map[el].grassMaterial + 'X'
                        }
                    }
                })
                let dtexCode = 0
                this.grassSilkDataMap.forEach((value, key) => {
                    dtexCode = Decimal.add(value.dtexCode, dtexCode).toNumber()
                })
                dtexCode = Decimal.div(dtexCode, 100).truncated().toString()
                if (dtexCode.length < 3) {
                    for (let i = 0; i < 3 - dtexCode.length; i++) {
                        dtexCode = '0' + dtexCode
                    }
                }
                result = grassSeries + '-' + grassSilkHeight + lineSpacCode + bottomclothCode + dtexCode + '-' + backGlueModeName
            }
            console.log('result', result)
            this.refsMap['productModel'].updates(result)
        },
        sortGrassMapBykey(map) {
            const keyword = 'grassSilk'
            let keys = []
            for (let key of map.keys()) {
                key = key.slice(-1)
                keys.push(key)
            }
            keys.sort((key1, key2) => key1 - key2)
            let newMap = new Map()
            keys.forEach(key => {
                newMap.set(keyword + key, map.get(keyword + key))
            })
            return newMap
        },
        /**
         * 计算草丝用量 - （草丝高度*2+底布厚度+100/每10cm针数）*织距*每米行数*（1+草丝放耗1）*克重1/100000
         */
        updateDosage(suffix = 0) {
            let val = 0
            this.updateFormData()
            let obj = this.formData
            const changeDosageArr = ['grassSilkHeight', 'buttomThickness', 'weavingPitch', 'eachMiNumber', 'gramWeight1', 'gramWeight2', 'gramWeight3', 'gramWeight4', 'depletion1', 'depletion2', 'depletion3', 'depletion4']
            for (let i in obj) {
                if (changeDosageArr.includes(i) && (obj[i]?.toString() || '0') === '0') {
                    obj[i] = 0
                }
            }
            let { grassSilkHeight = 0, buttomThickness = 0, weavingPitch = 0, eachMiNumber = 0, gramWeight1 = 0, gramWeight2 = 0, gramWeight3 = 0, gramWeight4 = 0, depletion1 = 0, depletion2 = 0, depletion3 = 0, depletion4 = 0 } = obj
            console.log('obj', grassSilkHeight, buttomThickness, weavingPitch, eachMiNumber, gramWeight1, depletion1)
            const map = {
                '1': [gramWeight1, depletion1],
                '2': [gramWeight2, depletion2],
                '3': [gramWeight3, depletion3],
                '4': [gramWeight4, depletion4]
            }
            for (let i = 1; i <= 4; i++) {
                val = Decimal.div(Decimal.mul(Decimal.add(Decimal.mul(grassSilkHeight, 2), buttomThickness).add(Decimal.div(100, weavingPitch)), weavingPitch).mul(eachMiNumber).mul(Decimal.add(1, map[i][1])).mul(map[i][0]), 100000).toNumber()
                val = this.setFloat('dosage' + i, isNaN(val) ? 0 : val)
                this.refsMap['dosage' + i].updates(isNaN(val) ? 0 : val)
            }
            this.updateTotalSilkMeter()
            this.updateMaxDosage()
            this.updateGRASS()
        },
        /**
         * 计算GRASSA、GRASSB、GRASSC、GRASSD
         */
        updateGRASS(suffix = 0) {
            this.updateFormData()
            console.log('this.formData', this.formData)
            let map = {
                1: 'a',
                2: 'b',
                3: 'c',
                4: 'd'
            }
            if (suffix) {
                let val = Decimal.mul(Decimal.div(this.formData['silkCost' + suffix], this.formData['silkCoefficient' + suffix]), this.formData['dosage' + suffix]).toNumber()
                val = this.setFloat('grass' + map[suffix], isNaN(val) ? 0 : val)
                this.refsMap['grass' + map[suffix]].updates(isNaN(val) ? 0 : val)
            } else {
                for (let i = 1; i <= 4; i++) {
                    let val = Decimal.mul(Decimal.div(this.formData['silkCost' + i], this.formData['silkCoefficient' + i]), this.formData['dosage' + i]).toNumber()
                    val = this.setFloat('grass' + map[i], isNaN(val) ? 0 : val)
                    this.refsMap['grass' + map[i]].updates(isNaN(val) ? 0 : val)
                }
            }
        },
        /**
         * 计算草丝最大用量、最大用量草丝系数
         */
        updateMaxDosage() {
            this.updateFormData()
            let dosageArr = [this.formData.dosage1, this.formData.dosage2, this.formData.dosage3, this.formData.dosage4]
            let obj = {
                maxDosage: 0,
                maxCoefficient: 0
            }
            for (let i = 0; i < dosageArr.length; i++) {
                if (dosageArr[i] > obj.maxDosage) {
                    obj.maxDosage = dosageArr[i]
                    obj.maxCoefficient = this.formData['silkCoefficient' + (i + 1)]
                } else if (dosageArr[i] == obj.maxDosage && obj.maxCoefficient > this.formData['silkCoefficient' + (i + 1)]) {
                    obj.maxCoefficient = this.formData['silkCoefficient' + (i + 1)]
                }
            }
            let maxDosageResult = this.setFloat('maxDosage', Math.max(...dosageArr))
            let maxCoefficientResult = this.setFloat('maxCoefficient', obj.maxCoefficient)
            this.refsMap['maxDosage'].updates(maxDosageResult)
            this.refsMap['maxCoefficient'].updates(maxCoefficientResult)
            this.updateTuftingCost()
        },
        /**
         * 计算每平方草丝总用量
         */
        updateTotalSilkMeter() {
            this.updateFormData()
            let weavingModeMap = {}
            let result = 0
            if (!this.parseResult) return
            let { parseObj, plusNum } = this.parseResult
            parseObj.forEach((el, index) => {
                weavingModeMap[el] = 'dosage' + (index + 1)
            })
            parseObj.forEach(el => {
                if (this.formData[weavingModeMap[el]]) {
                    result = Decimal.add(this.formData[weavingModeMap[el]], result)
                }
            })
            result = Decimal.div(result, Decimal.add(plusNum, 1)).toNumber()
            result = this.setFloat('totalSilkMeter', result)
            this.refsMap['totalSilkMeter'].updates(result)
            this.updateTotalSilk()
            this.updateTuftingCost()
        },

        /**
         * 计算簇绒制造费用
         * 写死判断行距如为3/16，则对应“簇绒分类”为高密度草，再判断单织混织是否是单织，则对应“簇绒分类”为单织，其他则为混织
         */
        async updateTuftingCost() {
            this.updateFormData()
            let { totalSilkMeter, factory, lineSpacing, singleMixed, maxCoefficient } = this.formData
            console.log('------计算簇绒制造费用------', totalSilkMeter, factory, lineSpacing, singleMixed, maxCoefficient)
            if (!(totalSilkMeter && factory && lineSpacing && singleMixed)) return
            // tufingType - 簇绒分类 1 - 高密度草；2 - 混织；3 - 单织；
            let data = {
                tufingType: '2',
                factory,
                startWeight_lte: totalSilkMeter,
                endWeight_gte: totalSilkMeter
            }
            if (lineSpacing === '3/16') {
                data.tufingType = '1'
            } else if (singleMixed == '1') {
                data.tufingType = '3'
            }
            const config = {
                before(request) {
                    if (this.previousRequest) {
                        this.previousRequest.abort()
                    }
                    this.previousRequest = request
                }
            }
            let res = await this.getBaseModuleData('CP007', data, config)
            if (res) {
                let result = this.setFloat('tuftingCost', Decimal.div(res[0]?.taxFee || 0, maxCoefficient).toNumber())
                let tuftingCostTaxResult = this.setFloat('tuftingCostTax', res[0]?.taxFee || 0)
                result && this.refsMap['tuftingCost'].updates(result || 0)
                tuftingCostTaxResult && this.refsMap['tuftingCostTax'].updates(tuftingCostTaxResult || 0)
            }
            this.updateExFactoryPrice()
            this.updateFullCostAmt()
        },
        /**
         * 更新底背价格
         */
        updateBottomPrice() {
            this.updateFormData()
            let result = Decimal.div(this.formData.bottomCost || 0, this.formData.bottomCoefficient || 0).toNumber()
            result = this.setFloat('bottomPrice', result)
            this.refsMap['bottomPrice'].updates(result)
        },
        /**
         * 草丝总用量（OZ/SQYD）- 即转换为盎司，“每平方草丝总用量”*1000/33.85
         */
        updateTotalSilk() {
            this.updateFormData()
            let result = Decimal.mul(this.formData.totalSilkMeter, 1000).div(33.85).toNumber()
            result = this.setFloat('totalSilk', result)
            this.refsMap['totalSilk'].updates(result)
        },
        changeExFactoryPrice() {
            this.updateExFactoryPrice()
            this.updateFullCostAmt()
        },
        /**
         * 计算出厂价（RMB）(里面的字段要除以系数)
         */
        updateExFactoryPrice() {
            this.updateFormData()
            let { bottomPrice, tuftingCost, backGlueCost, grassSilkSales, lawnSales, maxCoefficient } = this.formData
            grassSilkSales = Decimal.div(grassSilkSales || 0, maxCoefficient || 0).toNumber()
            lawnSales = Decimal.div(lawnSales || 0, maxCoefficient || 0).toNumber()
            let result = this.calcExFactoryPrice(bottomPrice, tuftingCost, backGlueCost, grassSilkSales, lawnSales)
            result = this.setFloat('exFactoryPrice', result)
            this.refsMap['exFactoryPrice'].updates(result)
            this.updateCompFullCostAmt()
            this.updateExFactoryCurPrice()
        },
        /**
         * 计算出厂价（完全成本）(里面的字段不需要要除以系数)
         */
        updateFullCostAmt() {
            this.updateFormData()
            let { bottomCost, tuftingCost, maxCoefficient, backGlueCost, bottomCoefficient, grassSilkSales, lawnSales } = this.formData
            tuftingCost = Decimal.mul(tuftingCost || 0, maxCoefficient || 0).toNumber()
            backGlueCost = Decimal.mul(backGlueCost || 0, bottomCoefficient || 0).toNumber()
            let result = this.calcExFactoryPrice(bottomCost, tuftingCost, backGlueCost, grassSilkSales, lawnSales, true)
            result = this.setFloat('fullCostAmt', result)
            this.refsMap['fullCostAmt'].updates(result)
            this.updateCompFullCostAmt()
        },
        /**
         * 单纯计算 - 出厂价 / 出厂价（完全成本）
         * @param { String Number } bottomPrice 底背价格
         * @param { String Number } tuftingCost 簇绒制造费用
         * @param { String Number } backGlueCost 背胶制造费用
         * @param { String Number } grassSilkSales 草丝单位管销财
         * @param { String Number } lawnSales 草坪单位管销财
         * @param { Boolean } isFullCost 是否是完全成本
         */
        calcExFactoryPrice(bottomPrice = 0, tuftingCost = 0, backGlueCost = 0, grassSilkSales = 0, lawnSales = 0, isFullCost = false) {
            this.updateFormData()
            let { totalSilkMeter, calculateWeight, coefficient = 0 } = this.formData
            if (!this.parseResult) return
            let { parseObj, plusNum } = this.parseResult
            if (!parseObj) return
            let result = 0
            let grassPrice = 0
            if (!parseObj) return
            for (let i = 0; i < parseObj.length; i++) {
                if (isFullCost) {
                    grassPrice = Decimal.add(Decimal.mul(this.formData['silkCost' + (i + 1)] || 0, this.formData['dosage' + (i + 1)] || 0), grassPrice)
                } else {
                    grassPrice = Decimal.add(Decimal.div(Decimal.mul(this.formData['silkCost' + (i + 1)] || 0, this.formData['dosage' + (i + 1)] || 0), this.formData['silkCoefficient' + (i + 1)] || 0), grassPrice)
                }
            }
            if (calculateWeight == '1') {
                grassSilkSales = Decimal.mul(grassSilkSales, totalSilkMeter || 0)
            }
            grassPrice = Decimal.div(grassPrice, plusNum + 1)
            result = Decimal.add(grassPrice, bottomPrice || 0).add(tuftingCost || 0).add(backGlueCost || 0).add(grassSilkSales || 0).add(lawnSales || 0).toNumber()

            // console.log(`资金占用成本系数：${coefficient};原${isFullCost ? '出厂价(完全成本)' : '出厂价'}：${result}`)

            // 需求变更逻辑 - 在原公式上增加 *（1 + 资金占用成本系数）
            result = Decimal.mul(result, (1 + (coefficient * 1))).toNumber()

            // console.log(`计算资金占用成本系数后的${isFullCost ? '出厂价(完全成本)' : '出厂价'}：${result}`)
            return result
        },
        /**
         * 更新高于/低于完全成本金额 - 出厂价RMB * 折扣率 - 出厂价(完全成本)
         */
        updateCompFullCostAmt() {
            this.updateFormData()
            let { exFactoryPrice, fullCostAmt, discountRate } = this.formData
            let result = Decimal.mul(exFactoryPrice || 0, discountRate || 0).sub(fullCostAmt || 0).toNumber()
            result = this.setFloat('compFullCostAmt', result)
            this.refsMap['compFullCostAmt'].updates(result)
        },
        /**
         * 更新出厂价（报价币种）- 出厂价RMB * （1 - 退税率 / (1 + 增值税率)）/ 汇率
         */
        updateExFactoryCurPrice() {
            this.updateFormData()
            let { exFactoryPrice, taxRebateRate, addRate, newCnyExRate } = this.formData
            // (1 - 退税率 / (1 + 增值税率))
            let rate = Decimal.sub(1, Decimal.div((taxRebateRate || 0) * 0.01, 1 + (addRate || 0) * 0.01))
            let result = Decimal.mul(exFactoryPrice || 0, rate).div(newCnyExRate || 0).toNumber()
            // console.log('result', result)
            result = this.setFloat('exFactoryCurPrice', result)
            this.refsMap['exFactoryCurPrice'].updates(result)
            this.updateSalesQuotation()
        },
        /**
         * 更新销售报价（报价币种）- (出厂价(报价币种) * 折扣率 + 内陆运费 + 海运费 + 其他外购 + 人工费 + 其他费用) * (1 + 信保费比例 + 放账费用比例)
         */
        updateSalesQuotation() {
            this.updateFormData()
            let { exFactoryCurPrice, discountRate, inlandFreight, seaFreight, outsourcing, labor, otherAmt, corporationRatio, accountRatio, usdExRate, newCnyExRate } = this.formData
            discountRate = discountRate || 0
            corporationRatio = (corporationRatio || 0) * 0.01
            accountRatio = (accountRatio || 0) * 0.01
            seaFreight = Decimal.mul(seaFreight || 0, usdExRate || 0).div(newCnyExRate || 0)
            let result = Decimal.add(Decimal.mul(exFactoryCurPrice || 0, discountRate), inlandFreight || 0).add(seaFreight).add(outsourcing || 0).add(labor || 0).add(otherAmt || 0)
            result = Decimal.mul(result, Decimal.add(1, corporationRatio).add(accountRatio)).toNumber()
            // console.log('result', result)
            result = this.setFloat('salesQuotation', result)
            this.refsMap['salesQuotation'].updates(result)
            this.updateSalesQuotationUsd()
        },
        /**
         * 更新销售报价（USD/SQFT）- 销售报价（报价币种）* 0.0929
         */
        updateSalesQuotationUsd() {
            this.updateFormData()
            let { salesQuotation } = this.formData
            let result = Decimal.mul(salesQuotation || 0, 0.0929).toNumber()
            // console.log('result - updateSalesQuotationUsd', result)
            result = this.setFloat('salesQuotationUsd', result)
            this.refsMap['salesQuotationUsd'].updates(result)
        },
        /**
         * 转换小数位
         */
        setFloat(fieldName, val) {
            let controlTypeId = this.fieldNameToIdMap[fieldName]
            if (controlTypeId) {
                let digitsValue
                if (controlTypeId?.toString() && (digitsValue = this.fieldFloatFixMap[controlTypeId])) {
                    digitsValue = this.getFieldDigits(digitsValue)
                    val = this.floatTransfer(val || 0, digitsValue)
                }
            }
            return val
        },
        getFieldDigits(showFormat) {
            let result
            if (showFormat !== void 0 && showFormat !== '') {
                const arr = showFormat?.split('.')
                if (arr.length === 1 && arr[0] === '0') {
                    result = 0
                } else {
                    result = arr[1]?.length
                }
            }
            return result
        },
        floatTransfer(val, digitsValue) {
            return new Decimal(val).toFixed(digitsValue)
        },
        // 获取小数位列表
        async _getDecimalList() {
            let result = []
            try {
                let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.controlTypeV2)
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    result = this.Global.utils.isArray(res.data.data) ? res.data.data : []
                }
            } catch (err) {
                console.warn(err)
            }
            return result
        },
        async getDecimalList() {
            let dacimalList = this.allShowFieldMap
            let dacimalMap = {}
            for (let key in dacimalList) {
                dacimalMap[dacimalList[key].controlTypeId] = dacimalList[key].showFormat
            }
            dacimalList = await this._getDecimalList()
            if (dacimalList.length > 0) {
                dacimalList.forEach(ele => {
                    dacimalMap[ele.controlTypeId] = ele.showFormat
                })
            }
            this.fieldFloatFixMap = Object.freeze(dacimalMap)
        },
        /**
         * 处理字段名与controlTypeId、item的map
         */
        setFieldNameMap() {
            let map = {}
            let nameMap = {}
            this.editSet.forEach(el => {
                map[el.fieldName] = el.controlTypeId
                nameMap[el.fieldName] = el
            })
            this.fieldNameToIdMap = map
            this.allShowFieldMap = nameMap
        },
        async updateCP005List() {
            const lineSpacing = this.refsMap['lineSpacing'].submit().lineSpacing
            const singleMixed = this.refsMap['singleMixed'].submit().singleMixed
            const dtexActual = this.refsMap['dtexActual'].submit().dtexActual
            if (lineSpacing && singleMixed && Number(dtexActual)) {
                let moduleCode = 'CP005'
                let selectOptions = {
                    rowSpacing: lineSpacing,
                    weaveType: singleMixed,
                    dtexBottom_lte: dtexActual,
                    dtexTop_gte: dtexActual
                }
                this.CP005List = await this.getBaseModuleData(
                    moduleCode,
                    selectOptions
                )
                if (this.CP005List.length > 0) {
                    this.refsMap['dtexAdvice'].updates(
                        this.CP005List[0].dtexSuggest
                    )
                    this.refsMap['lowerLimit'].updates(
                        this.CP005List[0].heightBottom
                    )
                    this.refsMap['upperLimit'].updates(
                        this.CP005List[0].heightTop
                    )
                    this.refsMap['upperWeavingLimit'].updates(
                        this.CP005List[0].weaveTop
                    )
                    this.refsMap['lowerWeavingLimit'].updates(
                        this.CP005List[0].weaveBottom
                    )
                } else {
                    this.$toast.fail('没有匹配到对应草丝极限')
                    this.refsMap['dtexAdvice'].updates('')
                    this.refsMap['lowerLimit'].updates('')
                    this.refsMap['upperLimit'].updates('')
                    this.refsMap['upperWeavingLimit'].updates('')
                    this.refsMap['lowerWeavingLimit'].updates('')
                }
            }
        },
        /**
         * 带出密度
         */
        updateDensity() {
            const eachMiNumber =
                this.refsMap['eachMiNumber'].submit().eachMiNumber
            const eachMiPin = this.refsMap['eachMiPin'].submit().eachMiPin
            this.refsMap['density'].updates(
                this.setFloat('density', Decimal.mul(eachMiNumber, eachMiPin).toNumber())
            )
        },
        async updateGrassSilk(val) {
            for (let suffix = 1; suffix <= 4; suffix++) {
                this.refsMap['grassSilk' + suffix].updateGCOption(val, {
                    factory: val
                })
            }
            // 带出底背价格表中的背胶方式
            if (this.$refs.field_backGlueMode) {
                let dictItemsCodes = []
                let CP004List = await this.getBaseModuleData('CP004', {factory: val})
                CP004List.forEach(el => {
                    if (!(el.aloneAdd * 1) || !(el.blendAdd * 1)) return
                    el.gumType && dictItemsCodes.push(Number(el.gumType))
                })
                dictItemsCodes = Array.from(new Set(dictItemsCodes))
                this.refsMap[backGlueMode].updateGCOption(dictItemsCodes)
            }
        },
        /**
         * 更新编织方式时清空数据
         */
        setNull() {
            console.log('this.refsMap', this.refsMap)
            // 清空 所有草丝、使用建议、草丝用量、克重、草丝放耗、草丝成本、草丝系数
            for (let suffix = 1; suffix <= 4; suffix++) {
                this.refsMap['grassSilk' + suffix].updates('')
                this.refsMap['grassSilk' + suffix + 'Advice'].updates('')
                this.refsMap['dosage' + suffix].updates(0)
                this.refsMap['gramWeight' + suffix].updates(0)
                this.refsMap['depletion' + suffix].updates(0)
                this.refsMap['silkCost' + suffix].updates(0)
                this.refsMap['silkCoefficient' + suffix].updates(0)
            }
            this.refsMap['dtexActual'].updates(0)
            this.refsMap['grassa'].updates(0)
            this.refsMap['grassb'].updates(0)
            this.refsMap['grassc'].updates(0)
            this.refsMap['grassd'].updates(0)
            this.refsMap['tuftingCostTax'].updates(0)
            this.refsMap['tuftingCost'].updates(0)
            this.refsMap['lawnStandard'].updates(0)
        },
        /**
         * 更新草丝成本 - grassCost、草丝系数 - markFactor
         */
        setGrassCostAndFactor() {
            if (this.grassSilkDataMap.size === 0) return
            this.grassSilk.forEach((el) => {
                let num = el.slice(-1)
                if (this.grassSilkDataMap.has(el)) {
                    const silkCostItem = 'silkCost' + num
                    const silkCoefficientItem = 'silkCoefficient' + num
                    const silkCostItemCost = this.setFloat(silkCostItem, this.grassSilkDataMap.get(el)?.grassCost)
                    const silkCoefficientItemCost = this.setFloat(silkCoefficientItem, this.grassSilkDataMap.get(el)?.markFactor)
                    this.refsMap[silkCostItem].updates(silkCostItemCost)
                    this.refsMap[silkCoefficientItem].updates(silkCoefficientItemCost)
                } else {
                    this.refsMap['silkCost' + num].updates(0)
                    this.refsMap['silkCoefficient' + num].updates(0)
                }
            })
            this.changeExFactoryPrice()
        },
        /**
         * 更新背胶制造费用
         */
        async setBackGlueCost() {
            let factory = this.refsMap['factory'].submit(true).factory
            let glueType = this.refsMap['glueType'].submit(true).glueType
            let widthCloth = this.refsMap['widthCloth'].submit(true).widthCloth
            let bottomCoefficient = this.refsMap['bottomCoefficient'].submit(true).bottomCoefficient
            if (!(factory && glueType && widthCloth && bottomCoefficient)) {
                this.refsMap['backGlueCost'].updates(0)
                return
            }
            let config = {
                factory,
                glueType,
                initialWidth_lte: widthCloth,
                endWidth_gte: widthCloth
            }
            let res = await this.getBaseModuleData('CP008', config)
            if (res) {
                const backGlueCost = this.setFloat('backGlueCost', Decimal.div(res[0]?.makeCostsTax || 0, bottomCoefficient).toNumber() || 0)
                const backglueCostTax = this.setFloat('backglueCostTax', res[0]?.makeCostsTax || 0)
                this.refsMap['backGlueCost'].updates(backGlueCost)
                this.refsMap['backglueCostTax'].updates(backglueCostTax)
            }
        },
        /**
         * 更新资金占用成本系数 - coefficient
         */
        async setCostCoefficientOfCapitalOccupation() {
            let factory = this.refsMap['factory'].submit(true).factory
            if (!factory) {
                this.refsMap['coefficient'].updates(0)
                return
            }
            let config = {
                factory
            }
            let res = await this.getBaseModuleData('CP013', config)
            if (res) {
                this.refsMap['coefficient'].updates(res[0]?.coefficient || 0)
            }
        },
        /**
         * 更新formData
         */
        updateFormData() {
            let data = {}
            if (this.$refs['control']) {
                this.$refs['control'].forEach((item) => {
                    let itemData = item?.submit(true)
                    if (itemData) {
                        data = Object.assign(data, itemData)
                    }
                })
                this.formData = data
            }
        },
        /**
         * 更新底背成本、底背系数
         */
        async setBottomCost(currentData) {
            this.updateFormData()
            if (
                !currentData &&
                this.formData.backGlueMode &&
                this.formData.widthCloth &&
                this.formData.baseClothName
            ) {
                // 选择背胶方式 + 幅宽 + 底布名称确定的底背价格表中的单据
                currentData = await this.getBaseModuleData('CP004', {
                    gumType: this.formData.backGlueMode,
                    breath: this.formData.widthCloth,
                    bottomName: this.formData.baseClothName.split('/')?.[1],
                    factory: this.formData.factory
                })
                let currentDataTemp = currentData
                try {
                    currentDataTemp && currentDataTemp.forEach(e => {
                        if (!(e.aloneAdd * 1) || !(e.blendAdd * 1)) return
                        currentData = e
                        throw new Error('breakForeach')
                    })
                } catch (e) {
                    console.log(e)
                }
            }
            const map = {
                1: ['aloneWeave', 'aloneAdd'],
                2: ['blendWeave', 'blendAdd']
            }
            if (this.formData.singleMixed && currentData) {
                const bottomCost = this.setFloat('bottomCost', currentData?.[map[this.formData.singleMixed][0]])
                const bottomCoefficient = this.setFloat('bottomCoefficient', currentData?.[map[this.formData.singleMixed][1]])
                this.refsMap['bottomCost'].updates(bottomCost)
                this.refsMap['bottomCoefficient'].updates(bottomCoefficient)
            } else {
                this.refsMap['bottomCost'].updates(0)
                this.refsMap['bottomCoefficient'].updates(0)
            }
            this.updateBottomPrice()
            this.setBackGlueCost()
        },
        /**
         * 带出草丝放耗
         */
        setDepletion(val = '') {
            if (this.grassSilkDataMap.size === 0) return
            console.log('this.grassSilkDataMap', this.grassSilkDataMap)
            // console.log('this.formData', this.formData)
            let depletionvValue =
                val || this.refsMap['needleFeedMode'].submit().needleFeedMode
            let needleFeedModeMap = {
                1: 'worddrain',
                2: 'zwordDrain'
            }
            this.grassSilk.forEach((el) => {
                let num = el.slice(-1)
                if (this.grassSilkDataMap.has(el)) {
                    const depletionLabel = 'depletion' + num
                    const depletionValue = this.setFloat(depletionLabel, this.grassSilkDataMap.get(el)?.[needleFeedModeMap[depletionvValue]])
                    this.refsMap[depletionLabel].updates(depletionValue)
                } else {
                    this.refsMap['depletion' + num].updates('')
                }
            })
        },
        /**
         * 带出DTEX实际值
         */
        setDtexActual() {
            if (this.grassSilkDataMap.size === 0) return
            let changeDisabledArr = []
            let allExis = true
            if (!this.parseResult) return
            let { parseObj } = this.parseResult
            for (let i = 1; i <= parseObj.length; i++) {
                changeDisabledArr.push('grassSilk' + i)
            }
            changeDisabledArr.forEach((el) => {
                if (!this.grassSilkDataMap.get(el)) {
                    allExis = false
                }
            })
            if (allExis) {
                const result = this.calcDtexActual()
                this.refsMap['dtexActual'].updates(result)
            } else {
                this.refsMap['dtexActual'].updates(0)
            }
            this.updateCP005List()
        },
        calcDtexActual() {
            if (!this.parseResult) return
            let { parseObj, parseStack, plusNum } = this.parseResult
            let result = []
            let arr = parseStack
            if (plusNum > 0) {
                arr = arr.slice(0, parseStack.length - 1)
            }
            this.weavingModeMap = {}
            parseObj.forEach((el, index) => {
                this.weavingModeMap[el] = 'grassSilk' + (index + 1)
            })
            arr.forEach((el) => {
                if (this.Global.utils.isArray(el)) {
                    let subRes = 0
                    let subArr = el.slice(0, el.length - 1)
                    subArr.forEach((e) => {
                        subRes += Number(
                            this.grassSilkDataMap.get(this.weavingModeMap[e])
                                .dtexCode
                        )
                    })
                    result.push(subRes)
                } else {
                    result.push(
                        this.grassSilkDataMap.get(this.weavingModeMap[el])
                            .dtexCode
                    )
                }
            })
            return Math.max(...result)
        },
        /**
         * 查询基础表
         * @param {String} moduleCode 模块号
         * @param {Object} selectOptions 筛选条件对象
         */
        async getBaseModuleData(moduleCode = '', selectOptions = {}, config = {}) {
            let data = {
                from: 0,
                moduleCode: moduleCode,
                order: 'desc',
                searchType: 'list',
                size: 9999,
                sort: 'modifyDate',
                withRight: false
            }
            data = { ...data, ...selectOptions }
            const url =
                this.Global.config.apiBaseURL +
                this.Global.api.v2.document_newStructSearch_do
            let options = []
            try {
                let res = await this.axios.post(url, data, config)
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    options = res.data.data.list || {}
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (err) {
                console.log(err)
            }
            return options
        },
        /**
         * 解析编织方式
         * @param { String } 编织方式
         * @returns { Object }
         *  parseObj - 解析后的草丝对象 eg: A&B+C => ['A', 'B', 'C']
         *  parseStack - 解析后的公式 eg: A&B+C => [['A', 'B', '&'], 'C', '+']
         *  plusNum - +的数量
         *  andNum - &的数量
         */
        parseWeavingMode(weavingMode) {
            // parseObj - 解析后的草丝对象 eg: A&B+C => ['A', 'B', 'C']
            // parseStack - 解析后的公式 eg: A&B+C => [['A', 'B', '&'], 'C', '+']
            // plusNum - +的数量
            // andNum - &的数量
            let parseObj = []
            let parseStack = []
            let plusNum = 0
            let andNum = 0
            const parseStr = ['+', '&']

            for (let item of weavingMode) {
                if (item === parseStr[0]) {
                    plusNum++
                } else if (item === parseStr[1]) {
                    andNum++
                }
            }
            let firstParseObj = weavingMode.split(parseStr[0])
            firstParseObj.forEach((el) => {
                el = el.split(parseStr[1])
                parseObj.push(...el)
                // 如果el的长度大于1，说明一定有&参与
                if (el.length > 1) {
                    el.push(parseStr[1])
                    parseStack.push(el)
                } else {
                    parseStack.push(...el)
                }
            })
            // 如果parseStack的长度大于1，说明一定有+参与
            if (parseStack.length > 1) {
                parseStack.push(parseStr[0])
            }
            return {
                parseObj,
                parseStack,
                plusNum,
                andNum
            }
        },
        async fieldChange(fieldName, data, item) {
            console.log('----------', fieldName, data, item)
            let val = typeof data[0] == 'object' ? data[0].value : data[0]
            let currentData = data[1]
            let options = []
            // 所查基础表模块号
            let moduleCode = ''
            // 所查基础表筛选条件
            let selectOptions = {}
            let dictItemsCodes = []
            if (this.moduleCode === 'CP010') {
                switch (fieldName) {
                    case 'weavingMode':
                        this.refsMap['singleMixed'].updates('')
                        this.refsMap['lawnMaterial'].updates('')
                        this.refsMap['lineSpacing'].updates('')
                        this.refsMap['lineSpacing'].updateGCOption()
                        this.grassSilkDataMap = new Map()
                        if (!val) {
                            // 清空 草坪材质的值以及选项、单织or混织
                            this.refsMap['bottomCost'].updates('')
                            this.refsMap['bottomCoefficient'].updates('')
                            this.refsMap['lawnMaterial'].updateGCOption([])
                            this.parseResult = {}
                            this.setNull()
                            break
                        }
                        options = this.sysBoxValueMap[item.dictCode]?.dictItem
                        const result = Object.entries(options).filter(
                            (e) => e[1].dictItemCode == val
                        )[0]
                        this.parseResult = this.parseWeavingMode(
                            result[1].itemName
                        )
                        // this.$emit('changeParseResult', this.parseResult)
                        console.log('this.parseResult', this.parseResult)
                        this.setNull()
                        // 清空 草坪材质的值以及选项、单织or混织
                        this.refsMap['bottomCost'].updates('')
                        this.refsMap['bottomCoefficient'].updates('')

                        moduleCode = 'CP006'
                        selectOptions = {
                            weavingMode: val
                        }
                        this.CP006List = await this.getBaseModuleData(
                            moduleCode,
                            selectOptions
                        )
                        // 带出草坪材质下拉项
                        this.CP006List.forEach((el) => {
                            el.material &&
                                dictItemsCodes.push(Number(el.material))
                        })
                        dictItemsCodes = Array.from(new Set(dictItemsCodes))
                        this.refsMap['lawnMaterial'].updateGCOption(
                            dictItemsCodes
                        )

                        let changeDisabledArr = []
                        let { parseObj } = this.parseResult
                        for (let i = 4 - parseObj.length; i > 0; i--) {
                            changeDisabledArr.push('grassSilk' + (5 - i))
                        }
                        this.$emit('changeGrassSilkDisabled', changeDisabledArr)
                        break
                    case 'lawnMaterial':
                        if (!val) {
                            // 清空 草坪材质的值以及选项、单织or混织
                            this.refsMap['bottomCost'].updates('')
                            this.refsMap['singleMixed'].updates('')
                            this.refsMap['bottomCoefficient'].updates('')
                            this.refsMap['lineSpacing'].updates('')
                            this.refsMap['lineSpacing'].updateGCOption()
                            break
                        }
                        let weavingModeVal =
                            this.refsMap['weavingMode'].submit(true).weavingMode
                        // 带出单织or混织
                        try {
                            this.CP006List.forEach((el) => {
                                if (
                                    el.weavingMode == weavingModeVal &&
                                    el.material == val &&
                                    el.singleMixed
                                ) {
                                    this.refsMap['singleMixed'].updates(
                                        Number(el.singleMixed)
                                    )
                                    this.updateCP005List()
                                    this.refsMap['lineSpacing'].updateGCOption(Number(el.singleMixed), { weaveType: Number(el.singleMixed)}, true)
                                    this.setBottomCost()
                                    this.updateProductModel()
                                    throw new Error('EndIterative')
                                }
                            })
                        } catch (e) {
                            console.error('e', e)
                        }
                        break
                    case 'grassSilk1':
                    case 'grassSilk2':
                    case 'grassSilk3':
                    case 'grassSilk4':
                        // 改变草丝会改变 草丝使用建议、草丝放耗、实际DTEX值、草丝成本、草丝系数
                        let grassSilkAdvice = fieldName + 'Advice'
                        let suffix = fieldName.slice(-1)
                        if (!val) {
                            this.refsMap[grassSilkAdvice].updates('')
                            this.refsMap['gramWeight' + suffix].updates(0)
                            this.grassSilkDataMap.set(fieldName, currentData)
                            this.setDepletion()
                            this.setGrassCostAndFactor()
                            this.refsMap['dtexActual'].updates('')
                            this.setLawnStandard()
                            break
                        }
                        if (currentData) {
                            this.grassSilkDataMap.set(fieldName, currentData)
                            this.refsMap[grassSilkAdvice].updates(
                                currentData?.useAdvise
                            )
                            this.refsMap['gramWeight' + suffix].updates(this.setFloat('gramWeight' + suffix,
                                currentData?.gramWeight
                            ))
                        } else {
                            this.refsMap[grassSilkAdvice].updates('')
                            this.refsMap['gramWeight' + suffix].updates(0)
                        }
                        this.setDepletion()
                        this.setGrassCostAndFactor()
                        this.setDtexActual()
                        this.updateProductModel()
                        this.updateDosage()
                        this.setLawnStandard()
                        break
                    case 'needleFeedMode':
                        // 改变走针方式带出各个草丝放耗
                        if (!val) {
                            this.setDepletion()
                            break
                        }
                        this.setDepletion(val)
                        break
                    case 'lineSpacing':
                        // 改变行距带出每米行数
                        if (!val) {
                            this.refsMap['eachMiNumber'].updates(0)
                            break
                        }
                        this.updateCP005List()
                        this.CP001List = await this.getBaseModuleData('CP001', {lineSpacName: val})
                        this.refsMap['eachMiNumber'].updates(
                            this.CP001List[0]?.rowperMeter
                        )
                        this.updateDensity()
                        this.updateDosage()
                        this.updateProductModel()
                        break
                    // 织距
                    case 'weavingPitch':
                        this.setGrassCostAndFactor()
                        // 织距 * 10 带出每米针数
                        this.refsMap['eachMiPin'].updates(
                            Decimal.mul(val, 10).toNumber()
                        )
                        this.updateDosage()
                        this.updateDensity()
                        break
                    // 草丝高度
                    case 'grassSilkHeight':
                        this.setGrassCostAndFactor()
                        this.updateProductModel()
                        this.updateDosage()
                        break
                    // 每米行数、每米针数
                    case 'eachMiNumber':
                    case 'eachMiPin':
                        this.updateDensity()
                        this.updateDosage()
                        break
                    // 背胶方式
                    case 'backGlueMode':
                        this.refsMap['widthCloth'].updates('')
                        this.refsMap['baseClothName'].updates('')
                        this.refsMap['glueType'].updates('')
                        // 清空底布厚度
                        this.refsMap['buttomThickness'].updates('')
                        if (!val) {
                            // 清空 幅宽、底部名称、胶水类型的下拉项和值
                            this.refsMap['widthCloth'].updateGCOption()
                            this.refsMap['baseClothName'].updateGCOption()
                            this.refsMap['glueType'].updateGCOption([])
                            break
                        }
                        moduleCode = 'CP009'
                        selectOptions = {
                            gumWay: val
                        }
                        // 带出幅宽下拉项
                        this.refsMap['widthCloth'].updateGCOption(val, {
                            gumType: val,
                            factory: this.refsMap['factory'].submit().factory
                        }, true)
                        // 带出胶水下拉项
                        this.CP009List = await this.getBaseModuleData(
                            moduleCode,
                            selectOptions
                        )
                        this.CP009List.forEach((el) => {
                            el.glueType &&
                                dictItemsCodes.push(Number(el.glueType))
                        })
                        dictItemsCodes = Array.from(new Set(dictItemsCodes))
                        this.refsMap['glueType'].updateGCOption(dictItemsCodes)
                        console.log('dictItemsCodes', JSON.stringify(dictItemsCodes))
                        this.refsMap['glueType'].updates(dictItemsCodes?.[0])
                        this.setBackGlueCost()
                        this.updateProductModel()
                        break
                    // 幅宽
                    case 'widthCloth':
                        // 清空底布厚度
                        this.refsMap['baseClothName'].updates('')
                        this.refsMap['buttomThickness'].updates(0)
                        if (!val) {
                            // 清空 底布名称的下拉项和值
                            this.refsMap['baseClothName'].updateGCOption()
                            break
                        }
                        this.setBackGlueCost()
                        // 带出底布名称的下拉项
                        this.refsMap['baseClothName'].updateGCOption(val, {
                            gumType:
                                this.refsMap['backGlueMode'].submit()
                                    .backGlueMode,
                            breath: val,
                            factory: this.refsMap['factory'].submit().factory
                        }, true)
                        break
                    // 底布名称
                    case 'baseClothName':
                        if (!val) {
                            // 清空底布厚度
                            this.refsMap['buttomThickness'].updates(0)
                            break
                        }
                        this.setBottomCost(currentData)
                        moduleCode = 'CP002'
                        selectOptions = {
                            'buttomclothName.keyword': val.split('/')?.[1]
                        }
                        // 带出底布基础表
                        let CP002List = await this.getBaseModuleData(
                            moduleCode,
                            selectOptions
                        )
                        // 带出底布厚度
                        this.refsMap['buttomThickness'].updates(
                            CP002List[0]?.buttomThickness
                        )
                        this.updateDosage()
                        this.updateProductModel()
                        break
                    // 放账天数
                    case 'releaseDays':
                        if (!val) {
                            // 清空信保费比例、放账费用比例
                            this.refsMap['corporationRatio'].updates(0)
                            this.refsMap['accountRatio'].updates(0)
                            break
                        }
                        if (currentData) {
                            // 带出信保费比例、放账费用比例
                            this.refsMap['corporationRatio'].updates(
                                currentData.corporationRatio
                            )
                            this.refsMap['accountRatio'].updates(
                                currentData.accountRatio
                            )
                        }
                        break
                    case 'glueType':
                        this.setBackGlueCost()
                        break
                    case 'factory':
                        this.setNull()
                        this.updateGrassSilk(val)
                        this.setBackGlueCost()
                        this.setNewCnyExRate()
                        this.setTaxRebateRateAndAddRate()
                        this.setCostCoefficientOfCapitalOccupation()
                        // 清空背胶方式、幅宽、底布名称、胶水类型
                        this.refsMap['backGlueMode'].updates('')
                        this.refsMap['widthCloth'].updates('')
                        this.refsMap['baseClothName'].updates('')
                        this.refsMap['glueType'].updates('')
                        // 清空底布厚度
                        this.refsMap['buttomThickness'].updates('')
                        break
                    case 'quotationCur':
                        this.setNewCnyExRate()
                        break
                    case 'cnyExRate':
                        this.setNewCnyExRate()
                        break
                    // 是否报关
                    case 'declareType':
                        this.setTaxRebateRateAndAddRate()
                        this.updateExFactoryCurPrice()
                    break
                }
                // const changeDosageBaseArr = ['grassSilkHeight', 'buttomThickness', 'weavingPitch', 'eachMiNumber']
                // const changeDosageArr = ['gramWeight1', 'gramWeight2', 'gramWeight3', 'gramWeight4', 'depletion1', 'depletion2', 'depletion3', 'depletion4']
                // if (changeDosageBaseArr.includes(fieldName)) {
                //     this.updateDosage()
                // }
                // if (changeDosageArr.includes(fieldName)) {
                //     let suffix = fieldName.slice(-1)
                //     this.updateDosage(suffix)
                // }
                if (['silkCost1', 'silkCost2', 'silkCost3', 'silkCost4', 'silkCoefficient1', 'silkCoefficient2', 'silkCoefficient3', 'silkCoefficient4'].includes(fieldName)) {
                    let suffix = fieldName.slice(-1)
                    this.updateGRASS(suffix)
                    this.updateExFactoryPrice()
                }
                if (['bottomCost', 'bottomCoefficient'].includes(fieldName)) {
                    this.updateBottomPrice()
                }
                // 判断工厂、行距、编织方式变化,更新簇绒制造费用
                if (['factory', 'lineSpacing', 'singleMixed'].includes(fieldName)) {
                    if ((fieldName === 'lineSpacing') || (fieldName === 'singleMixed') || fieldName === 'factory') {
                        this.updateTuftingCost()
                    }
                }
                // 判断底背价格、背胶制造费用、草丝成本、草丝系数、资金占用成本系数字段，更新出厂价
                if (['bottomPrice', 'backGlueCost', 'coefficient'].includes(fieldName)) {
                    this.updateExFactoryPrice()
                }
                // 判断底背成本、背胶制造费用、草丝成本、资金占用成本系数字段，更新出厂价（完全成本）
                if (['bottomCost', 'backGlueCost', 'silkCost1', 'silkCost2', 'silkCost3', 'silkCost4', 'coefficient'].includes(fieldName)) {
                    this.updateFullCostAmt()
                }
                // 判断折扣率计算高于/低于完全成本金额
                if (fieldName === 'discountRate') {
                    this.updateCompFullCostAmt()
                }
                // 判断退税率、增值税率、汇率（本币币种）字段，更新出厂价（报价币种）
                if (['taxRebateRate', 'addRate', 'quotationCur', 'newCnyExRate'].includes(fieldName)) {
                    this.updateExFactoryCurPrice()
                }
                // 判断折扣率 + 内陆运费 + 海运费 + 其他外购 + 人工费 + 其他费用 + 放账天数 + 汇率（本币币种）字段，更新销售价格（报价币种）
                if (['discountRate', 'inlandFreight', 'seaFreight', 'outsourcing', 'labor', 'otherAmt', 'releaseDays', 'usdExRate', 'quotationCur', 'newCnyExRate'].includes(fieldName)) {
                    this.updateSalesQuotation()
                }
            }
        },
        /**
         * 更改是否报关和工厂时，更新退税率和增值税率
         */
        async setTaxRebateRateAndAddRate() {
          this.updateFormData()
          await this.$nextTick()
          const { declareType, factory } = this.formData
          if (!this.domesticFactory.includes(factory)) {
            this.refsMap['taxRebateRate'].updates(0)
            this.refsMap['addRate'].updates(0)
            return
          }
          if (declareType == '2') {
              this.refsMap['taxRebateRate'].updates(0)
              this.refsMap['addRate'].updates(0)
          } else {
              const taxRebateRate = this.allShowFieldMap['taxRebateRate']
              const addRate = this.allShowFieldMap['addRate']
              this.refsMap['taxRebateRate'].updates(taxRebateRate?.inDefaultValue)
              this.refsMap['addRate'].updates(addRate?.inDefaultValue)
          }
        },

        /**
         * 更新本币汇率和工厂时，更新本币汇率-新
         */
        async setNewCnyExRate() {
          this.updateFormData()
          await this.$nextTick()
          const { cnyExRate = 0, usdExRateFixed = 0, factory } = this.formData
          if (this.domesticFactory.includes(factory)) {
            this.refsMap['newCnyExRate'].updates(cnyExRate)
          } else {
            this.refsMap['newCnyExRate'].updates(this.setFloat('newCnyExRate', Decimal.div(cnyExRate || 0, usdExRateFixed || 0).toNumber() || 0))
          }
        },
        /**
         * 更新草丝时，判断草丝的标准类型带出草坪标准分类
         */
        setLawnStandard() {
            let flag = false
            if (this.grassSilkDataMap.size === 0) {
                this.refsMap['lawnStandard'].updates('')
                return
            }
            for (let item of this.grassSilkDataMap) {
                if (item[1] === void 0) continue
                // 判断草丝的标准类型是否为标准款
                if (item[1].standardType && item[1].standardType == '1') {
                    flag = true
                    break
                }
            }
            // 1是基础款 2是常规款 - 当草丝的标准类型为标准款时则赋值基础款，其他则为常规款
            this.refsMap['lawnStandard'].updates(flag ? '1' : '2')
            this.updateSales()
        },
        /**
         * 根据草坪标准分类带出草坪单位管销财模块中的草丝单位管销财、草坪单位管销财、是否计算重量
         */
        async updateSales() {
            this.updateFormData()
            if (!this.formData.lawnStandard) {
                this.refsMap['grassSilkSales'].updates(0)
                this.refsMap['lawnSales'].updates(0)
                this.refsMap['calculateWeight'].updates('')
                this.changeExFactoryPrice()
                return
            }
            const res = await this.getBaseModuleData('CP012', {
                sort: 'createDate',
                lawnStandard: this.formData.lawnStandard,
                factory: this.formData.factory
            })
            if (res.length && res[0]) {
                this.refsMap['grassSilkSales'].updates(res[0].grassSilkSales || 0)
                this.refsMap['lawnSales'].updates(res[0].lawnSales || 0)
                this.refsMap['calculateWeight'].updates(res[0].calculateWeight || '')
                this.changeExFactoryPrice()
            } else {
                this.refsMap['grassSilkSales'].updates(0)
                this.refsMap['lawnSales'].updates(0)
                this.refsMap['calculateWeight'].updates('')
                this.changeExFactoryPrice()
            }
        },
        async getTime() {
            this.$refs.datePickerTime.getTime((res) => {
                this.nextTrackDate = res
            })
        },
        filterCompenent(item) {
            // 语音输入:104-voiceUrl, 语音日期:1-voiceDate, 语音时间长度:1-voiceSecond, 图片:15, 附件:16,
            // 下次跟进时间:nextTrackDate, 下次跟进备注:nextTrackDesc
            if (this.isNewFollow && this.moduleCode == 'BF004') {
                if (
                    item.controlTypeId == 104 ||
                    item.fieldName == 'voiceDate' ||
                    item.fieldName == 'voiceSecond' ||
                    item.controlTypeId == 15 ||
                    item.controlTypeId == 16 ||
                    item.fieldName == 'nextTrackDate' ||
                    item.fieldName == 'nextTrackDesc' ||
                    item.fieldGroup == 3
                ) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        },
        relyOn(value, name) {
            let _this = this
            _this.editSet.forEach(function (item) {
                if (item.parFilterField == name) {
                    _this.$refs['control'].forEach((items) => {
                        if (items.$attrs.nameId == item.fieldName) {
                            items.getData(value, true) // 这里客户联动联系人不生效，因为选择客户的时候联系人组件不能载入，只能从数据层面直接写入(bindContact)
                        }
                    })
                }
            })
        },
        bindContact(custContacts, name) {
            let _this = this
            _this.editSet.forEach(function (item) {
                if (item.parFilterField == name) {
                    _this.$refs['control'].forEach((items) => {
                        if (items.$attrs.nameId == item.fieldName) {
                            if (custContacts && custContacts.length > 0) {
                                items.submitItem(custContacts[0])
                            }
                        }
                    })
                }
            })
        },
        returnFieldList(data, field) {
            console.log('data', data)
            let _this = this
            let fieldList = field.split(';')
            fieldList.forEach((element) => {
                if (element != '') {
                    let elementList = element.split('=')
                    if (elementList[0] && elementList[1]) {
                        _this.$refs['control'].forEach((items) => {
                            if (items.$attrs?.nameId == elementList[0]) {
                                let value = data[elementList[1]]
                                items.updates(value)
                            }
                        })
                    }
                }
            })
        },
        update(obj) {
            let obj1 = JSON.parse(obj)
            if (this.Global.utils.isArray(obj1)) {
                this.$refs['control'].forEach((item) => {
                    obj1.forEach((element) => {
                        if (element.key == item.$attrs.nameId) {
                            item.updates(element.value)
                        }
                    })
                })
            } else {
                this.$refs['control'].forEach((item) => {
                    if (obj1.key == item.$attrs.nameId) {
                        item.updates(obj1.value)
                    }
                })
            }
        },
        updates(obj) {
            let obj1 = JSON.parse(obj)
            let _this = this
            setTimeout(function () {
                _this.$refs['control'].forEach((item) => {
                    if (obj1[item.$attrs.nameId]) {
                        item.updates(obj1[item.$attrs.nameId])
                    }
                })
                _this.$refs['controlGroup'].forEach((item) => {
                    item.updates(obj1)
                })
            }, 20)
        },
        updateCard(obj) {
            this.$nextTick(() => {
                this.$refs['control'].forEach((item) => {
                    if (obj[item.$attrs.nameId]) {
                        item.updates(obj[item.$attrs.nameId])
                    }
                })
                this.$refs['controlGroup'].forEach((item) => {
                    item.updates(obj)
                })
            })
        },
        submit() {
            let data = {}
            let isCheck = true
            if (this.$refs['control']) {
                this.$refs['control'].forEach((item) => {
                    let itemData = item.submit()
                    if (itemData) {
                        data = Object.assign(data, itemData)
                    } else {
                        isCheck = false
                        return false
                    }
                })
            }
            if (this.$refs['controlGroup']) {
                this.$refs['controlGroup'].forEach((item) => {
                    let itemData = item.submit()
                    if (itemData) {
                        data = Object.assign(data, itemData)
                    } else {
                        isCheck = false
                        return false
                    }
                })
            }
            const { grassSilkHeight, lowerLimit, upperLimit, weavingPitch, lowerWeavingLimit, upperWeavingLimit } = data
            if (Number(grassSilkHeight) < lowerLimit || Number(grassSilkHeight) > upperLimit) {
                this.$toast.fail('草丝高度需在建议区间内')
                return false
            }
            if (Number(weavingPitch) < lowerWeavingLimit || Number(weavingPitch) > upperWeavingLimit) {
                this.$toast.fail('织距需在建议区间内')
                return false
            }
            const shouldNotZeroFields = ['bottomCost', 'bottomCoefficient', 'tuftingCostTax', 'tuftingCost', 'bottomPrice', 'maxDosage', 'maxCoefficient', 'totalSilkMeter', 'totalSilk', 'grassSilkSales', 'lawnSales']
            for (let i = 0; i < shouldNotZeroFields.length; i++) {
                if (Number(data[shouldNotZeroFields[i]]) === 0) {
                    const fieldObj = this.allShowFieldMap[shouldNotZeroFields[i]]
                    this.$toast.fail(`${fieldObj.cnFieldCaption}字段不可为0，请修改`)
                    return
                }
            }
            if (isCheck) {
                return data
            } else {
                return false
            }
        }
    },
    watch: {},
    components: Object.assign({}, Controls, GroupControls, {
        'date-picker': DatePickerTime
    })
}
</script>

<style lang="less" scoped>
.addVue {
    .margin-bottom(10px);
    .displayHidden {
        display: none;
    }
    .list {
        .listBox {
            .padding-left(0);
            .margin-left(16px);
            .min-height(45px);
            // .line-height(45px);
            display: flex;
            align-items: center;
            &.listBoxV1 {
                .padding-left(16px);
                .margin-left(0px);
            }
            border-bottom: 1px solid #eaedef;
            &.noPaddingLeft {
                padding-left: 0px;
            }
            &-component {
                width: 100%;
            }
            /deep/ .Controls {
                display: flex;
                align-items: center;
                .name {
                    width: 5rem
                }
                .ControlsBox {
                    width: 100%
                }
            }
        }
        .contactTitle {
            .min-height(45px);
            // .line-height(45px);
            background: #f5f5f5;
            .padding-left(16px);
            .font-size(14px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            &-warn {
                color: red;
                font-size: 12px;
            }
        }
    }
    .list:last-child {
        .listBox {
            border-bottom: 0;
        }
    }
    .next__follow {
        color: #909399;
        border-bottom: 1px solid #eaedef;
        .padding-left(16px);
        .next__follow-time {
            .height(30px);
            .line-height(30px);
            .padding-right(16px);
            .name {
                display: inline-block;
                .width(100px);
            }
        }
        .next__follow-desc {
            .padding-left(100px);
            &:lang(en) {
                .padding-left(130px);
            }
            .padding-right(16px);
            /deep/ .van-cell {
                .padding(10px, 0px);
            }
        }
    }
    .files__ware {
        .list {
            vertical-align: top;
            .width(75px);
            .height(75px);
            display: inline-block;
            .margin(5px);
            .margin-left(0);
            .margin-right(10px);
            line-height: 75px;
            text-align: center;
            position: relative;
            > img {
                .width(75px);
                .height(75px);
                vertical-align: top;
            }
            .iconBox {
                position: absolute;
                .width(19px);
                .line-height(14px);
                .height(19px);
                background: rgba(0, 0, 0, 0.6);
                border: 2px solid rgba(255, 255, 255, 1);
                border-radius: 50%;
                color: white;
                .font-size(12px);
                font-weight: bold;
                .right(-4px);
                .top(-4px);
            }
        }
        /deep/ .van-cell {
            .padding(10px, 16px, 10px, 0px);
        }
        /deep/ .van-collapse-item__content {
            .padding(0px, 16px, 0px, 0px);
        }
        /deep/ .van-hairline--top-bottom::after,
        .van-hairline-unset--top-bottom::after {
            border: none;
        }
        .AppItem {
            .padding-left(16px);
            .font-size(14px);
            border-bottom: 1px solid #eaedef;
        }
        .show-img-annex {
            font-size: 0;
            .iconBox-ware {
                position: relative;
                display: inline-block;
                width: 100%;
                .margin-right(10px);
                .margin-bottom(5px);
                .height(40px);
                font-size: 0;
                border: 1px solid #eaedef;
                .icon-img {
                    .width(25px);
                    .height(25px);
                    .margin-left(5px);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    .iconIMG,
                    .iconSVG {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        display: inline-block;
                        vertical-align: middle;
                        .width(25px);
                        .height(25px);
                    }
                }
                .iconfont {
                    color: #65585a;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
                .contentBox {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: inline-block;
                    vertical-align: middle;
                    overflow: hidden;
                    width: calc(100% - 2rem);
                    height: 100%;
                    .margin-left(5px);
                    .content {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        & > p {
                            .height(17px);
                            .line-height(17px);
                            .font-size(12px);
                        }
                        .fileName {
                            color: #909399;
                            /*  .margin-bottom(8px); */
                        }
                        .fileInfo {
                            color: #909399;
                        }
                    }
                }
                &:last-child {
                    .contentBox {
                        border-bottom: unset !important;
                    }
                }
            }
        }
    }
    .upload_button {
        .height(45px);
        position: fixed;
        bottom: 0;
        left: 0;
        border-top: 1px solid #eaedef;
        background: #fff;
        display: inline-block;
        width: 100%;
        .padding(0px, 16px);
        .upload_file {
            .margin-right(10px);
            color: #008cee;
            cursor: pointer;
            display: inline-block;
            .width(20px);
            .line-height(45px);
            .font-size(20px);
        }
    }
}
</style>

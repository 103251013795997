<template>
    <div class="Controls" :class="{'Controls--disabled':itemData.disabled}">
        <span class="name">
            {{itemData.cnFieldCaption}}
            <span style="color:#D0021B" v-if="prop()">*</span>
        </span>
        <div class="ControlsBox">
            <van-cell style="padding-left:0" :value="obj.contName" is-link @click="openPopup()" />
        </div>
        <van-popup v-model="show" position="right" class="contBox" :lock-scroll="false">
            <contList @submitItem="submitItem" ref="contList" :custId="custId"></contList>
        </van-popup>
    </div>
</template>

<script>
import contList from './contList/index'
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    mounted() {
        if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
            this.updates(this.itemData.inDefaultValue)
        }
    },
    data() {
        return {
            obj: {
                contName: '',
                contId: ''
            },
            show: false,
            custId: ''
        }
    },
    created() {
    },
    methods: {
        openPopup() {
            if (this.itemData.disabled) return
            this.show = true
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        updates(value) {
            if (value == '') {
                return false
            }
        },
        update(item) {
            if (item) {
                this.obj = item
            }
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.obj.contId == '') {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            data[this.itemData.fieldName] = this.obj.contId
            return data
        },
        submitItem(item) {
            this.obj = item
            this.show = false
        },
        getData(value) {
            this.custId = value
            if (this.$refs.contList && this.$refs.contList.unit) {
                let _this = this
                setTimeout(function () {
                    _this.$refs.contList.unit({ 'custId': value })
                }, 300)
            }
        }
    },
    watch: {
        obj: function (val, old) {
            if (this.itemData.returnFieldList && this.itemData.returnFieldList != '') {
                this.$emit('returnFieldList', val, this.itemData.returnFieldList)
            }
        }
    },
    components: {
        'contList': contList
    }
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    .ControlsBox {
        .min-height(45px);
    }
    .contBox {
        width: 80%;
        height: 100%;
        background: white;
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

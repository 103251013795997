<template>
    <div class="Setting">
        <transition name="van-fade">
            <scroll v-if="!loading&&dataList.length>0" class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                <div class="buyLog">
                    <dl v-for="(item,index) in dataList" :key="index">
                        <div>
                            <dt>
                                <span>订单编号：{{item.orderno || '---'}}</span>
                                <em>{{item.createdate.substr(0,10)}}</em>
                            </dt>
                            <dd>
                                <span>{{item.companyname}}</span>
                                <em class="end">￥{{returnFloat(item.actualreceiveamount)}}</em>
                            </dd>
                            <dd style="flex-direction: column">
                                {{item.desc}}
                            </dd>
                        </div>
                    </dl>
                </div>
            </scroll>
        </transition>
        <transition name="van-fade">
            <nodata v-if="dataList.length<=0&&!loading"></nodata>
        </transition>
        <transition name="van-fade">
            <loading v-if="loading" size="30px"></loading>
        </transition>
    </div>
</template>

<script>
// import titleMixin from '../../../mixin/title'
import Scroll from '@/components/Scroll/index'
export default {
    name: 'Record',
    // mixins: [titleMixin],
    title: '我的订单',
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            dataList: [],
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: this.$t('mx_mobile.Client.1585271749707'), // 加载更多
            pullUpLoadNoMoreTxt: this.$t('mx_mobile.Client.1585271760385'), // 没有更多数据了
            startY: 0,
            page: {
                total: 0,
                from: 1,
                size: 10
            },
            loading: false
        }
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }
    },
    created() {
        this.reGetList()
    },
    methods: {
        async onPullingUp() {
            if (this.dataList.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.dataList.length < this.page.total) {
                this.page.from += 1
                let dataList = await this.getDataList()
                this.dataList = this.dataList.concat(dataList)
                this.$nextTick(() => {
                    if (this.dataList.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        },
        async onPullingDown() {
            this.page.from = 1
            this.checkedItems = []
            this.dataList = await this.getDataList()
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        returnFloat(x) {
            var value = Math.round(parseFloat(x) * 100) / 100
            var xsd = value.toString().split('.')
            if (xsd.length == 1) {
                value = value.toString() + '.00'
                return value
            }
            if (xsd.length > 1) {
                if (xsd[1].length < 2) {
                    value = value.toString() + '0'
                }
                return value
            }
        },
        async reGetList() {
            this.page.from = 1
            this.loading = true
            this.dataList = await this.getDataList()
            this.loading = false
        },
        async getDataList() {
            let listArr = []
            try {
                let data = {
                    type: 'history',
                    pageIndex: this.page.from,
                    pageSize: 10,
                    custId: this.item.custId
                }
                let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.companyOrder, { params: data })

                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    listArr = res.data.data.list
                    // if (listArr.length > 0) {
                    //     listArr.forEach(item => {
                    //         item.desc = item.desc.split(',')
                    //     })
                    // }
                    this.page.total = res.data.data.totalNums
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
            return listArr
        }
    },
    watch: {
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        }
    },
    components: {
        'Scroll': Scroll
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.Setting {
    position: fixed;
    left: 0;
    right: 0;
    top: 3.2em;
    bottom: 0;
    overflow-y: auto;
}
.buyLog {
    margin-bottom: 15px;
    > dl {
        background: #fff;
        padding: 10px 15px;
        margin: 10px 0 0;
        color: #909399;
        dt,
        dd {
            display: flex;
            justify-content: space-between;
            margin: 8px 0;
        }
        dt {
            color: #303133;
            font-size: 14px;
        }
        .state {
            border-top: 1px solid #eee;
            padding: 10px 0 0;
            margin: 15px 0 0;
            > div {
                line-height: 30px;
                em {
                    color: #d0021b;
                }
            }
        }
        .wait {
            font-weight: bold;
            color: #d0021b;
        }
        .end {
            font-weight: bold;
            color: #00c2b3;
        }
        .desc {
            display: block;
        }
    }
}
</style>

<template>
    <div class="TypeBox">
        <ul class="typeList">
            <li v-for="(item,index) in typeList" class="typeItem" @click="$emit('change',item.value)" :key="index">
                <i v-if="item.value=='ALL'" class="iconfont icon-app"></i>
                <svg v-else class="iconSVG" aria-hidden="true">
                    <use :xlink:href="getIcon(item.icon||item.value)"></use>
                </svg>
                <p class="name">{{item.name}}</p>
            </li>
        </ul>
    </div>
</template>
<script>
import mixin from '../mixin.js'
export default {
    name: '',
    data() {
        return {
            typeList: [
                { name: this.$t('mx_mobile.Doc.1585205181576'), value: 'ALL' }, // '全部'
                { name: this.$t('mx_mobile.Doc.1585205256899'), value: 'PIC', icon: 'jpg' }, // '图片'
                { name: this.$t('mx_mobile.Doc.1585205221395'), value: 'VIDEO', icon: 'avi' }, // '视频'
                { name: 'DOC', value: 'DOC' },
                { name: 'XLS', value: 'XLS' },
                { name: 'PPT', value: 'PPT' },
                { name: 'PDF', value: 'PDF' }
            ]
        }
    },
    mixins: [mixin],
    methods: {

    },
    components: {

    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

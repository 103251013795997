import baseComponent from './baseComponent'
import { getIsApp } from '@/libs/utils'

export default {
    name: 'DetailRight',
    mixins: [baseComponent],

    props: {
        detailMailIndex: {
            type: Number,
            default: 0
        },
        detailMailList: {
            type: Array,
            default: () => []
        },
        boxId: {
            type: [Number, String],
            default: ''
        },
        isTopList: {
            type: Boolean,
            default: false
        },
        isUndistributed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            mailIndex: 0
        }
    },
    mounted() {
        if (!this.detailMailList.length) {
            this.closeDetail()
            return
        }
        this.mailIndex = this.detailMailIndex
        this.getMailCurrent(this.detailMailList[this.mailIndex].mId)
        this.setMenu()
    },
    methods: {
        closeDetail() {
            this.$emit('close')
        },
        openLink() {
            let Id = this.detailMailList[this.mailIndex].mId

            if (!Id) {
                console.log(Id)
                return
            }
            let url = `${window.location.origin}/render/maildetail?mId=${Id}&accessToken=${this.Global.utils.getToken()[this.Global.config.accessToken]}&type=details${getIsApp() ? '&showHeader=0' : ''}`
            window.open({ url: url, isShare: false })
        },
        nextMailGet() {
            this.mailIndex++
            if (this.mailIndex >= this.detailMailList.length) {
                this.mailIndex--
                this.$toast.fail('已经是最后一封了')
                return
            }
            this.getMailCurrent(this.detailMailList[this.mailIndex].mId)
        },
        actionList(type, action) {
            console.log(type)
            this.showAction = false
            if (type == 'delete') {
                this.detailMailList.splice(this.mailIndex, 1)

                if (!this.detailMailList.length) {
                    this.closeDetail()
                    return
                }

                if (this.mailIndex >= this.detailMailList.length) {
                    this.mailIndex--
                }

                let Id = this.detailMailList[this.mailIndex].mId
                this.getMailCurrent(Id)
            }

            if (type == 'mark' && !!action) {
                let { key, value } = action
                Object.assign(this.detailMailList[this.mailIndex], {
                    [key]: value
                })
            }
        }
    },
    watch: {
        mailId() {
            this.getMailCurrent(this.mailId)
        }
    }
}

<template>
    <div class="Controls" :class="{'Controls--disabled':itemData.disabled}">
        <span class="name">
            {{itemData.cnFieldCaption}}
            <span style="color:#D0021B" v-if="prop()">*</span>
        </span>
        <van-field style="padding-left:0" v-model="value" clearable :disabled="itemData.disabled" :placeholder="itemData.cnFieldHint" :error-message="(checkRepeat && (checkMsg == 1) && itemData.fieldName == 'custName') ? '此客户已存在，可以仅新增联系人不新增客户' : ''" :maxlength="itemData.fieldLength" @input="handleInputBlur"></van-field>
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        type: {
            type: String,
            default: 'otNew'
        },
        checkRepeat: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            value: '',
            checkMsg: -1,
            checkLoading: false,
            repeatModule: ''
        }
    },
    created() {},
    mounted() {
        if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
            this.updates(this.itemData.inDefaultValue)
        }
    },
    methods: {
        handleInputBlur() {
            if (this.itemData.fieldName == 'custName') {
                this.checkRepeats()
            }
        },
        checkRepeats() {
            if (this.checkRepeat && this.itemData.uniqueCheck == 2) {
                this.checkMsg = 2
                if (this.value == '') {
                    return false
                }
                let argument = {
                    fieldName: this.itemData.fieldName,
                    fieldValue: this.value.replace(/(^\s*)|(\s*$)/g, ''),
                    moduleCode: this.itemData.moduleCode,
                    searchType: 'checkUnique'
                }
                this.checkLoading = true
                this.axios
                    .get(
                        this.Global.config.apiBaseURL +
                            this.Global.api.v2.document_config_get,
                        { params: argument }
                    )
                    .then(
                        (res) => {
                            if (
                                res.data.code.toString() ==
                                this.Global.config.RES_OK
                            ) {
                                if (res.data.data.isRepeat == 0) {
                                    this.checkMsg = 0
                                    this.$emit('custRepeatCheck', false)
                                } else {
                                    this.checkMsg = 1
                                    this.$emit('custRepeatCheck', true)
                                    this.repeatModule =
                                        res.data.data.repeatModule
                                }
                            } else {
                                this.checkMsg = 2
                                this.$message.error(this.msg(res.data))
                            }
                            this.checkLoading = false
                        },
                        (res) => {
                            this.checkMsg = 2
                            this.checkLoading = false
                            this.$message.error(this.$t(this.Global.errorTitle))
                        }
                    )
            }
        },
        updates(value) {
            // if (value == '') {
            //     return false
            // }
            this.value = value
            this.handleInputBlur()
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.value == '') {
                    this.$toast.fail(
                        this.itemData.cnFieldCaption +
                            this.$t('mx_mobile.Client.1585301350281')
                    ) // 不能为空
                    return false
                }
            }
            data[this.itemData.fieldName] = this.value
            return data
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {},
    components: {}
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
    .padding-left(100px);
    &:lang(en) {
        .padding-left(130px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
    ::v-deep .van-field__error-message {
        font-size: 10px;
    }
}
</style>

<template>
    <div class="contList">
        <div class="screenBox">
            <!-- 输入姓名/昵称/电话/手机/社交/邮箱账号等 -->
            <search-bar v-model="keywords" @keyup.enter.native="getListDatas" @btnClick="getListDatas" :placeholder="$t('mx_mobile.Client.1585301448617')"></search-bar>
        </div>
        <div style="height:1px"></div>
        <div class="moduleCodePage">
            <scroll class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                <!-- <div class="list ellipsis" v-for="(item, index) in listData" :key="index">
              {{item.contName}}
          </div> -->
                <van-cell-group>
                    <van-cell v-for="(item, index) in listData" :key="index" :value="item.contName" @click="$emit('submitItem', item)" />
                </van-cell-group>
            </scroll>
        </div>
    </div>
</template>
<script>
import Scroll from '@/components/Scroll/index'
import SearchBar from '@/page/Document/Vue/SearchBar/index'
export default {
    name: 'contList',
    props: {
        custId: {
            type: [String, Number],
            default: ''
        }
    },
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: this.$t('mx_mobile.Client.1585271749707'), // 加载更多
            pullUpLoadNoMoreTxt: this.$t('mx_mobile.Client.1585271760385'), // 没有更多数据了
            startY: 0,
            scrollToX: 0,
            scrollToY: -200,
            scrollToTime: 700,
            scrollToEasing: 'bounce',
            scrollToEasingOptions: ['bounce', 'swipe', 'swipeBounce'],

            msg: 'contList',
            listData: [],
            keywords: '',
            sort: 'createDate',
            page: {
                total: 0,
                from: 0,
                size: 20
            }
        }
    },
    created() {
        this.pageUrl = this.$route.path
        this.unit()
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }
    },
    mounted() {
    },
    methods: {
        async getListDatas() {
            let obj = {
                keywords: this.keywords
            }
            this.page = {
                total: 0,
                from: 0,
                size: 20
            }
            this.listData = await this.getListData(obj)
        },
        async sortSeachClick(fieldName) {
            this.sort = fieldName
            this.listData = await this.getListData()
        },
        async getListData(obj) {
            let data = {
                moduleCode: 'BF003',
                order: 'desc',
                from: this.page.from,
                size: this.page.size,
                sort: this.sort,
                searchType: 'allList'
            }
            if (obj) {
                data = Object.assign(data, obj)
            }
            if (this.custId != '') {
                data.custId = this.custId
            }
            let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.document_generalOperate_get, { params: data })
            if (res.data.code.toString() === this.Global.config.RES_OK) {
                this.page.total = res.data.data.totalNum
                return res.data.data.list
            } else {
                this.$toast.fail(res.data.msg)
                return []
            }
        },
        unit(obj) {
            let _this = this
            var p1 = new Promise((resolve, reject) => {
                let data = {
                    moduleCode: 'BF003',
                    order: 'desc',
                    from: this.page.from,
                    size: this.page.size,
                    sort: this.sort,
                    searchType: 'allList'
                }
                if (this.custId != '') {
                    data.custId = this.custId
                }
                this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.document_generalOperate_get, { params: data }).then((res) => {
                    if (res.data.code.toString() === this.Global.config.RES_OK) {
                        resolve(res.data.data.list)
                        this.page.total = res.data.data.totalNum
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    this.$toast.fail(this.Global.config.errorTitle)
                })
            })
            Promise.all([p1]).then(function (results) {
                _this.listData = results[0]
                if (obj && obj.custId) {
                    // parFilterField-联动(客户 - 联系人)
                    _this.$emit('submitItem', _this.listData[0])
                }
            })
        },
        async onPullingDown() {
            this.page.from = 0
            this.listData = await this.getListData()
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },
        async onPullingUp() {
            if (this.listData.length >= this.page.total) {
                this.$refs.scroll.forceUpdate(false)
                return
            }
            if (this.listData.length < this.page.total) {
                this.page.from += 10
                let listData = await this.getListData()
                this.listData = this.listData.concat(listData)
                this.$nextTick(() => {
                    if (this.listData.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        },
        rebuildScroll() {
            this.$nextTick(() => {
                this.$refs.scroll.destroy()
                this.$refs.scroll.initScroll()
            })
        }
    },
    watch: {
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        },
        startY() {
            this.rebuildScroll()
        }
    },
    components: {
        'Scroll': Scroll,
        'search-bar': SearchBar
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

<template>
    <!-- 移交给 -->
    <van-popup v-model="show" position="right" class="screenSearchPop">
        <div class="list1">
            <!-- 搜索 -->
            <search-bar v-model="value" @keyup.enter.native="toSearch" :placeholder="$t('mx_mobile.Client.1585115144101')"></search-bar>
        </div>
        <div class="addPeople">
            <div class="listBox">
                <div class="list" v-for="(item, index) in list" :key="index" @click="clickItem(item)">
                    {{item.realName}}
                </div>
            </div>
            <van-popup v-model="showDep" position="right" class="showDep">
                <div class="listBox">
                    <div class="list" v-for="(item, index) in list1" :key="index" @click="selecteDep(item)">
                        {{item.deptName}}
                    </div>
                </div>
            </van-popup>
        </div>
        <div class="dialogFooter">
            <div class="list">
                <!-- 同时移交联系人和跟进 -->
                {{$t('mx_mobile.Client.1585305174639')}}
                <van-switch class="but" size="24px" v-model="checked" />
            </div>
            <div class="list" v-if="item.cooperate&&item.cooperate.length!==0">
                <!-- 是否保留共享协作人 -->
                {{$t('mx_mobile.Client.1585305184278')}}
                <van-switch class="but" size="24px" v-model="checked1" />
            </div>
        </div>
    </van-popup>
</template>
<script>
/**
 * 描述：客户管理-客户列表-新增客户
 * 作者：何俊峰
 * 时间：2017/11/21
 */
import SearchBar from '@/page/Document/Vue/SearchBar/index'
// import titleMixin from '@/mixin/title'
export default {
    title: function fc() { return this.$t('mx_mobile.Client.1585305184614') }, // '移交'
    name: 'otTransfer',
    // mixins: [titleMixin],
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: 'otNew'
        },
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            show: false,
            showDep: false,
            value: '',
            list: [],
            list1: [],
            listBase: [],
            checked: true,
            checked1: true,
            radio: '',
            ctId: ''
        }
    },
    created() {
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        selecteDep(item) {
            this.radio = item.dkey
            this.submit()
        },
        setMenu() {
            this.Global.utils.rightMenu.clearMenu()
        },
        closeThis() {
            this.show = false
            this.$emit('setMenu')
            this.$emit('setTitle')
        },
        toSearch() {
            let list = []
            this.listBase.forEach(element => {
                if (element.realName && element.realName.indexOf(this.value) != -1) {
                    list.push(element)
                }
            })
            this.list = list
        },
        openWindow() {
            this.setHeaderTitle(this.$t('mx_mobile.Client.1585305184614')) // 移交
            this.showDep = false
            this.value = ''
            this.checked = true
            this.checked1 = true
            this.radio = ''
            this.ctId = ''
            this.getData()
            this.show = true
            this.setMenu()
        },
        getData() {
            let _this = this
            let contactData = {
                dataType: 'contact',
                optCode: _this.optCode,
                funType: 'withRight',
                moduleCode: _this.moduleCode
            }
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.accountDropList_get, { params: contactData }).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    _this.listBase = res.data.data
                    _this.list = res.data.data
                } else {
                    _this.listBase = []
                    _this.list = []
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        getData1(ctId) {
            this.ctId = ctId
            let _this = this
            let contactData = {
                dataType: 'department',
                funType: 'withRight',
                moduleCode: _this.moduleCode,
                optCode: _this.optCode,
                deptCascade: true,
                ctId: ctId
            }
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.accountDropList_get, { params: contactData }).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    let list = []
                    res.data.data.forEach(element => {
                        if (element.inUse == '1') {
                            list.push(element)
                        }
                    })
                    _this.list1 = list
                    if (_this.list1.length > 0) {
                        _this.radio = _this.list1[0].dkey
                        _this.showDep = true
                    }
                    if (_this.list1.length == '1') {
                        _this.submit()
                    }
                } else {
                    _this.list1 = []
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        clickItem(item) {
            this.list1 = []
            this.radio = ''
            this.$toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: ''
            })
            this.getData1(item.ctId)
        },
        submit() {
            this.showDep = false
            let _this = this
            let data = {
                optModuleCode: _this.moduleCode,
                identFieldValue: _this.item.custId,
                optCode: 'otTransfer',
                toCtId: this.ctId,
                toDept: this.radio
            }
            if (this.checked) {
                if (this.item.cooperate && this.item.cooperate.length !== 0) {
                    if (this.checked1) {
                        data.transferList = '1,2'
                        data.cooperateCtIds = this.item.cooperate.join(',')
                    } else {
                        data.transferList = '1'
                    }
                } else {
                    data.transferList = '1'
                }
            } else {
                if (this.item.cooperate && this.item.cooperate.length !== 0) {
                    if (this.checked1) {
                        data.transferList = '2'
                        data.cooperateCtIds = this.item.cooperate
                    } else {
                        data.transferList = ''
                    }
                } else {
                    data.transferList = ''
                }
            }
            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: this.$t('mx_mobile.Client.1585305330782') + '...' // 移交中
            })
            _this.axios.put(_this.Global.config.apiBaseURL + _this.Global.api.v2.document_operate_detailOpt_put, data).then(function (res) {
                _this.$toast.clear()
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    _this.$emit('getListData')
                    _this.show = false
                    _this.$toast.success(res.data.msg)
                    _this.closeThis()
                } else {
                    _this.$toast.fail(res.data.msg)
                }
            })
        }
    },
    watch: {
    },
    components: {
        'search-bar': SearchBar
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

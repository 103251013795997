<template>
<div class="object"></div>
</template>
<script>
export default {
    name: 'loading',
    props: {
    },
    data() {
        return {
        }
    },
    methods: {
    },
    components: {
    },
    watch: {
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.object{
    .height(40px);
    .width(40px);
    background-color: rgba(239,74,74,1);
    -webkit-animation: animate 1s infinite ease-in-out;
    animation: animate 1s infinite ease-in-out;
    margin-right: auto;
    margin-left: auto;
    margin-top: 60px;
}
@-webkit-keyframes animate {
    0% { -webkit-transform: perspective(160px); }
    50% { -webkit-transform: perspective(160px) rotateY(-180deg); }
    100% { -webkit-transform: perspective(160px) rotateY(-180deg) rotateX(-180deg); }
}

@keyframes animate {
    0% {
    transform: perspective(160px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(160px) rotateX(0deg) rotateY(0deg);
    } 50% {
    transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
    -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(0deg) ;
    } 100% {
    transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
    -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
    }
}
</style>

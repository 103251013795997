<template>
    <div class="searchLabels">
        <div class="rightBox">
            <template v-for="(item, index) in options">
                <span v-if="item.key!=undefined" class="comment" :key="item.dictItemCode" :class="[check == item.dictItemCode+'' ? 'comment2':'comment1']" @click="changeItem(item)" :style="Global.utils.commentsColor(item.key)"><i class="iconfont icon-dot"></i></span>
                <span v-else :key="item.dictItemCode" class="label ellipsis" :class="[check == item.dictItemCode+'' ? 'label2':'label1']" :style="index!=0&&(index+1)%3 == 0?'margin-right:0':''" @click="changeItem(item)">{{item.itemName}}</span>
            </template>

        </div>
    </div>
</template>

<script>
// import Controls from '@/page/Client/CustPublic/Controls/index.js'
// import GroupControls from '@/page/Client/CustPublic/GroupControls/index.js'

export default {
    name: 'searchLabels',
    props: {
        defaultValue: {
            type: String,
            default: function () {
                return ''
            }
        },
        options: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            check: '0'// this.defaultValue == '' ? '0' : check
        }
    },
    created() {
    },
    mounted() {
    },
    methods: {
        submit() {
            let obj = {}
            if (this.check != 1) {
                obj[this.list.fieldName] = this.check
            }
            return obj
        },
        clear() {
            this.check = '0'
            this.$emit('clearFilfter')
        },
        changeItem(value) {
            if (this.check !== value.dictItemCode) {
                this.check = value.dictItemCode
                this.$emit('anomalyCheck', value)
            }
        }
    },
    computed: {
    },
    watch: {
    },
    components: {}
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.searchLabels {
    .rightBox {
        min-height: 36px;
        .label,
        .noLabel {
            display: inline-block;
            .width(65px);
            .height(26px);
            text-align: center;
            .line-height(26px);
            .border-radius(4px);
            .font-size(12px);
            .margin-bottom(17px);
            .margin-right(19px);
        }
        .comment {
            .padding(5px, 0, 10px);
            .width(28px);
            .height(28px);
            text-align: center;
            display: inline-block;
            border: 1px #efefef solid;
            .border-radius(14px);
            .font-size(12px);
            .margin-bottom(17px);
            vertical-align: top;
            .margin-right(15px);
        }
        .comment1 {
            background: #efefef;
        }
        .comment2 {
            background: #fff;
            border: 1px solid #e6001f;
        }
        .label1 {
            color: #606266;
            background: #efefef;
        }
        .label2 {
            color: #e6001f;
            background: #fff;
            border: 1px solid #e6001f;
        }
        .control {
            margin-bottom: 0;
            line-height: 33px;
            display: inline-block;
        }
    }
}
</style>

import { goodsEncrypt } from '@/libs/crypto.js'
import { mapGetters } from 'vuex'
export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters([
            'companie',
            'domain'
        ])
    },
    methods: {
        // 商品链接
        getGoodsLink(spuId, noShare) {
            let { cId } = this.companie
            let domain = this.domain.hostName
            if (this.Global.config.STAGE_ENV === 'prod' && !noShare) {
                domain = 'https://goods.im'
            }

            if (!noShare) {
                return domain + '/goods/detail?cId=' + goodsEncrypt(cId + '') + '&id=' + goodsEncrypt(spuId + '') + '&opCtId=' + goodsEncrypt(this.companie.ctId + '')
            }
            return domain + '/goods/detail?cId=' + goodsEncrypt(cId + '') + '&id=' + goodsEncrypt(spuId + '')
        },
        // 目录链接
        getGoodsCatalogLink(noShare) {
            let { cId } = this.companie
            let domain = this.domain.hostName
            if (this.Global.config.STAGE_ENV === 'prod' && !noShare) {
                domain = 'https://goods.im'
            }
            return domain + '/goods/list?cId=' + goodsEncrypt(cId + '')
        }
    }
}

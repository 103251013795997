  <template>
    <img ref="image" :src="getimg(item.url)">

</template>
<script>
export default {
    name: '',
    props: {
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        getimg(url, times = 3) {
            let afterUrl = ''
            if (url.length < 20) {
                afterUrl = this.getGlobalImgSrc(url, '34x34')
            } else {
                afterUrl = url
                console.log(afterUrl)
            }
            times--
            let img = new Image()
            img.src = afterUrl
            img.onload = () => {
                console.log(afterUrl)
                this.$refs.image.src = afterUrl
                img = null
            }
            img.onerror = () => {
                if (times <= 0) { return }
                setTimeout(() => {
                    this.getimg(url, times)
                }, 2 * 1000)
                img = null
            }
        }

    },
    components: {
    }
}
</script>

<template>
    <div class="Mail__ware" :class="{'del__mask': dynamicData.deleteFlag}">
        <!-- dynamicData.mailFlag  1: 发件 2: 收件 -->
        <div class="Mail__top">
            <div v-if="dynamicData.mailFlag == 1">
                <div>
                    <span class="top__icon">
                        <i class="icon iconfont icon-arrow-left-circle"></i>
                    </span>
                    <span class="top__name">{{dynamicData.createName}}&nbsp;</span>
                    <span class="top__desc">
                        <!-- 发送了一封邮件 -->
                        {{$t('mx_mobile.Client.1627018705698')}}
                    </span>
                    <span class="top__del" v-if="dynamicData.deleteFlag"><span class="top__del-word">
                            <!-- 已删 -->
                            {{$t('mx_mobile.Client.1627018747290')}}
                        </span></span>
                    <span class="top__right pull-right">
                        <span class="top__time">{{returnTime(dynamicData.createTime)}}</span>
                    </span>
                </div>
                <div class="contacts_ware ellipsis" :title="returnCustInfo">
                    <!-- 收件人 -->
                    {{$t('mx_mobile.Client.1627018873189')}}:&nbsp;
                    <span class="contacts_info" v-if="dynamicData.curContactsCard && dynamicData.curContactsCard[0]">{{returnCustInfo}}</span>
                </div>
            </div>
            <template v-if="dynamicData.mailFlag == 2">
                <div>
                    <span class="top__icon">
                        <i class="icon iconfont icon-arrow-right-circle"></i>
                    </span>
                    <span class="top__name">{{dynamicData.receiveName}}&nbsp;</span>
                    <span class="top__desc">
                        <!-- 收到了一封邮件 -->
                        {{$t('mx_mobile.Client.1627018925336')}}
                    </span>
                    <span class="top__del" v-if="dynamicData.deleteFlag"><span class="top__del-word">
                            <!-- 已删 -->
                            {{$t('mx_mobile.Client.1627018747290')}}
                        </span></span>
                    <span class="top__right pull-right">
                        <span class="top__time">{{returnTime(dynamicData.createTime)}}</span>
                    </span>
                </div>
                <div class="contacts_ware ellipsis" :title="returnCustInfo">
                    <!-- 发件人 -->
                    {{$t('mx_mobile.Client.1627018967541')}}:&nbsp;
                    <span class="contacts_info" v-if="dynamicData.curContactsCard && dynamicData.curContactsCard[0]">{{returnCustInfo}}</span>
                </div>
            </template>
        </div>
        <div class="Mail__body">
            <p class="body__theme"><span class="body__theme-span" @click="ListCenterShowDetail(dynamicData.sourceId, 0, [{'mId':dynamicData.sourceId}])">{{dynamicData.theme}}</span></p>
            <p class="body__content"><span class="body__content-span" @click="ListCenterShowDetail(dynamicData.sourceId, 0, [{'mId':dynamicData.sourceId}])">{{dynamicData.content}}</span></p>
            <div class="body__annex" v-if="dynamicData.annexList">
                <!-- 图片 -->
                <template v-if="imgLink.length > 0">
                    <span @click="previewImage(item10)" class="iconBox" v-for="(item10) in imgLink" :key="item10.url">
                        <img v-if="isImage(substringSuffix(item10.name))" class="iconIMG" :src="item10.url" alt=".">
                        <svg v-else class="iconSVG" aria-hidden="true">
                            <use :xlink:href="getIcon(substringSuffix(item10.name))"></use>
                        </svg>
                    </span>
                </template>
                <div class="show-img-annex">
                    <div @click="previewImage(item10)" class="iconBox-ware" :class="index10 % 2 == 0 ? '' : 'iconBox-ware-odd'" v-for="(item10, index10) in fileList" :key="index10">
                        <div class="icon-img">
                            <img v-if="isImage(substringSuffix(item10.name))" class="iconIMG" :src="item10.url" alt=".">
                            <svg v-else class="iconSVG" aria-hidden="true">
                                <use :xlink:href="getIcon(substringSuffix(item10.name))"></use>
                            </svg>
                        </div>
                        <div class="contentBox">
                            <div class="content">
                                <p class="fileName ellipsis" v-html="item10.name"></p>
                                <p class="fileInfo ellipsis" :size="Global.utils.byteCalc(item10.size)">
                                    {{getSize(item10.size)}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body__bottom" v-if="dynamicData.DetailData">
                <!-- <enable-track ref="enableTrack" useFrom="dynamic" :Detail="dynamicData.DetailData" :showTitle="true"></enable-track> -->
            </div>
        </div>
    </div>
</template>

<script>
/* 邮件 */
import { mapMutations } from 'vuex'
import controlImg from '@/page/Client/CustPublic/ControlsShow/Picture/index'
import { formatValidTime } from '@/libs/utils.js'
// import ShowName from '@/components/MailInfo/DetailShowName/index'
// import enableTrack from '@/page/Main/Mail/List/vue/enableTrack/index'
export default {
    name: 'Mail',
    props: {
        dynamicData: {
            type: Object,
            default: () => ({})
        }
    },
    provide: {
        isShowLastNext: false
    },
    components: {
        // 'enable-track': enableTrack,
        'controlImg': controlImg
    },
    data() {
        return {
            isPreView: false
        }
    },
    computed: {
        imgLink() {
            let arr = []
            arr = this.dynamicData.annexList.filter(item => this.isImage(this.substringSuffix(item.name))).filter(v => v.url.indexOf('http' != -1))
            return [...arr]
        },
        fileList() {
            let arr = []
            arr = this.dynamicData.annexList.filter(item => !this.isImage(this.substringSuffix(item.name)))
            return [...arr]
        },
        returnCustInfo() {
            let info = ''
            if (this.dynamicData.curContactsCard && this.dynamicData.curContactsCard[0]) {
                if (this.dynamicData.curContactsCard[0].contName) {
                    info += this.dynamicData.curContactsCard[0].contName
                }
                if (this.dynamicData.curContactsCard[0].address) {
                    info += '<' + this.dynamicData.curContactsCard[0].address + '>'
                }
            }
            return info
        }
    },
    watch: {},
    created() {
    },
    mounted() { },
    methods: {
        ...mapMutations('Mail', {
            set_boxIndex: 'SET_BOXINDEX',
            set_boxAarry: 'SET_BOXAARRY'
        }),
        substringSuffix(name) {
            let str = name.substr(name.lastIndexOf('.') + 1)
            return str
        },
        isImage(exe) {
            const imgArr = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
            if (exe) {
                return imgArr.includes(exe.toLowerCase())
            }
        },
        getSize(size) {
            if (size > 1024) {
                return parseFloat(size / 1024).toFixed(2) + ' M'
            } else {
                return parseFloat(size).toFixed(2) + ' KB'
            }
        },
        getIcon(fileExtName) {
            return `#file-${this.Global.utils.isHasSuffix(fileExtName)}`
        },
        async previewImage(item) {
            try {
                let suffix = this.substringSuffix(item.name || '')
                if (!this.Global.utils.filePreview.isSupport(suffix)) {
                    this.$toast(this.$t('mx_mobile.Client.1585279886218')) // 暂不支持该格式文件预览
                    return
                }
                this.Global.utils.filePreview.open(item.url, suffix, `${item['fileName'] || item['name'] || (new Date().getTime() % 100000)}.${suffix}`)
            } catch (error) {
                alert(error)
            }
        },
        exeDownload(item) {
            let _link = ''
            if (item.url && item.url.indexOf('http' != -1)) {
                _link = item.url
            } else {
                _link = this.fileUrl + item.url
            }
            window.location.href = _link
        },
        transferToDoc(attachmentList = []) {
            this.$refs.docTransfer.open(attachmentList, 'byte')
        },
        willPreView(imgsList, index) {
            this.isPreView = true
            this.$nextTick(() => {
                this.$refs.filePreview && this.$refs.filePreview.show(imgsList, index, 0)
            })
        },
        returnTime(validTime) {
            return formatValidTime(validTime, 'MM-dd HH:mm')
        },
        ListCenterShowDetail(mId, index, listALL) {
            this.$toast.loading(this.$t('mx_mobile.Client.1585281647545')) // 加载中...
            let list = []
            listALL.forEach(item => {
                list.push(item.mId)
            })
            this.set_boxAarry(list)
            this.set_boxIndex(index)
            this.$router.push({ name: 'DetailRight', query: { mId: mId, ...this.g_setQuery() }, params: { index: index, listALL: list } })
        }
    },
    beforeDestroy() {
    },
    beforeRouteLeave(to, from, next) {
        next()
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.Mail__ware {
    border-bottom: 1px solid #f9f9f9;
    .margin-bottom(10px);
    color: #6b6b6b;
    position: relative;
    &.del__mask {
        color: #bcbcbc !important;
        .top__name,
        .contacts_info {
            color: #bcbcbc !important;
        }
    }
    &.del__mask::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        background: rgba(249, 249, 249, 0.1);
        position: absolute;
        top: 0;
        left: 0;
    }
    .Mail__top {
        font-size: 0;
        position: relative;
        .top__icon {
            display: inline-block;
            .height(20px);
            .width(20px);
            .line-height(20px);
            .margin-right(5px);
            .icon-arrow-right-circle {
                display: inline-block;
                .font-size(20px);
                color: #36ab60;
            }
            .icon-arrow-left-circle {
                display: inline-block;
                .font-size(20px);
                color: #efb336;
            }
        }
        .top__name {
            display: inline-block;
            .height(20px);
            .font-size(14px);
            color: #222;
            font-weight: bold;
            display: inline-block;
            vertical-align: top;
        }
        .top__desc {
            display: inline-block;
            .height(20px);
            .font-size(14px);
            vertical-align: top;
            .top__desc--name {
                display: inline-block;
                position: relative;
                color: #008cee;
                cursor: pointer;
            }
        }
        .contacts_ware {
            .font-size(14px);
            .contacts_info {
                color: #008cee;
                font-weight: 600;
            }
        }
        .top__del {
            display: inline-block;
            .height(20px);
            vertical-align: top;
            position: relative;
            .top__del-word {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                .width(35px);
                text-align: center;
                display: inline-block;
                color: #fff !important;
                .font-size(12px);
                .margin-left(5px);
                background: #d0021b;
                .height(20px);
                .line-height(20px);
                .border-radius(10px);
                .padding(0px, 5px);
                z-index: 1;
            }
        }
        .top__right {
            display: inline-block;
            .height(20px);
            .top__time {
                display: inline-block;
                .height(20px);
                .line-height(20px);
                .font-size(14px);
            }
        }
    }
    .Mail__body {
        .padding-bottom(5px);
        .body__theme {
            .padding-bottom(5px);
            .body__theme-span {
                cursor: pointer;
                .font-size(12px);
                font-weight: 600;
            }
        }
        .body__content {
            display: inline-block;
            white-space: pre-wrap;
            word-break: break-word;
            .body__content-span {
                cursor: pointer;
                .font-size(12px);
            }
        }
        .body__annex {
            .font-size(12px);
            .padding(5px, 0px);
            .iconBox {
                display: inline-block;
                .margin-right(5px);
                .width(50px);
                .height(50px);
                .iconIMG,
                .iconSVG {
                    display: inline-block;
                    .width(50px);
                    .height(50px);
                }
            }
            .show-img-annex {
                font-size: 0;
                .iconBox-ware {
                    position: relative;
                    display: inline-block;
                    width: calc(50% - 5px);
                    .margin-right(10px);
                    .margin-bottom(5px);
                    .height(40px);
                    font-size: 0;
                    border: 1px solid #eaedef;
                    &.iconBox-ware-odd {
                        margin-right: 0;
                    }
                    .icon-img {
                        .width(25px);
                        .height(25px);
                        .margin-left(5px);
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        .iconIMG,
                        .iconSVG {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            display: inline-block;
                            vertical-align: middle;
                            .width(25px);
                            .height(25px);
                        }
                    }
                    .iconfont {
                        color: #d0021b;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                    }
                    .contentBox {
                        position: absolute;
                        right: 0;
                        top: 0;
                        display: inline-block;
                        vertical-align: middle;
                        overflow: hidden;
                        width: calc(100% - 2rem);
                        height: 100%;
                        .margin-left(5px);
                        .content {
                            width: 100%;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            & > p {
                                .height(17px);
                                .line-height(17px);
                                .font-size(12px);
                            }
                            .fileName {
                                color: #909399;
                                /*  .margin-bottom(8px); */
                            }
                            .fileInfo {
                                color: #909399;
                            }
                        }
                    }
                    &:last-child {
                        .contentBox {
                            border-bottom: unset !important;
                        }
                    }
                }
            }
        }
    }
}
</style>

<template>
    <!-- 批注 -->
    <!-- <van-popup v-model="show" position="right" class="screenSearchPop" :lock-scroll="false" :style="{height:isHeight+'px'}"> -->
    <van-popup v-model="show" position="right" class="screenSearchPop" :lock-scroll="false">
        <div class="addPeople">
            <div class="boxList">
                <div class="titleBox">
                    <!-- 客户 -->
                    <div class="title">{{$t('mx_mobile.Client.1584681452354')}}</div>
                    <van-cell class="custName" :value="cust.custName" is-link @click="custIdshow = true" />
                </div>
            </div>
            <div class="boxList">
                <div class="titleBox">
                    <!-- 标记 -->
                    <div class="title">{{$t('mx_mobile.Client.1585629548204')}}</div>
                </div>
                <ul class="mailSign">
                    <div v-for="(item,index) in arrMailSign" :key="index" class="mailSignBox">
                        <li :class="[{active:item==commentFlag}]" :style="setCommentsColor(item)" @click="commentFlag = item">
                            <i class="iconfont icon-dot"></i>
                        </li>
                    </div>
                </ul>
            </div>
            <div class="boxList boxList1">
                <!-- 请输入备注信息 -->
                <van-field style="background-color:#F5F5F5" v-model="message" type="textarea" :placeholder="$t('mx_mobile.Client.1585629597955')" rows="4" autosize />
            </div>
            <div class="boxList">
                <div class="titleBox">
                    <!-- 提醒时间 -->
                    <div class="title">{{$t('mx_mobile.Client.1585304564052')}}</div>
                    <div class="content" @click="getTime()">{{reminderDate}}</div>
                </div>
            </div>
            <div class="boxList">
                <div class="titleBox">
                    <!-- 提醒人员 -->
                    <div class="title">{{$t('mx_mobile.Client.1585629594574')}}</div>
                    <div class="content" @click="peopleShow=(peopleList.length>0)">
                        <span class="peopleLabel" v-for="(item, index) in people" :key="index">
                            {{item.realName}}
                        </span>
                    </div>
                </div>
            </div>
            <van-popup v-model="custIdshow" position="right" class="custBox" :lock-scroll="false">
                <cust-lists @submitItem="submitItem"></cust-lists>
            </van-popup>
            <van-popup v-model="peopleShow" position="right" class="peopleListsBox">
                <div class="peopleLists">
                    <div class="peopleList" v-for="(item, index) in peopleList" :key="index" @click="peopleOnSelect(item)">
                        {{item.realName}}
                        <span class="iconBox" v-if="item.check">
                            <i class="iconfont icon-hook"></i>
                        </span>
                    </div>
                </div>
                <div class="dialogFooter">
                    <!-- 取消 -->
                    <div class="butCen" @click="peopleCancel()">
                        {{$t('mx_mobile.Client.1585301326634')}}
                    </div>
                    <!-- 保存 -->
                    <div class="butSave" @click="submitPeople()">
                        {{$t('mx_mobile.Client.1585301134763')}}
                    </div>
                </div>
            </van-popup>
        </div>
        <DatePickerTime ref="datePickerTime"></DatePickerTime>
    </van-popup>
</template>
<script>
import custList from '@/page/Client/CustPublic/Controls/Customer/custList/index'
import DatePickerTime from '@/components/PublicControls/DatePickerTime/index'
// import titleMixin from '@/mixin/title'
/**
 * 描述：客户管理-客户列表-新增客户
 * 作者：何俊峰
 * 时间：2017/11/21
 */
export default {
    name: 'otComment',
    title: function fc() { return this.$t('mx_mobile.Client.1585629730382') }, // '批注'
    // mixins: [titleMixin],
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: ''
        },
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            commentFlag: '1',
            show: false,
            cust: {
                custName: '',
                custId: ''
            },
            message: '',
            custIdshow: false,

            reminderDate: '',

            people: [],
            peopleList: [],
            peopleShow: false,
            // isHeight: window.screen.availHeight,
            arrMailSign: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'] // 标记组
        }
    },
    created() {
    },
    computed: {
    },
    methods: {
        setMenu() {
            setTimeout(() => {
                let setMenu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Client.1585301134763'))// 保存
                setMenu.onClick(() => {
                    this.submitAddFrom()
                })
            }, 100)
        },
        closeThis() {
            this.show = false
            this.$emit('setMenu')
            this.$emit('setTitle')
        },
        peopleOnSelect(item) {
            item.check = !item.check
        },
        async getTime() {
            this.$refs.datePickerTime.getTime((res) => {
                this.reminderDate = res
            })
        },
        submitPeople() {
            let list = []
            this.peopleList.forEach(element => {
                if (element.check) {
                    list.push(element)
                }
            })
            this.people = list
            this.peopleShow = false
        },
        peopleCancel() {
            this.peopleList.forEach(element => {
                let isHave = false
                this.people.forEach(item => {
                    if (element.ctId == item.ctId) {
                        isHave = true
                    }
                })
                element.check = isHave
            })
            this.peopleShow = false
        },
        submitItem(value) {
            this.cust = {
                custName: value.custName,
                custId: value.custId
            }
            this.custIdshow = false
        },
        setCommentsColor(flag) {
            return this.Global.utils.commentsColor(flag)
        },
        // 初始第一个下拉先 <-_->
        getData() {
            let _this = this
            let contactData = {
                dataType: 'contact',
                optCode: _this.optCode,
                funType: 'withRight',
                moduleCode: _this.moduleCode
            }
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.accountDropList_get, { params: contactData }).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    let peopleList = res.data.data
                    peopleList.forEach(element => {
                        element.check = false
                    })
                    _this.peopleList = peopleList
                } else {
                    _this.peopleList = []
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        openWindow() {
            this.cust = {
                custName: this.item.custName,
                custId: this.item.custId
            }
            this.getData()
            this.setMenu()
            this.setHeaderTitle(this.$t('mx_mobile.Client.1585629730382')) // 批注
            this.show = true
        },
        submitAddFrom() {
            let _this = this
            let people = []
            this.people.forEach(element => {
                people.push(element.ctId)
            })
            let data = {
                moduleCode: _this.moduleCode,
                identFieldValue: _this.cust.custId + '',
                commentFlag: _this.commentFlag,
                content: _this.message,
                noticeOwner: '0',
                targets: people.join(','),
                sourceId: 'mobile',
                deliveryTime: _this.reminderDate + ':00'
            }
            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: this.$t('mx_mobile.Client.1585300903213') // 保存中...
            })
            _this.axios.post(_this.Global.config.apiBaseURL + _this.Global.api.v2.billComment_add, data).then(function (res) {
                _this.$toast.clear()
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    _this.$emit('getListData')
                    _this.closeThis()
                    _this.$toast.success(res.data.msg)
                } else {
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.clear()
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        }
    },
    // watch: {
    //     isHeight(val, old) {
    //         if (this.isHeight != val) {
    //             this.isHeight = val
    //             console.log('屏幕的高度', this.isHeight)
    //         }
    //     }
    // },
    components: {
        'cust-lists': custList,
        'DatePickerTime': DatePickerTime
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

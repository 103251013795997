<template>
    <span class="region">
        <span class="list">{{showName}}</span>
    </span>
</template>
<script>
export default {
    name: 'Controls',
    props: {
        value: {
            type: String,
            default: ''
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        item: {
            type: Object,
            default: function () {
                return {}
            }
        },
        items: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            showName: ''
        }
    },
    created() {
        if (this.item.fieldName == 'accountperDesc') {
            this.showName = this.items.accountperDesc || ''
        } else {
            this.getOwerData()
        }
    },
    mounted() {
    },
    methods: {

        // 初始第一个下拉先 <-_->
        getOwerData() {
            let key = ''
            let options = []
            if (this.items.accountPeriod) {
                key = this.item.dictCode
            }
            this.cusBoxValue.forEach(element => {
                if (element.dictCode == key) {
                    options = element.customDictitems
                }
            })
            for (let index = 0; index < options.length; index++) {
                const element = options[index]
                if (element.dictItemCode == this.items.accountPeriod) {
                    this.showName = element.itemName
                }
            }
        }
    },
    components: {
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.control {
    margin-bottom: 0;
}
</style>

<template>
    <div class="Detail">
        <loading-new :loading="loading"></loading-new>
        <!-- <scroll ref="scroll" :pullUpLoad="enablePullup" @pullingUp="onPullingUp" :preventDefault="false" v-if="!loading" class="wrapper"> -->
            <div v-if="!loading">
                <div class="detailBox">
                    <cust-detail-view ref="custDetailView" :item="item" :listLabels="listLabels" :personnelTable="contactValue" :sysBoxValue="sysBoxValue" @optClick="optClick"></cust-detail-view>
                </div>
                <van-dialog v-model="showOther" show-cancel-button :before-close="beforeClose">
                    <div class="optOtherMsg">
                        {{optOtherMsg}}
                    </div>
                </van-dialog>
                <div class="detailTab" v-if="showTab">
                    <van-tabs v-model="activeTab" :line-width="24" sticky @click="onClick" disabled>
                        <!-- 详情 -->
                        <van-tab :title="$t('mx_mobile.Client.1585276644929')" v-show="showTab">
                            <div class="tabContent">
                                <component :detailData="item" :sysBoxValue="sysBoxValue" :currency="currency" :AONIcustSort="AONIcustSort" :cusBoxValue="cusBoxValue" :detailSet="detailSet" v-bind:is="'funcMark1'" :moduleCode="moduleCode" ref="funcMark"></component>
                            </div>
                        </van-tab>
                        <van-tab v-for="(items,index) in tabList" :key="index" :title="items.cnStrucName">
                            <div class="tabContent">
                                <component :detailData="item" :categoryVal="categoryVal" :searchGroupIdValue="searchGroupIdValue" :fjTitle="fjTitle" :groupArr="groupArr" :listTab="listTab" v-bind:is="fg(items)" @toSendEmail="toSendEmail" :sysBoxValue="sysBoxValue" :moduleCode="moduleCode" :personnelTable="contactValue" :listLabels="listLabels" :ref="'funcMark'+items.strucId" :detailOptBF003="detailOptBF003" @handleShow="handleShow" :bindModuleCode="items.bindModuleCode"></component>
                            </div>
                        </van-tab>
                    </van-tabs>
                </div>
                <otSendEmail @setTitle="setTitle" @setMenu="setMenu" @getListData="getData" ref="otSendEmail" :moduleCode="moduleCode" optCode="otSendEmail" :item="item"></otSendEmail>
                <otReceive @setTitle="setTitle" @setMenu="setMenu" @getListData="getData" ref="otReceive" :moduleCode="moduleCode" optCode="otReceive" :item="item"></otReceive>
                <otDistribution @setTitle="setTitle" @setMenu="setMenu" @getListData="getData" ref="otDistribution" :moduleCode="moduleCode" optCode="otDistribution" :item="item"></otDistribution>
                <upload-data ref="uploadData"></upload-data>
            </div>
        <!-- </scroll> -->
        <otReminder @setTitle="setTitle" @setMenu="setMenu" @getListData="getData" ref="otReminder" :moduleCode="moduleCode" optCode="otReminder" :item="item"></otReminder>
        <otAddShare @setTitle="setTitle" @setMenu="setMenu" @getListData="getData" ref="otAddShare" :moduleCode="moduleCode" optCode="otAddShare" :item="item"></otAddShare>
        <otTransfer @setTitle="setTitle" @setMenu="setMenu" @getListData="getData" ref="otTransfer" :moduleCode="moduleCode" optCode="otTransfer" :item="item"></otTransfer>
        <otComment @setTitle="setTitle" @setMenu="setMenu" @getListData="getData" ref="otComment" :moduleCode="moduleCode" optCode="otComment" :item="item"></otComment>
        <filter-box :moduleCode="moduleCode" :isScreen="show" :categoryVal="categoryVal" :listTab="listTab" :searchId="searchId" @determineClick="determineClick" @cancelClick="cancelClick" @categoryValue="categoryValue" @searchGroupId="searchGroupIdVal" @groupArr="groupArrData" @listTabValue="listTabVal" @enclosureTitle="enclosureTitle"></filter-box>
    </div>
</template>
<script>
import filterBox from './funcMark/funcMark6/Filterbox/index.vue'
import { mapGetters } from 'vuex'
import custDetailView from '../../CustPublic/custDetailView/index'
import loadingNew from '../../CustPublic/LoadingVue/index.vue'
import otComment from '@/page/Client/CustPublic/optVue/otComment/index'
import otTransfer from '@/page/Client/CustPublic/optVue/otTransfer/index'
import otAddShare from '@/page/Client/CustPublic/optVue/otAddShare/index'
import otReminder from '@/page/Client/CustPublic/optVue/otReminder/index'
import otSendEmail from '@/page/Client/CustPublic/optVue/otSendEmail/index'
import otReceive from '@/page/Client/CustPublic/optVue/otReceive/index'
import otDistribution from '@/page/Client/CustPublic/optVue/otDistribution/index'
import uploadData from '@/components/UploadDataFile/index'
import Scroll from '@/components/Scroll/index'
import funcMark from './funcMark/index.js'

export default {
    name: 'DetailTel',
    props: {
        viewType: {
            type: String,
            default: ''
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        },
        currency: {
            type: Array,
            default: function () {
                return []
            }
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            fjTitle: this.$t('mx_mobile.Client.1631864216033'),
            groupArr: [],
            searchId: '',
            searchGroupIdValue: '',
            listTab: 'listTab1',
            categoryVal: 'list',
            show: false,
            billId: '',
            pageUrl: '',
            listLabels: [],
            item: {},
            activeTab: 0,
            tabList: [],
            moduleCode: 'BF001',
            detailSet: [],
            showTab: false,
            detailOpt: [],
            showOther: false,
            optOtherMsg: '',
            optItem: {},
            loading: true,
            fileClear: true,
            detailOptBF003: [],
            currentTag: 0,
            pullUpLoadFlag: false,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: this.$t('mx_mobile.Client.1585271749707'), // 加载更多
            pullUpLoadNoMoreTxt: this.$t('mx_mobile.Client.1585271760385') // 没有更多数据了
        }
    },
    async created() {
        this.billId = this.$route.params.id
        await this.getItemData()
        this.unit()
    },
    computed: {
        ...mapGetters([
            'contactValue' // 全部人员
        ]),
        enablePullup() {
            return this.pullUpLoadFlag ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        }
    },
    mounted() {
        this.setMenu()
    },
    methods: {
        // tab项点击选择的文件, 待补充
        fg(item) {
            let obj = {
                2: 'funcMark2', // 动态
                1003: 'funcMark2',
                3: 'funcMark3', // 提醒
                1004: 'funcMark3',
                4: 'funcMark4', // 联系人
                5: 'funcMark5', // 共享协作
                1005: 'funcMark5',
                6: 'funcMark6', // 附件
                1001: 'funcMark6',
                7: 'funcMark7', // 下级客户
                8: 'funcMark8', // 操作记录
                1002: 'funcMark8',
                9: 'funcMark9' // 邮件往来
            }
            return obj[item.strucId]
        },
        enclosureTitle(v) {
            if (this.fjTitle != v) {
                this.fjTitle = v
            }
        },
        groupArrData(v) {
            if (this.groupArr != v) {
                this.groupArr = v
            }
        },
        // 点击确定传的值
        determineClick(value) {
            this.show = value
            let obj = this.$refs['funcMark' + this.currentTab.strucId]
            if (obj[0]) {
                obj[0].getData()
            }
        },
        // 点击取消传的值
        cancelClick(value) {
            this.show = value
        },
        searchGroupIdVal(v) {
            if (this.searchGroupIdValue != v) {
                this.searchGroupIdValue = v.toString()
                this.searchId = v.toString()
                // this.$refs.funcMark4[0].getData()
                // this.getData()
            }
        },
        // 区分全部客户附件还是邮件附件
        listTabVal(item) {
            if (item || this.listTab != item) {
                this.listTab = item
                // this.$refs.funcMark4[0].getData()
                // this.getData()
            }
        },
        categoryValue(v) {
            if (this.categoryVal != v) {
                this.categoryVal = v
                // this.$nextTick(() => {
                //     this.$refs.funcMark4[0].getData()
                // })
            }
        },
        handleShow() {
            this.show = true
        },
        setMenu() {
            let setMenu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Client.1585276758192')) // 更多
            setMenu.onClick(() => {
                this.spaceAction()
            })
        },
        beforeLeave(next) {
            const flag = this.Global.utils.chosen.close()
            const flag2 = this.Global.utils.actionSheet.close()
            const flag3 = this.Global.utils.prompt.close()
            if (flag || flag2 || flag3) {
                next(false)
            } else if (this.$refs.otComment && this.$refs.otComment.show) {
                if (this.$refs.otComment.custIdshow) {
                    this.$refs.otComment.custIdshow = false
                } else if (this.$refs.otComment.peopleShow) {
                    this.$refs.otComment.peopleShow = false
                } else {
                    this.$refs.otComment.closeThis()
                }
                next(false)
            } else if (this.$refs.otTransfer && this.$refs.otTransfer.show) {
                this.$refs.otTransfer.closeThis()
                next(false)
            } else if (this.$refs.otAddShare && this.$refs.otAddShare.show) {
                this.$refs.otAddShare.closeThis()
                next(false)
            } else if (this.$refs.otReminder && this.$refs.otReminder.show) {
                if (this.$refs.otReminder.custIdshow) {
                    this.$refs.otReminder.custIdshow = false
                } else {
                    this.$refs.otReminder.closeThis()
                }
                next(false)
            } else if (this.$refs.otSendEmail && this.$refs.otSendEmail.show) {
                this.$refs.otSendEmail.closeThis()
                next(false)
            } else if (this.$refs.otReceive && this.$refs.otReceive.showDep) {
                this.$refs.otReceive.closeThis()
                next(false)
            } else if (this.$refs.otDistribution && this.$refs.otDistribution.show) {
                this.$refs.otDistribution.closeThis()
                next(false)
            } else if (this.$refs.custDetailView && this.$refs.custDetailView.showDynamics) {
                this.$refs.custDetailView.showDynamics = false
                next(false)
            } else {
                next()
            }
        },
        setTitle() {
            this.setHeaderTitle(this.$t('mx_mobile.Client.1584683900186')) // 客户信息
        },
        async updateData(id, load) {
            if (!load) {
                this.loading = true
            }
            this.billId = id
            this.activeTab = '0'
            await this.getItemData()
            this.unit()
            this.setMenu()
        },
        toOtherPage(url) {
            this.$router.push({ path: url, query: this.g_setQuery() })
        },
        optClick(item) {
            console.log('嗷嗷，被触发看', item)

            this.optItem = item
            this.rightCheck(item)
        },
        toSendEmail() {
            this.$refs.otSendEmail.openWindow()
        },
        rightCheck(item) {
            let _this = this
            if (item.optCode == 'otAddAttach') {
                _this.uploadDingFile()
                return
            }
            let obj = {
                moduleCode: 'BF001',
                identFieldValue: _this.item.custId,
                optCode: item.optCode
            }
            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: _this.$t('mx_mobile.Client.1585281647545') // 加载中...
            })
            _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.document_rightCheck_do, { params: obj }).then(function (res) {
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    // _this.$toast.success(res.data.msg)
                    _this.$toast.clear() // 关闭遮罩
                    if (item.optCode == 'otNew') {
                        let data = {
                            key: 'custId',
                            value: _this.item
                        }
                        _this.Global.utils.setItem('addObj' + item.optModuleCode, JSON.stringify(data))
                        _this.toOtherPage('/client/add/' + item.optModuleCode)
                    } else if (item.optCode == 'otEdit') {
                        let data = _this.item
                        _this.Global.utils.setItem('editObj' + item.optModuleCode, JSON.stringify(data))
                        _this.toOtherPage('/client/edit/' + item.optModuleCode + '/' + _this.billId)
                    } else if (item.optCode == 'otDistribution' || item.optCode == 'otReceive' || item.optCode == 'otAddShare' || item.optCode == 'otSendEmail' || item.optCode == 'otComment' || item.optCode == 'otReminder' || item.optCode == 'otTransfer') {
                        _this.$refs[item.optCode].openWindow()
                    } else if (item.optCode == 'otFocus') {
                        _this.optEvent(_this.optItem)
                    } else {
                        _this.optOtherMsg = _this.$t('mx_mobile.Client.1627010069815') + item.optName + _this.$t('mx_mobile.Client.1627010202702') // 是否要对此客户进行  操作
                        _this.showOther = true
                    }
                } else {
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        },
        async uploadDingFile() {
            // try {
            let option = {
                url: this.Global.config.apiBaseURL + this.Global.api.v2.folders_files,
                data: {
                    url: 'v2/folders/files',
                    fileSource: 3,
                    billId: this.item.custId,
                    custId: this.item.custId,
                    remarks: '',
                    moduleCode: this.moduleCode
                }
            }
            let res = await this.$refs.uploadData.open(option)
            if (res.code.toString() == this.Global.config.RES_OK) {
                this.$toast.success(this.$t('mx_mobile.Client.1627010815459')) // 上传成功
                this.getData()
            } else {
                this.$toast.fail(res.msg)
            }
            // } catch (error) {
            //     console.log(error)
            // }
        },
        beforeClose(action, done) {
            if (action === 'confirm') {
                this.optEvent(this.optItem, done)
            } else {
                done()
            }
        },
        optEvent(item, done) {
            let _this = this
            let optCode = item.optCode
            if (optCode == 'otFocus' && item.optCodes) {
                optCode = item.optCodes
            }
            let data, url
            data = {
                optModuleCode: item.optModuleCode,
                identFieldValue: _this.billId,
                optCode: optCode
            }
            url = _this.Global.api.v2.document_operate_detailOpt_put
            _this.axios.put(_this.Global.config.apiBaseURL + url, data).then(function (res) {
                if (res.data.code.toString() === _this.Global.config.RES_OK) {
                    _this.$toast.success(res.data.msg)
                    if (item.optCode == 'otDelete' || item.optCode == 'otPutSeas') {
                        _this.$router.back()
                    } else {
                        _this.getData()
                    }
                    if (done) { done() }
                } else {
                    _this.$toast.fail(res.data.msg)
                    if (done) { done() }
                }
            }, function (res) {
                _this.$toast.fail(_this.Global.config.errorTitle)
                if (done) { done() }
            })
        },
        async spaceAction() {
            console.log('aaaaaaa', this.detailOpt)

            try {
                let option = []
                this.detailOpt.forEach(items => {
                    if (items.optCode != 'otPrint' && items.optCode != 'otTag' && !(items.optCode == 'otNew' && items.optModuleCode == 'WO001')) {
                        items.name = items.optName
                        option.push(items)
                    }
                })
                let res = await this.Global.utils.actionSheet.open(option)
                this.optClick(res)
            } catch (error) {
                console.log(error)
            }
        },
        // onClick(index) {
        //     this.currentTab = this.tabList[this.activeTab - 1]
        //     console.log('被点击后的tab', index, this.activeTab, this.currentTab.strucId, this.tabList)
        //     let name = 'funcMark' + (index - 1)
        //     this.currentTag = (index - 0 + 1)
        //     if (index != '0' && this.$refs[name] && this.$refs[name][0] && this.$refs[name][0].getData) {
        //         this.$refs[name][0].getData()
        //     }
        //     if (this.currentTag === 9) {
        //         this.pullUpLoadFlag = true
        //         this.$refs[name][0].initPage()
        //         this.$refs[name][0].getData()
        //     } else {
        //         this.pullUpLoadFlag = false
        //     }
        // },
        onClick() {
            this.currentTab = this.tabList[this.activeTab - 1]
            if (this.currentTab) {
                let strucId = this.currentTab.strucId
                let res = this.fg(this.currentTab)
                if (strucId != '0' && this.$refs[res] && this.$refs[res][0] && this.$refs[res][0].getData) {
                    this.$refs[res][0].getData()
                }
                if (strucId === 9) {
                    this.pullUpLoadFlag = true
                    this.$refs[res][0].initPage()
                    this.$refs[res][0].getData()
                } else {
                    this.pullUpLoadFlag = false
                }
            }
        },
        async getItemData() {
            let _this = this
            let data = {
                moduleCode: 'BF001',
                searchType: 'detail',
                identFieldValue: _this.billId
            }
            let res = await _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.document_generalOperate_get, { params: data }).catch(() => {
                _this.$router.back()
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
            if (res.data.code.toString() === _this.Global.config.RES_OK) {
                _this.item = res.data.data
            } else {
                _this.$router.back()
                _this.$toast.fail(res.data.msg)
            }
        },
        unit() {
            let _this = this
            var p3 = new Promise((resolve, reject) => {
                _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.label_get, { params: { searchType: 'list', moduleCode: 'BF001' } }).then((res) => {
                    if (res.data.code.toString() === _this.Global.config.RES_OK) {
                        resolve(res.data.data)
                    } else {
                        _this.$router.back()
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$router.back()
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })

            var p4 = new Promise((resolve, reject) => {
                _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.moduleStruct + 'BF001', { params: { fieldValue: _this.item.custId } }).then((res) => {
                    if (res.data.code.toString() === _this.Global.config.RES_OK) {
                        resolve(res.data.data)
                    } else {
                        _this.$router.back()
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$router.back()
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })

            var p5 = new Promise((resolve, reject) => {
                _this.axios.get(this.Global.config.apiBaseURL + _this.Global.api.v2.fieldShow_get, {
                    params: {
                        moduleCode: 'BF001',
                        type: 'viewSet'
                    }
                }).then((res) => {
                    if (res.data.code.toString() === _this.Global.config.RES_OK) {
                        resolve(res.data.data)
                    } else {
                        _this.$router.back()
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$router.back()
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })
            var p6 = new Promise((resolve, reject) => {
                let data = {
                    moduleCode: 'BF001',
                    viewType: _this.viewType
                }
                this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.moduleOptSet_get, { params: data }).then((res) => {
                    if (res.data.code.toString() === _this.Global.config.RES_OK) {
                        resolve(res.data.data.detailOpt)
                    } else {
                        _this.$router.back()
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$router.back()
                    _this.$toast.fail(this.Global.config.errorTitle)
                })
            })
            var p7 = new Promise((resolve, reject) => {
                let data = {
                    moduleCode: 'BF003',
                    viewType: _this.viewType
                }
                this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.moduleOptSet_get, { params: data }).then((res) => {
                    if (res.data.code.toString() === _this.Global.config.RES_OK) {
                        resolve(res.data.data.detailOpt)
                    } else {
                        _this.$router.back()
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$router.back()
                    _this.$toast.fail(this.Global.config.errorTitle)
                })
            })
            Promise.all([p3, p4, p5, p6, p7]).then(function (results) {
                _this.listLabels = results[0]
                // _this.item = results[0]
                let tabList = results[1]
                let tabList1 = []
                // if (Array.prototype.isPrototypeOf(tabList)) {
                //     tabList.filter(element => {
                //         if (element.strucType != '1' && element.strucId < 10) {
                //             tabList1.push(element)
                //         }
                //     })
                // }
                if (Array.prototype.isPrototypeOf(tabList)) {
                    tabList.filter(element => {
                        if (['2', '1003', '3', '1004', '4', '5', '1005', '6', '1001', '7', '8', '1002', '9'].includes(element.strucId + '')) {
                            tabList1.push(element)
                        }
                    })
                }
                _this.tabList = tabList1
                // console.log('客户详情数据', _this.tabList)

                _this.detailSet = _this.swidchEditSet(results[2])
                _this.detailOpt = results[3]
                _this.detailOptBF003 = results[4]
                _this.showTab = true
                _this.loading = false
            })
        },
        getData(type) {
            if (type == 'close') {
                this.$router.back()
                return false
            }
            let data = {
                moduleCode: 'BF001',
                searchType: 'detail',
                identFieldValue: this.billId
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.document_generalOperate_get, { params: data }).then((res) => {
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    this.item = res.data.data
                    this.showTab = true
                    this.loading = false
                } else {
                    this.item = {}
                    this.loading = false
                    this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                this.$toast.fail(this.Global.config.errorTitle)
            })
            this.onClick(this.activeTab)
        },
        returnGroupName(groupId) {
            switch (groupId) {
                case 1:
                    return this.$t('mx_mobile.Client.1585625326467') // '地区'
                case 3:
                    return '所属'
                case 4:
                    return this.$t('mx_mobile.Client.1585625343907') // '创建人'
                case 5:
                    return this.$t('mx_mobile.Client.1585625344676') // '修改人'
                default:
                    return ''
            }
        },
        swidchEditSet(list) { // 编辑字段数据转化
            let _this = this
            let newLList = []
            let groupFirst = []
            list.forEach((element) => {
                if (element.fieldGroup !== 0) { // 判断是否是一个组
                    let isHave = false
                    let thisGroup = ''
                    groupFirst.forEach((item) => { // 判断这个组是否出现过，出现则在groupFirst里做个标记
                        if (item == element.fieldGroup) {
                            isHave = true
                            thisGroup = item // 记住这个组id
                        }
                    })
                    if (!isHave) { // 如果没有出现过新建一个对象放入newList里面
                        let newObj = {
                            fieldId: element.fieldId,
                            fieldGroup: element.fieldGroup,
                            cnFieldCaption: _this.returnGroupName(element.fieldGroup),
                            group: []
                        }
                        newObj.group.push(element)
                        newLList.push(newObj)
                        groupFirst.push(element.fieldGroup)
                    } else { // 如果出现过就找之前创建的对象将自己放入到group数组中
                        newLList.forEach(function (them) {
                            if (them.fieldGroup == thisGroup) {
                                them.group.push(element)
                            }
                        })
                    }
                } else {
                    newLList.push(element)
                }
            })
            return newLList
        },
        async onPullingUp() {
            let isOverflow = ''
            if (this.currentTab.cnStrucName === '邮件往来') {
                isOverflow = await this.$refs['funcMark' + this.currentTab.strucId][0].getMoreData()
            }
            console.log(isOverflow, 'isOverflow')
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate(!isOverflow)
            })
        }
    },
    watch: {
        '$route': function (val, old) {
            this.$refs['scroll'] && this.$refs['scroll'].refresh()
            if (val.path.indexOf('/client/detail') != -1 || val.path.indexOf('/client/seas_detail') != -1) {
                this.setTitle()
            }
        }
    },
    components: Object.assign({
        'cust-detail-view': custDetailView,
        'loading-new': loadingNew,
        'otComment': otComment,
        'otTransfer': otTransfer,
        'otAddShare': otAddShare,
        'otReminder': otReminder,
        'otSendEmail': otSendEmail,
        'otReceive': otReceive,
        'scroll': Scroll,
        'otDistribution': otDistribution,
        'upload-data': uploadData,
        'filter-box': filterBox
    }, funcMark)
}
</script>
<style>
.Client .Detail .van-tab {
    -webkit-box-flex: 0 !important;
    -webkit-flex: 0 0 16% !important;
    flex: 0 0 19% !important;
}
</style>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

<template>
    <div class="UploadDataFile">
        <input type="file" v-show="false" ref="inputFile" :accept="accept" name="" @change="fileChange($event)" :multiple="multiple" />
    </div>
</template>

<script>
export default {
    name: 'UploadDataFile',
    props: {
        accept: {
            type: String,
            default: '*'
        },
        multiple: {
            type: Boolean,
            default: true
        },
        compress: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            option: {

            },
            limitSize: 30,
            promise: {}
        }
    },
    methods: {
        open(option) {
            return new Promise((resolve, reject) => {
                Object.assign(this.option, option)
                Object.assign(this.promise, { resolve, reject })
                this._dispatch()
            })
        },
        _dispatch() {
            this.$refs.inputFile.value = ''
            this.$refs.inputFile.click()
        },
        fileChange(e) {
            let filesData = e.target.files
            // console.log(filesData)
            // 检测文件大小限制，并复制一份待用
            let newFiles = []
            for (var i = 0; i < filesData.length; i++) {
                let fileSize = (filesData[i].size / 1048576).toFixed(2) // 转成M，并保存2位小数
                if (fileSize > this.limitSize) {
                    this.$toast.fail(`${this.$t('mx_mobile.common.1665983829972')}${this.limitSize}M`) // 有文件超过了
                    this.promise.reject({ msg: this.$t('mx_mobile.common.1665983875824') }) // 文件太大
                    return
                }
                newFiles.push(filesData[i])
            }

            let { data } = this.option
            let _this = this
            this.Global.utils.sendDataFile({
                data: Object.assign(data, this.Global.utils.getToken()),
                url: _this.option.url,
                files: newFiles,
                fileKey: _this.option.fileName || 'uploadFile',
                method: 'post',
                compress: _this.compress,
                compressStart: function () {
                    _this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true, // 禁用背景点击
                        loadingType: 'spinner',
                        message: _this.$t('mx_mobile.common.1665983909421') // 压缩中...
                    })
                },
                compressEnd: function () {
                    _this.$toast.clear()
                },
                onprogress: function (event) { // 上传进度
                },
                onloadstart: function (event) { // 开始上传
                    _this.$toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true, // 禁用背景点击
                        loadingType: 'spinner',
                        message: _this.$t('mx_mobile.common.1665983964576') // '上传中...'
                    })
                },
                onload: function (res) { // 上传成功
                    _this.promise.resolve(res)
                },
                onerror: function (event) { // 出错
                    _this.promise.reject({ msg: _this.$t('mx_mobile.Doc.1585194816901') }) // '出错了'
                },
                onabort: function (event) { // 取消上传
                },
                onloadend: function (event) { // 不管成功还是失败都执行
                    _this.$toast.clear()
                }
            })
        }

    },
    components: {

    }
}
</script>
<style lang='less' rel='stylesheet/less' scoped>
</style>

<template>
    <div class="funcMark3">
        <loading-new :loading="loading"></loading-new>
        <template v-if="!loading">
            <nodata v-if="list.length == 0" :title="title" img="noData"></nodata>
            <div v-else class="list" v-for="(item, index) in list" :key="index">
                <div>{{$m_utcOffset(item.deliveryTime).format('YYYY-MM-DD')}}</div>
                <div class="content" v-if="item.msgBody != undefined">
                    <span class="contentTit">
                        <!-- 提醒 -->
                        {{$t('mx_mobile.Client.1585279571604')}}
                    </span>
                    <span v-html="item.msgBody"></span>
                </div>
                <div class="content" v-else>
                    <span class="contentTit">
                        <!-- 日程 -->
                        {{$t('mx_mobile.Client.1585279573739')}}
                    </span>
                    <span v-html="item.body.msgBody"></span>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import loadingNew from '../../../../CustPublic/LoadingVue/index.vue'
export default {
    name: 'funcMark3',
    props: {
        detailData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        personnelTable: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            list: [],
            title: this.$t('mx_mobile.Client.1585279561580'), // 暂无提醒
            loading: true
        }
    },
    created() {
        this.getData()
    },
    mounted() {
    },
    methods: {
        getData() {
            let _this = this
            var p0 = new Promise((resolve, reject) => {
                let data = {
                    type: 'unHandleList',
                    sort: 'deliveryTime',
                    custId: _this.detailData.custId,
                    order: 'desc'
                }
                _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.message_get, { params: data }).then(function (res) {
                    if (res.data.code.toString() === _this.Global.config.RES_OK && res.data.data) {
                        resolve(res.data.data)
                    }
                }, function (res) {
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })
            var p1 = new Promise((resolve, reject) => {
                let data = {
                    sort: 'deliveryTime',
                    custId: _this.detailData.custId,
                    order: 'desc'
                }
                _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.messenger_get, { params: data }).then(function (res) {
                    if (res.data.code.toString() === _this.Global.config.RES_OK && res.data.data) {
                        resolve(res.data.data)
                    } else {
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })
            Promise.all([p0, p1]).then(function (results) {
                let backData = results[0].messageRecordList.concat(results[1].timingTaskList)
                _this.list = backData
                _this.loading = false
            })
        }
    },
    watch: {
    },
    components: {
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

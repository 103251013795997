<template>
    <div class="funcMark7">
        <loading-new :loading="loading"></loading-new>
        <custListView v-if="!loading" ref="custListView" :listData="list" :listLabels="listLabels" :personnelTable="personnelTable" :sysBoxValue="sysBoxValue"></custListView>
    </div>
</template>
<script>
import loadingNew from '../../../../CustPublic/LoadingVue/index.vue'
import custListView from '@/page/Client/CustPublic/custListView/index'
export default {
    name: 'funcMark7',
    props: {
        listLabels: {
            type: Array,
            default: function () {
                return []
            }
        },
        personnelTable: {
            type: Array,
            default: function () {
                return []
            }
        },
        detailData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            list: [],
            loading: true
        }
    },
    created() {
        this.getData()
    },
    mounted() {
    },
    methods: {
        getData() {
            let data = {
                moduleCode: 'BF001',
                order: 'desc',
                from: 0,
                size: 10,
                sort: 'createDate',
                searchType: 'lowLevelList',
                parCustId: this.detailData.custId
            }
            this.axios.get(this.Global.config.apiBaseURL + this.Global.api.v2.document_generalOperate_get, { params: data }).then((res) => {
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    this.list = res.data.data.list
                    this.loading = false
                } else {
                    this.list = []
                    this.loading = false
                    this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                this.$toast.fail(this.Global.config.errorTitle)
            })
        }
    },
    watch: {
    },
    components: {
        'custListView': custListView,
        'loading-new': loadingNew
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

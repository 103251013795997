<template>
    <div class="addVue">
        <template v-for="(item, index) in editSet">
            <div :key="index" class="list" v-if="filterCompenent(item) && (controlsKey.includes('control'+item.controlTypeId) || (groupControlsKey.includes('group'+item.fieldGroup)))">
                <template v-if="item.fieldGroup == 0">
                    <template v-if="item.controlTypeId == 0||item.controlTypeId == 14">
                        <p class="contactTitle" :key="index">{{ item.cnFieldCaption }}</p>
                    </template>
                    <template v-else-if="item.controlTypeId != 84">
                        <div class="listBox" :class="{'noPaddingLeft': isNewFollow && moduleCode == 'BF004' && item.controlTypeId == 46 && item.fieldName == 'trackContent', 'listBoxV1': isNewFollow && moduleCode == 'BF004'}">
                            <component :isNewFollow="isNewFollow" :sysBoxValue="sysBoxValue" :currency="currency" :AONIcustSort="AONIcustSort" :type="type" @relyOn="relyOn" @bindContact="bindContact" @returnFieldList="returnFieldList" :cusBoxValue="cusBoxValue" :dataId="'control'+index" :nameId="item.fieldName" :optCode="optCode" :moduleCode="moduleCode" :key="index" ref="control" v-bind:is="'control'+item.controlTypeId" :itemData="item" :checkRepeat="true" @custRepeatCheck="custRepeatCheck"></component>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div class="listBox" :class="{'noPaddingLeft': isNewFollow && moduleCode == 'BF004' && item.fieldGroup == 3, 'listBoxV1': isNewFollow && moduleCode == 'BF004'}">
                        <component :isNewFollow="isNewFollow" :sysBoxValue="sysBoxValue" :optCode="optCode" :type="type" :moduleCode="moduleCode" :key="index" :dataId="'control'+index" :cusBoxValue="cusBoxValue" v-bind:is="'group'+item.fieldGroup" ref="controlGroup" :itemData="item"></component>
                    </div>
                </template>
            </div>
        </template>
        <template v-if="isNewFollow && moduleCode == 'BF004'">
            <!-- 下次跟进时间，备注 -->
            <div class="next__follow">
                <div class="next__follow-time">
                    <div @click="getTime()">
                        <!-- 下次跟进 -->
                        <!-- 请选择下次跟进时间 -->
                        <span class="name">{{$t('mx_mobile.Client.1626945179747')}}</span><span>{{nextTrackDate ? nextTrackDate : $t('mx_mobile.Client.1626945234984')}}</span>
                        <i class="iconfont icon-time pull-right"></i>
                    </div>
                    <date-picker ref="datePickerTime" :addSecond="true"></date-picker>
                </div>
                <div class="next__follow-desc">
                    <!-- 请输入下次跟进备注 -->
                    <van-field v-model="nextTrackDesc" rows="1" autosize type="textarea" :placeholder="$t('mx_mobile.Client.1626945610414')" />
                </div>
            </div>
            <template v-for="(item, index) in belongToData">
                <div v-if="groupControlsKey.includes('group'+item.fieldGroup)" :key="JSON.stringify(item)" class="list">
                    <div class="listBox listBoxV1 noPaddingLeft">
                        <component :isNewFollow="isNewFollow" :optCode="optCode" :type="type" :moduleCode="moduleCode" :key="index" :dataId="'control'+index" v-bind:is="'group'+item.fieldGroup" ref="controlGroup" :itemData="item"></component>
                    </div>
                </div>
            </template>
            <!-- 图片:15, 附件:16 -->
            <div class="files__ware">
                <div class="openImgBox" v-if="picList.length > 0">
                    <van-collapse class="AppItem" v-model="activeImg" accordion>
                        <!-- 图片 -->
                        <!-- 个 -->
                        <!-- 收起 -->
                        <!-- 展开 -->
                        <van-collapse-item :title="$t('mx_mobile.Client.1626945668546')+' (' + picList.length +$t('mx_mobile.Client.1626945752625')+')'" name="1" :value="activeImg ? $t('mx_mobile.common.1584683536248') : $t('mx_mobile.common.1584683570755')" :is-link="false" :border="false">
                            <div v-for="(item, index) in picList" :key="index" class="list">
                                <img :src="picUrl(item,'75x75')" alt="" @click="showImg(index)">
                                <span class="iconBox" @click="delFile(index, 1)">
                                    x
                                </span>
                            </div>
                        </van-collapse-item>
                    </van-collapse>
                </div>
                <div class="show-img-annex" v-if="fileLists.length > 0">
                    <van-collapse class="AppItem" v-model="activeFile" accordion>
                        <!-- 附件 -->
                        <!-- 个 -->
                        <!-- 收起 -->
                        <!-- 展开 -->
                        <van-collapse-item :title="$t('mx_mobile.Client.1626945917030')+' (' + fileLists.length + $t('mx_mobile.Client.1626945752625')+')'" name="2" :value="activeFile ? $t('mx_mobile.common.1584683536248'): $t('mx_mobile.common.1584683570755')" :is-link="false" :border="false">
                            <div @click="previewImage(item10)" class="iconBox-ware" v-for="(item10, index10) in fileLists" :key="index10">
                                <div class="icon-img">
                                    <!-- <img v-if="isImage(substringSuffix(item10.name))" class="iconIMG" :src="item10.url" alt="."> -->
                                    <svg class="iconSVG" aria-hidden="true">
                                        <use :xlink:href="getIcon(substringSuffix(item10.name))"></use>
                                    </svg>
                                </div>
                                <div class="contentBox">
                                    <div class="content">
                                        <p class="fileName ellipsis" v-html="item10.name"></p>
                                        <p class="fileInfo ellipsis" :size="Global.utils.byteCalc(item10.size)">
                                            {{converSize(item10.size)}}
                                        </p>
                                    </div>
                                </div>
                                <i @click.stop="delFile(index10, 2)" class="iconfont icon-remove pull-right"></i>
                            </div>
                        </van-collapse-item>
                    </van-collapse>
                </div>
            </div>
            <div class="upload_button" v-show="true">
                <!-- 图片 -->
                <i class="icon iconfont icon-photo upload_file" @click="uploadFiles(1)" :title="$t('mx_mobile.Client.1626945668546')"></i>
                <!-- 附件 -->
                <i class="icon iconfont icon-attachment upload_file" @click="uploadFiles(2)" :title="$t('mx_mobile.Client.1626945917030')"></i>
                <input v-show="false" type="file" ref="inputFile1" class="PictureAddFile" accept="image/*" :multiple="true" @change="onChange" />
                <input v-show="false" type="file" ref="inputFile2" class="PictureAddFile" accept="." @change="onChange" />
            </div>
        </template>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ImagePreview } from 'vant'
import Controls from '@/page/Client/CustPublic/Controls/index.js'
import GroupControls from '@/page/Client/CustPublic/GroupControls/index.js'
import DatePickerTime from '@/components/PublicControls/DatePickerTime/index'
export default {
    name: 'addVue',
    props: {
        editSet: {
            type: Array,
            default: function () {
                return []
            }
        },
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: 'otNew'
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        AONIcustSort: {
            type: Array,
            default: function () {
                return []
            }
        },
        currency: {
            type: Array,
            default: function () {
                return []
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            picList: [],
            fileLists: [],
            maxNum: 9,
            maxNumBase: 9,
            nextTrackDate: '', // 下次跟进时间
            nextTrackDesc: '', // 下次跟进备注
            fileType: '', // 1-图片, 2-附件
            activeImg: '1',
            activeFile: '2',
            custRepeatType: false,
            controlsKey: Object.freeze([]),
            groupControlsKey: Object.freeze([])
        }
    },
    created() {
        this.controlsKey = Object.freeze(Object.keys(Controls))
        this.groupControlsKey = Object.freeze(Object.keys(GroupControls))
    },
    computed: {
        ...mapGetters(['greyFunctions']),
        isNewFollow() {
            if (this.greyFunctions.includes('BF004__newFollow')) {
                return true
            } else {
                return false
            }
        },
        belongToData() {
            // 分离所属字段
            let belongTo = []
            if (this.isNewFollow && this.moduleCode == 'BF004') {
                belongTo = this.editSet.filter(item => item.hasOwnProperty('fieldGroup') && item.fieldGroup == 3)
                return [...belongTo]
            } else {
                return [...belongTo]
            }
        }
    },
    mounted() {
    },
    methods: {
        // 冲突检测 - 客户名称是否冲突
        custRepeatCheck(type) {
            this.custRepeatType = type
        },
        isImage(exe) {
            const imgArr = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
            if (exe) {
                return imgArr.includes(exe.toLowerCase())
            }
        },
        substringSuffix(name) {
            let str = name.substr(name.lastIndexOf('.') + 1)
            return str
        },
        getSize(size) {
            if (size > 1024) {
                return parseFloat(size / 1024).toFixed(2) + ' M'
            } else {
                return parseFloat(size).toFixed(2) + ' KB'
            }
        },
        getIcon(fileExtName) {
            return `#file-${this.Global.utils.isHasSuffix(fileExtName)}`
        },
        async previewImage(item) {
            try {
                let suffix = this.substringSuffix(item.name || '')
                if (!this.Global.utils.filePreview.isSupport(suffix)) {
                    this.$toast(this.$t('mx_mobile.Client.1585279886218')) // 暂不支持该格式文件预览
                    return
                }
                let link = item.url
                if (link.indexOf('http') == -1) {
                    link = this.Global.config.previewUrl + link
                }

                this.Global.utils.filePreview.open(link, suffix, `${item['fileName'] || item['name'] || (new Date().getTime() % 100000)}.${suffix}`)
            } catch (error) {
                alert(error)
            }
        },
        picUrl(picId, size) {
            return this.getGlobalImgSrc(picId, size)
        },
        delFile(indexof, type) {
            if (type == 1) {
                this.picList.splice(indexof, 1)
            } else {
                this.fileLists.splice(indexof, 1)
            }
        },
        showImg(index) {
            let list = []
            this.picList.forEach(element => {
                list.push(this.picUrl(element, 'orig'))
            })
            ImagePreview({
                images: list,
                startPosition: index
            })
        },
        uploadFiles(type) {
            this.fileType = type
            if (type == 1) {
                if (this.picList.length < this.maxNumBase) {
                    this.$refs['inputFile' + type].click()
                } else {
                    this.$toast.fail(this.$t('mx_mobile.Client.1626947995845') + this.maxNumBase + this.$t('mx_mobile.Client.1626945668546')) // 最多上传 张图片
                }
            } else {
                this.$refs['inputFile' + type].click()
            }
        },
        onChange(e) {
            let file = Array.from(e.target.files)
            if (this.fileType == 1) {
                // 图片限制9张, 附件不限制
                let totalNum = file.length + this.picList.length
                if (totalNum > this.maxNumBase) {
                    // 选择数量超过最大限制数量, 目前还可上传   张, 请重新选择!
                    this.$toast.fail(this.$t('mx_mobile.Client.1626947995845') + (this.maxNumBase - this.picList.length) + this.$t('mx_mobile.Client.1626948295971'))
                    return
                }
            }
            if (file.length > 0) {
                file.forEach(element => {
                    let files = []
                    files.push(element)
                    let data = {
                        url: this.Global.config.uploadUrl,
                        FileList: files,
                        onprogress: (event) => { // 上传进度
                            this.$toast.clear()
                            this.$toast.loading({
                                duration: 0,
                                loadingType: 'spinner',
                                message: this.$t('mx_mobile.Client.1626948374297') + '...' + event.percent + '%' // 上传中
                            })
                        },
                        onloadstart: (event) => { // 开始上传
                            this.$toast.loading({
                                duration: 0,
                                loadingType: 'spinner',
                                message: this.$t('mx_mobile.Client.1626948374297') + '...'// 上传中
                            })
                        },
                        onload: (res) => { // 上传成功
                            this.$toast.clear()
                            this.fileUploadSuccess(res)
                        }
                    }
                    this.Global.utils.fileUpload(data)
                })
            }
        },
        // 图片更新成功的返回
        fileUploadSuccess(res) {
            if (this.fileType == 1) {
                this.picList.push(res.data)
                this.maxNum = this.maxNumBase - this.picList.length
                this.inputHave = false
                this.$nextTick(() => {
                    this.inputHave = true
                })
            } else {
                this.fileLists.push({
                    name: res.name,
                    size: res.size,
                    attachmentId: res.data,
                    url: res.data
                })
            }
        },
        async getTime() {
            this.$refs.datePickerTime.getTime((res) => {
                this.nextTrackDate = res
            })
        },
        filterCompenent(item) {
            // 语音输入:104-voiceUrl, 语音日期:1-voiceDate, 语音时间长度:1-voiceSecond, 图片:15, 附件:16,
            // 下次跟进时间:nextTrackDate, 下次跟进备注:nextTrackDesc
            if (this.isNewFollow && this.moduleCode == 'BF004') {
                if (item.controlTypeId == 104 ||
                    item.fieldName == 'voiceDate' ||
                    item.fieldName == 'voiceSecond' ||
                    item.controlTypeId == 15 ||
                    item.controlTypeId == 16 ||
                    item.fieldName == 'nextTrackDate' ||
                    item.fieldName == 'nextTrackDesc' ||
                    item.fieldGroup == 3
                ) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        },
        relyOn(value, name) {
            let _this = this
            _this.editSet.forEach(function (item) {
                if (item.parFilterField == name) {
                    _this.$refs['control'].forEach((items) => {
                        if (items.$attrs.nameId == item.fieldName) {
                            items.getData(value, true) // 这里客户联动联系人不生效，因为选择客户的时候联系人组件不能载入，只能从数据层面直接写入(bindContact)
                        }
                    })
                }
            })
        },
        bindContact(custContacts, name) {
            let _this = this
            _this.editSet.forEach(function (item) {
                if (item.parFilterField == name) {
                    _this.$refs['control'].forEach((items) => {
                        if (items.$attrs.nameId == item.fieldName) {
                            if (custContacts && custContacts.length > 0) {
                                items.submitItem(custContacts[0])
                            }
                        }
                    })
                }
            })
        },
        returnFieldList(data, field) {
            let _this = this
            let fieldList = field.split(';')
            fieldList.forEach(element => {
                if (element != '') {
                    let elementList = element.split('=')
                    if (elementList[0] && elementList[1]) {
                        _this.$refs['control'].forEach((items) => {
                            if (items.$attrs.nameId == elementList[0]) {
                                let value = data[elementList[1]]
                                items.updates(value)
                            }
                        })
                    }
                }
            })
        },
        update(obj) {
            let obj1 = JSON.parse(obj)
            if (this.Global.utils.isArray(obj1)) {
                this.$refs['control'].forEach((item) => {
                    obj1.forEach(element => {
                        if (element.key == item.$attrs.nameId) {
                            item.updates(element.value)
                        }
                    })
                })
            } else {
                this.$refs['control'].forEach((item) => {
                    if (obj1.key == item.$attrs.nameId) {
                        item.updates(obj1.value)
                    }
                })
            }
        },
        updates(obj) {
            let obj1 = JSON.parse(obj)
            let _this = this
            setTimeout(function () {
                _this.$refs['control'].forEach((item) => {
                    if (obj1[item.$attrs.nameId]) {
                        item.updates(obj1[item.$attrs.nameId])
                    }
                })
                _this.$refs['controlGroup'].forEach((item) => {
                    item.updates(obj1)
                })
            }, 20)
        },
        updateCard(obj) {
            this.$nextTick(() => {
                this.$refs['control'].forEach((item) => {
                    if (obj[item.$attrs.nameId]) {
                        item.updates(obj[item.$attrs.nameId])
                    }
                })
                this.$refs['controlGroup'].forEach((item) => {
                    item.updates(obj)
                })
            })
        },
        submit() {
            let data = {}
            let isCheck = true
            if (this.$refs['control']) {
                this.$refs['control'].forEach((item) => {
                    let itemData = item.submit && item.submit()
                    if (itemData) {
                        data = Object.assign(data, itemData)
                    } else {
                        isCheck = false
                        return false
                    }
                })
            }
            if (this.$refs['controlGroup']) {
                this.$refs['controlGroup'].forEach((item) => {
                    let itemData = item.submit && item.submit()
                    if (itemData) {
                        data = Object.assign(data, itemData)
                    } else {
                        isCheck = false
                        return false
                    }
                })
            }
            if (this.isNewFollow && this.moduleCode == 'BF004') {
                // 新增跟进
                data['attachmentFile'] = this.fileLists.map(v => {
                    let obj = { ...v }
                    delete obj.url
                    return { ...obj }
                })
                data['imagesId'] = [...this.picList]
                data['nextTrackDate'] = this.nextTrackDate
                data['nextTrackDesc'] = this.nextTrackDesc
            }
            data['custRepeatType'] = this.custRepeatType
            if (isCheck) {
                return data
            } else {
                return false
            }
        }
    },
    watch: {

    },
    components: Object.assign({}, Controls, GroupControls, {
        'date-picker': DatePickerTime
    })
}
</script>

<style lang="less" scoped>
.addVue {
    .margin-bottom(10px);
    .list {
        .listBox {
            .padding-left(0);
            .margin-left(16px);
            .min-height(45px);
            .line-height(45px);
            &.listBoxV1 {
                .padding-left(16px);
                .margin-left(0px);
            }
            border-bottom: 1px solid #eaedef;
            &.noPaddingLeft {
                padding-left: 0px;
            }
        }
        .contactTitle {
            .height(45px);
            .line-height(45px);
            background: #f5f5f5;
            .padding-left(16px);
            .font-size(14px);
        }
    }
    .list:last-child {
        .listBox {
            border-bottom: 0;
        }
    }
    .next__follow {
        color: #909399;
        border-bottom: 1px solid #eaedef;
        .padding-left(16px);
        .next__follow-time {
            .height(30px);
            .line-height(30px);
            .padding-right(16px);
            .name {
                display: inline-block;
                .width(100px);
            }
        }
        .next__follow-desc {
            .padding-left(100px);
            &:lang(en) {
                .padding-left(130px);
            }
            .padding-right(16px);
            /deep/ .van-cell {
                .padding(10px, 0px);
            }
        }
    }
    .files__ware {
        .list {
            vertical-align: top;
            .width(75px);
            .height(75px);
            display: inline-block;
            .margin(5px);
            .margin-left(0);
            .margin-right(10px);
            line-height: 75px;
            text-align: center;
            position: relative;
            > img {
                .width(75px);
                .height(75px);
                vertical-align: top;
            }
            .iconBox {
                position: absolute;
                .width(19px);
                .line-height(14px);
                .height(19px);
                background: rgba(0, 0, 0, 0.6);
                border: 2px solid rgba(255, 255, 255, 1);
                border-radius: 50%;
                color: white;
                .font-size(12px);
                font-weight: bold;
                .right(-4px);
                .top(-4px);
            }
        }
        /deep/ .van-cell {
            .padding(10px, 16px, 10px, 0px);
        }
        /deep/ .van-collapse-item__content {
            .padding(0px, 16px, 0px, 0px);
        }
        /deep/ .van-hairline--top-bottom::after,
        .van-hairline-unset--top-bottom::after {
            border: none;
        }
        .AppItem {
            .padding-left(16px);
            .font-size(14px);
            border-bottom: 1px solid #eaedef;
        }
        .show-img-annex {
            font-size: 0;
            .iconBox-ware {
                position: relative;
                display: inline-block;
                width: 100%;
                .margin-right(10px);
                .margin-bottom(5px);
                .height(40px);
                font-size: 0;
                border: 1px solid #eaedef;
                .icon-img {
                    .width(25px);
                    .height(25px);
                    .margin-left(5px);
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    .iconIMG,
                    .iconSVG {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        display: inline-block;
                        vertical-align: middle;
                        .width(25px);
                        .height(25px);
                    }
                }
                .iconfont {
                    color: #65585a;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
                .contentBox {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: inline-block;
                    vertical-align: middle;
                    overflow: hidden;
                    width: calc(100% - 2rem);
                    height: 100%;
                    .margin-left(5px);
                    .content {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        & > p {
                            .height(17px);
                            .line-height(17px);
                            .font-size(12px);
                        }
                        .fileName {
                            color: #909399;
                            /*  .margin-bottom(8px); */
                        }
                        .fileInfo {
                            color: #909399;
                        }
                    }
                }
                &:last-child {
                    .contentBox {
                        border-bottom: unset !important;
                    }
                }
            }
        }
    }
    .upload_button {
        .height(45px);
        position: fixed;
        bottom: 0;
        left: 0;
        border-top: 1px solid #eaedef;
        background: #fff;
        display: inline-block;
        width: 100%;
        .padding(0px, 16px);
        .upload_file {
            .margin-right(10px);
            color: #008cee;
            cursor: pointer;
            display: inline-block;
            .width(20px);
            .line-height(45px);
            .font-size(20px);
        }
    }
}
</style>

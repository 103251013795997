<template>
    <div class="DocPage">

        <div class="toolBox ">
            <tool-bar class="toolBar van-hairline--bottom" @click01="toggleSelect" @click02="toggleFilter" @click03="toggleSearch" :class="{'searchShow':showSearch}"></tool-bar>
            <type-box class="typeBox" :class="{'active':showFilter}" @change="typeChange"></type-box>
            <search-bar class="searchBar" v-model="searchKey" :class="{'active':showSearch}" :showBtnNow="true" @keyup.enter.native="toSearch" @btnClick="toggleSearch"></search-bar>
        </div>
        <div class="resultBox ">
            <transition name="van-fade">
                <scroll v-if="!isLoading&&list.length>0" class="wrapper" ref="scroll" :pullDownRefresh="pullDownRefreshObj" :pullUpLoad="pullUpLoadObj" :startY="parseInt(startY)" @pullingDown="onPullingDown" @pullingUp="onPullingUp">
                    <ul class="docList" :class="{'isRecycleBin':isRecycleBin}">
                        <van-checkbox-group v-model="checkedItems" @change="checkItemChange">
                            <template v-for="(item,index) in list">
                                <li v-if="item.isFolder" class="docItem" :class="{
                                    'van-hairline--bottom':index!=list.length-1,
                                    'showCheck':showCheck
                                    }" @click="intoFolder(item.folderId,item.folderName)" :key="index">
                                    <div v-show="showCheck" class="checkBox">
                                    </div>
                                    <div class="iconBox">
                                        <i class="iconFONT iconfont " :class="item.folderType==1?'icon-folder-solid':'icon-space-close'"></i>
                                    </div>
                                    <div class="contentBox">
                                        <p class="fileName ellipsis" v-html="item.folderName"></p>
                                        <p class="fileSource ellipsis" v-if="isRecycleBin" v-html="item.showPath"></p>
                                        <p class="fileInfo ellipsis">
                                            {{item.modifyDate||item.createDate}}
                                        </p>
                                    </div>
                                    <div v-show="!showCheck" class="funBox">
                                        <i class="iconfont icon-more" @click.stop="isKRoot?spaceAction(item):folderAction(item)"></i>
                                    </div>
                                </li>
                                <li v-else class="docItem " :class="{'van-hairline--bottom':index!=list.length-1,'showCheck':showCheck}" :key="index">
                                    <div v-show="showCheck" class="checkBox">
                                        <van-checkbox :name="item.fileId">
                                        </van-checkbox>
                                    </div>
                                    <div @click="previewImage(item)" class="iconBox">
                                        <img v-if="isImage(item)" class="iconIMG" :src="item.preViewImageUrl" alt="">
                                        <svg v-else class="iconSVG" aria-hidden="true">
                                            <use :xlink:href="getIcon(item.fileExtName)"></use>
                                        </svg>
                                    </div>
                                    <div class="contentBox">
                                        <p class="fileName ellipsis" @click="previewImage(item)" v-html="`${item.fileName}.${item.fileExtName}`"></p>
                                        <p class="fileSource ellipsis" v-if="isRecycleBin" v-html="item.showPath"></p>
                                        <p class="fileInfo ellipsis">
                                            {{Global.utils.byteCalc(item.fileSize)}}&nbsp;{{item.createRealName}}&nbsp;{{item.modifyDate||item.createDate}}
                                        </p>
                                    </div>
                                    <div v-show="!showCheck" class="funBox">
                                        <i class="iconfont icon-more" @click.stop="isRecycleBin?recycleFileAction(item):fileAction(item)"></i>
                                    </div>
                                </li>
                            </template>
                        </van-checkbox-group>
                    </ul>
                </scroll>
            </transition>
            <transition name="van-fade">
                <nodata v-if="list.length<=0&&!isLoading"></nodata>
            </transition>
            <transition name="van-fade">
                <loading v-if="isLoading" size="30px"></loading>
            </transition>
        </div>
        <fun-bar v-if="showCheck" :checked="checkAll" :isRecycleBin="isRecycleBin" @change="toggleCheckAll" @delClick="delChecked" @moveClick="moveChecked" @copyClick="copyChecked" @cancel="toggleSelect"></fun-bar>
        <upload-data-file ref="uploadData"></upload-data-file>
        <upload-files ref="uploadFile"></upload-files>

    </div>
</template>

<script>
import mixin from '../mixin.js'
import ToolBar from '../ToolBar/index'
import TypeBox from '../TypeBox/index'
import SearchBar from '../SearchBar/index'
import FunBar from '../FunBar/index'
import Scroll from '@/components/Scroll/index'
import UploadDataFile from '@/components/UploadDataFile/index'
import UploadFiles from '@/components/UploadFiles/index'
import { getToken, downloadFile } from '@/libs/utils'

export default {
    name: 'DocPage',
    mixins: [mixin],
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        rootFolderId: {
            type: Number,
            default: 0
        },
        rootFolderName: {
            type: String,
            default: ''
        },
        folderSource: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            pullDownRefresh: true,
            pullDownRefreshThreshold: 60,
            pullDownRefreshStop: 60,
            pullUpLoad: true,
            pullUpLoadThreshold: 0,
            pullUpLoadMoreTxt: this.$t('mx_mobile.Doc.1585194359043'), // '加载更多'
            pullUpLoadNoMoreTxt: this.$t('mx_mobile.Doc.1585194375114'), // '没有更多数据了'
            startY: 0,
            scrollToX: 0,
            scrollToY: -200,
            scrollToTime: 700,
            scrollToEasing: 'bounce',
            scrollToEasingOptions: ['bounce', 'swipe', 'swipeBounce'],

            isLoading: false,
            showCheck: false,
            showFilter: false,
            showSearch: false,
            checkAll: false,
            searchKey: '',
            checkedItems: [],
            list: [],
            docType: 'ALL',
            folderNavs: [{ id: this.rootFolderId, name: this.rootFolderName }],
            page: {
                pageN: 1,
                pageSize: 10,
                total: 0
            },
            popupBoxShow: false
        }
    },
    computed: {
        pullDownRefreshObj: function () {
            return this.pullDownRefresh ? {
                threshold: parseInt(this.pullDownRefreshThreshold),
                stop: parseInt(this.pullDownRefreshStop)
            } : false
        },
        pullUpLoadObj: function () {
            return this.pullUpLoad ? {
                threshold: parseInt(this.pullUpLoadThreshold),
                txt: { more: this.pullUpLoadMoreTxt, noMore: this.pullUpLoadNoMoreTxt }
            } : false
        },
        isKRoot() {
            return this.folderNavs.length == 1 && this.folderSource == 1
        },
        isRecycleBin() {
            return this.folderSource != 1 && this.folderSource != 2
        }

    },
    created() {
    },
    mounted() {
        this.setMenu()
        this.reGetList()
    },
    methods: {
        /* 文件服务器上传测试 */
        uploadTest() {
            let fileUploadUtils = this.$refs.uploadFile.init()
            fileUploadUtils.on('loadend', (res) => {
                console.log(123)
            })
            fileUploadUtils.trigger()
        },

        setMenu() {
            if (this.isRecycleBin) {
                this.Global.utils.rightMenu.clearMenu()
                return false
            }
            let menu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Doc.1585198311261')) // '更多'
            menu.onClick(() => {
                this.isKRoot ? this.moreOptionShow01() : this.moreOptionShow02()
            })
        },

        async previewImage(item) {
            try {
                if (!this.Global.utils.filePreview.isSupport(item.fileExtName)) {
                    return
                }
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_downloadURL
                let { fileId, fileVersion, isOverCid, billId } = item
                let params = {
                    fileId,
                    fileVersion,
                    fileType: 1,
                    fileExtName: item.fileExtName
                }
                if (isOverCid !== null && isOverCid !== undefined) {
                    Object.assign(params, { isOverCid })
                }
                if (billId) {
                    Object.assign(params, { billId })
                }

                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    let _url = ''
                    if (res.data.data[0].inLocalFileSystem == 1) {
                        /*  let protocol = window.location.protocol
                         let host = window.location.host */
                        /*  protocol + '//' + host +  */
                        _url = this.Global.config.apiBaseURL + this.Global.api.v2.doc_preView + res.data.data[0].downloadURL + '?'
                        let data = Object.assign({}, getToken())
                        Object.keys(data).forEach(key => {
                            _url += key + '=' + data[key] + '&'
                        })
                        _url = _url.substring(0, _url.length - 1)
                    } else {
                        _url = res.data.data[0].downloadURL
                    }

                    this.Global.utils.filePreview.open(_url, item.fileExtName, `${item['fileName'] || item['name'] || (new Date().getTime() % 100000)}.${item.fileExtName}`)
                }
            } catch (error) {
                alert(error)
            }
        },
        /* 点击文件夹进入 */
        intoFolder(folderId, folderName) {
            this.folderNavs.push({ id: folderId, name: folderName })
            this.setTitle(folderName)
            this.reGetList()
        },
        setTitle(title) {
            this.setHeaderTitle(title)
        },
        /* 点击更多时弹出选项 */
        async moreOptionShow01() {
            try {
                let options = [{ name: this.$t('mx_mobile.Doc.1585200724130'), index: 0 }] // '新建空间'
                let res = await this.Global.utils.actionSheet.open(options)
                if (!res) { return }
                switch (res.index) {
                    case 0:
                        this.createFolder()
                        break
                    default:
                        break
                }
            } catch (error) {
                console.log(error)
            }
        },
        async moreOptionShow02() {
            try {
                // '上传文件' '新建文件夹'
                let options = [{ name: this.$t('mx_mobile.Doc.1585201650460'), index: 0 }, { name: this.$t('mx_mobile.Doc.1585201680353'), index: 1 }]
                let res = await this.Global.utils.actionSheet.open(options)
                if (!res) { return }
                switch (res.index) {
                    case 0:
                        this.uploadFile()
                        break
                    case 1:
                        this.createFolder()
                        break
                    default:
                        break
                }
            } catch (error) {
                console.log(error)
            }
        },

        async uploadFile() {
            try {
                let option = {
                    url: this.Global.config.apiBaseURL + this.Global.api.v2.folders_files,
                    data: {
                        folderId: this.folderNavs[this.folderNavs.length - 1].id,
                        moduleCode: this.moduleCode,
                        fileSource: this.folderSource,
                        folderPath: '/' + this.folderNavs.reduce((arr, item) => {
                            arr.push(item.id)
                            return arr
                        }, []).join('/')
                    }
                }
                let res = await this.$refs.uploadData.open(option)
                if (res.code.toString() == this.Global.config.RES_OK) {
                    this.$toast.success(this.$t('mx_mobile.Doc.1585201701074')) // '上传成功'
                    this.reGetList()
                } else {
                    this.$toast.fail(res.msg)
                }
            } catch (error) {
                console.log(error)
            }
        },

        /* 新建空间or文件夹 */
        async createFolder() {
            try {
                // '请输入空间名称' '请输入文件夹名称'
                let title = this.isKRoot ? this.$t('mx_mobile.Doc.1585201811863') : this.$t('mx_mobile.Doc.1585201865413')
                let result = await this.Global.utils.prompt.open({ title })

                if (result.value.trim() == '') { return }
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_foldersTree
                let data = {
                    parFolderId: this.folderNavs[this.folderNavs.length - 1].id,
                    folderSource: this.folderSource,
                    folderType: this.isKRoot ? '2' : '1',
                    folderName: result.value,
                    moduleCode: this.moduleCode
                }
                let res = await this.axios.post(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.reGetList()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
        },

        toggleSelect() {
            if (this.isKRoot) {
                return false
            }
            this.showCheck = !this.showCheck
            if (!this.showCheck) {
                this.checkedItems = []
            }
        },

        toggleFilter() {
            this.showFilter = !this.showFilter
        },

        toggleSearch() {
            this.showSearch = !this.showSearch
            if (!this.showSearch && this.searchKey) {
                this.searchKey = ''
                this.reGetList()
            }
        },

        checkItemChange() {
            let canChecks = this.list.reduce((arr, item) => {
                if (!item.isFolder) {
                    arr.push(item.fileId)
                }
                return arr
            }, [])
            if (this.checkedItems.length == canChecks.length && canChecks.length != 0) {
                this.checkAll = true
            } else {
                this.checkAll = false
            }
        },

        toggleCheckAll(flag) {
            flag = !flag
            if (flag) {
                this.checkedItems = this.list.reduce((arr, item) => {
                    if (!item.isFolder) {
                        arr.push(item.fileId)
                    }
                    return arr
                }, [])
            } else {
                this.checkedItems = []
            }
        },

        /* 类型切换 */
        typeChange(type) {
            this.toggleFilter()
            if (type !== this.docType) {
                this.docType = type
                this.reGetList()
            }
        },

        /* 搜索 */
        toSearch() {
            this.reGetList()
        },

        /* 删除选中的文件 */
        async delChecked() {
            if (this.checkedItems.length <= 0) {
                this.$toast.fail(this.$t('mx_mobile.Doc.1585201933697')) // '没有选择任何文件'
            } else {
                if (this.isRecycleBin) {
                    // '注意'  '彻底删除选中的文件？'
                    this.$dialog.confirm({ title: this.$t('mx_mobile.Doc.1585201989171'), message: this.$t('mx_mobile.Doc.1585202017078') })
                        .then(() => {
                            this.realDelete({ fileIds: this.checkedItems })
                        })
                        .catch(() => {
                            console.log('取消了')
                        })
                } else {
                    let flag = await this.deleteFile(this.checkedItems, this.moduleCode)
                    if (flag) {
                        this.reGetList()
                    }
                }
            }
        },
        moveChecked() {
            if (this.checkedItems.length <= 0) {
                this.$toast.fail(this.$t('mx_mobile.Doc.1585201933697')) // '没有选择任何文件'
            } else {
                this.moveFiles(this.checkedItems, 'move')
            }
        },

        copyChecked() {
            if (this.checkedItems.length <= 0) {
                this.$toast.fail(this.$t('mx_mobile.Doc.1585201933697')) // '没有选择任何文件'
            } else {
                this.moveFiles(this.checkedItems, 'copy')
            }
        },

        /* 文件操作按钮 */
        async fileAction(item) {
            try {
                let options = [
                    { name: this.$t('mx_mobile.Doc.1585202555572'), index: 0 }, // '移动'
                    { name: this.$t('mx_mobile.Doc.1585202583833'), index: 1 }, // '复制'
                    { name: this.$t('mx_mobile.Doc.1585202612611'), index: 2 }, // '发邮件'
                    { name: this.$t('mx_mobile.Doc.1585202637212'), index: 3 }, // '重命名'
                    { name: this.$t('mx_mobile.Doc.1585202671131'), index: 4 }, // '下载'
                    { name: this.$t('mx_mobile.App.1596630918224'), index: 5 } // '删除'
                ]
                let res = await this.Global.utils.actionSheet.open(options)
                switch (res.index) {
                    case 0:
                        this.moveFiles([item.fileId], 'move')
                        break
                    case 1:
                        this.moveFiles([item.fileId], 'copy')
                        break
                    case 2:
                        this.goToNewMail({
                            actionType: 'FileMail',
                            fileList: [{ fileId: item.fileId, fileVersion: item.fileVersion }]
                        })
                        break
                    case 3:
                        this.renameFile(item)
                        break
                    case 4:
                        this.previewImage(item)
                        break
                    case 5:
                        let flag = await this.deleteFile([item.fileId], this.moduleCode)
                        if (flag) {
                            this.reGetList()
                        }
                        break
                    default:
                        break
                }
            } catch (error) {
                console.log(error)
            }
        },

        async spaceAction(item) {
            try {
                let options = [
                    { name: this.$t('mx_mobile.Doc.1585198113947'), index: 0 }, // '权限设置'
                    { name: this.$t('mx_mobile.Doc.1585202637212'), index: 1 }, // '重命名'
                    { name: this.$t('mx_mobile.App.1596630918224'), index: 2 } // '删除'
                ]

                let res = await this.Global.utils.actionSheet.open(options)

                switch (res.index) {
                    case 0:
                        this.$router.push({ name: 'PowerList', query: { folderId: item.folderId, ...this.g_setQuery() } })
                        break
                    case 1:
                        this.renameFolder(item)
                        break
                    case 2:
                        let flag = await this.deleteFolder(item.folderId, this.moduleCode, item.folderType)
                        if (flag) {
                            this.reGetList()
                        }
                        break
                    default:
                        break
                }
            } catch (error) {
                console.log(error)
            }
        },

        async folderAction(item) {
            try {
                // '重命名' '删除'
                let options = [{ name: this.$t('mx_mobile.Doc.1585202637212'), index: 0 }, { name: this.$t('mx_mobile.App.1596630918224'), index: 1 }]

                let res = await this.Global.utils.actionSheet.open(options)
                switch (res.index) {
                    case 0:
                        this.renameFolder(item)
                        break
                    case 1:
                        let flag = await this.deleteFolder(item.folderId, this.moduleCode, item.folderType)
                        if (flag) {
                            this.reGetList()
                        }
                        break
                    default:
                        break
                }
            } catch (error) {
                console.log(error)
            }
        },

        async recycleFileAction(item) {
            try {
                // 还原 彻底删除
                let options = [{ name: this.$t('mx_mobile.Doc.1585203458963'), index: 0 }, { name: this.$t('mx_mobile.Doc.1585203482843'), index: 1 }]
                let res = await this.Global.utils.actionSheet.open(options)
                switch (res.index) {
                    case 0:
                        this.recoverFile({ fileIds: [item.fileId] })
                        break
                    case 1:
                        this.realDelete({ fileIds: [item.fileId] })
                        break
                    default:
                        break
                }
            } catch (error) {
                console.log(error)
            }
        },

        /* 文件移动,文件复制 */
        moveFiles(fileIds, type) {
            this.$router.push({ name: 'MoveFile', params: { type, fileIds } })
        },

        /* 重命名文件 */
        async renameFile(item) {
            try {
                let result = await this.Global.utils.prompt.open({ title: this.$t('mx_mobile.Doc.1585203876909') }) // '请输入新的名称'

                if (result.value.trim() == '') { return }
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_files
                let data = {
                    fileId: item.fileId,
                    fileName: result.value
                }
                let res = await this.axios.put(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    item.fileName = result.value
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
        },

        /* 重命名文件夹 */
        async renameFolder(item) {
            try {
                let config = { msg: this.$t('mx_mobile.Doc.1585203876909') } // '请输入新的名称'
                let result = await this.Global.utils.prompt.open(config)

                if (result.value.trim() == '') {
                    return false
                }
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_foldersTree
                let data = {
                    moduleCode: this.moduleCode,
                    folderType: item.folderType,
                    targetFolderId: item.folderId,
                    folderName: result.value
                }
                let res = await this.axios.put(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    item.folderName = result.value
                    console.log(res)
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
        },

        /**
         * 回收站文件彻底删除
         * @param { Object } data
         * @param { Array } [data.fileIds]
         * @param { Array } [data.folderIds]
         */
        async realDelete(data) {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_recycleBinFile
                let res = await this.axios.delete(url, { data })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.reGetList()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                this.$toast.fail(this.$t('mx_mobile.Doc.1585194816901')) // '出错了'
            }
        },

        /**
         * 回收站文件恢复
         * @param { Object } data
         * @param { Array } [data.fileIds]
         * @param { Array } [data.folderIds]
         */
        async recoverFile(data) {
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_recycleBinFile
                let res = await this.axios.put(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    this.reGetList()
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                this.$toast.fail(this.$t('mx_mobile.Doc.1585194816901')) // '出错了'
            }
        },

        async reGetList() {
            this.page.pageN = 1
            this.isLoading = true
            this.checkedItems = []
            this.list = await this.getData()
            this.isLoading = false
        },

        async getData() {
            let listArr = []
            try {
                let url = this.Global.config.apiBaseURL + (this.isRecycleBin
                    ? this.Global.api.v2.folders_recycleBinFile
                    : this.Global.api.v2.folders_files)

                let params = {
                    docType: this.docType,
                    pageN: this.page.pageN,
                    pageSize: this.page.pageSize,
                    params: 'w_80,h_80,m_fill'
                }
                if (!this.isRecycleBin) {
                    Object.assign(params, {
                        folderSource: this.folderSource,
                        folderId: this.folderNavs[this.folderNavs.length - 1].id,
                        moduleCode: this.moduleCode
                    })
                }

                if (this.searchKey) {
                    Object.assign(params, { wildCardWords: this.searchKey })
                }

                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK && this.Global.utils.isObject(res.data.data)) {
                    listArr = res.data.data.list
                    this.page.total = res.data.data.totalNum
                } else {
                    listArr = []
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
                listArr = []
            }
            return listArr
        },
        async downloadFile(fileItem) {
            let { fileId, fileVersion } = fileItem
            let hasPower = await this._hasDownloadPower(fileId)
            if (!hasPower) {
                // 无下载权限
                return
            }
            let downloadURL = this.Global.config.apiBaseURL + this.Global.api.v2.folders_fileDownloadV2

            downloadFile(downloadURL, Object.assign({ fileId, fileVersion }, getToken()), `${fileItem.fileName}.${fileItem.fileExtName}`)
        },
        async _hasDownloadPower(fileId) {
            let flag = false
            try {
                const url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_fileRights
                let params = {
                    fileId,
                    optCode: 'otDownload'
                }
                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    if (res.data.data.hasTheRight == 1) {
                        flag = true
                    } else {
                        this.$toast.fail(this.$t('mx_mobile.Doc.1585204405265')) // '没有权限'
                    }
                } else {
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {
                console.log(error)
            }
            return flag
        },

        async onPullingDown() {
            this.page.pageN = 1
            this.checkedItems = []
            this.list = await this.getData()
            if (this._isDestroyed) {
                return
            }
            this.$nextTick(() => {
                this.$refs.scroll.forceUpdate()
            })
        },

        async onPullingUp() {
            if (this.list.length >= this.page.total) {
                this.$nextTick(() => {
                    this.$refs.scroll.forceUpdate(false)
                })
                return
            }

            if (this.list.length < this.page.total) {
                this.page.pageN += 1
                let list = await this.getData()
                this.list = this.list.concat(list)

                this.$nextTick(() => {
                    if (this.list.length >= this.page.total) {
                        this.$refs.scroll.forceUpdate(false)
                    } else {
                        this.$refs.scroll.forceUpdate(true)
                    }
                })
            }
        },

        rebuildScroll() {
            this.$nextTick(() => {
                this.$refs.scroll.destroy()
                this.$refs.scroll.initScroll()
            })
        },

        /* 路由离开时通过父组件调用 */
        beforeLeave(to, from, next) {
            const flag = this.Global.utils.chosen.close()
            const flag2 = this.Global.utils.actionSheet.close()
            const flag3 = this.Global.utils.prompt.close()
            if (flag || flag2 || flag3) {
                next(false)
            } else {
                let len = this.folderNavs.length
                const RouteWhiteList = ['MoveFile', 'NewMail']
                if (len >= 2 && !RouteWhiteList.includes(to.name)) {
                    this.folderNavs.splice(len - 1, 1)
                    this.setTitle(this.folderNavs[this.folderNavs.length - 1].name)
                    this.reGetList()
                    next(false)
                } else {
                    next()
                }
            }
        }
    },

    watch: {
        pullDownRefreshObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullDown()
                } else {
                    scroll.closePullDown()
                }
            },
            deep: true
        },
        pullUpLoadObj: {
            handler(val) {
                const scroll = this.$refs.scroll.scroll
                if (val) {
                    scroll.openPullUp()
                } else {
                    scroll.closePullUp()
                }
            },
            deep: true
        },
        list() {
            this.checkItemChange()
        },
        startY() {
            this.rebuildScroll()
        }
    },

    components: {
        'tool-bar': ToolBar,
        'type-box': TypeBox,
        'search-bar': SearchBar,
        'fun-bar': FunBar,
        'scroll': Scroll,
        'upload-data-file': UploadDataFile,
        'upload-files': UploadFiles
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

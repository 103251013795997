<template>
    <div class="Controls" @click="openTel(value.value)">
        {{value.value}}
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        value: {
            type: Object,
            default: function () {
                return {
                    value: ''
                }
            }
        }
    },
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    },
    watch: {
    },
    components: {
    }
}
</script>

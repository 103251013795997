<template>
    <div>
        <template>
            <div v-for="(item,index) in itemData.group" :key="index">
                <div v-if="item.fieldName == 'accountPeriod'" class="list" @click="show=(actions.length!=0)">

                    <span class="name">
                        {{item.cnFieldCaption}}
                        <span style="color:#D0021B" v-if="prop(item)">*</span>
                    </span>
                    <span>{{obj1.name}}</span>
                    <!-- 取消 -->
                    <van-action-sheet v-model="show" :actions="actions" @select="onSelect" :cancel-text="$t('mx_mobile.Client.1585301326634')" @cancel="cancel" />
                </div>
                <div v-if="item.fieldName == 'accountperDesc'" class="list" style="border:0;" ref="accountperDescControls">
                    <span class="name">
                        {{item.cnFieldCaption}}
                        <span style="color:#D0021B" v-if="prop(ismust,item)">*</span>
                    </span>
                    <van-field v-model="obj5.value" style="padding-left:0;" clearable :placeholder="itemData.cnFieldHint" :maxlength="itemData.fieldLength" />
                </div>
            </div>
        </template>

    </div>
</template>
<script>
// import selectCountryEvent from '@/components/SelectCountry/event.js'
// import Controls from "../../Controls/index"
export default {
    name: 'BelongTo',
    props: {
        itemData: {// 赋值给控件的数据
            type: Object,
            default: () => {
                return {}
            }
        },
        optCode: {
            type: String,
            default: ''
        },
        cusBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        moduleCode: {
            type: String,
            default: ''
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            show: false,
            actions: [],
            obj1: {
                name: '',
                value: ''
            },
            obj5: {
                text: '',
                value: ''
            },
            ismust: false

        }
    },
    created() {
        this.unit()
    },
    mounted() {
    },
    methods: {
        cancel() {
            this.show = false
        },
        onSelect(item) {
            // 点击选项时默认不会关闭菜单，可以手动关闭
            this.show = false
            this.obj1 = item
            this.ismust = item.dictItemCode == 12
        },
        updates(value) {
            if (value == '') {
                return false
            }
            this.returnItem(value.accountPeriod)
            this.obj5.value = value.accountperDesc
        },
        async choosePerson() {
            this.show = this.actions.length != 0
        },
        returnItem(value) {
            this.actions.forEach(element => {
                if (element.dictItemCode == value) {
                    this.obj1.value = value
                    this.obj1.name = element.name
                }
            })
        },
        submit() {
            let data = {}
            if (this.prop(this.ismust, this.itemData.group[0])) {
                if (this.obj1.value == '') {
                    this.$toast.fail(this.itemData.group[0].cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            if (this.$refs.accountperDescControls) {
                if (this.prop(this.ismust, this.itemData.group[1])) {
                    if (this.obj5.value == '') {
                        this.$toast.fail(this.itemData.group[1].cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                        return false
                    }
                }
            }

            data = {
                accountPeriod: this.obj1.dictItemCode || this.obj1.value || '0',
                accountperDesc: this.obj5.value + '' || ''
            }
            return data
        },
        prop(ismust = false, item) {
            if (ismust) {
                return true
            }
            if (item.disabled) {
                return ''
            }
            if (item.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        unit() {
            let options = []

            this.cusBoxValue.forEach(element => {
                if (element.dictCode == this.itemData.group[0].dictCode) {
                    options = element.customDictitems
                }
            })
            options.forEach(element => {
                element.name = element.itemName
                element.disabled = (element.inUse == '0')
            })
            this.actions = options
        }
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
.list {
    .min-height(45px);
    .line-height(45px);
    border-bottom: 1px solid #eaedef;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    position: relative;
    .listBox {
        .min-height(45px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
}
</style>

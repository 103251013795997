<template>
    <div>
        <div class="Controls" @click="choosePerson()" :class="{'Controls--disabled':itemData.disabled}">
            <span class="name">
                {{itemData.cnFieldCaption}}
                <span style="color:#D0021B" v-if="prop()">*</span>
            </span>
            <span>{{obj.text}}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Controls',
    props: {
        itemData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        },
        type: {
            type: String,
            default: 'otNew'
        }
    },
    data() {
        return {
            show: false,
            actions: [],
            obj: {
                text: '',
                value: ''
            }
        }
    },
    created() {
    },
    mounted() {
        this.unit()
    },
    methods: {
        updates(value) {
            if (value == '') {
                return false
            }
            this.obj.value = value
            this.returnItem(value)
        },
        async choosePerson() {
            if (this.itemData.disabled) return
            try {
                this.obj = await this.Global.utils.chosen.open(this.actions)
            } catch (error) {

            }
        },
        returnItem(value) {
            this.sysBoxValue.forEach(element => {
                if (element.dictCode == this.itemData.dictCode) {
                    element.dictItems.forEach(item => {
                        if (item.dictItemCode == value) {
                            this.obj.text = item.itemName
                        }
                    })
                }
            })
        },
        submit(isNoCheckNull = false) {
            let data = {}
            if (this.prop() && !isNoCheckNull) {
                if (this.obj.value == '') {
                    this.$toast.fail(this.itemData.cnFieldCaption + this.$t('mx_mobile.Client.1585301350281')) // 不能为空
                    return false
                }
            }
            if (this.obj.value != '' && this.obj.value != 0) {
                data[this.itemData.fieldName] = this.obj.value + ''
            } else {
                data[this.itemData.fieldName] = 0
            }
            return data
        },
        prop() {
            if (this.itemData.disabled) {
                return ''
            }
            if (this.itemData.isNotNull == 1) {
                return true
            } else {
                return false
            }
        },
        unit() {
            this.actions = []
            let options = []
            this.sysBoxValue.forEach(element => {
                if (element.dictCode == this.itemData.dictCode) {
                    options = element.dictItems
                }
            })
            options.forEach(element => {
                if (element.inUse != '0') {
                    this.actions.push({
                        text: element.itemName,
                        value: element.dictItemCode
                    })
                }
            })
            if (this.type == 'otNew' && this.itemData.inDefaultValue != '') {
                this.updates(this.itemData.inDefaultValue)
            }
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

<style lang="less" scoped>
.Controls {
    width: 100%;
    .min-height(45px);
    position: relative;
        .padding-left(100px);
    &:lang(en){
        .padding-left(130px);
    }
    .name {
        color: #909399;
        .font-size(14px);
        position: absolute;
        left: 0;
    }
    &--disabled {
        .name {
            color: #eaeaea;
        }
    }
}
</style>

<template>
    <div class="FollowUp__ware" :class="{'del__mask': dynamicData.deleteFlag}">
        <div class="FollowUp__top">
            <div class="contacts_title">
                <span class="top__icon">
                    <color-icon class="top__icon-color" iconName="dynamic-follow" fontSize="20"></color-icon>
                </span>
                <span class="top__name">{{dynamicData.createName}}&nbsp;</span>
                <span class="top__desc">
                    <!-- 新建了一条跟进记录 -->
                    {{$t('mx_mobile.Client.1627018016592')}}
                </span>
                <span class="top__del" v-if="dynamicData.deleteFlag"><span class="top__del-word">
                        <!-- 已删 -->
                        {{$t('mx_mobile.Client.1627018747290')}}
                    </span></span>
                <span class="top__right pull-right">
                    <span class="top__time">{{returnTime(dynamicData.createTime)}}</span>
                    <span class="top__del__icon" @click="handleCommand('del', dynamicData)">
                        <i class="iconfont icon-del"></i>
                    </span>
                </span>
            </div>
            <div class="contacts_ware ellipsis" :title="returnCustInfo">
                <!-- 关联联系人 -->
                {{$t('mx_mobile.Client.1627018108905')}}:&nbsp;
                <span class="contacts_info" v-if="dynamicData.curContactsCard && dynamicData.curContactsCard[0]">{{returnCustInfo}}</span>
            </div>
        </div>
        <div class="FollowUp__body">
            <p class="body__content">{{dynamicData.content}}</p>
            <div class="body__annex" v-if="dynamicData.annexList">
                <!-- 图片 -->
                <controlImg :value="{value: imgList}" size="large"></controlImg>
                <!-- 附件 -->
                <div class="show-img-annex">
                    <div @click="previewImage(item10)" class="iconBox-ware" :class="index10 % 2 == 0 ? '' : 'iconBox-ware-odd'" v-for="(item10, index10) in fileList" :key="index10">
                        <div class="icon-img">
                            <!-- <img v-if="isImage(substringSuffix(item10.name))" class="iconIMG" :src="item10.url" alt="."> -->
                            <svg class="iconSVG" aria-hidden="true">
                                <use :xlink:href="getIcon(substringSuffix(item10.name))"></use>
                            </svg>
                        </div>
                        <div class="contentBox">
                            <div class="content">
                                <p class="fileName ellipsis" v-html="item10.name"></p>
                                <p class="fileInfo ellipsis" :size="Global.utils.byteCalc(item10.size)">
                                    {{converSize(item10.size)}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="body__bottom">
                <!-- 跟进方式 -->
                <span class="body__bottom-type">{{$t('mx_mobile.Client.1627017105160')}}：{{converTrackMode(dynamicData.followType)}}</span>
                <!-- 下次跟进时间 -->
                <span class="body__bottom-time" v-show="dynamicData.nextFollowTime">{{$t('mx_mobile.Client.1585278146281')}}：{{returnTime(dynamicData.nextFollowTime)}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import ColorIcon from '@/components/ColorIcon/iconColor.vue'
import controlImg from '@/page/Client/CustPublic/ControlsShow/Picture/index'
import { formatValidTime, getUUID } from '@/libs/utils.js'
/* 跟进组件 */
export default {
    name: 'FollowUp',
    props: {
        dynamicData: {
            type: Object,
            default: () => ({})
        },
        bindModuleCode: {
            type: String,
            default: ''
        },
        followUpModeList: {
            type: Array,
            default: () => ([])
        }
    },
    components: {
        'controlImg': controlImg,
        'color-icon': ColorIcon
    },
    data() {
        return {
            uploadUrl: this.Global.uploadUrl, // 图片服务器上传URL
            fileUrl: this.Global.downloadBaseUrl // 文件下载url前缀
        }
    },
    computed: {
        imgList() {
            let arr = []
            arr = this.dynamicData.annexList.filter(item => item.name == '').map(v => {
                let newV = { ...v }
                return newV.url
            })
            return [...arr]
        },
        fileList() {
            let arr = []
            arr = this.dynamicData.annexList.filter(item => item.name != '')
            return [...arr]
        },
        returnCustInfo() {
            let info = ''
            if (this.dynamicData.curContactsCard && this.dynamicData.curContactsCard[0]) {
                if (this.dynamicData.curContactsCard[0].contName) {
                    info += this.dynamicData.curContactsCard[0].contName
                }
                if (this.dynamicData.curContactsCard[0].address) {
                    info += '<' + this.dynamicData.curContactsCard[0].address + '>'
                }
            }
            return info
        }
    },
    watch: {},
    created() {
    },
    mounted() { },
    methods: {
        isImage(exe) {
            const imgArr = ['png', 'jpg', 'jpeg', 'gif', 'bmp']
            if (exe) {
                return imgArr.includes(exe.toLowerCase())
            }
        },
        substringSuffix(name) {
            let str = name.substr(name.lastIndexOf('.') + 1)
            return str
        },
        getSize(size) {
            if (size > 1024) {
                return parseFloat(size / 1024).toFixed(2) + ' M'
            } else {
                return parseFloat(size).toFixed(2) + ' KB'
            }
        },
        getIcon(fileExtName) {
            return `#file-${this.Global.utils.isHasSuffix(fileExtName)}`
        },
        // async previewImage(item) {
        //     console.log(item)
        //     try {
        //         let suffix = this.substringSuffix(item.name || '')
        //         if (!this.Global.utils.filePreview.isSupport(suffix)) {
        //             this.$toast('暂不支持该格式文件预览')
        //             return
        //         }
        //         let link = item.url
        //         if (link.indexOf('http') == -1) {
        //             link = this.Global.config.previewUrl + link
        //         }
        //         this.Global.utils.filePreview.open(link, suffix)
        //     } catch (error) {
        //         alert(error)
        //     }
        // },
        async previewImage(item) {
            if (item.name) {
                item['fileExtName'] = this.substringSuffix(item.name)
            }
            item['fileId'] = item.url
            console.log(item)
            try {
                if (!this.Global.utils.filePreview.isSupport(item.fileExtName)) {
                    this.$toast(this.$t('mx_mobile.Client.1585279886218')) // 暂不支持该格式文件预览
                    return
                }
                let url = this.Global.config.apiBaseURL + this.Global.api.v2.folders_downloadURL
                let { fileId, fileVersion, isOverCid, billId } = item
                let params = {
                    fileId,
                    fileVersion
                }
                if (isOverCid !== null && isOverCid !== undefined) {
                    Object.assign(params, { isOverCid })
                }
                if (billId) {
                    Object.assign(params, { billId })
                }

                let res = await this.axios.get(url, { params })
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    let _url = ''
                    if (res.data.data[0].inLocalFileSystem == 1) {
                        /*  let protocol = window.location.protocol
                          let host = window.location.host */
                        /*  protocol + '//' + host +  */
                        _url = this.Global.config.apiBaseURL + this.Global.api.v2.doc_preView + res.data.data[0].downloadURL + '?'
                        let data = Object.assign({}, getToken())
                        Object.keys(data).forEach(key => {
                            _url += key + '=' + data[key] + '&'
                        })
                        _url = _url.substring(0, _url.length - 1)
                    } else {
                        _url = res.data.data[0].downloadURL
                    }

                    this.Global.utils.filePreview.open(_url, item.fileExtName, `${item['fileName'] || item['name'] || (new Date().getTime() % 100000)}.${item.fileExtName}`)
                }
            } catch (error) {
                alert(error)
            }
        },
        getId() {
            return getUUID()
        },
        picUrl(picId, picSize) {
            return this.getGlobalImgSrc(picId, picSize)
        },
        exeDownload(item) {
            let _link = ''
            if (item.url && item.url.indexOf('http' != -1)) {
                _link = item.url
            } else {
                _link = this.fileUrl + item.url
            }
            window.location.href = _link
        },
        transferToDoc(attachmentList = []) {
            this.$refs.docTransfer.open(attachmentList, 'byte')
        },
        returnTime(validTime) {
            return formatValidTime(validTime, 'MM-dd HH:mm')
        },
        // 跟进方式
        converTrackMode(id) {
            let mode
            this.followUpModeList.forEach((item) => {
                if (item.dictItemCode == id) {
                    mode = item.itemName
                }
            })
            return mode
        },
        handleCommand(command, row) {
            this.$dialog.confirm({
                title: this.$t('mx_mobile.Client.1585304004347'), // 提示
                message: this.$t('mx_mobile.Client.1627018362304') // 确定删除这个跟进？
            })
                .then(() => {
                    let data = {
                        sourceId: row.sourceId,
                        moduleCode: this.bindModuleCode,
                        id: row.id
                    }
                    this.axios.post(this.Global.config.apiBaseURL + this.Global.api.dynamic.dynamic_delFollow, data)
                        .then((res) => {
                            if (res.data.code.toString() == this.Global.config.RES_OK) {
                                this.$emit('delDynamic', row.sourceId, 1)
                                this.$toast.success(this.$t('mx_mobile.Client.1627018537680')) // 删除成功
                            } else {
                                this.$toast.fail(res.data.msg)
                            }
                        })
                        .catch((res) => {
                            this.$toast.fail(this.Global.config.errorTitle)
                        })
                })
                .catch(() => {
                    console.log('cancel')
                })
        }
    },
    beforeDestroy() { },
    beforeRouteLeave(to, from, next) {
        next()
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.FollowUp__ware {
    border-bottom: 1px solid #f9f9f9;
    .margin-bottom(10px);
    color: #6b6b6b;
    position: relative;
    &.del__mask {
        color: #bcbcbc !important;
        .top__name,
        .contacts_info {
            color: #bcbcbc !important;
        }
    }
    &.del__mask::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        background: rgba(249, 249, 249, 0.1);
        position: absolute;
        top: 0;
        left: 0;
    }
    .FollowUp__top {
        font-size: 0;
        .top__icon {
            display: inline-block;
            .height(20px);
            .width(20px);
            .margin-right(5px);
            position: relative;
            .top__icon-color {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .top__name {
            display: inline-block;
            .height(20px);
            .font-size(14px);
            color: #222;
            font-weight: bold;
            display: inline-block;
            vertical-align: top;
        }
        .top__desc {
            display: inline-block;
            .height(20px);
            .font-size(14px);
            vertical-align: top;
            .top__desc--name {
                display: inline-block;
                position: relative;
                color: #008cee;
                cursor: pointer;
            }
        }
        .top__del {
            display: inline-block;
            .height(20px);
            vertical-align: top;
            position: relative;
            .top__del-word {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                .width(35px);
                text-align: center;
                display: inline-block;
                color: #fff !important;
                .font-size(12px);
                .margin-left(5px);
                background: #d0021b;
                .height(20px);
                .line-height(20px);
                .border-radius(10px);
                .padding(0px, 5px);
                z-index: 1;
            }
        }
        .top__right {
            display: inline-block;
            .height(20px);
            .top__time {
                display: inline-block;
                .height(20px);
                .line-height(20px);
                .font-size(14px);
            }
            .top__del__icon {
                display: inline-block;
                .height(20px);
                .line-height(20px);
                .margin-left(10px);
                cursor: pointer;
            }
        }
        .contacts_title {
        }
        .contacts_ware {
            .font-size(14px);
            .contacts_info {
                color: #008cee;
                font-weight: 600;
            }
        }
    }
    .FollowUp__body {
        .padding-bottom(5px);
        .body__content {
            display: inline-block;
            white-space: pre-wrap;
            word-break: break-word;
            .padding-bottom(5px);
        }
        .body__annex {
            .font-size(12px);
            .padding(5px, 0px);
            .show-img-annex {
                font-size: 0;
                .iconBox-ware {
                    position: relative;
                    display: inline-block;
                    width: calc(50% - 5px);
                    .margin-right(10px);
                    .margin-bottom(5px);
                    .height(40px);
                    font-size: 0;
                    border: 1px solid #eaedef;
                    &.iconBox-ware-odd {
                        margin-right: 0;
                    }
                    .icon-img {
                        .width(25px);
                        .height(25px);
                        .margin-left(5px);
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        .iconIMG,
                        .iconSVG {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            display: inline-block;
                            vertical-align: middle;
                            .width(25px);
                            .height(25px);
                        }
                    }
                    .iconfont {
                        color: #d0021b;
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                    }
                    .contentBox {
                        position: absolute;
                        right: 0;
                        top: 0;
                        display: inline-block;
                        vertical-align: middle;
                        overflow: hidden;
                        width: calc(100% - 2rem);
                        height: 100%;
                        .margin-left(5px);
                        .content {
                            width: 100%;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            & > p {
                                .height(17px);
                                .line-height(17px);
                                .font-size(12px);
                            }
                            .fileName {
                                color: #909399;
                                /*  .margin-bottom(8px); */
                            }
                            .fileInfo {
                                color: #909399;
                            }
                        }
                    }
                    &:last-child {
                        .contentBox {
                            border-bottom: unset !important;
                        }
                    }
                }
            }
        }
        .body__bottom {
            .body__bottom-type {
                .margin-right(10px);
            }
        }
    }
}
</style>

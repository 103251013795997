import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import ECB from 'crypto-js/mode-ecb'
import Pkcs7 from 'crypto-js/pad-pkcs7'
/**
 * 加密
 */
export const encrypt = data => {
    var ciphertext = AES.encrypt(data, 'laifuyun.com')
    return ciphertext.toString()
}

/**
 * 解密
 */
export const decrypt = data => {
    var bytes = AES.decrypt(data, 'laifuyun.com')
    var plaintext = bytes.toString(Utf8)
    return plaintext
}

/**
 * 新加密
 */
export const goodsEncrypt = data => {
    var key = Utf8.parse('fumasoftsoftfuma')
    var srcs = Utf8.parse(data)
    var encrypted = AES.encrypt(srcs, key, {
        mode: ECB,
        padding: Pkcs7
    })
    return encrypted.toString().replace(/\+/g, '%2B')
}

/**
 * 新解密
 */
export const goodsDecrypt = data => {
    var key = Utf8.parse('fumasoftsoftfuma')
    let value = data.replace(new RegExp('%2B', 'g'), '+')
    value = value.replace(new RegExp(' ', 'g'), '+')
    var decrypt = AES.decrypt(value, key, {
        mode: ECB,
        padding: Pkcs7
    })
    return Utf8.stringify(decrypt).toString()
}

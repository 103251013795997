/*
[{
  "cnFieldCaption": "跟进方式",
  "cnFieldHint": "",
  "ControlsShowTypeId": 2,//决定使用哪个控件
  "dictCode": 12,
  "fieldCategory": 2,
  "fieldGroup": 0,
  "fieldLength": 3,
  "fieldName": "trackMode",
  "inDefaultValue": "",
  "isNotNull": 0,
  "strucId": 1,
  "uniqueCheck": 0
}]
*/
import Text from './Text/index.vue'
import SystemDownBox from './SystemDownBox/index.vue'
import CustomDownBox from './CustomDownBox/index.vue'
import MultilineText from './MultilineText/index.vue'
import Time from './Time/index.vue'
import Integer from './Integer/index.vue'
import Decimal from './Decimal/index.vue'
import AmountOfMoney from './AmountOfMoney/index.vue'
import PurchasingUnitPric from './PurchasingUnitPric/index.vue'
import AutoNumber from './AutoNumber/index.vue'
import MobilePhone from './MobilePhone/index.vue'
import Mailbox from './Mailbox/index.vue'
import Block from './Block/index.vue'
import Picture from './Picture/index.vue'
import Attachment from './Attachment/index.vue'
import Customer from './Customer/index.vue'
import PersonnelSelection from './PersonnelSelection/index.vue'
import SelectedPersonnel from './SelectedPersonnel/index.vue'
import SectorSelection from './SectorSelection/index.vue'
import MultipleDepartments from './MultipleDepartments/index.vue'
import Radio from './Radio/index.vue'
import SelectDropDown from './SelectDropDown/index.vue'
import Owner from './Owner/index.vue'
import Currency from './Currency/index.vue'
import ExchangeRate from './ExchangeRate/index.vue'
import NetWeight from './NetWeight/index.vue'
import GrossWeight from './GrossWeight/index.vue'
import Volume from './Volume/index.vue'
import Supplier from './Supplier/index.vue'
import Contacts from './Contacts/index.vue'
import Country from './Country/index.vue'
import Province from './Province/index.vue'
import City from './City/index.vue'
import Town from './Town/index.vue'
import SubordinateDepartme from './SubordinateDepartme/index.vue'
import ChooseCustom from './ChooseCustom/index.vue'
import Sex from './Sex/index.vue'
import Web from './Web/index.vue'
import SocialContact from './SocialContact/index.vue'
import customStr2 from './CustomStr2/index.vue'
import Score from './Score/index.vue'
import Intimacy from './Intimacy/index.vue'
import Port from './Port/index.vue'
import AONIcustSort from './AONIcustSort/index.vue'
// import region from './region/index.vue'
export default {
  'ControlsShow0': Text,
  'ControlsShow1': Text,
  'ControlsShow2': SystemDownBox,
  'ControlsShow3': CustomDownBox,
  'ControlsShow4': MultilineText,
  'ControlsShow5': Time,
  'ControlsShow6': Integer,
  'ControlsShow7': Decimal,
  'ControlsShow8': AmountOfMoney,
  'ControlsShow9': Decimal,
  'ControlsShow10': PurchasingUnitPric,
  'ControlsShow11': AutoNumber,
  'ControlsShow12': MobilePhone,
  'ControlsShow13': Mailbox,
  'ControlsShow14': Block,
  'ControlsShow15': Picture,
  'ControlsShow16': Attachment,
  'ControlsShow17': Customer,
  'ControlsShow18': Radio,
  'ControlsShow19': SelectDropDown,
  'ControlsShow20': PersonnelSelection,
  'ControlsShow21': SelectedPersonnel,
  'ControlsShow22': SectorSelection,
  'ControlsShow23': MultipleDepartments,
  'ControlsShow24': Owner,
  'ControlsShow25': Currency,
  'ControlsShow26': ExchangeRate,
  'ControlsShow27': NetWeight,
  'ControlsShow28': GrossWeight,
  'ControlsShow29': Volume,
  'ControlsShow30': Supplier,
  'ControlsShow31': Contacts,
  'ControlsShow32': Country,
  'ControlsShow33': Province,
  'ControlsShow34': City,
  'ControlsShow35': Town,
  'ControlsShow36': SubordinateDepartme,
  'ControlsShow37': ChooseCustom,
  'ControlsShow38': SystemDownBox,
  'ControlsShow39': Sex,
  'ControlsShow40': Text,
  'ControlsShow41': SocialContact,
  'ControlsShow42': MobilePhone,
  'ControlsShow43': Web,
  'ControlsShow46': customStr2,
  'ControlsShow47': Score,
  'ControlsShow56': Port,
  'ControlsShow71': Intimacy,
  'ControlsShow84': AutoNumber,
  'ControlsShow89': Decimal,
  'ControlsShow111': AONIcustSort,
  'ControlsShow113': Text,
  'ControlsShow114': Text,
  'ControlsShow193': Text

}

<template>
    <!-- 添加共享 -->
    <van-popup v-model="show" position="right" class="screenSearchPop">
        <div class="addPeople">
            <div class="boxList">
                <div class="titleBox">
                    <!-- 协作人 -->
                    <div class="title">{{$t('mx_mobile.Client.1585629363637')}}</div>
                    <span class="label ellipsis" v-for="(item, index) in personnelTable" :key="index" :class="item.check?'label1':'label2'" @click="item.check=!item.check">{{item.realName}}</span>
                </div>
            </div>
            <div class="boxList" v-for="(item, index) in setList" :key="index">
                <div class="titleBox" v-for="(items, indexs) in item.list" :key="indexs">
                    <div class="title">{{items.moduleName}}</div>
                    <div class="label ellipsis" v-for="(element, index1) in items.list" :key="index1" :class="element.check?'label1':'label2'" @click="element.check=!element.check">
                        {{element.funName}}
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="dialogFooter">
            <van-button class="butSave" size="large" :loading="submitLoading" @click="submitAddFrom(true)">保存</van-button>
        </div> -->
        <DatePickerTime ref="datePickerTime"></DatePickerTime>
    </van-popup>
</template>
<script>
/**
 * 描述：客户管理-客户列表-新增客户
 * 作者：何俊峰
 * 时间：2017/11/21
 */
export default {
    name: 'view1',
    props: {
        moduleCode: {
            type: String,
            default: ''
        },
        optCode: {
            type: String,
            default: ''
        },
        item: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    data() {
        return {
            submitLoading: false,
            show: false,
            list: [],
            personRight: [],
            personnelTable: [],
            setList: []
        }
    },
    created() {
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        setMenu() {
            setTimeout(() => {
                let setMenu = this.Global.utils.rightMenu.setMenu(this.$t('mx_mobile.Client.1585301134763'))// 保存
                setMenu.onClick(() => {
                    this.submitAddFrom()
                })
            }, 100)
        },
        closeThis() {
            this.show = false
            this.$emit('setMenu')
            this.$emit('setTitle')
        },
        openWindow() {
            this.show = true
            this.setHeaderTitle(this.$t('mx_mobile.Client.1585629421739')) // 添加共享
            this.setMenu()
            this.getData()
        },
        switch(list) {
            let _this = this
            let newList = [
                {
                    // 客户资料
                    title: this.$t('mx_mobile.Client.1585629424636'),
                    list: []
                }, {
                    // 客户相关资料
                    title: this.$t('mx_mobile.Client.1585629425131'),
                    list: []
                }
            ]
            list.forEach(function (items) {
                let isHave = false
                items.check = false
                newList.forEach(function (lists) {
                    lists.list.forEach(function (item) {
                        if (items.moduleCode == item.moduleCode) {
                            if (_this.isHave(items.funCode)) {
                                item.value.push(items.funCode)
                            }
                            item.list.push(items)
                            isHave = true
                        };
                    })
                })
                if (!isHave) {
                    let value = []
                    if (_this.isHave(items.funCode)) {
                        value.push(items.funCode)
                    };
                    let data = {
                        moduleCode: items.moduleCode,
                        moduleName: items.moduleName,
                        value: value,
                        value1: '0',
                        list: [items]
                    }
                    if (items.moduleCode == 'BF001') {
                        newList[0].list.push(data)
                    } else {
                        newList[1].list.push(data)
                    }
                }
            })
            return newList
        },
        isHave(code) {
            let isHave = false
            this.personRight.forEach(function (item) {
                item.rights.split(',').forEach(function (items) {
                    if (items == code) {
                        isHave = true
                    }
                })
            })
            return isHave
        },
        getData() {
            let _this = this
            var p1 = new Promise((resolve, reject) => {
                let contactData = {
                    dataType: 'contact',
                    funType: 'withRight',
                    moduleCode: 'BF001',
                    optCode: 'otAddShare'
                }
                _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.accountDropList_get, { params: contactData }).then(function (res) {
                    if (res.data.code.toString() == _this.Global.config.RES_OK) {
                        let personnelTable = res.data.data
                        let list = []
                        personnelTable.forEach(element => {
                            if (element.inUse == '1') {
                                element.check = false
                                list.push(element)
                            }
                        })
                        _this.personnelTable = list
                    } else {
                        _this.personnelTable = []
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
                resolve([])
            })
            var p2 = new Promise((resolve, reject) => {
                _this.axios.get(_this.Global.config.apiBaseURL + this.Global.api.v2.customerCooperate_do, {
                    params: {
                        type: 'operatePersonRight'
                    }
                }).then(function (res) {
                    if (res.data.code.toString() == _this.Global.config.RES_OK) {
                        resolve(res.data.data)
                    } else {
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })
            var p3 = new Promise((resolve, reject) => {
                _this.axios.get(_this.Global.config.apiBaseURL + _this.Global.api.v2.permissions_get, { params: { tabCode: 'T04', type: 'item' } }).then(function (res) {
                    if (res.data.code.toString() == _this.Global.config.RES_OK) {
                        resolve(res.data.data)
                    } else {
                        resolve([])
                        _this.$toast.fail(res.data.msg)
                    }
                }, function (res) {
                    _this.$toast.fail(_this.Global.config.errorTitle)
                })
            })
            Promise.all([p1, p2, p3]).then(function (results) {
                _this.personRight = results[1]
                _this.setList = _this.switch(results[2])
                console.log(_this.setList)
            })
        },
        submitAddFrom() {
            let _this = this
            let rights = []
            this.setList.forEach(function (items) {
                items.list.forEach(function (item) {
                    if (item.value1 == '0') {
                        item.list.forEach(function (list) {
                            rights.push(list.funCode)
                        })
                    } else {
                        rights = rights.concat(item.value)
                    }
                })
            })
            rights = rights.toString()
            let select = []
            this.personnelTable.forEach(element => {
                if (element.check) {
                    select.push(element.ctId)
                }
            })
            if (select.length == 0) {
                _this.$toast(this.$t('mx_mobile.Client.1585629490701')) // 请选择人员
                return false
            }
            select = select.join(',')
            let data = {}
            let url = ''
            data = {
                optModuleCode: this.moduleCode,
                rights: rights,
                identFieldValue: this.item.custId,
                optCode: this.optCode,
                toCtIds: select
            }
            url = this.Global.api.v2.document_operate_detailOpt_put
            _this.$toast.loading({
                mask: true,
                duration: 0, // 持续展示 toast
                forbidClick: true, // 禁用背景点击
                loadingType: 'spinner',
                message: this.$t('mx_mobile.Client.1585300903213') // 保存中...
            })
            this.axios.put(_this.Global.config.apiBaseURL + url, data).then(function (res) {
                _this.$toast.clear()
                if (res.data.code.toString() == _this.Global.config.RES_OK) {
                    _this.submitLoading = false
                    _this.closeThis()
                    _this.$emit('getListData')
                    _this.$toast.success(res.data.msg)
                } else {
                    _this.submitLoading = false
                    _this.$toast.fail(res.data.msg)
                }
            }, function (res) {
                _this.$toast.clear()
                _this.$toast.fail(_this.Global.config.errorTitle)
            })
        }
    },
    watch: {
    },
    components: {
    }
}
</script>

<style lang="less" rel="stylesheet/less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>

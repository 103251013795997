<template>
    <div class="funcMark2">
        <!-- 动态筛选组件 -->
        <div class="dynamic__topInfo">
            <dynamic-filter ref="dynamicFilter" class="dynamic__topInfo--filter" :personnelTable="personnelTable" :detailData="detailData" @getFilterParams="getFilterParams"></dynamic-filter>
            <!-- <span class="tab-list clearfix pull-right">
                            <div v-for="(item, index) in iconList" @click="clickTabIcon(item)" :class="iconActive == item ? 'active': ''" :key="index"><i class="iconfont" :class="item"></i></div>
                        </span>
                        <span class="dynamic__topInfo--total pull-right">{{allTotal + '条'}}</span> -->
        </div>
        <div class="box">
            <loading-new :loading="isLoadingData"></loading-new>
            <template v-if="!isLoadingData">
                <nodata v-if="dynamicList.length == 0" :title="title" img="noFollow"></nodata>
                <template v-if="dynamicList.length > 0">
                    <van-collapse class="dynamic__collapse" v-model="activeTime" accordion @change="changeActive">
                        <van-collapse-item v-for="(group, index) in dynamicGroup" :key="index" :title="returnTitle(group.time, group.count)" :name="group.time" :border="false">
                            <template v-for="(item, n) in dynamicList">
                                <component :key="n" v-bind:is="dynamicType[item.type]" :dynamicData="item" :bindModuleCode="bindModuleCode" :followUpModeList="formData.followUpMode.list" :mainObj="detailData" @delDynamic="delDynamic"></component>
                            </template>
                            <div class="view__more" v-if="isShowPageCtrl">
                                <!-- 查看更多 -->
                                <span class="view__more-desc" @click="handlePage">{{$t('mx_mobile.Client.1627017309696')}}</span>
                            </div>
                        </van-collapse-item>
                    </van-collapse>
                </template>
            </template>
        </div>
    </div>
</template>
<script>
import controlImg from '@/page/Client/CustPublic/ControlsShow/Picture/index'
import loadingNew from '../../../../CustPublic/LoadingVue/index.vue'
import DynamicFilter from './DynamicFilter/index.vue'
import componentList from './list/index.js'
import { addZero, isArray } from '@/libs/utils.js'
export default {
    name: 'funcMark2V2',
    props: {
        detailData: {
            type: Object,
            default: function () {
                return {}
            }
        },
        personnelTable: {
            type: Array,
            default: function () {
                return []
            }
        },
        sysBoxValue: {
            type: Array,
            default: () => ([])
        },
        bindModuleCode: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            trackStageOptions: [], // 跟进阶段
            formData: {
                followUpMode: {
                    value: '',
                    list: [] // 跟进方式
                },
                time: [] // 操作时间
            },
            list: [],
            title: this.$t('mx_mobile.Client.1585278213025'), // 暂无跟进
            sort: 'createDate',
            isLoadingData: false,
            queryParam: {
                pageNum: 0, // [pageNum*pageSize + 1, pageNum*(pageSize + 1)]
                pageSize: 10,
                customerId: '',
                type: '',
                relationId: '',
                ownerId: '',
                year: '', // 年月日都传，year和month字段是为了区分年和月
                month: '' // 年月日都传，year和month字段是为了区分年和月
            },
            dynamicType: {
                // 类型 1.跟进 2.联系人 3.邮件 4.营销AM 5.报价 6.合同
                1: 'follow-up',
                2: 'contacts',
                3: 'mail',
                4: 'market-am',
                5: 'show-price',
                6: 'contract'
            },
            dynamicList: [], // 动态列表: 最近的，以前的
            dynamicGroup: [], // 动态分组
            filterParams: {}, // 筛选条件
            activeTime: '', // 折叠组的时间（月/年） 默认最近'0' -> 显示本月(废弃最近)
            curViewTotal: 0 // 当前展开面板的数据总数
        }
    },
    created() {
        // 过滤找出跟进方式下拉
        this.sysBoxValue.forEach((item) => {
            if (item.dictCode == 12) {
                this.formData.followUpMode.list = item.dictItems
            }
            if (item.dictCode == 10) {
                this.trackStageOptions = item.dictItems
            }
        })
        this.getData()
    },
    computed: {
        isShowPageCtrl() {
            // 显示页
            return this.curViewTotal > this.queryParam.pageSize && this.curViewTotal > this.dynamicList.length
        }
    },
    mounted() {
    },
    methods: {
        changeActive() {
            setTimeout(() => {
                this.$nextTick(() => {
                    if (this.activeTime) {
                        this.initTimeParams()
                        this.queryParam.pageNum = 0
                        this.dynamicList = [] // 切换分组
                        this.getDynamicList()
                    }
                })
            }, 50)
        },
        handlePage() {
            // 下一页
            this.queryParam.pageNum++
            this.getDynamicList('more')
        },
        getFilterParams(params) {
            this.dynamicList = [] // 切换分组
            this.queryParam.pageNum = 0
            this.filterParams = { ...params }
            this.initDynamic()
        },
        async delDynamic(id, type) {
            // sourceId
            if (type === 1) {
                // 跟进 - 直接删除
                this.allTotal = await this.getDynamicTotal() // 动态列表总记录数查询
                this.dynamicGroup = await this.getDynamicGroup() // 默认布局动态分组展示
                this.dynamicList.splice(this.dynamicList.findIndex(v => v.sourceId === id), 1)
            } else {
                // 置灰
            }
        },
        returnEffectiveOfObject(obj) {
            // 只过滤掉空字符串
            try {
                let tempObj = {}
                for (const key in obj) {
                    if (obj[key] === 0 || obj[key] != '') {
                        tempObj[key] = obj[key]
                    }
                }
                return tempObj
            } catch (err) {
                console.log(err)
                return obj
            }
        },
        returnTitle(time, value) {
            time = parseInt(time)
            if (time == 0) {
                return `${this.$t('mx_mobile.Client.1630733472669')}` + '(' + value + ')' // 最近
            } else if (time == new Date().getMonth() + 1) {
                return `${this.$t('mx_mobile.workbench.1584939196431')}` + '(' + value + ')' // 本月
            } else if (time <= 12 && time >= 1) {
                return time + '月' + '(' + value + ')'
            } else {
                return time + '年' + '(' + value + ')'
            }
        },
        async initDynamic() {
            // params ： 筛选条件
            this.$refs.dynamicFilter && this.$refs.dynamicFilter.getDynamicType() // 更新动态数据
            this.allTotal = await this.getDynamicTotal() // 动态列表总记录数查询
            this.dynamicGroup = await this.getDynamicGroup() // 默认布局动态分组展示
            if (this.dynamicGroup.length > 0) {
                this.activeTime = this.dynamicGroup[0].time
            }
            this.initTimeParams()
            this.getDynamicList() // 获取动态列表
        },
        // 动态列表
        async getDynamicList(type) {
            if (type != 'more') {
                this.isLoadingData = true
            }
            let params = this.returnEffectiveOfObject({
                ...this.queryParam,
                customerId: this.detailData.custId,
                ...this.filterParams,
                serviceId: this.detailData.custId,
                serviceType: 1
            })
            params.displayType = 1 // 1.分组视图 2.全部
            await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.dynamic.dynamic_findPage, { params: params })
                .then(res => {
                    if (res.data.code.toString() == this.Global.config.RES_OK) {
                        let tempList = res.data.data.content || []
                        // detailData.custContacts // 客户所有联系人
                        let mergeArr = this.mergeData(tempList) // 拆分重组数据
                        this.dynamicList.push(...mergeArr)
                        this.curViewTotal = res.data.data.totalElements || 0
                    } else {
                        this.$toast.fail(res.data.msg)
                    }
                    this.isLoadingData = false
                })
                .catch(res => {
                    console.log(res)
                    this.isLoadingData = false
                })
        },
        mergeData(tempList) {
            let mergeArr = []
            tempList.forEach((item) => {
                // 类型 1.跟进 2.联系人 3.邮件 4.营销AM 5.报价 6.合同
                if (item.annexList) {
                    item.annexList = item.annexList.map(item => {
                        let _url = ''
                        item.url = item.url || _url
                        item.size = item.size || ''
                        item.name = item.name || ''
                        return item
                    })
                }
                if (item.type === 3 || item.type === 4) {
                    // mailFlag  1: 发件 2: 收件
                    let newItem = {}
                    // 千里眼功能参数 DetailData
                    let eyeParam
                    if (item.sourceId && item.enableTrack) {
                        eyeParam = {
                            mId: item.sourceId,
                            enableTrack: item.enableTrack,
                            readFlag: item.readFlag
                        }
                    } else {
                        eyeParam = null
                    }
                    item['DetailData'] = eyeParam
                    if (isArray(item.receiveEntityList)) {
                        // flag: 1 // 表示该邮件接收人是当前客户联系人
                        if (item.mailFlag === 1) {
                            let _receiveEntityList = item.receiveEntityList.filter(v => v.flag === 1) // 过滤当前客户联系人
                            // let _receiveEntityList = item.receiveEntityList
                            // 拆分邮件（普通邮件/营销邮件） receiveEntityList
                            if (_receiveEntityList.length > 0) {
                                _receiveEntityList.forEach((obj) => {
                                    let _obj = {
                                        address: obj.receiveMail || '',
                                        contId: obj.receiveId || '',
                                        contName: obj.receiveName || '',
                                        custId: this.detailData.custId || '',
                                        custName: this.detailData.custName || '',
                                        category: 1
                                        // group: ''
                                        // personal: '',
                                        // type: ''
                                    }
                                    newItem = JSON.parse(JSON.stringify(item))
                                    newItem['curContactsCard'] = [_obj] // 客户联系人卡片参数
                                })
                            } else {
                                newItem = JSON.parse(JSON.stringify(item))
                            }
                            mergeArr.push({ ...newItem })
                        } else if (item.mailFlag === 2) {
                            // 组合客户联系人（回复人）卡片参数
                            let _obj = {
                                address: item.sendMail || '',
                                contId: item.relationId || '',
                                contName: item.relationName || '',
                                custId: this.detailData.custId || '',
                                custName: this.detailData.custName || '',
                                category: 1
                            }
                            newItem = JSON.parse(JSON.stringify(item))
                            newItem['curContactsCard'] = [_obj]
                            // 回复人只有一个，接收人可能存在多个，根据接收人拆分
                            let _receiveEntityList = item.receiveEntityList
                            _receiveEntityList.forEach((obj) => {
                                newItem['receiveName'] = obj.receiveName || obj.receiveMail.split('@')[0] // 接收人
                                mergeArr.push({ ...newItem })
                            })
                        }
                    } else {
                        mergeArr.push(JSON.parse(JSON.stringify(item)))
                    }
                } else if (item.type === 1 || item.type === 2) {
                    // 跟进,联系人 - 卡片参数重组
                    let _address = ''
                    if (item.relationMails) {
                        _address = item.relationMails[0]
                    }
                    item['curContactsCard'] = [
                        {
                            address: _address,
                            contId: item.relationId,
                            contName: item.relationName,
                            custId: this.detailData.custId,
                            custName: this.detailData.custName,
                            category: 1
                        }
                    ]
                    mergeArr.push(JSON.parse(JSON.stringify(item)))
                } else {
                    mergeArr.push(JSON.parse(JSON.stringify(item)))
                }
            })
            return mergeArr
        },
        initTimeParams() {
            if (this.activeTime) {
                let _activeTime = parseInt(this.activeTime)
                if (_activeTime <= 12 && _activeTime >= 1) {
                    // 自然月
                    this.queryParam.month = new Date().getFullYear() + '-' + addZero(_activeTime) + '-01'
                    this.queryParam.year = ''
                } else {
                    // 年
                    this.queryParam.month = ''
                    this.queryParam.year = _activeTime + '-01-01'
                }
                if (_activeTime == 0) {
                    // 最近 - 不传时间
                    this.queryParam.month = ''
                    this.queryParam.year = ''
                }
            }
        },
        async getDynamicTotal() {
            try {
                let params = this.returnEffectiveOfObject({
                    customerId: this.detailData.custId,
                    ...this.filterParams,
                    serviceId: this.detailData.custId,
                    serviceType: 1
                })
                let resTotal = 0
                let res = await this.axios.get(this.Global.config.apiBaseURL + this.Global.api.dynamic.dynamic_total, { params: params })
                if (res.data.code.toString() === this.Global.config.RES_OK) {
                    resTotal = res.data.data || 0
                } else {
                    this.$toast.fail(res.data.msg)
                }
                return resTotal
            } catch (err) {
                console.log(err)
            }
        },
        async getDynamicGroup() {
            try {
                let params = this.returnEffectiveOfObject({
                    customerId: this.detailData.custId,
                    ...this.filterParams,
                    serviceId: this.detailData.custId,
                    serviceType: 1
                })
                let groupRes = []
                const monthUrl = this.Global.config.apiBaseURL + this.Global.api.dynamic.dynamic_monthStatistics
                const yearUrl = this.Global.config.apiBaseURL + this.Global.api.dynamic.dynamic_yearStatistics
                let monthRes = await this.axios.get(monthUrl, { params: params })
                let yearRes = await this.axios.get(yearUrl, { params: params })
                let dataMonth = {}
                let dataYear = {}
                if (monthRes.data.code.toString() == this.Global.config.RES_OK) {
                    dataMonth = monthRes.data.data || {}
                }
                if (yearRes.data.code.toString() == this.Global.config.RES_OK) {
                    dataYear = yearRes.data.data || {}
                }
                groupRes = [
                    ...this.makeArr(dataMonth).sort(this.compareFn('time')),
                    ...this.makeArr(dataYear).sort(this.compareFn('time'))
                ]
                return groupRes
            } catch (err) {
                console.log(err)
            }
        },
        compareFn(prop) {
            return function (obj1, obj2) {
                var val1 = obj1[prop]
                var val2 = obj2[prop]
                return val2 - val1 // 降序
            }
        },
        makeArr(object) {
            try {
                let tempArr = []
                for (const key in object) {
                    let tempObj = {}
                    if (object.hasOwnProperty(key) && key != new Date().getFullYear()) {
                        if (object[key] != 0) {
                            // 过滤空数据
                            tempObj['time'] = key
                            tempObj['count'] = object[key]
                            tempArr.push(tempObj)
                        }
                    }
                }
                return tempArr
            } catch (err) {
                console.log(err)
                return []
            }
        },
        returnRealName(ctId) {
            let realName = ''
            this.personnelTable.forEach(element => {
                if (element.ctId == ctId) {
                    realName = element.realName
                }
            })
            return realName
        },
        returnSysBoxValue(dictCode, dictItemCode) {
            let itemName = ''
            if (!dictItemCode) {
                return ''
            }
            this.sysBoxValue.forEach(element => {
                if (element.dictCode == dictCode) {
                    element.dictItems.forEach(items => {
                        if (items.dictItemCode == dictItemCode) {
                            itemName = items.itemName
                        }
                    })
                }
            })
            return itemName
        },
        returnTime(date) {
            if (date && date != 0) {
                return this.$m_utcOffset(date).format('MM-DD HH:mm:ss')
            } else {
                return ''
            }
        },
        returnContName(contId) {
            let contName = ''
            this.detailData.custContacts.forEach(element => {
                if (element.contId == contId) {
                    contName = element.contName
                }
            })
            return contName
        },
        getData() {
            this.$refs.dynamicFilter && this.$refs.dynamicFilter.initData()
            // 点击tab切入【动态】时候调用
            this.dynamicList = [] // 切换分组
            this.queryParam.pageNum = 0
            this.initDynamic()
        },
        getIcon(trackMode) {
            switch (trackMode) {
                case '1':
                    return 'icon-telephone'
                case '2':
                    return 'icon-mail'
                case '3':
                    return 'icon-one-to-one'
                case '4':
                    return 'icon-customer'
                case '5':
                    return 'icon-mail-a'
                case '6':
                    return 'icon-print'
                case '':
                    return 'icon-go'
            }
        }
    },
    watch: {
    },
    components: {
        controlImg: controlImg,
        'loading-new': loadingNew,
        'dynamic-filter': DynamicFilter,
        ...componentList
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
